import React, { useCallback, useState, useEffect, useMemo } from "react";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { useDropzone } from "react-dropzone";
import uploadIcon from "../../../assets/svg/uploadfile.svg";
import CancelIcon from "../../../assets/svg/close.svg";
import SelectField from "../../../components/SelectField";
import PlusIcon from "../../../assets/PlusIcon";
import { assignedCategoryDataAtom, productCategoryAtom } from "../../../atom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getSeries, postAssignedData } from "../../../api/product_series";
import { postProductCategory } from "../../../api/product_category";
import { getProduct } from "../../../api/product";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageUpload from "../../../components/ImageUpload";
import SelectField2 from "../../../components/SelectedField2";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import _debounce from "lodash/debounce";
import Dropdown from "../productcomponents/Dropdown";
import TextArea from "../../../components/TextArea";
import ProductsOfCategory from "./ProductsOfCategory";

const initState = {
  name: "",
  description: "",
  image: "",

  slug: "",
  is_active: true,
};

const CategoryForm = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState<any>(initState);
  const [errors, setErrors] = useState<any>({});

  const [selectedAssignedData, setSelectedAssignedData] = useState<any>("");
  const [loading, setLoading] = useState(false);

  const [assignedNewData, setAssignedNewData] = useRecoilState(
    assignedCategoryDataAtom
  );
  const [page, setPage] = useState(1);
  const [productData, setProductData] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const setProductCategoryState = useSetRecoilState(productCategoryAtom);

  const handleAssignedDataChange = (assign: any) => {
    setSelectedAssignedData(assign);
  };

  const handleAddAssigned = () => {
    if (selectedAssignedData) {
      const { id, name } = selectedAssignedData;

      const isAlreadyAssigned = assignedNewData.some(
        (data: any) => data.value.id === id
      );
      if (isAlreadyAssigned) {
        toast.error("Already added to assigned");
        return;
      }
      if (!isAlreadyAssigned) {
        const newData: any = {
          value: { id: id, name: name },
          label: name,
        };
        setAssignedNewData((prevData) => [...prevData, newData]);
      }

      setSelectedAssignedData("");
    }
  };

  const handleSubmitForm = async () => {
    setLoading(true); // Set loading state to true before making the API call

    try {
      const res1 = await postProductCategory(form);
      const categoryId = res1.data.category.id;

      const assign = assignedNewData.map((el: any) => {
        return el.id;
      });

      const payload = {
        changes: { category_id: categoryId },
        products: assign,
      };

      const res2 = await postAssignedData(payload);
      if (res2 && res1) {
        toast.success("Category created successfully");
        setAssignedNewData([]);
        navigate("/products/categories");
        setProductCategoryState([]);
      }
    } catch (e: any) {
      console.error(e);
      if (e?.data?.errors?._schema?.length === 1) {
        toast.error(e?.data?.errors?._schema?.[0]);
      } else if (e?.data?.errors?._schema?.length > 1) {
        toast.error(
          <ul className="list-disc pl-4">
            {e?.data?.errors?._schema?.map((errorMessage: any, i: number) => (
              <li key={i}>{errorMessage}</li>
            ))}
          </ul>
        );
      } else {
        toast.error("Category creation failed");
      }

      setErrors(e.data.errors);
    } finally {
      setLoading(false); // Reset loading state to false after the API call completes
    }
  };

  const handleCancleForm = () => {
    setForm(initState);
    setSelectedAssignedData("");

    setAssignedNewData([]);
    navigate("/products/categories");
  };

  const imageList = useMemo(
    () => (form.image ? [form.image] : []),
    [form.image]
  );

  useEffect(() => {
    const formattedSlug = form?.name?.toLowerCase().split(" ").join("-");
    setForm((prev: any) => ({ ...prev, slug: formattedSlug }));
  }, [form.name]);

  const [assignProductsPageLoading, setAssignProductsPageLoading] =
    useState(true);
  const fetchProducts = async () => {
    setAssignProductsPageLoading(true);
    protectedAxiosInstance
      .get("/admin/products", {
        params: { length: 20, page, search: searchText },
      })
      .then((res) => {
        const productList = res.data.data.product;

        setProductData((prevData) => [
          ...prevData,
          ...productList.map((item: any) => ({
            value: { id: item.id, name: item.name },
            label: item.name,
          })),
        ]);

        setTotalPage(res.data.data.pagination.total_pages);
        setAssignProductsPageLoading(false);
      })
      .catch((error: any) => {
        setAssignProductsPageLoading(false);
        // Handle errors
      });
  };
  const [time, setTime] = useState(500);

  const debouncedFetchProducts = _debounce(fetchProducts, time);
  useEffect(() => {
    setTime(10);
    const fetchData = async () => {
      await debouncedFetchProducts();
    };
    fetchData();
    return () => {
      debouncedFetchProducts.cancel();
    };
  }, [page]);

  useEffect(() => {
    setTime(500);
    setPage(1);
    setProductData([]);
    setAssignProductsPageLoading(true);
    const fetchData = async () => {
      await debouncedFetchProducts();
    };
    fetchData();
    return () => {
      debouncedFetchProducts.cancel();
    };
  }, [searchText]);

  useEffect(() => {
    const newlyUpdatedList = productData.filter(
      (newDataItem) =>
        !assignedNewData.some(
          (dataItem: any) => newDataItem.value.id === dataItem.value.id
        )
    );
    setProductData(newlyUpdatedList);
  }, [assignedNewData]);

  return (
    <div className="h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
      {/* heading */}
      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  px-4">
        <h2 className="font-semibold">Create Category</h2>
      </div>

      <div className="flex w-full justify-between  flex-1  overflow-y-auto custom-scrollbar  py-3">
        <div className="w-[55%] overflow-y-auto custom-scrollbar px-4">
          <InputField
            label="Category Name:"
            placeholder="Category Name"
            value={form.name}
            onChange={(e) => {
              if (errors.name) {
                delete errors.name;
                setErrors(structuredClone(errors));
              }
              setForm((prev: any) => ({ ...prev, name: e }));
            }}
            hint={errors.name || ""}
            required
          />
          <InputField
            label="Category Slug:"
            placeholder="Category Slug"
            value={form.slug}
            onChange={(e) => {
              if (errors.slug) {
                delete errors.slug;
                setErrors(structuredClone(errors));
              }
              setForm((prev: any) => ({ ...prev, slug: e }));
            }}
            hint={errors.slug || ""}
            required
          />

          <TextArea
            className="w-full"
            onChange={(e) => {
              if (errors.description) {
                delete errors.description;
                setErrors(structuredClone(errors));
              }
              setForm((prev: any) => ({
                ...prev,
                description: e,
              }));
            }}
            value={form?.description}
            hint={errors?.description}
            required
            label="Description:"
            rows={5}
          />
          {/* Upload img file */}
          <div className="w-full mt-2">
            <ImageUpload
              kind="CATEGORY"
              imageList={imageList}
              setter={(value) => {
                form.image = value as string;
                setForm(structuredClone(form));
              }}
              removeError={() => {
                delete errors.image;
                setErrors(structuredClone(errors));
              }}
              errors={errors.image}
              required
              label="Upload image:"
            />
          </div>

          <div className="w-full">
            <div className="w-full flex flex-row items-center  gap-2">
              <div className="w-full">
                <SelectField2
                  loading={assignProductsPageLoading}
                  totalPage={totalPage}
                  page={page}
                  setPage={setPage}
                  label="Assign products:"
                  options={productData}
                  value={selectedAssignedData}
                  onChange={handleAssignedDataChange}
                  placeholder="Select an assigned product"
                  externalSearch={searchText}
                  externalSetSearch={setSearchText}
                />
              </div>

              <div
                onClick={handleAddAssigned}
                className="px-1 h-10 mt-6 flex items-center cursor-pointer"
              >
                <PlusIcon color="#000000" width="14" />
              </div>
            </div>
            {!selectedAssignedData && errors.products && (
              <p className="text-left font-light text-xs pt-1 text-red-700">
                {errors.products}
              </p>
            )}
          </div>
        </div>

        <div className="px-4 border-r border-[#ECECEC]"></div>

        <ProductsOfCategory />
      </div>

      {/* Footer */}
      <div className="flex gap-x-4 w-[100%] py-3 border-t  border-[#ECECEC] px-4">
        <Button label="Create" onClick={handleSubmitForm} disabled={loading} />
        <Button
          variant="secondary-outline"
          label="Cancel"
          onClick={handleCancleForm}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default CategoryForm;
