import React, { useEffect, useState } from "react";
import AddFloorsPopup from "../components/AddFloorsPopup";
import editPen from "../../../assets/svg/pen.svg";
import trashIcon from "../../../assets/svg/trash.svg";

const ProjectDetailsFloor = ({ data }: any) => {
  return (
    <div className="w-full">
      {data?.floors?.length === 0 ? (
        <div className="border-2 border-[#F4F2EE] px-4 py-10 flex text-center justify-center items-center font-gilroy-bold text-[12px] leading-[14.7px]">
          No Floors & Area Added
        </div>
      ) : (
        <div>
          {data.floors &&
            data.floors.map((floor: any, floorIndex: number) => (
              <div key={floorIndex} className="border-b border-gray-300 p-4">
                <li className="font-gilroy-bold text-[12px] leading-[22px] capitalize">
                  {floor.name}
                </li>
                <>
                  <div className="w-full flex flex-row gap-1 max-w-max font-gilroy-regular text-[12px] leading-[18px]">
                    <p className="flex flex-col font-gilroy-regular text-[12px] leading-[18px] max-w-fit pr-1">
                      {floor.areas.length > 1
                        ? "Areas :"
                        : floor.areas.length >= 0
                        ? "Area :"
                        : null}
                    </p>
                    {/*  */}
                    {(!floor?.areas?.length || floor?.areas?.length === 0) &&
                      "No area added."}
                    {floor.areas.map((area: any, areaIndex: any) => (
                      <p key={areaIndex}>
                        <span className="capitalize">
                          {area?.name}
                          {areaIndex < floor.areas.length - 1 ? "," : "."}
                        </span>
                      </p>
                    ))}
                  </div>
                </>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ProjectDetailsFloor;
