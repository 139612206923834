import React, { useState } from "react";
import { motion } from "framer-motion";
import HipchatChevronDownIcon from "../../assets/HipchatChevronDownIcon";
import PlusIcon from "../../assets/PlusIcon";

type OrderDetailsDropDownProps = {
  label: string;
  staticData: React.ReactNode;
  dropDown: React.ReactNode;
  showCreate?: string;
};

export default function OrderDetailsDropDown({
  label,
  staticData,
  dropDown,
  showCreate = "",
}: OrderDetailsDropDownProps) {
  const [isOpen, setIsOpen] = useState(true);

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className="w-full border-2 h-auto mt-4">
        {/* <div onClick={handleSelectClick} className="flex cursor-pointer justify-between items-center bg-[#F6F6F6] h-[40px] px-5 py-2">
          <p className="font-medium text-[14px] leading-5">{label}</p>
          <motion.div
            animate={showCreate ? {} : { rotate: isOpen ? 180 : 0 }}
            className="w-[38px]  h-[38px] flex gap-x-2 items-center justify-center cursor-pointer"
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if(!showCreate) {
                    handleSelectClick()
                }
            }}
          >
            {showCreate ? (<>
            <p className="stroke-pot-yellow"><PlusIcon /></p>
            <p className="text-pot-yellow text-xs whitespace-nowrap mr-12">{showCreate}</p>
            </>) : (<HipchatChevronDownIcon color="black" width="22" />)}
          </motion.div>
        </div> */}

        <div
          className={`flex justify-between items-center bg-[#FBFBFB] px-5 h-[40px] py-2`}
        >
          <p className="font-medium text-[14px] leading-5">{label}</p>
          <motion.div
            className="w-fit flex gap-x-5 items-center justify-center"
            onClick={handleSelectClick}
          >
            {showCreate && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!showCreate) {
                    handleSelectClick();
                  }
                }}
                className="w-fit flex gap-x-2 items-center"
              >
                <p className="stroke-pot-yellow">
                  <PlusIcon />
                </p>
                <p className="text-pot-yellow text-xs whitespace-nowrap">
                  {showCreate}
                </p>
              </button>
            )}

            <motion.button animate={{ rotate: isOpen ? 180 : 0 }}>
              <HipchatChevronDownIcon color="black" width="22" />
            </motion.button>
          </motion.div>
        </div>

        <motion.div
          layout
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{
            opacity: 0,
            y: -30,
            transition: { duration: 0.2 },
          }}
          transition={{ delay: 0.1, duration: 0.2 }}
        >
          {staticData}
        </motion.div>

        {isOpen && (
          <>
            <motion.div
              layout
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{
                opacity: 0,
                y: -30,
                transition: { duration: 0.2 },
              }}
              transition={{ delay: 0.1, duration: 0.2 }}
            >
              {dropDown}
            </motion.div>
          </>
        )}
      </div>
    </div>
  );
}
