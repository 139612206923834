import {  protectedAxiosInstance } from "./axiosManagement";

export const getCustomer = (params) => {
  return protectedAxiosInstance
    .get("/admin/accounts/customers", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const getSingleCustomer = (id) => {
  return protectedAxiosInstance
    .get(`/admin/accounts/customers/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateCustomer = (id, payload) => {
  return protectedAxiosInstance
    .patch(`/admin/accounts/customers/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postCustomer = (payload) => {
  return protectedAxiosInstance
    .post("/admin/accounts/customers", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};



export const getCustomerQuicklist = ( params ) => {
  return protectedAxiosInstance
    .get("/admin/accounts/customers/quick-list",{ params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};


