import React from "react";

const DragHandleIcon = () => {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.49996 1.66667H16.5C16.721 1.66667 16.9329 1.57887 17.0892 1.42259C17.2455 1.26631 17.3333 1.05435 17.3333 0.833333C17.3333 0.61232 17.2455 0.400358 17.0892 0.244078C16.9329 0.0877975 16.721 0 16.5 0H1.49996C1.27895 0 1.06698 0.0877975 0.910704 0.244078C0.754423 0.400358 0.666626 0.61232 0.666626 0.833333C0.666626 1.05435 0.754423 1.26631 0.910704 1.42259C1.06698 1.57887 1.27895 1.66667 1.49996 1.66667ZM16.5 8.33333H1.49996C1.27895 8.33333 1.06698 8.42113 0.910704 8.57741C0.754423 8.73369 0.666626 8.94565 0.666626 9.16667C0.666626 9.38768 0.754423 9.59964 0.910704 9.75592C1.06698 9.9122 1.27895 10 1.49996 10H16.5C16.721 10 16.9329 9.9122 17.0892 9.75592C17.2455 9.59964 17.3333 9.38768 17.3333 9.16667C17.3333 8.94565 17.2455 8.73369 17.0892 8.57741C16.9329 8.42113 16.721 8.33333 16.5 8.33333ZM16.5 4.16667H1.49996C1.27895 4.16667 1.06698 4.25446 0.910704 4.41074C0.754423 4.56702 0.666626 4.77899 0.666626 5C0.666626 5.22101 0.754423 5.43297 0.910704 5.58926C1.06698 5.74554 1.27895 5.83333 1.49996 5.83333H16.5C16.721 5.83333 16.9329 5.74554 17.0892 5.58926C17.2455 5.43297 17.3333 5.22101 17.3333 5C17.3333 4.77899 17.2455 4.56702 17.0892 4.41074C16.9329 4.25446 16.721 4.16667 16.5 4.16667Z"
        fill="#4E4E4E"
      />
    </svg>
  );
};

export default DragHandleIcon;
