import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Variants } from "../CreateEditVariant";
import Card1 from "./OptionCards/Card1";
import Card2 from "./OptionCards/Card2";
import Card3 from "./OptionCards/Card3";
import Card4 from "./OptionCards/Card4";
import Card5 from "./OptionCards/Card5";
import TrackTypeOptionCard from "./OptionCards/TrackTypeOptionCard";
import BigBlockWithImgPopup from "./ProductVariantsPopupForms/BigBlockWithImgPopup";
import {
  VariantBigBlockWithImageProps,
  VariantBigBlockWithoutImageProps,
  VariantColorPickerProps,
  VariantMiniBlockWithImageProps,
  VariantSmallBlockWithImageProps,
} from "../../../types";
import BigBlockWithoutImgPopup from "./ProductVariantsPopupForms/BigBlockWithoutImgPopup";
import ColorPickerPopup from "./ProductVariantsPopupForms/ColorPickerPopup";
import SmallBlockWithImgPopup from "./ProductVariantsPopupForms/SmallBlockWithImgPopup";
import MiniBlockWithImgPopup from "./ProductVariantsPopupForms/MiniBlockWithImgPopup";
import SelectField from "../../../components/SelectField";
import Button from "../../../components/Button";
import PlusIcon from "../../../assets/PlusIcon";
import InputField from "../../../components/InputField";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DownTriangle from "../../../assets/svg-tsx/DownTriangle";
import UpTriangle from "../../../assets/svg-tsx/UpTriangle";
import TrackTypePopup from "./ProductVariantsPopupForms/TrackTypePopup";
import TextArea from "../../../components/TextArea";
import SelectField2 from "../../../components/SelectedField2";
import ErrorMessage from "../../../components/ErrorMessage";

type VariantFormOnePropType = {
  pendingVariantData: any;
  setPendingVariantData?: any;
  handleSave?: (value?: any) => void;
  type?: "selected" | "normal" | "another";
  errors?: any;
  setErrors?: any;
  variantPopupError?: any;
  setVariantPopupError?: any;
  openModal?: any;
  setOpenModal?: any;
};

const initVariantData = {
  added: true,
};

const VariantFormOne = ({
  pendingVariantData,
  setPendingVariantData,
  handleSave,
  type = "selected",
  errors,
  setErrors,
  openModal,
  setOpenModal,
  variantPopupError,
  setVariantPopupError,
}: VariantFormOnePropType) => {
  const [seletedOption, setSeletedOption] = useState<any>("");

  const handleClose = () => {
    setOpenModal(false);
    setSeletedOption("");
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(pendingVariantData?.options);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setPendingVariantData((prev: any) => ({
      ...prev,
      options: reorderedItems,
    }));
  };

  const OptionList = ({ el, i, length }: any) => {
    const handleStageUp = () => {
      if (i > 0) {
        setPendingVariantData((prevData: any) => {
          const updatedOptions = [...(prevData?.options || [])];
          const temp = updatedOptions[i];
          updatedOptions[i] = updatedOptions[i - 1];
          updatedOptions[i - 1] = temp;
          return { ...prevData, options: updatedOptions };
        });
      }
    };

    const handleStageDown = () => {
      if (i < length - 1) {
        setPendingVariantData((prevData: any) => {
          const updatedOptions = [...(prevData?.options || [])];
          const temp = updatedOptions[i];
          updatedOptions[i] = updatedOptions[i + 1];
          updatedOptions[i + 1] = temp;
          return { ...prevData, options: updatedOptions };
        });
      }
    };
    return (
      <div
        className="flex flex-col gap-2 text-sm text-left w-full mt-4 "
        key={i}
      >
        <div
          className="flex justify-start items-center gap-4"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex">
            <UpTriangle
              stroke="black"
              onClick={handleStageUp}
              disabled={i === 0}
            />

            <DownTriangle
              stroke="black"
              onClick={handleStageDown}
              disabled={i === length - 1}
            />
          </div>
          <p className="text-[#000000] text-[13px] font-medium">
            Option {i + 1}:
          </p>
        </div>
        {pendingVariantData?.kind === "BIG_BLOCK_WITH_IMAGE" && (
          <Card1
            el={el}
            variantData={pendingVariantData}
            setVariantData={setPendingVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
        {pendingVariantData?.kind === "BIG_BLOCK_WITHOUT_IMAGE" && (
          <Card2
            el={el}
            variantData={pendingVariantData}
            setVariantData={setPendingVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
        {pendingVariantData?.kind === "COLOR_PICKER" && (
          <Card3
            el={el}
            variantData={pendingVariantData}
            setVariantData={setPendingVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
        {pendingVariantData?.kind === "SMALL_BLOCK_WITH_IMAGE" && (
          <Card4
            el={el}
            variantData={pendingVariantData}
            setVariantData={setPendingVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
        {pendingVariantData?.kind === "MINI_BLOCK_WITH_IMAGE" && (
          <Card5
            el={el}
            variantData={pendingVariantData}
            setVariantData={setPendingVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
        {/* kind TRACK TYPE */}
        {pendingVariantData?.kind === "TRACK_TYPE" && (
          <TrackTypeOptionCard
            el={el}
            variantData={pendingVariantData}
            setVariantData={setPendingVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      {type === "selected" ? (
        <div className="">
          <label
            htmlFor="message1"
            className="block mb-2 text-[13px] text-left font-medium text-gray-900"
          >
            Select a variant:
          </label>

          <div className="border bg-pot-grey3 text-sm py-1 px-2 rounded-md">
            {pendingVariantData?.name}
          </div>
        </div>
      ) : (
        <></>
      )}
      <InputField
        onChange={(e) => {
          setVariantPopupError((prev: any) => ({ ...prev, name: [] }));
          setPendingVariantData((previous: any) => ({
            ...previous,
            name: e,
          }));
        }}
        value={pendingVariantData?.name}
        placeholder="Variant name"
        label="Variant name:"
        className=""
        required
        hint={variantPopupError?.name || []}
      />
      <InputField
        onChange={(e) => {
          setVariantPopupError((prev: any) => ({ ...prev, info: [] }));
          setPendingVariantData((previous: any) => ({
            ...previous,
            info: e,
          }));
        }}
        value={pendingVariantData?.info}
        placeholder="Info"
        label="Info:"
        className="mt-4"
        required
        hint={variantPopupError?.info || []}
      />
      <TextArea
        className="my-4"
        label="Description:"
        placeholder="Description"
        rows={4}
        onChange={(e) => {
          setVariantPopupError((prev: any) => ({ ...prev, description: [] }));
          setPendingVariantData((previous: any) => ({
            ...previous,
            description: e,
          }));
        }}
        value={pendingVariantData?.description}
        required
        hint={variantPopupError?.description || []}
      />

      <SelectField2
        label="Select variant type:"
        options={Variants}
        value={pendingVariantData?.kind}
        disabled={!pendingVariantData?.added}
        onChange={(value) => {
          setVariantPopupError((prev: any) => ({
            ...prev,
            options: [],
            kind: [],
          }));
          setPendingVariantData((prev: any) => ({
            ...prev,
            kind: value,
            options: [],
          }));
        }}
        placeholder="Select variant type"
        required
        hint={
          variantPopupError?.kind
            ? variantPopupError.kind
            : variantPopupError?.options
            ? variantPopupError.options
            : []
        }
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="reorderable-list">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {pendingVariantData?.options
                ?.filter((each: any) => !each.to_delete)
                .map((el: any, i: any, arr: any) => {
                  return (
                    <Draggable
                      key={i}
                      draggableId={`item-${i}`}
                      index={i}
                      isDragDisabled={arr.length < 2}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <OptionList el={el} i={i} length={arr?.length} />
                        </div>
                      )}
                    </Draggable>
                  );
                })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="py-4 border-b mb-8">
        <Button
          variant="secondary"
          onClick={() => setOpenModal(true)}
          label="Add options"
          leftIcon={<PlusIcon />}
          disabled={!pendingVariantData?.kind ? true : false}
        />
      </div>
      {pendingVariantData?.kind === "BIG_BLOCK_WITH_IMAGE" && openModal && (
        <BigBlockWithImgPopup
          handleClose={handleClose}
          setVariantData={setPendingVariantData}
          task={seletedOption?.task}
          setVariantPopupError={setVariantPopupError}
          data={
            pendingVariantData?.options.find(
              (el: any) => el.id === seletedOption?.elid //eslint-disable-line
            ) as VariantBigBlockWithImageProps
          }
        />
      )}
      {pendingVariantData?.kind === "BIG_BLOCK_WITHOUT_IMAGE" && openModal && (
        <BigBlockWithoutImgPopup
          handleClose={handleClose}
          setVariantData={setPendingVariantData}
          task={seletedOption?.task}
          setVariantPopupError={setVariantPopupError}
          data={
            pendingVariantData?.options.find(
              (el: any) => el?.id === seletedOption?.elid //eslint-disable-line
            ) as VariantBigBlockWithoutImageProps
          }
        />
      )}
      {pendingVariantData?.kind === "COLOR_PICKER" && openModal && (
        <ColorPickerPopup
          handleClose={handleClose}
          setVariantData={setPendingVariantData}
          task={seletedOption?.task}
          setVariantPopupError={setVariantPopupError}
          data={
            pendingVariantData?.options.find(
              (el: any) => el.id === seletedOption?.elid //eslint-disable-line
            ) as VariantColorPickerProps
          }
        />
      )}
      {pendingVariantData?.kind === "SMALL_BLOCK_WITH_IMAGE" && openModal && (
        <SmallBlockWithImgPopup
          handleClose={handleClose}
          setVariantData={setPendingVariantData}
          task={seletedOption?.task}
          setVariantPopupError={setVariantPopupError}
          data={
            pendingVariantData?.options.find(
              (el: any) => el.id === seletedOption?.elid //eslint-disable-line
            ) as VariantSmallBlockWithImageProps
          }
        />
      )}
      {pendingVariantData?.kind === "MINI_BLOCK_WITH_IMAGE" && openModal && (
        <MiniBlockWithImgPopup
          handleClose={handleClose}
          setVariantData={setPendingVariantData}
          task={seletedOption?.task}
          setVariantPopupError={setVariantPopupError}
          data={
            pendingVariantData?.options.find(
              (el: any) => el.id === seletedOption?.elid //eslint-disable-line
            ) as VariantMiniBlockWithImageProps
          }
        />
      )}
      {pendingVariantData?.kind === "TRACK_TYPE" && openModal && (
        <TrackTypePopup
          handleClose={handleClose}
          setVariantData={setPendingVariantData}
          task={seletedOption?.task}
          setVariantPopupError={setVariantPopupError}
          data={
            pendingVariantData?.options.find(
              (el: any) => el.id === seletedOption?.elid //eslint-disable-line
            ) as VariantMiniBlockWithImageProps
          }
        />
      )}
    </div>
  );
};

export default VariantFormOne;
