import React from "react";

export default function BigBlockWithoutImgPreview() {
  return (
    <div className="flex flex-col gap-3 text-left ">
      <div>
        <p className="text-[#AEAEAE] text-md">Preview:</p>
      </div>
      <div className="border w-[65%] flex flex-row">
        <div className="flex flex-col w-full">
          <div className="p-2 border border-b">
            <p className=" h-6 m-auto bg-[#DEDEDE] "></p>
          </div>
          <div className="px-2 py-3 border border-b flex flex-col  justify-start items-start gap-4">
            <p className=" h-6 bg-[#ECECEC] w-72"></p>
            <p className=" h-6 bg-[#ECECEC] w-64"></p>
            <p className=" h-6 bg-[#ECECEC] w-40"></p>
          </div>
          <div className="px-2 py-3 border border-b">
            <p className=" h-6 bg-[#DEDEDE] w-28"></p>
          </div>
        </div>
      </div>
    </div>
  );
}
