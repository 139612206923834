import React, { useEffect, useState } from "react";

type CheckboxPropType = {
  checkValue?: 0 | 1 | 2;
  onClick?: (value: number) => void;
  disabled?: boolean;
  big?: boolean;
  label?: string;

  borderType?: "rounded" | "square" | "circle";
  toggleOff?: boolean;
};

const Checkbox = ({
  checkValue = 0,
  onClick,
  disabled = false,
  big = false,
  label = "",
  borderType = "rounded",
  toggleOff,
}: CheckboxPropType) => {
  const [value, setValue] = useState<0 | 1 | 2>(checkValue);

  const size = !big ? "w-4 h-4" : "lg:w-5 lg:h-5 w-5 h-5";
  const borderCorner =
    borderType === "rounded"
      ? "rounded-md"
      : borderType === "square"
      ? ""
      : borderType === "circle"
      ? "rounded-full"
      : "rounded-md";
  const borderStyle = `cursor-pointer border-2 border-pot-grey group-hover:border-2 group-hover:border-pot-yellow group-hover:opacity-50 active:border-2 active:border-black active:opacity-100 ${borderCorner}`;
  const tickStyle = `checked before:bg-pot-yellow bg-white border-2 border-pot-yellow  group-hover:bg-pot-yellow  group-hover:bg-opacity-25 ${borderCorner} active:border-2 active:border-black cursor-pointer relative`;
  const minusStyle = `minus before:bg-pot-yellow bg-white border-2 border-pot-yellow group-hover:bg-pot-yellow  group-hover:bg-opacity-25 active:border-black cursor-pointer relative  ${borderCorner}`;

  const checkStyle =
    value === 0
      ? borderStyle
      : value === 1
      ? tickStyle
      : value === 2
      ? minusStyle
      : "";

  const borderDisabledStyle = "cursor-not-allowed relative  border-2 ";
  const checkedDisabledStyle =
    "checked relative cursor-not-allowed before:bg-pot-grey2 border-2 before:bg-opacity-25";
  const disabledStyle =
    value === 0 ? borderDisabledStyle : value === 1 ? checkedDisabledStyle : "";

  const handleClick = () => {
    if (disabled) {
      return;
    }

    let newValue: 0 | 1 | 2;

    if (value === 0) {
      newValue = toggleOff ? 0 : 1;
    } else if (value === 1) {
      newValue = toggleOff ? 1 : 0;
    } else {
      newValue = toggleOff ? 2 : 0;
    }

    setValue(newValue);

    if (onClick) {
      onClick(newValue);
    }
  };

  useEffect(() => {
    if (value !== checkValue) {
      setValue(checkValue);
    }
  }, [checkValue]);

  return (
    <div
      className="flex gap-x-2.5 items-center relative cursor-pointer w-auto group"
      onClick={handleClick}
    >
      <input
        type="checkbox"
        className={` appearance-none  ${size} ${
          disabled ? disabledStyle : checkStyle
        }`}
        checked={value === 1}
        onChange={() => {}}
      />

      <p
        className={`font-gilroy-regular text-[14px] leading-[20px] ${
          disabled ? "opacity-30 " : "opacity-100"
        }`}
      >
        {label}
      </p>
    </div>
  );
};

export default Checkbox;
