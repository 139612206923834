import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { productSeriesAtom, titleAtom } from "../../atom";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getSingleSeries,
  postAssignedData,
  postSeries,
  updateSeries,
} from "../../api/product_series";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import TrashIcon from "../../assets/TrashIcon";
import InputField from "../../components/InputField";
import SelectField2 from "../../components/SelectedField2";
import PlusIcon from "../../assets/PlusIcon";
import Button from "../../components/Button";
import _debounce from "lodash/debounce";
import {
  checkHexCode,
  validateHexCode,
  validateSpaceNotAllowed,
} from "../../utils/validations";
import ErrorMessage from "../../components/ErrorMessage";
import { simulateAsyncDelay } from "../../utils/simulateAsyncDelay";
import Notfound from "../404/components/Notfound";
import { removeExtraSpacesfromObject } from "../../utils/helper";

const initState = {
  full_name: "",
  short_name: "",
  color: "",
};

type Option = {
  value: { id: string; name: string };
  label: string;
};

export default function CreateEditSeries() {
  const setTitle = useSetRecoilState(titleAtom);
  const { id: seriesID } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState(initState);
  const [selectedAssignedData, setSelectedAssignedData] = useState<any>("");
  // this is for sidelist
  const [assignedData, setAssignedData] = useState<any[]>([]);
  // // this is for updated sidelist
  const [assignedNewData, setAssignedNewData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [productData, setProductData] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [pageLoading, setPageLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const setProductSeriesState = useSetRecoilState(productSeriesAtom);

  // Get Edit series details from api
  useEffect(() => {
    const fetchData = async () => {
      if (seriesID) {
        setPageLoading(true);
        try {
          const res = await getSingleSeries(seriesID);
          if (res?.data?.series) {
            const seriesData = { ...res.data.series };
            delete seriesData.id;
            delete seriesData.product_count;
            setForm(seriesData);
          }
        } catch (err: any) {
          console.error("Error in getting series details", err);
          if (err?.status === 404) {
            setNotFound(true);
          }
        } finally {
          setPageLoading(false);
        }
      }
    };

    fetchData();
  }, [seriesID]);

  const handleCancleForm = () => {
    setForm(initState);
    setSelectedAssignedData("");
    setAssignedNewData([]);
    navigate("/products/series");
  };

  const handleAddAssigned = () => {
    if (selectedAssignedData) {
      const { id, name } = selectedAssignedData;

      const isAlreadyAssigned = assignedNewData.some(
        (data) => data.value.id === id
      );
      if (isAlreadyAssigned) {
        toast.error("Already added to assigned");
        return;
      }
      if (!isAlreadyAssigned) {
        const newData: any = {
          value: { id: id, name: name },
          label: name,
        };
        setAssignedNewData((prevData) => [...prevData, newData]);
      }

      setSelectedAssignedData("");
    }
  };

  const handleSubmitForm = async () => {
    setPageLoading(true); // Set loading state to true before making the API call

    if (form?.color && !checkHexCode(form?.color)) {
      setErrors((prev: any) => ({ ...prev, color: ["Not a valid hex code"] }));
      setPageLoading(false); // Reset loading state to false
      return;
    }

    if (seriesID) {
      try {
        const res = await updateSeries(
          seriesID,
          removeExtraSpacesfromObject(form)
        );

        const seriesId = res.data.series.id;

        const newlyAddedItems = assignedNewData
          .filter(
            (newDataItem) =>
              !assignedData.some(
                (dataItem) => newDataItem.value.id === dataItem.value.id
              )
          )
          .map((el) => el.value.id);

        const newlyDeletedItems = assignedData
          .filter(
            (dataItem) =>
              !assignedNewData.some(
                (newDataItem) => newDataItem.value.id === dataItem.value.id
              )
          )
          .map((el) => el.value.id);

        let res2Success = false;
        if (newlyAddedItems.length > 0) {
          const payload = {
            changes: { series_id: seriesId },
            products: newlyAddedItems,
          };
          const res2 = await postAssignedData(payload);
          if (res2) {
            res2Success = true;
          }
        }

        let res3Success = false;
        if (newlyDeletedItems.length > 0) {
          const payload2 = {
            changes: { series_id: null },
            products: newlyDeletedItems,
          };
          const res3 = await postAssignedData(payload2);
          if (res3) {
            res3Success = true;
          }
        }

        if (
          res &&
          (newlyAddedItems.length === 0 || res2Success) &&
          (newlyDeletedItems.length === 0 || res3Success)
        ) {
          toast.success("Series updated successfully");
          navigate("/products/series");
          setProductSeriesState([]);
        }
      } catch (e: any) {
        console.error(e, "error");

        if (e?.data?.errors?._schema?.length === 1) {
          toast.error(e?.data?.errors?._schema?.[0]);
        } else if (e?.data?.errors?._schema?.length > 1) {
          toast.error(
            <ul className="list-disc pl-4">
              {e?.data?.errors?._schema?.map((errorMessage: any, i: number) => (
                <li key={i}>{errorMessage}</li>
              ))}
            </ul>
          );
        } else {
          toast.error("Series update failed");
        }

        setErrors(e?.data?.errors);
      } finally {
        setPageLoading(false); // Reset loading state to false after API call completes
      }
    } else {
      try {
        const res = await postSeries(removeExtraSpacesfromObject(form));
        const seriesId = res.data.series.id;

        const assign = assignedNewData.map((el: any) => {
          return el?.value?.id;
        });

        const payload = {
          changes: { series_id: seriesId },
          products: assign,
        };

        const res2 = await postAssignedData(payload);
        if (res && res2) {
          navigate("/products/series");
          toast.success("Series created successfully");
          setProductSeriesState([]);
        }
      } catch (e: any) {
        console.error(e);
        if (e?.data?.errors?._schema?.length === 1) {
          toast.error(e?.data?.errors?._schema?.[0]);
        } else if (e?.data?.errors?._schema?.length > 1) {
          toast.error(
            <ul className="list-disc pl-4">
              {e?.data?.errors?._schema?.map((errorMessage: any, i: number) => (
                <li key={i}>{errorMessage}</li>
              ))}
            </ul>
          );
        } else {
          toast.error("Series creation failed");
        }

        setErrors(e?.data?.errors);
      } finally {
        setPageLoading(false); // Reset loading state to false after API call completes
      }
    }
  };

  useEffect(() => {
    if (seriesID) {
      protectedAxiosInstance
        .get("/admin/products", {
          params: { length: 100, series_id: seriesID },
        })

        .then((res) => {
          const assignedProduct = res.data.data.product;

          setAssignedData(
            assignedProduct.map((item: any) => ({
              value: { id: item.id, name: item.name },
              label: item.name,
            }))
          );
          setAssignedNewData(
            assignedProduct.map((item: any) => ({
              value: { id: item.id, name: item.name },
              label: item.name,
            }))
          );
        })
        .catch((error) => {});
    }
  }, [seriesID]);

  const [assignProductsPageLoading, setAssignProductsPageLoading] =
    useState(true);

  const fetchProducts = async () => {
    setAssignProductsPageLoading(true);
    protectedAxiosInstance
      .get("/admin/products", {
        params: { length: 20, page, search: searchText },
      })
      .then((res) => {
        const productList = res.data.data.product;

        setProductData((prevData) => [
          ...prevData,
          ...productList.map((item: any) => ({
            value: { id: item.id, name: item.name },
            label: item.name,
          })),
        ]);

        setTotalPage(res.data.data.pagination.total_pages);
        setAssignProductsPageLoading(false);
      })
      .catch((error) => {
        setAssignProductsPageLoading(false);
        // Handle errors
      });
  };

  const [time, setTime] = useState(500);

  const debouncedFetchProducts = _debounce(fetchProducts, time);
  useEffect(() => {
    setTime(10);
    const fetchData = async () => {
      await debouncedFetchProducts();
    };
    fetchData();
    return () => {
      debouncedFetchProducts.cancel();
    };
  }, [page]);

  useEffect(() => {
    setTime(500);
    setPage(1);
    setProductData([]);
    setAssignProductsPageLoading(true);
    const fetchData = async () => {
      await debouncedFetchProducts();
    };
    fetchData();
    return () => {
      debouncedFetchProducts.cancel();
    };
  }, [searchText]);

  const handleDeleteAssignedData = (id: any) => {
    const updatedAssignedProducts = assignedNewData.filter(
      (el: any) => el.value.id !== id
    );
    setAssignedNewData(updatedAssignedProducts);
  };

  if (notFound) {
    return <Notfound />;
  }
  return (
    <div className="h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
      {/* heading */}
      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  px-4">
        <h2 className="font-semibold mb-0">
          {seriesID ? "Edit Series" : "Create Series"}
        </h2>
      </div>

      {/* Content */}
      <div className="flex w-full justify-between  flex-1  overflow-y-auto custom-scrollbar  py-3">
        <div className="w-[55%] overflow-y-auto custom-scrollbar px-4">
          <InputField
            required
            disabled={pageLoading}
            label="Series name:"
            placeholder="Series name"
            value={form.full_name}
            onChange={(e) => {
              if (errors.full_name) {
                delete errors.full_name;
                setErrors({ ...errors });
              }
              setForm((prev) => ({ ...prev, full_name: e }));
            }}
            hint={errors.full_name || ""}
          />
          <InputField
            required
            disabled={pageLoading}
            label="Series short name:"
            placeholder="Series name"
            value={form.short_name}
            onChange={(e) => {
              if (errors.short_name) {
                delete errors.short_name;
                setErrors({ ...errors });
              }
              setForm((prev) => ({ ...prev, short_name: e }));
            }}
            hint={errors.short_name || ""}
          />

          {/* color */}
          <InputField
            disabled={pageLoading}
            maxLength={7}
            required
            onChange={(e) => {
              setForm((prevData) => ({
                ...prevData,
                color: e,
              }));
              setErrors((err: any) => ({ ...err, color: [] }));
            }}
            validate={validateHexCode}
            value={form.color}
            placeholder="Hex code"
            label="Color Picker:"
            hint={errors?.color}
            leftIcon={
              checkHexCode(form.color) ? (
                <div
                  style={{
                    backgroundColor: form.color,
                    width: "1.5rem",
                    height: "60%",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: "0.625rem",
                  }}
                ></div>
              ) : (
                <div className="font-gilroy-medium ">NA</div>
              )
            }
          />

          <div className="w-full mt-2">
            <div className="w-full flex flex-row items-center  gap-2">
              <div className="w-full">
                <SelectField2
                  disabled={pageLoading}
                  loading={assignProductsPageLoading}
                  totalPage={totalPage}
                  page={page}
                  setPage={setPage}
                  label="Assign products:"
                  options={productData}
                  value={selectedAssignedData}
                  onChange={(e) => {
                    setSelectedAssignedData(e);
                    if (errors.products) {
                      delete errors.products;
                      setErrors({ ...errors });
                    }
                  }}
                  removeOption={() => {
                    setSelectedAssignedData("");
                    if (errors.products) {
                      delete errors.products;
                      setErrors({ ...errors });
                    }
                  }}
                  searchPlaceHolder="Select an assigned product"
                  externalSearch={searchText}
                  externalSetSearch={setSearchText}
                  filterLogic={(options) => {
                    return options?.filter(
                      (newDataItem: any) =>
                        !assignedNewData.some(
                          (dataItem: any) =>
                            newDataItem?.value?.id === dataItem.value.id
                        )
                    );
                  }}
                />
              </div>

              <div
                onClick={handleAddAssigned}
                className="px-1 h-10 mt-6 flex items-center cursor-pointer"
              >
                <PlusIcon color="#000000" width="14" />
              </div>
            </div>
            <ErrorMessage error={errors?.products} />
          </div>
        </div>

        <div className="px-4 border-r border-[#ECECEC]"></div>

        <div className="w-[45%] px-4 flex flex-col gap-0">
          <div className="flex flex-col items-start">
            <p className="font-gilroy-bold leading-5 text-[16px]">
              List of products assigned:
            </p>
          </div>
          <div className="w-full flex bg-[#ECECEC]/80 mt-4 font-gilroy-bold divide-x-2 ">
            <div className="w-[5vw] px-2 py-3 font-gilroy-semi-bold leading-5 text-[12px]">
              Sr. no
            </div>
            <div className="w-full flex justify-start px-4 py-3 font-gilroy-semi-bold leading-5 text-[12px]">
              Product name
            </div>
          </div>
          <div className="flex flex-col justify-start items-start divide-y overflow-y-auto custom-scrollbar">
            {assignedNewData.map((el: any, index) => (
              <div key={index} className="w-full flex bg-white divide-x">
                <div className="w-[5vw] px-2 py-2">{index + 1}</div>
                <div className="w-full flex justify-between items-center px-4 py-2">
                  <p className="font-gilroy-medium leading-5 text-[12px]">
                    {el.value.name}
                  </p>
                  <div
                    onClick={() => handleDeleteAssignedData(el.value.id)}
                    className="cursor-pointer"
                  >
                    <TrashIcon />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex gap-x-4 w-[100%] py-3 border-t  border-[#ECECEC] px-4">
        <Button
          label={seriesID ? "Edit" : "Create"}
          onClick={handleSubmitForm}
          disabled={pageLoading}
        />
        <Button
          variant="secondary-outline"
          label="Cancel"
          onClick={handleCancleForm}
          disabled={pageLoading}
        />
      </div>
    </div>
  );
}
