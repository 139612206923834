import React, { useRef, useState } from "react";
import InputField, { InputFieldType } from "../../../components/InputField";
import useClickOutside from "../../../hooks/useClickOutside";
import { motion } from "framer-motion";
import { DownwardArrowIcon } from "../../../assets/ArrowIcons";
import EmailIcon from "../../../assets/EmailIcon";
import PhoneIcon from "../../../assets/PhoneIcon";
import ErrorMessage from "../../../components/ErrorMessage";

type DropdownPropType = {
  options?: any;
  onSelect?: any;
  hint?: any;
};

const CustomerDropdownSearchable = ({
  options,
  onSelect,
  hint,
  ...props
}: InputFieldType & DropdownPropType) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const inputRef = useRef(null);
  useClickOutside(inputRef, () => setOpenDropdown(false));

  return (
    <div ref={inputRef} className="relative mt-3 cursor-pointer">
      <div
        className="flex justify-between "
        onClick={() => setOpenDropdown((prev) => !prev)}
      >
        <InputField
          rightIcon={
            <motion.div
              className="stroke-black"
              animate={{ rotate: openDropdown ? 180 : 0 }}
            >
              <DownwardArrowIcon />
            </motion.div>
          }
          {...props}
        />
      </div>

      {hint && <ErrorMessage error={hint} />}
      {openDropdown && (
        <div className="absolute cursor-pointer text-sm z-10 top-[4.3rem] w-[100%] max-h-[40vh] overflow-y-auto custom-scrollbar bg-white rounded-md border min-h-[40px]">
          {options?.map((each: any, index: number) => (
            <div
              key={index}
              onClick={() => {
                onSelect(each);
                setOpenDropdown(false);
              }}
              className=" px-3 text-left flex flex-col py-2 hover:bg-[#FCFCFC]"
            >
              <div className="font-gilroy-medium text-[14px]">{each?.name}</div>
              <div className="text-sm text-gray-500 flex gap-6  py-1">
                {each?.phone && (
                  <div className="flex gap-1 items-center w-32">
                    <PhoneIcon width={"12"} />{" "}
                    <p className="text-[#AEAEAE] text-xs">{each?.phone}</p>
                  </div>
                )}
                {each?.email && (
                  <div className="flex gap-1 items-center ">
                    <EmailIcon width={"14"} />{" "}
                    <p className="text-[#AEAEAE] text-xs">{each?.email}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomerDropdownSearchable;
