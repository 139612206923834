import FileDownloadIcon from "../../../../assets/svg-tsx/FileDownloadIcon";

const BusinessSection = ({ data, loading }: any) => {
  const renderValue = (value: any) => {
    return value ?? "-";
  };

  return (
    <div className="text-start px-4 pt-2 py-4 border-b">
      <div className="w-full flex justify-between">
        <h1 className="text-pot-yellow font-medium text-lg">
          Business Details:{" "}
        </h1>
      </div>
      <div
        className={`flex flex-col ${
          loading ? "gap-3" : "space-y-1 "
        } text-start mt-4 text-[12px] leading-6`}
      >
        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Legal Name:</p>
          )}
          {loading ? (
            <div className="w-[60%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p>{renderValue(data?.p_company?.biz_legal_name)}</p>
          )}
        </div>
        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Type:</p>
          )}
          {loading ? (
            <div className="w-[60%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p>{renderValue(data?.p_company?.biz_type?.name)}</p>
          )}
        </div>

        {/* <div className="flex justify-between items-center">
          <div>
            <p className="text-[#AEAEAE]">GSTIN:</p>
            <p>{data?.p_company?.biz_gst_no || "NA"}</p>
          </div>
          {data?.p_company?.biz_gst_doc && (
            <div>
              <a href={data.p_company.biz_gst_doc} download>
                <FileDownloadIcon />
              </a>
            </div>
          )}
        </div> */}

        <div className="flex justify-between items-center">
          {loading ? (
            <div className=" flex flex-col gap-1 w-full">
              <div className="w-24 h-4 bg-skeleton-text"></div>
              <div className="w-[80%] h-5 bg-skeleton-text"></div>
            </div>
          ) : (
            <div>
              <p className="text-[#AEAEAE]">GSTIN:</p>
              <p>{data?.p_company?.biz_gst_no || "NA"}</p>
            </div>
          )}
          {loading ? (
            <div className="w-[10%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            data?.p_company?.biz_gst_doc && (
              <div>
                <a href={data.p_company.biz_gst_doc} download>
                  <FileDownloadIcon />
                </a>
              </div>
            )
          )}
        </div>

        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">PAN:</p>
          )}
          {loading ? (
            <div className="w-[60%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p>{renderValue(data?.p_company?.biz_pan_no)}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessSection;
