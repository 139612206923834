import { useEffect, useState } from "react";
import { getOrderAppConfig } from "../api/orderManagement";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  authAtom,
  countryAtom,
  ordeAppConfigAtom,
  stateAtom,
  titleAtom,
  userInfoAtom,
} from "../atom";
import {
  protectedAxiosInstance,
  unprotectedAxiosInstance,
} from "../api/axiosManagement";
import { useLocation } from "react-router-dom";

const Initializer = () => {
  const setCountryState = useSetRecoilState(countryAtom);
  const setStateState = useSetRecoilState(stateAtom);
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const setOrderAppConfig = useSetRecoilState(ordeAppConfigAtom);
  const [authState, setAuthState] = useRecoilState(authAtom);

  const location = useLocation();
  const [title, setTitle] = useRecoilState(titleAtom);
  useEffect(() => {
    const updateDocumentTitle = () => {
      const currentPath: any = location.pathname;
      if (currentPath === "/login") {
        document.title = "Login";
      }

      //
      else if (currentPath === "/dashboard") {
        document.title = "Dashboard";
        setTitle(["Dashboard"]);
      }
      //
      else if (currentPath === "/orders") {
        document.title = "Orders";
        setTitle(["Orders"]);
      }
      //
      else if (currentPath === "/orders/create") {
        document.title = "Order Create";
        setTitle(["Orders", "Create"]);
      }

      //
      else if (currentPath === "/customers") {
        document.title = "Customers";
        setTitle(["Customers"]);
      }

      //
      else if (currentPath === "/customers/professionals") {
        document.title = "Professionals";
        setTitle(["Professionals"]);
      }
      //
      else if (currentPath.startsWith("/customers/professionals/employee/")) {
        setTitle(["Professionals", "Employee", "Details"]);
      }
      //
      else if (currentPath.startsWith("/customers/professionals/company/")) {
        setTitle(["Professionals", "Company", "Details"]);
      }
      //
      else if (currentPath === "/customers/professionals/pending-requests") {
        document.title = "Professional - Pending Requests";
        setTitle(["Professionals", "Pending Requests"]);
      }
      //
      else if (currentPath === `/customers/create`) {
        document.title = `Customer Create`;
        setTitle(["Customers", "Create"]);
      }
      //
      else if (currentPath === `/customers/professionals/create`) {
        document.title = `Professional Create`;
        setTitle(["Professionals", "Create"]);
      }
      //
      else if (currentPath === `/users`) {
        document.title = `Users`;
        setTitle(["Hello"]);
      }
      //
      else if (currentPath === `/users/create`) {
        document.title = `Users Create`;
        setTitle(["Hello"]);
      }
      //
      else if (currentPath === `/products`) {
        document.title = `Products`;
        setTitle(["Products"]);
      }
      //
      else if (currentPath === `/products/create`) {
        document.title = `Product Create`;
        setTitle(["Products", "Create"]);
      }
      //
      else if (currentPath === `/products/variant-templates`) {
        document.title = `Variant Templates`;
        setTitle(["Products", "Variant Templates"]);
      }
      //
      else if (currentPath === `/products/variant-templates/create`) {
        document.title = `Variant Template Create`;
        setTitle(["Products", "Variant Templates", "Create"]);
      }
      //
      else if (currentPath === `/products/categories`) {
        document.title = `Categories`;
        setTitle(["Products", "Categories"]);
      }
      //
      else if (currentPath === `/products/categories/create`) {
        document.title = `Category Create`;
        setTitle(["Products", "Categories", "Create"]);
      }
      //
      else if (currentPath === `/products/series`) {
        document.title = `Series`;
        setTitle(["Products", "Series"]);
      }
      //
      else if (currentPath === `/products/series/create`) {
        document.title = `Series Create`;
        setTitle(["Products", "Series", "Create"]);
      }
      //
      else if (currentPath === `/products/brands`) {
        document.title = `Brands`;
        setTitle(["Products", "Brands"]);
      }
      //
      else if (currentPath === `/products/brands/create`) {
        document.title = `Brands Create`;
        setTitle(["Products", "Brands", "Create"]);
      }
      //
      else if (currentPath === `/projects`) {
        document.title = `Projects`;
        setTitle(["Project Management"]);
      }
      //
      else if (currentPath === `/projects/create`) {
        document.title = `Project Create`;
        setTitle(["Projects", "Create"]);
      }
      //
      else if (currentPath === `/404`) {
        document.title = `Page Not Found`;
        setTitle(["Error"]);
      }

      //
      else if (currentPath.startsWith("/orders/")) {
        setTitle(["Orders", "Details"]);
      }
      //
      else if (
        currentPath.startsWith("/customers/") &&
        !currentPath.endsWith("/edit")
      ) {
        setTitle(["Customers", "Details"]);
      }
      //
      else if (
        currentPath.startsWith("/customers/professionals/") &&
        currentPath.endsWith("/edit")
      ) {
        setTitle(["Professionals", "Edit"]);
      }
      //
      else if (currentPath.startsWith("/customers/professionals/")) {
        setTitle(["Professionals", "Details"]);
      }
      //
      else if (
        currentPath.startsWith("/customers/") &&
        currentPath.endsWith("/edit")
      ) {
        setTitle(["Customers", "Edit"]);
      }
      //
      else if (currentPath.startsWith("/users/")) {
        setTitle(["Hello"]);
      }
      //
      else if (currentPath.startsWith("/products/variant-templates/")) {
        setTitle(["Products", "Variant Templates", "Details"]);
      }
      //
      else if (currentPath.startsWith("/products/categories/")) {
        setTitle(["Products", "Categories", "Details"]);
      }
      //
      else if (currentPath.startsWith("/products/series/")) {
        setTitle(["Products", "Series", "Details"]);
      }
      //
      else if (currentPath.startsWith("/products/brands/")) {
        setTitle(["Products", "Brands", "Details"]);
      }
      //
      else if (currentPath.startsWith("/products/")) {
        setTitle(["Products", "Details"]);
      }
      //
      else if (
        currentPath.startsWith("/projects/") &&
        currentPath.endsWith("/edit")
      ) {
        setTitle(["Projects", "Edit"]);
      }
      //
      else if (currentPath.startsWith("/projects/")) {
        setTitle(["Project Management", "Details"]);
      }
      //
      else {
        document.title = "Undecided";
        setTitle(["Undecided"]);
      }
    };
    updateDocumentTitle();
  }, [location.pathname]);

  useEffect(() => {
    const auth_token = localStorage.getItem("auth_token");
    const refresh_token = localStorage.getItem("auth_token");
    if (auth_token && refresh_token) {
      setAuthState({
        is_auth: true,
        auth_token: auth_token,
        refresh_token: refresh_token,
      });
    }
  }, []);

  useEffect(() => {
    if (authState.is_auth) {
      protectedAxiosInstance.get(`/admin/accounts/info`).then((res) => {
        setUserInfo(res.data.data.user);
      });
    }
  }, [authState.is_auth]);

  useEffect(() => {
    protectedAxiosInstance.get("/country-data").then((res) => {
      setCountryState(res.data.data);
    });

    protectedAxiosInstance.get(`/country-data/${"IN"}`).then((res) => {
      setStateState(res.data.data);
    });
    getOrderAppConfig({})
      .then((config) => {
        setOrderAppConfig(config.data);
      })
      .catch((error) => {
        console.error("Error fetching app config:", error);
      });
  }, []);

  return null;
};

export default Initializer;
