import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import TrashIcon from "../../../assets/TrashIcon";
import Button from "../../../components/Button";
import PlusIcon from "../../../assets/PlusIcon";
import Modal from "../../../components/common/Modal";
import CrossIcon from "../../../assets/CrossIcon";
import SelectField from "../../../components/SelectField";
import VariantFormOne from "./VariantFormOne";
import Card1 from "./OptionCards/Card1";
import Card2 from "./OptionCards/Card2";
import Card3 from "./OptionCards/Card3";
import Card4 from "./OptionCards/Card4";
import Card5 from "./OptionCards/Card5";
import EditIcon from "../../../assets/EditIcon";
import InputField from "../../../components/InputField";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import { motion } from "framer-motion";
import HipchatChevronDownIcon from "../../../assets/HipchatChevronDownIcon";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import UpTriangle from "../../../assets/svg-tsx/UpTriangle";
import DownTriangle from "../../../assets/svg-tsx/DownTriangle";
import TrackTypeOptionCard from "../OptionCards/TrackTypeOptionCard";
import { StageComponent } from "./StageComponent";
import ErrorMessage from "../../../components/ErrorMessage";
import {
  removeExtraSpaces,
  removeExtraSpacesfromObject,
} from "../../../utils/helper";

type StagesType = {
  errors: any;
  setErrors: Dispatch<SetStateAction<any>>;
  setFormData: Dispatch<SetStateAction<any>>;
  formData: any;
  variantList: any[];
};

const Stages = ({
  setFormData,
  formData,
  errors,
  setErrors,
  variantList,
}: StagesType) => {
  // pendingStageManagement
  const [pendingStageName, setPendingStageName] = useState("");
  const [pendingStageError, setPendingStageError] = useState<any>([]);
  const [pendingVariantData, setPendingVariantData] = useState<any>({});

  // Set Intitial Stages
  const { id } = useParams();
  const initStageList = [
    { name: "Trim Selection", variants: [] as any[], added: true },
    { name: "Lighting Selection", variants: [] as any[], added: true },
    { name: "Lighting Controls", variants: [] as any[], added: true },
    { name: "Futuristic Lighting", variants: [] as any[], added: true },
  ];
  useEffect(() => {
    if (!id) {
      setFormData((prev: any) => ({ ...prev, stages: [] }));
    }
  }, []);

  // to track all the dropdowns opens in stages
  const [isStageListDropDownOpen, setIsStageListDropDownOpen] = useState<
    boolean[]
  >([]);
  useEffect(() => {
    if (isStageListDropDownOpen.length > 0) {
      setIsStageListDropDownOpen(isStageListDropDownOpen);
    } else {
      setIsStageListDropDownOpen(Array(formData?.stages?.length).fill(true));
    }
  }, [formData?.stages]);

  // For dragging of states
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(formData?.stages);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setFormData((prev: any) => ({ ...prev, stages: reorderedItems }));
  };

  // popup manangement
  const [addEditStageIndex, setAddEditStageIndex] = useState<any>(null);
  const [deleteStageIndex, setDeleteStageIndex] = useState(null);
  const [stageSelectIndex, setStageSelectIndex] = useState<any>(-1);
  const [deleteVariantIndex, setDeleteVariantIndex] = useState<any>(null);
  const [variantID, setVariantID] = useState(null); // This is for targeting the variant popup
  const [formPage, setFormPage] = useState<any>(null);
  const [optionModal, setOptionModal] = useState(false);
  // -1 -- close
  // 0 -- Choose Variant
  // 1 -- Edit or Create Variant
  useEffect(() => {
    if (addEditStageIndex !== null) {
      setPendingStageName(formData?.stages?.[addEditStageIndex]?.name);
    }
  }, [addEditStageIndex]);

  // index to insert
  const [indexToInsert, setIndexToInsert] = useState(-1);
  const addStageHandler = () => {
    const stagesLength = formData?.stages?.length || 0;
    const updatedStages = [...(formData?.stages || [])]; // Create a copy of existing stages
    const targetIndex = indexToInsert === -1 ? stagesLength : indexToInsert;
    if (targetIndex !== 0) {
      updatedStages.splice(targetIndex, 0, { added: true });
      setFormData({ ...formData, stages: updatedStages });
    } else if (targetIndex === 0) {
      updatedStages.splice(0, 0, { added: true });
      setFormData({ ...formData, stages: updatedStages });
    }
    setAddEditStageIndex(targetIndex);
  };
  useEffect(() => {
    if (formData?.stages?.length) {
      setErrors((prev: any) => ({ ...prev, stages: [] }));
    }
    setIndexToInsert(
      formData?.stages?.findIndex((stage: any) => stage.to_delete === true)
    );
  }, [formData?.stages]);

  // For getting the data of particular variant template
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedVariantLoading, setSelectedVariantLoading] = useState(false);
  const fetchSelectedVariantData = async () => {
    setSelectedVariantLoading(true);
    try {
      const { data: response } = await protectedAxiosInstance.get(
        `/admin/products/variant-template/${selectedVariant}`
      );
      const vt = structuredClone(response.data.variant_template);
      vt.added = true;
      delete vt.id;
      vt?.options?.forEach((each: any) => {
        each.added = true;
      });
      setPendingVariantData(vt);
    } catch (err) {
      console.error("Error in getting selected variant data");
    } finally {
      setSelectedVariantLoading(false);
    }
  };

  const [variantPopupError, setVariantPopupError] = useState({});
  const handleVariantSave = async (value: any) => {
    const errors: any = {};
    if (!value?.name) {
      errors.name = ["Name is a required field"];
    }
    if (!value?.info) {
      errors.info = ["Info is a required field"];
    }
    if (!value?.description) {
      errors.description = ["Description is a required field"];
    }
    if (!value?.kind) {
      errors.kind = ["You have to choose a variant type"];
    }
    if (!value?.options?.length || value?.options?.length === 0) {
      errors.options = ["You have to create at least one option"];
    }
    setVariantPopupError(errors);
    if (Object.keys(errors).length > 0) {
      setVariantPopupError(errors);
      return;
    }
    delete value.is_active;
    delete value.stage;

    if (!value.id) {
      value.added = true;
    }

    if (value?.added && !value.id) {
      if (!value.id) {
        value.id = uuid();
        value.added = true;
      }
      const stages = formData?.stages || [];
      const stage = stages[stageSelectIndex] || { variants: [], added: true };
      const variants = stage.variants || [];
      const variantIndexToInsert = variants.findIndex(
        (variant: any) => variant.to_delete === true
      );

      if (variantIndexToInsert === -1) {
        // If no variant with to_delete true found, append value to the end of the variants array
        variants.push(value);
      } else {
        // If a variant with to_delete true is found, insert value at the position of variantIndexToInsert
        variants.splice(variantIndexToInsert, 0, value);
      }

      // Update the stages array with the modified variants array
      stages[stageSelectIndex] = { ...stage, variants };

      // Update formData with the modified stages array
      setFormData((prev: any) => ({ ...prev, stages }));
    } else {
      const updatedStages = formData?.stages || [];
      const stage = updatedStages[stageSelectIndex] || { variants: [] };
      const variantIndexToUpdate = stage.variants.findIndex(
        (variant: any) => variant.id === variantID
      );

      if (variantIndexToUpdate !== -1) {
        stage.variants[variantIndexToUpdate] = value;

        // Update the stages array with the modified variants array
        updatedStages[stageSelectIndex] = {
          ...stage,
          variants: stage.variants,
        };

        // Update formData with the modified stages
        setFormData((prev: any) => ({ ...prev, stages: updatedStages }));
      }
    }

    setStageSelectIndex(null);
    setPendingVariantData(null);
    setSelectedVariant("");
    setFormPage(null);
    setVariantID(null);
  };

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="reorderable-list">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {formData?.stages
                ?.filter((el: any) => !el?.to_delete && el.name)
                ?.map((stage: any, index: any, stages: any) => (
                  <Draggable
                    key={index}
                    draggableId={`item-${index}`}
                    index={index}
                    isDragDisabled={isStageListDropDownOpen.some(
                      (isOpen: any) => isOpen
                    )}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="my-4"
                      >
                        <StageComponent
                          stage={stage}
                          setFormData={setFormData}
                          formData={formData}
                          length={
                            indexToInsert === -1
                              ? stages?.length
                              : indexToInsert
                          }
                          index={index}
                          isStageListDropDownOpen={isStageListDropDownOpen}
                          setIsStageListDropDownOpen={
                            setIsStageListDropDownOpen
                          }
                          setDeleteStageIndex={setDeleteStageIndex}
                          setAddEditStageIndex={setAddEditStageIndex}
                          setStageSelectIndex={setStageSelectIndex}
                          setFormPage={setFormPage}
                          setVariantID={setVariantID}
                          setPendingVariantData={setPendingVariantData}
                          setDeleteVariantIndex={setDeleteVariantIndex}
                          errors={errors}
                          setErrors={setErrors}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        variant="secondary"
        onClick={addStageHandler}
        label="Add a stage"
        leftIcon={<PlusIcon />}
      />

      {/* addEditStageModal */}
      {Number.isInteger(addEditStageIndex) && (
        <Modal
          clickOutsideToClose
          allowScroll
          header={
            "Stage: " +
            `${
              indexToInsert === -1 && addEditStageIndex === -1
                ? formData?.stages?.length + 1
                : addEditStageIndex !== -1
                ? addEditStageIndex + 1
                : indexToInsert + 1
            }`
          }
          footer={
            <Button
              variant="primary"
              label="Save"
              onClick={() => {
                if (!pendingStageName) {
                  setPendingStageError(["Stage is a required field"]);
                  return;
                }
                const updatedStages = [...(formData?.stages || [])];
                if (addEditStageIndex !== -1) {
                  if (addEditStageIndex < updatedStages.length) {
                    updatedStages[addEditStageIndex].name =
                      removeExtraSpaces(pendingStageName);
                  } else {
                    updatedStages.push({
                      name: removeExtraSpaces(pendingStageName),
                      variants: [],
                      added: true,
                    });
                  }
                } else {
                  updatedStages.splice(indexToInsert, 0, {
                    name: removeExtraSpaces(pendingStageName),
                    variants: [],
                    added: true,
                  });
                }

                setFormData((prev: any) => ({
                  ...prev,
                  stages: updatedStages,
                }));

                setPendingStageName("");
                setAddEditStageIndex(null);
              }}
            />
          }
          handleCancel={() => {
            const targetIndex =
              indexToInsert === -1
                ? formData?.stages?.length || 0
                : indexToInsert;

            const updatedStages = [...(formData?.stages || [])]; // Create a copy of existing stages
            if (
              targetIndex - 1 >= 0 &&
              targetIndex - 1 < updatedStages.length &&
              Object.keys(formData?.stages?.[targetIndex - 1])?.length === 1 &&
              formData?.stages?.[targetIndex - 1]?.added === true
            ) {
              updatedStages.splice(targetIndex - 1, 1); // Remove the stage at targetIndex - 1
              setFormData({ ...formData, stages: updatedStages }); // Update formData with the modified stages
            }

            // Reset state variables
            setAddEditStageIndex(null);
            setPendingStageName("");
          }}
        >
          <div className="text-sm w-full">
            <InputField
              required
              label="Stage name"
              value={pendingStageName}
              placeholder="Stage name"
              onChange={(value) => {
                setPendingStageError([]);
                setPendingStageName(value);
              }}
              hint={pendingStageError}
              maxLength={20}
            />
          </div>
        </Modal>
      )}

      {/* Delete Stage Modal */}
      {Number.isInteger(deleteStageIndex) && (
        <Modal
          clickOutsideToClose
          handleCancel={() => setDeleteStageIndex(null)}
          top
          header="Delete Stage"
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => {
                  if (deleteStageIndex === null) {
                    return;
                  }
                  if (formData?.stages?.[deleteStageIndex]?.added) {
                    const updatedStages = [...formData.stages];
                    updatedStages.splice(deleteStageIndex, 1);
                    setFormData((prev: any) => ({
                      ...prev,
                      stages: updatedStages,
                    }));
                    setDeleteStageIndex(null);
                  } else {
                    const updatedStages = [...formData.stages];
                    const removedStage = updatedStages.splice(
                      deleteStageIndex,
                      1
                    )[0]; // Remove the stage at deleteStageIndex
                    removedStage.to_delete = true; // Set to_delete key to true
                    updatedStages.push(removedStage); // Add the removed stage at the end with to_delete key true
                    setFormData((prev: any) => ({
                      ...prev,
                      stages: updatedStages,
                    }));
                    setDeleteStageIndex(null);
                  }
                }}
              />

              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setDeleteStageIndex(null);
                }}
              />
            </div>
          }
        >
          <p className="text-left w-full">
            Are you sure you want to delete the stage
          </p>
        </Modal>
      )}

      {/* Variant Select Modal */}
      {Number.isInteger(stageSelectIndex) && formPage !== null && (
        <Modal
          top
          allowScroll={formPage !== 0}
          header={
            formPage === 0
              ? "Choose Variant Template"
              : variantID
              ? "Edit Variant:"
              : "Add Variant:"
          }
          hideCondition={optionModal && pendingVariantData?.kind}
          footer={
            formPage === 0 ? (
              <Button
                variant="secondary"
                label="Create new variant"
                onClick={() => {
                  setFormPage(1);
                  if (selectedVariant) {
                    fetchSelectedVariantData();
                  } else {
                    setPendingVariantData({ added: true });
                  }
                  setSelectedVariant("");
                }}
              />
            ) : (
              <Button
                variant="secondary"
                label={
                  variantID && formPage === 1
                    ? "Update variant"
                    : "Add a variant"
                }
                onClick={() => {
                  handleVariantSave(
                    removeExtraSpacesfromObject(pendingVariantData)
                  );
                }}
              />
            )
          }
          handleCancel={() => {
            setFormPage(null);
            setPendingVariantData({});
            if (formPage === 0) {
              setSelectedVariant("");
            }
          }}
        >
          <div className="w-full">
            {formPage === 0 ? (
              <div className="text-sm">
                <SelectField
                  label="Select a Variant:"
                  onChange={(value) => setSelectedVariant(value)}
                  options={variantList}
                  placeholder="Select a variant"
                  value={selectedVariant}
                />
              </div>
            ) : formPage === 1 ? (
              <VariantFormOne
                pendingVariantData={pendingVariantData}
                setPendingVariantData={setPendingVariantData}
                type="normal"
                errors={errors}
                setErrors={setErrors}
                variantPopupError={variantPopupError}
                setVariantPopupError={setVariantPopupError}
                openModal={optionModal}
                setOpenModal={setOptionModal}
              />
            ) : null}
          </div>
        </Modal>
      )}

      {/* Delete Variant Modal */}
      {deleteVariantIndex && (
        <Modal
          top
          header="Delete Variant"
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => {
                  const { sidx, vid } = deleteVariantIndex;
                  const updatedStages = [...formData.stages]; // Make a copy of the stages array

                  if (updatedStages[sidx]?.variants) {
                    const variantIndex = updatedStages[sidx].variants.findIndex(
                      (variant: any) => variant.id === vid
                    );
                    if (variantIndex !== -1) {
                      if (updatedStages[sidx].variants[variantIndex].added) {
                        // Variant is added, remove it directly
                        updatedStages[sidx].variants.splice(variantIndex, 1);
                      } else {
                        // Variant is not added, mark it for deletion
                        updatedStages[sidx].variants[variantIndex].to_delete =
                          true;
                      }
                    }
                  }

                  // Update the state with the modified stages
                  setFormData((prev: any) => ({
                    ...prev,
                    stages: updatedStages,
                  }));
                  setDeleteVariantIndex(null); // Reset deleteVariantIndex
                }}
              />
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setDeleteVariantIndex(null);
                }}
              />
            </div>
          }
          handleCancel={() => setDeleteVariantIndex(null)}
        >
          <p className="text-left">
            Are you sure you want to delete the variant
          </p>
        </Modal>
      )}
    </div>
  );
};

export default Stages;
