import React, { useEffect, useMemo, useState } from "react";
import UpandDownArrow from "../../../assets/svg/dateIcon.svg";
import { useTable, usePagination, useSortBy } from "react-table";
import { useSticky } from "react-table-sticky";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import { Link } from "react-router-dom";
import ManagementTableLayout from "../../../components/MangementTable";
import Pagination from "../../../components/Pagination";
import EditIcon from "../../../assets/EditIcon";

const ProjectDraftTable = () => {
  const [totalCount, setTotalCount] = useState<number>(50);
  const [pageLength, setPageLength] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [settableData, setSettableData] = useState<any>([]);
  const type = ["Professional", "Customer", "Admin"];
  const all_data = Array.from({ length: 50 }).map((_, id) => {
    return {
      project_name: "project 1",
      location: "Vadodara, Gujarat",
      role: type[id % 3],
      date: "25/05/2023",
      total_floors: "25",
      total_areas: "25",
      total_orders: "25",
      professionals: "5",
      created_by: "Aishwarya Chatterjee",
    };
  });

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  const COLUMN = [
    {
      id: "project_name",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Project name</div>
      ),
      accessor: "project_name",
      width: 100,
    },
    {
      id: "location",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Location</div>
      ),
      accessor: "location",
      width: 90,
    },
    {
      id: "date",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Date</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      width: 90,
      accessor: "date",
    },
    {
      id: "total_floors",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Floors</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      width: 50,
      accessor: "total_floors",
    },
    {
      id: "total_areas",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Areas</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      width: 50,
      accessor: "total_areas",
    },
    {
      id: "total_orders",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Orders</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      width: 50,
      accessor: "total_orders",
    },
    {
      id: "professionals",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Professionals</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      width: 20,
      accessor: "professionals",
    },
    {
      id: "created_by",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Created by</div>
      ),
      accessor: "created_by",
      width: 120,
    },
    {
      id: "role",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Role</div>
      ),
      accessor: "role",
      width: 30,
    },
    {
      id: "action",
      width: 30,
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          Action Items
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      accessor: "action",
      Cell: ({ row }: { row: any }) => (
        <div className="bg-pot-yellow flex justify-center items-center w-[max-content] p-2 rounded-md mx-auto">
          <Link to={`/`}>
            {/* <Link to={`/products/${row.original.id}`}> */}
            <EditIcon color="#fff" width="14px" />
          </Link>
        </div>
      ),
    },
  ];
  // console.log(filteredData, 'filteredData');

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    if (page > 2) return;
    setSettableData(all_data.slice(page * pageLength, (page + 1) * pageLength));
    setTotalCount(all_data.length);
  }, [totalCount, pageLength, page]);

  const columns = useMemo(() => COLUMN, [userData]);
  const data = useMemo(() => settableData || [], [settableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page - 1, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
      useSticky,
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="h-full">
      <div className="flex flex-row justify-between items-center py-2 w-full px-3 h-[3rem] ">
        <div className="flex gap-4 w-full justify-between">
          <div className="w-[17%]">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Enter Something"
              value={inputVal}
              rightIcon={<SearchIcon />}
            />
          </div>
          <div className="inline-flex gap-3 items-center">
            <Link to={"/project-drafts"}>
              <div className="flex items-center gap-3 border bg-[#383838] text-white rounded-md px-2  py-1 text-xs">
                <p className="font-medium">Drafts</p>
                <p className="bg-white px-1.5 py-0.5 rounded-full text-black text-xs">
                  2
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="h-[calc(100%-5.5rem)] w-full relative -z-0">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>

      <div className="w-full">
        <Pagination
          currentPage={page}
          pageSize={pageLength}
          totalCount={totalCount}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
};

export default ProjectDraftTable;
