import React, { useState } from "react";

type ProjectCardPropType = {
  count: number;
  color: string;
  title: string;
  icon: React.ReactNode;
  setIsHovered: (isHovered: boolean) => void;
  isHovered: boolean;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
};

const ProjectCard = ({
  count,
  color,
  title,
  icon,
  setIsHovered,
  isHovered,
  handleMouseEnter,
  handleMouseLeave,
}: ProjectCardPropType) => {
  const cardStyle = {
    backgroundColor: isHovered ? color : "transparent",
    color: isHovered ? "white" : "black",
  };

  return (
    <div
      className="bg-[#F9F9F9] w-full flex flex-col max-w-[14vw]"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          borderColor: color,
          backgroundColor: cardStyle.backgroundColor,
        }}
        className="border-l-2 py-3 px-3"
      >
        <div className="flex justify-between items-center">
          <p
            className="font-medium text-[20px]"
            style={{ color: cardStyle.color }}
          >
            {count}
          </p>
          <div style={{ color: cardStyle.color }}>{icon}</div>
        </div>
        <p
          className="text-xs font-gilroy-medium text-[#AEAEAE] text-left "
          style={{ color: cardStyle.color }}
        >
          {title}
        </p>
      </div>
    </div>
  );
};

export default ProjectCard;
