import React, { useEffect, useMemo, useState } from "react";
import calenderIcon from "../../../assets/svg/clenderIcon.svg";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useParams } from "react-router-dom";
import ProgressBar from "../../../components/Progressbar";
import EditIcon from "../../../assets/EditIcon";
import activeStatus from "../../../assets/svg/activestatus.svg";
import DeActiveStatus from "../../../assets/svg/deactivatestatus.svg";
import { formatDate } from "../../../utils/formatDateTime";
import { useRecoilState, useRecoilValue } from "recoil";
import { singleProjectAtom, stateAtom } from "../../../atom";
import { updateProject } from "../../../api/project";
import ScaleLoading from "../../../components/ScaleLoading";
import { states } from "../../../utils/states";

export default function OrderProjectDetails({ projectDetails }: any) {
  const { id } = useParams();
  const stateData = useRecoilValue(stateAtom);
  const [visibilityLoading, setVisibilityLoading] = useState(false);
  const [visibilityError, setVisibilityError] = useState(false);
  const [singleProjectData, setSingleProjectData] =
    useRecoilState(singleProjectAtom);

  // Function to convert Iso state code into full name of state (MH, Maharashtra)
  const stateCodeToName = (stateCode: any) => {
    if (states[stateCode]) {
      return states[stateCode];
    } else {
      return "-";
    }
  };

  const handleVisibility = async () => {
    setVisibilityLoading(true);
    try {
      if (singleProjectData) {
        const { name, address_state, address_city, is_public } =
          singleProjectData;
        const { data } = await updateProject(id, {
          name,
          address_state,
          address_city,
          is_public: !is_public,
        });
        setSingleProjectData(data?.project);
      }
    } catch (err) {
      setVisibilityError(true);
    }
    setVisibilityLoading(false);
  };

  const getTotalAreas = () => {
    let areaCount: number = 0;
    projectDetails?.floors?.map((floor: any) => {
      areaCount += floor?.areas?.length;
    });
    return areaCount;
  };

  return (
    <div className="h-auto">
      <div className={`flex justify-between`}>
        <p className="font-medium text-[14px] leading-5">Project Details</p>
      </div>
      <div className="flex justify-between w-full">
        <div className={`w-full flex gap-44`}>
          <div className="w-full flex flex-col items-baseline justify-between">
            <div className="w-full">
              {projectDetails?.name ? (
                <p className="text-[25px] flex font-bold ">
                  {projectDetails?.name}
                </p>
              ) : (
                <p className="text-[25px] flex font-mangoGrotesque-black opacity-30">
                  No Project Added
                </p>
              )}
            </div>
            <div className="w-full flex">
              <div className="w-[50%]">
                <div className="flex flex-col gap-2 text-[12px]">
                  <div className="flex items-center gap-3">
                    <p className="text-[12px] flex  text-[#000000]">
                      Date created:{" "}
                      {projectDetails?.created_at
                        ? formatDate(projectDetails?.created_at)
                        : "-"}
                    </p>
                  </div>
                  <p className="text-[12px] flex  text-[#000000] gap-2">
                    Location:
                    <span>
                      {/* If both city and state are present then display both */}
                      {projectDetails?.address_city &&
                      projectDetails?.address_state
                        ? `${projectDetails.address_city}, ${
                            stateData &&
                            stateData.find(
                              (state: any) =>
                                state.state_code ===
                                projectDetails.address_state
                            )?.name
                          }`
                        : projectDetails?.address_city &&
                          !projectDetails?.address_state
                        ? projectDetails.address_city
                        : "-"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="w-[50%]">
                <div className="text-sm flex flex-col justify-center gap-2 text-[12px]">
                  <p className="text-[12px] flex  text-[#000000]">
                    Total Floors: {projectDetails?.floors?.length || "-"}
                  </p>
                  <p className="text-[12px] flex  text-[#000000]">
                    Total Areas: {getTotalAreas() || "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
