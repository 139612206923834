import React from "react";

const FacebookIcon = ({ size = "20", color = "#AEAEAE" }: any) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6917 7.86536C11.6153 7.75227 11.5123 7.65969 11.3917 7.59576C11.271 7.53182 11.1366 7.49849 11.0001 7.4987H8.50008V5.83203H10.3334C10.5544 5.83203 10.7664 5.74423 10.9227 5.58795C11.0789 5.43167 11.1667 5.21971 11.1667 4.9987V1.66536C11.1667 1.44435 11.0789 1.23239 10.9227 1.07611C10.7664 0.919829 10.5544 0.832031 10.3334 0.832031H7.66675C6.38487 0.832031 5.15549 1.34126 4.24907 2.24768C3.34264 3.15411 2.83341 4.38349 2.83341 5.66536V7.4987H1.00008C0.779068 7.4987 0.567106 7.5865 0.410826 7.74278C0.254546 7.89906 0.166748 8.11102 0.166748 8.33203V11.6654C0.166748 11.8864 0.254546 12.0983 0.410826 12.2546C0.567106 12.4109 0.779068 12.4987 1.00008 12.4987H2.83341V18.332C2.83341 18.553 2.92121 18.765 3.07749 18.9213C3.23377 19.0776 3.44573 19.1654 3.66675 19.1654H7.66675C7.88776 19.1654 8.09972 19.0776 8.256 18.9213C8.41228 18.765 8.50008 18.553 8.50008 18.332V12.4987H9.66675C9.83365 12.4989 9.99675 12.4489 10.1349 12.3553C10.2731 12.2617 10.38 12.1288 10.4417 11.9737L11.7751 8.64036C11.8254 8.51418 11.8441 8.3776 11.8296 8.24252C11.8151 8.10744 11.7678 7.97797 11.6917 7.86536ZM9.10008 10.832H7.66675C7.44573 10.832 7.23377 10.9198 7.07749 11.0761C6.92121 11.2324 6.83341 11.4444 6.83341 11.6654V17.4987H4.50008V11.6654C4.50008 11.4444 4.41228 11.2324 4.256 11.0761C4.09972 10.9198 3.88776 10.832 3.66675 10.832H1.83341V9.16537H3.66675C3.88776 9.16537 4.09972 9.07757 4.256 8.92129C4.41228 8.76501 4.50008 8.55305 4.50008 8.33203V5.66536C4.50228 4.82619 4.83662 4.02201 5.43001 3.42862C6.0234 2.83523 6.82757 2.5009 7.66675 2.4987H9.50008V4.16536H8.83341C8.55126 4.12426 8.26368 4.14151 7.98846 4.21605C7.71324 4.29058 7.45626 4.42082 7.23341 4.5987C7.09818 4.74028 6.99302 4.90778 6.92427 5.0911C6.85553 5.27443 6.82462 5.46977 6.83341 5.66536V8.33203C6.83341 8.55305 6.92121 8.76501 7.07749 8.92129C7.23377 9.07757 7.44573 9.16537 7.66675 9.16537H9.76675L9.10008 10.832Z"
        fill={color}
      />
    </svg>
  );
};

export default FacebookIcon;
