import React from "react";

const EmailIcon = ({ width, color }: any) => {
  return (
    <svg
      width={width || "18"}
      height={width || "14"}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8333 0.333984H3.16663C2.50358 0.333984 1.8677 0.597377 1.39886 1.06622C0.930018 1.53506 0.666626 2.17094 0.666626 2.83398V11.1673C0.666626 11.8304 0.930018 12.4662 1.39886 12.9351C1.8677 13.4039 2.50358 13.6673 3.16663 13.6673H14.8333C15.4963 13.6673 16.1322 13.4039 16.6011 12.9351C17.0699 12.4662 17.3333 11.8304 17.3333 11.1673V2.83398C17.3333 2.17094 17.0699 1.53506 16.6011 1.06622C16.1322 0.597377 15.4963 0.333984 14.8333 0.333984ZM14.4916 2.00065L9.59163 6.90065C9.51416 6.97876 9.42199 7.04075 9.32044 7.08306C9.21889 7.12537 9.10997 7.14715 8.99996 7.14715C8.88995 7.14715 8.78103 7.12537 8.67948 7.08306C8.57793 7.04075 8.48576 6.97876 8.40829 6.90065L3.50829 2.00065H14.4916ZM15.6666 11.1673C15.6666 11.3883 15.5788 11.6003 15.4225 11.7566C15.2663 11.9129 15.0543 12.0007 14.8333 12.0007H3.16663C2.94561 12.0007 2.73365 11.9129 2.57737 11.7566C2.42109 11.6003 2.33329 11.3883 2.33329 11.1673V3.17565L7.23329 8.07565C7.70204 8.54382 8.33746 8.80678 8.99996 8.80678C9.66246 8.80678 10.2979 8.54382 10.7666 8.07565L15.6666 3.17565V11.1673Z"
        fill={color || "#AEAEAE"}
      />
    </svg>
  );
};

export default EmailIcon;
