import React, { Dispatch, SetStateAction, useEffect } from "react";
import InputField from "../../../components/InputField";
import Card1 from "../OptionCards/Card1";
import Card2 from "../OptionCards/Card2";
import Card3 from "../OptionCards/Card3";
import Card4 from "../OptionCards/Card4";
import Card5 from "../OptionCards/Card5";
import TrackTypeOptionCard from "../OptionCards/TrackTypeOptionCard";
import Button from "../../../components/Button";
import PlusIcon from "../../../assets/PlusIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../../atom";
import SelectField2 from "../../../components/SelectedField2";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import UpTriangle from "../../../assets/svg-tsx/UpTriangle";
import DownTriangle from "../../../assets/svg-tsx/DownTriangle";
import TrackTypePreview from "../components/TrackTypePreview";
import MiniBlockWithImgPreview from "../components/MiniBlockWithImgPreview";
import SmallBlockWithImgPreview from "../components/SmallBlockWithImgPreview";
import ColorpickerPreview from "../components/ColorpickerPreview";
import BigBlockWithoutImgPreview from "../components/BigBlockWithoutImgPreview";
import BigBlockWithImgPreview from "../components/BigBlockWithImgPreview";

import TextArea from "../../../components/TextArea";

type VariantFormType = {
  setVariantData: Dispatch<SetStateAction<any>>;
  variantData: any;
  Variants: { label: string; value: string }[];
  selectedVariants: string;
  handleVariantsSelection: (value: string) => void;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setSeletedOption: Dispatch<SetStateAction<string>>;
  handleSubmitData: () => void;
  errors: any;
  setErrors: Dispatch<SetStateAction<any>>;
  loading?: boolean;
};

const Form = ({
  loading = false,
  errors,
  setErrors,
  setVariantData,
  variantData,
  Variants,
  selectedVariants,
  handleVariantsSelection,
  setOpenModal,
  setSeletedOption,
  handleSubmitData,
}: VariantFormType) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const setTitle = useSetRecoilState(titleAtom);

  // useEffect(() => {
  //   setTitle(pathTitles["Create Variant"]);
  //   document.title = `${Sidebartitles[window.location.pathname]}`;
  // }, [setTitle]);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(variantData?.options);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setVariantData((prev: any) => ({ ...prev, options: reorderedItems }));
  };

  const OptionList = ({ el, i, length }: any) => {
    const handleStageUp = () => {
      if (i > 0) {
        setVariantData((prevData: any) => {
          const updatedOptions = [...(prevData?.options ?? [])];
          const temp = updatedOptions[i];
          updatedOptions[i] = updatedOptions[i - 1];
          updatedOptions[i - 1] = temp;
          return { ...prevData, options: updatedOptions };
        });
      }
    };

    const handleStageDown = () => {
      if (i < length - 1) {
        setVariantData((prevData: any) => {
          const updatedOptions = [...(prevData?.options ?? [])];
          const temp = updatedOptions[i];
          updatedOptions[i] = updatedOptions[i + 1];
          updatedOptions[i + 1] = temp;
          return { ...prevData, options: updatedOptions };
        });
      }
    };
    return (
      <div className="flex flex-col gap-2 text-left w-full pb-4" key={i}>
        <div
          className="flex justify-start items-center gap-4"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex">
            <UpTriangle
              stroke="black"
              onClick={handleStageUp}
              disabled={i === 0}
            />

            <DownTriangle
              stroke="black"
              onClick={handleStageDown}
              disabled={i === length - 1}
            />
          </div>
          <p className="text-[#000000] text-[13px] font-medium">
            Option {i + 1}:
          </p>
        </div>
        {el.type === "BIG_BLOCK_WITH_IMAGE" && (
          <Card1
            el={el}
            variantData={variantData}
            setVariantData={setVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
        {el.type === "BIG_BLOCK_WITHOUT_IMAGE" && (
          <Card2
            el={el}
            variantData={variantData}
            setVariantData={setVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
        {el.type === "COLOR_PICKER" && (
          <Card3
            el={el}
            variantData={variantData}
            setVariantData={setVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
        {el.type === "SMALL_BLOCK_WITH_IMAGE" && (
          <Card4
            el={el}
            variantData={variantData}
            setVariantData={setVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
        {el.type === "MINI_BLOCK_WITH_IMAGE" && (
          <Card5
            el={el}
            variantData={variantData}
            setVariantData={setVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
        {el.type === "TRACK_TYPE" && (
          <TrackTypeOptionCard
            el={el}
            variantData={variantData}
            setVariantData={setVariantData}
            setOpenModal={setOpenModal}
            setSeletedOption={setSeletedOption}
          />
        )}
      </div>
    );
  };

  return (
    <div className=" text-sm  h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
      {/* heading */}
      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  px-4">
        <p className="flex font-bold">
          {id ? "Edit" : "Create"} Variant Template
        </p>
      </div>

      <div className="flex w-full gap-2  flex-1  overflow-y-auto custom-scrollbar py-3">
        <div className="w-[55%] overflow-y-auto custom-scrollbar  flex flex-col space-y-4 mt-4 px-4">
          {/* name */}
          <InputField
            disabled={loading}
            onChange={(e) => {
              if (errors.name) {
                delete errors.name;
                setErrors({ ...errors });
              }
              setVariantData((previous: any) => ({
                ...previous,
                name: e,
              }));
            }}
            value={variantData.name}
            placeholder="Variant name"
            label="Variant Name:"
            hint={errors.name}
            required
          />

          {/* info */}
          <InputField
            disabled={loading}
            onChange={(e) => {
              if (errors.info) {
                delete errors.info;
                setErrors({ ...errors });
              }
              setVariantData((previous: any) => ({
                ...previous,
                info: e,
              }));
            }}
            value={variantData.info}
            placeholder="Info"
            label="Info:"
            hint={errors.info}
            required
          />

          {/* description */}
          <TextArea
            rows={4}
            label="Description:"
            disabled={loading}
            onChange={(e) => {
              if (errors.description) {
                delete errors.description;
                setErrors({ ...errors });
              }
              setVariantData((previous: any) => ({
                ...previous,
                description: e,
              }));
            }}
            value={variantData.description}
            placeholder="Variant description"
            hint={errors.description}
            required
          />

          <SelectField2
            required
            disabled={loading || !!id}
            label="Select Variant Type:"
            options={Variants}
            value={selectedVariants}
            onChange={handleVariantsSelection}
            placeholder="Select variant type"
            hint={[...(errors?.kind || []), ...(errors?.options || [])]}
          />

          {/* previews */}
          {selectedVariants === "BIG_BLOCK_WITH_IMAGE" ? (
            <BigBlockWithImgPreview />
          ) : selectedVariants === "BIG_BLOCK_WITHOUT_IMAGE" ? (
            <BigBlockWithoutImgPreview />
          ) : selectedVariants === "COLOR_PICKER" ? (
            <ColorpickerPreview />
          ) : selectedVariants === "SMALL_BLOCK_WITH_IMAGE" ? (
            <SmallBlockWithImgPreview />
          ) : selectedVariants === "MINI_BLOCK_WITH_IMAGE" ? (
            <MiniBlockWithImgPreview />
          ) : selectedVariants === "TRACK_TYPE" ? (
            <TrackTypePreview />
          ) : null}

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="reorderable-list">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {variantData?.options
                    ?.filter((each: any) => !each.to_delete)
                    .map((el: any, i: any, arr: any) => {
                      return (
                        <Draggable
                          key={i}
                          draggableId={`item-${i}`}
                          index={i}
                          isDragDisabled={arr.length < 2}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <OptionList el={el} i={i} length={arr?.length} />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div>
            <Button
              label="Add option"
              leftIcon={<PlusIcon />}
              onClick={() => {
                setOpenModal(true);
                setSeletedOption("");
              }}
              disabled={selectedVariants ? false : true}
              variant="secondary"
            />
          </div>
        </div>

        {/* <div className="px-4 border-r border-[#ECECEC]"></div> */}
      </div>

      {/* footer */}
      <div className="flex gap-x-4 w-[100%] py-3 border-t  border-[#ECECEC] px-4">
        <Button
          label={id ? "Update Variant Template" : "Create Variant Template"}
          variant="primary"
          onClick={handleSubmitData}
          disabled={loading}
        />

        <Button
          onClick={() => navigate(`/products/variant-templates`)}
          label="Cancel"
          variant="secondary-outline"
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default Form;
