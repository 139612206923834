import React from "react";
export type IconProp = { color?: string , width?:string }
function TickMark({ color, width }: IconProp) {
  return (
    <svg
      width={width || "12"}
      height={width || "8"}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0325 0.407487C10.9628 0.337191 10.8799 0.281395 10.7885 0.243318C10.6971 0.205242 10.599 0.185638 10.5 0.185638C10.401 0.185638 10.303 0.205242 10.2116 0.243318C10.1202 0.281395 10.0372 0.337191 9.96752 0.407487L4.38002 6.00249L2.03252 3.64749C1.96013 3.57756 1.87468 3.52257 1.78103 3.48567C1.68739 3.44877 1.5874 3.43067 1.48677 3.43241C1.38613 3.43415 1.28682 3.4557 1.19451 3.49582C1.1022 3.53594 1.0187 3.59385 0.948773 3.66624C0.878844 3.73863 0.823859 3.82408 0.786956 3.91773C0.750053 4.01137 0.731955 4.11136 0.733696 4.212C0.735437 4.31263 0.756983 4.41194 0.797103 4.50425C0.837223 4.59656 0.895132 4.68006 0.967523 4.74999L3.84752 7.62999C3.91725 7.70028 4.0002 7.75608 4.09159 7.79416C4.18299 7.83223 4.28101 7.85184 4.38002 7.85184C4.47903 7.85184 4.57706 7.83223 4.66846 7.79416C4.75985 7.75608 4.8428 7.70028 4.91252 7.62999L11.0325 1.50999C11.1087 1.43976 11.1694 1.35452 11.211 1.25964C11.2525 1.16477 11.274 1.06231 11.274 0.958737C11.274 0.855161 11.2525 0.752707 11.211 0.657832C11.1694 0.562958 11.1087 0.477719 11.0325 0.407487Z"
        fill={color || "#D4A15E"}
      />
    </svg>
  );
}

export default TickMark;
