type ClockIconProps = {
  color?: string;
};
const ClockIcon = ({ color }: ClockIconProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16659 0.666504C7.51841 0.666504 5.90725 1.15525 4.53684 2.07092C3.16643 2.9866 2.09832 4.28809 1.46759 5.81081C0.836863 7.33353 0.671835 9.00908 0.993378 10.6256C1.31492 12.2421 2.10859 13.727 3.27403 14.8924C4.43947 16.0578 5.92433 16.8515 7.54084 17.173C9.15734 17.4946 10.8329 17.3296 12.3556 16.6988C13.8783 16.0681 15.1798 15 16.0955 13.6296C17.0112 12.2592 17.4999 10.648 17.4999 8.99984C17.4999 7.90549 17.2844 6.82186 16.8656 5.81081C16.4468 4.79976 15.833 3.8811 15.0591 3.10728C14.2853 2.33346 13.3667 1.71963 12.3556 1.30084C11.3446 0.882052 10.2609 0.666504 9.16659 0.666504ZM9.16659 15.6665C7.84805 15.6665 6.55911 15.2755 5.46279 14.543C4.36646 13.8104 3.51198 12.7692 3.00739 11.5511C2.50281 10.3329 2.37079 8.99244 2.62802 7.69924C2.88526 6.40603 3.52019 5.21814 4.45254 4.28579C5.38489 3.35344 6.57278 2.7185 7.86599 2.46127C9.15919 2.20403 10.4996 2.33606 11.7178 2.84064C12.936 3.34522 13.9772 4.19971 14.7097 5.29604C15.4423 6.39236 15.8333 7.6813 15.8333 8.99984C15.8333 10.7679 15.1309 12.4636 13.8806 13.7139C12.6304 14.9641 10.9347 15.6665 9.16659 15.6665ZM11.7499 9.52484L9.99992 8.5165V4.83317C9.99992 4.61216 9.91212 4.4002 9.75584 4.24392C9.59956 4.08764 9.3876 3.99984 9.16659 3.99984C8.94558 3.99984 8.73361 4.08764 8.57733 4.24392C8.42105 4.4002 8.33326 4.61216 8.33326 4.83317V8.99984C8.33326 8.99984 8.33326 9.0665 8.33326 9.09984C8.33819 9.15726 8.35226 9.21352 8.37492 9.2665C8.39208 9.31595 8.41443 9.36343 8.44159 9.40817C8.46439 9.45554 8.49234 9.50025 8.52492 9.5415L8.65826 9.64984L8.73326 9.72484L10.8999 10.9748C11.0269 11.0468 11.1706 11.0842 11.3166 11.0832C11.5011 11.0845 11.6808 11.0245 11.8276 10.9126C11.9743 10.8008 12.0798 10.6434 12.1275 10.4651C12.1751 10.2869 12.1623 10.0978 12.0909 9.92765C12.0196 9.75748 11.8938 9.61581 11.7333 9.52484H11.7499Z"
        fill={color || "#EADA4A"}
      />
    </svg>
  );
};

export default ClockIcon;
