import CrossIcon from "../../../assets/CrossIcon";
import Button from "../../../components/Button";
import Modal from "../../../components/common/Modal";
import ActiveStatusIcon from "../../../assets/svg/activestatus.svg";
import DeactiveStatusIcon from "../../../assets/svg/deactivatestatus.svg";
import { Link } from "react-router-dom";
const ProfessionalTable = ({
  data,
  handleDeleteCollab,
  handleCollabDeleteModal,
  collabDeleteModal,
  setCollabDeleteModal,
  handleHiddenVisibility,
  actionLoading,
}: any) => {
  return (
    <>
      {collabDeleteModal && (
        <Modal
          header="Remove Collab"
          clickOutsideToClose
          allowScroll
          maxHeight="max-h-[90vh] "
          handleCancel={() => setCollabDeleteModal(null)}
          footer={
            <div className="flex gap-2">
              <Button
                label="Remove"
                onClick={() => handleDeleteCollab(collabDeleteModal)}
                variant="primary"
              />
              <Button
                label="Cancel"
                variant="secondary"
                onClick={() => setCollabDeleteModal(null)}
              />
            </div>
          }
        >
          <div className="py-2">
            Are you sure you want to remove the collab?
          </div>
        </Modal>
      )}

      <div className="text-sm text-gray-700 mb-0 overflow-x-auto overflow-y-clip custom-scrollbar">
        <table className="w-full border border-c-gray-4 border-collapse text-[0.7rem] text-center table-auto">
          <tbody>
            {/* Heading */}
            <tr className="border border-c-gray-4 [&>th]:px-5 [&>th]:py-3 font-gilroy-semi-bold [&>th]:border [&>th]:border-c-gray-4 [&>th]:whitespace-nowrap [&>th]:bg-c-gray-3 [&>th]:text-start  [&>th]:text-[12px] bg-[#ECECEC]">
              <th>Professional Name</th>
              <th>Company</th>
              <th>Role</th>
              <th>Show Publically</th>

              {/* {!data?.can_delete_member && */}
              <th>Action</th>
              {/*  } */}
            </tr>
            {data?.length === 0 ? (
              <tr>
                <td
                  colSpan={5}
                  className="text-center py-10 font-gilroy-medium text-[12px] leading-[22px]"
                >
                  No professional added for Collaboration
                </td>
              </tr>
            ) : (
              data?.map((item: any, itemIndex: any) => {
                // console.log(item?.id);

                return (
                  <tr
                    className="border border-c-gray-4 [&>td]:border [&>td]:border-c-gray-4 [&>td]:whitespace-nowrap [&>td]:font-gilroy-semi-bold [&>td]:text-[12px] [&>td]:leading-[20px] [&>td]:px-5 [&>td]:text-start [&>td]:py-1"
                    key={itemIndex}
                  >
                    <td>
                      <div className="flex justify-start items-center gap-3">
                        <div>
                          <img
                            src={`${process.env?.REACT_APP_BUCKET}/${item?.professional?.profile_picture}`} // eslint-disable-line
                            alt="profile_picture"
                            className="w-10 h-10 object-cover"
                          />
                        </div>
                        <Link
                          to={`/customers/professionals/employee/${item?.professional?.customer_id}`}
                        >
                          <p className="hover:underline cursor-pointer">
                            {
                              (item?.professional?.salutation,
                              item?.professional?.name)
                            }
                          </p>
                        </Link>
                      </div>
                    </td>
                    <td>
                      <Link
                        to={`/customers/professionals/company/${item?.professional?.company?.customer_id}`}
                      >
                        <p className="hover:underline cursor-pointer">
                          {item?.professional?.company?.name}
                        </p>
                      </Link>
                    </td>

                    <td>{item?.role}</td>
                    <td>
                      <div
                        className={` h-8 w-8 ${
                          actionLoading
                            ? "cursor-not-allowed opacity-50"
                            : "cursor-pointer opacity-100"
                        }`}
                        onClick={() =>
                          handleHiddenVisibility(
                            item?.id,
                            item?.is_visible_on_profile
                          )
                        }
                      >
                        {item?.is_visible_on_profile ? (
                          <div className="cursor-pointer h-8 w-8">
                            <img
                              src={ActiveStatusIcon}
                              alt="status-icon"
                              className="w-full object-contain h-full"
                            />
                          </div>
                        ) : (
                          <div className="cursor-pointer  h-8 w-8">
                            <img
                              src={DeactiveStatusIcon}
                              alt="status-icon"
                              className="w-full object-contain h-full"
                            />
                          </div>
                        )}
                      </div>
                    </td>

                    {/* {!data?.can_delete_member && ( */}
                    <td>
                      <div
                        className="flex justify-center items-center mx-1 cursor-pointer"
                        onClick={() => handleCollabDeleteModal(item?.id)}
                      >
                        <CrossIcon color="black" />
                      </div>
                    </td>
                    {/* )} */}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ProfessionalTable;
