import { useNavigate } from "react-router-dom";
import pagenotfound from "../../../assets/images/404.png";
import Button from "../../../components/Button";
interface NotfoundProps {
  redirectURL?: string;
  redirectLabel?: string;
}
function Notfound({
  redirectURL = "/",
  redirectLabel = "Go back to Dashboard",
}: NotfoundProps) {
  const navigate = useNavigate();

  const navigateToHomePage = () => {
    navigate(redirectURL);
  };

  return (
    <div className="h-full m-auto flex justify-center flex-col items-center">
      <div className=" w-[50%] h-[70%] m-auto">
        <img
          src={pagenotfound}
          alt="404"
          className="w-[100%] h-[100%] object-contain"
        />
      </div>

      <div className="m-auto flex justify-center items-center">
        <Button
          onClick={navigateToHomePage}
          label={redirectLabel}
          variant="secondary-outline"
        />
      </div>
    </div>
  );
}

export default Notfound;
