import { useEffect, useState } from "react";

type SingleSelectionPropType = {
  isSelected?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  rounded?: boolean;
  label?: string;
};

const SingleSelection = ({
  isSelected = false,
  onClick,
  disabled = false,
  rounded = false,
  label = "",
}: SingleSelectionPropType) => {
  const [value, setValue] = useState<boolean>(isSelected);

  const handleClick = () => {
    if (disabled) {
      return;
    }
    setValue(!value);

    if (onClick) {
      onClick();
    }
  };
  // console.log(value, isSelected)
  const activeStyle = ` border hover:border-pot-yellow hover:bg-pot-yellow  hover:bg-opacity-25 active:border-black cursor-pointer ${
    rounded ? "rounded-full" : ""
  }`;
  const disabledStyle = `cursor-not-allowed border-[#F4F2EE] ${
    rounded ? "rounded-full" : ""
  }`;

  useEffect(() => {
    if (value !== isSelected) {
      setValue(isSelected);
    }
  }, [isSelected]);

  return (
    <div
      className={`flex flex-row justify-start items-center space-x-3 mt-2  relative ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={handleClick}
    >
      <div
        onClick={handleClick}
        className={`flex items-center justify-center w-4 h-4 border-2 ${
          disabled ? disabledStyle : activeStyle
        }`}
      >
        {value && (
          <div
            className={`${
              disabled ? `bg-[#F4F2EE] border` : `bg-pot-yellow`
            } w-[60%] h-[60%] ${rounded ? "rounded-full" : ""}`}
          >
            {" "}
          </div>
        )}
      </div>
      <p
        className={`font-gilroy-medium text-[12px] leading-[20px] ${
          disabled ? "opacity-30" : "opacity-100"
        } `}
      >
        {label && label}
      </p>
    </div>
  );
};

export default SingleSelection;
