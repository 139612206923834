import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { AnimatePresence, motion } from "framer-motion";
import UpandDownArrow from "../../../assets/svg/dateIcon.svg";
import ManagementTableLayout from "../../../components/MangementTable";
import ShowEntries from "../../../components/ShowEntries";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import Button from "../../../components/Button";
import PlusIcon from "../../../assets/PlusIcon";
import SelectDropDown from "../../../components/FilterDropDown";
import DownloadIcon from "../../../assets/DownloadIcon";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { MainAppSideBarExpandAtom } from "../../../atom";
import FilterIcon from "../../../assets/svg/filtericon.svg";
import { DatePicker } from "antd";
import Pagination from "../../../components/Pagination";

type getUserDataProps = {
  page?: number;
  totalCount?: number;
  pageLength?: number;
};

const getUserData = async ({
  page,
  totalCount,
  pageLength,
}: getUserDataProps) => {
  return fetch(
    `https://dummyjson.com/users?limit=${pageLength}&skip=${page}&total=${totalCount}`
  ).then((response) => response.json());
};

const assignData = [
  {
    name: "Tommy Geoco",
    ongoing: 2,
  },
  {
    name: "Tommy Geoco",
    ongoing: 2,
  },
  {
    name: "Tommy Geoco",
    ongoing: 2,
  },
];

const options = [
  { label: "Option 1", value: "option1" },
  { label: "Option 2", value: "option2" },
  { label: "Option 3", value: "option3" },
];

const datas = [
  { id: 1, name: "John", status: "Unverified" },
  { id: 2, name: "Jane", status: "Verified" },
  { id: 3, name: "Mike", status: "Verified" },
];
function DesignConsultantTable() {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageLength, setPageLength] = useState<number>(16);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initial, setInitial] = useState(true);
  const [status, setStatus] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [userData, setUserData] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isDivOpen, setIsDivOpen] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getUserData({ page, totalCount, pageLength })
      .then((res) => {
        console.log(res, "result");
        setUserData(res?.users || []);
        setPageLength(res?.limit || 0);
        setPage(res?.skip || 0);
        setTotalCount(res?.total || 0);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [
    totalCount,
    setTotalCount,
    pageLength,
    setPageLength,
    page,
    setPage,
    status,
  ]);

  const downloadReport = async () => {
    const response = await fetch("your-api-url", {
      method: "GET",
      headers: {
        "Content-Type": "application/xml",
      },
    });

    const blob = await response.blob();

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.xml");
    document.body.appendChild(link);
    link.click();
  };

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  const handleAssign = async (id: string) => {
    setSelectedId(id);
    setIsDivOpen(!isDivOpen);
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  const COLUMN = [
    {
      id: "firstName",
      Header: ({ column }: { column: any }) => (
        <div className=" w-[88px]">
          <div className="flex justify-start items-center">Name</div>
        </div>
      ),
      accessor: "firstName",
      width: 40,
      Cell: ({ row }: { row: any }) => (
        <Link to={`/consultancy-list/${row.original.id}`}>
          <p>{row.original.firstName}</p>
        </Link>
      ),
    },
    {
      id: "email",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Email id</div>
      ),
      accessor: "email",
      Cell: ({ value }: { value: any }) => <p>{value}</p>,
    },
    {
      id: "phone",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Phone Number</div>
      ),
      accessor: "phone",
      Cell: ({ value }: { value: any }) => (
        <div className="font-semibold">{value}</div>
      ),
    },
    {
      id: "location",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Location</div>
      ),
      accessor: "location",
      width: 100,
      Cell: ({ row }: { row: any }) => (
        // console.log(row)
        <p>{row.original.address?.city}</p>
      ),
    },
    {
      id: "opendate",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Open date</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      accessor: "birthDate",
      width: 100,
      Cell: ({ value }: { value: any }) => <p>{value}</p>,
    },
    {
      id: "closedate",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2">
          <p>Close date</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      accessor: "birthDate",
      width: 100,
      Cell: ({ value }: { value: any }) => <p>{value}</p>,
    },
    {
      id: "consultantNames",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          Consultant Name
        </div>
      ),
      accessor: "maidenName",
      width: 100,
      Cell: ({ value, row }: { value: any; row: any }) => (
        <div className="relative">
          <div
            className=" w-12 text-center px-1.5 py-0.5 bg-black text-white font-medium rounded-md cursor-pointer"
            onClick={() => handleAssign(row.original.id)}
          >
            Assign
          </div>
          {selectedId === row.original.id && isDivOpen && (
            <AnimatePresence>
              <motion.div
                className={`absolute top-full  ${
                  sidebarExpand ? "right-12" : "left-0"
                } z-20 w-[20vw] h-auto bg-white shadow-lg py-1`}
              >
                <div className="relative">
                  <input
                    onChange={() => {}}
                    placeholder="Search"
                    className="w-full border pl-6 pr-10 py-2 text-sm outline-none hover:border-pot-yellow"
                    type="text"
                  />
                  <div className="absolute right-0 top-1 h-13 w-13 mr-3 ">
                    <SearchIcon width="31" height="31" />
                  </div>
                </div>
                <div className="grid grid-cols-1 ">
                  {assignData?.map((el: any, i: number) => {
                    return (
                      <div
                        key={i}
                        className="flex justify-between px-6 items-center text-center py-3 border hover:border-pot-yellow cursor-pointer"
                      >
                        <div className="flex flex-col gap-3">
                          <p className="text-sm font-black">{el.name}</p>
                          <button className="border-black border text-sm py-1 rounded-md ">
                            View details
                          </button>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                          <p className="rounded-full py-1 px-1 bg-pot-yellow h-6 w-6 text-center flex items-center justify-center text-sm text-white">
                            {el.ongoing}
                          </p>
                          <p className="text-sm">Ongoing</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </motion.div>
            </AnimatePresence>
          )}
        </div>
      ),
    },
    {
      id: "status",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          Status
        </div>
      ),
      accessor: "status",
      width: 80,
      Cell: ({ value }: { value: any }) => (
        <p className="px-1.5 py-0.5 text-black font-medium">Awaiting</p>
      ),
    },
    {
      id: "proposal",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          Proposal
        </div>
      ),
      accessor: "status",
      width: 80,
      Cell: ({ value }: { value: any }) => (
        // <p className="bg-[#BBF8DF] w-14 px-1.5 py-0.5 text-black font-medium text-center ">Rejected</p>
        <p className="bg-[#FFB4A1] w-14 px-1.5 py-0.5 text-black font-medium text-center ">
          Accepted
        </p>
      ),
    },
  ];
  const columns = useMemo(() => COLUMN, [userData, isDivOpen, sidebarExpand]);
  const data = useMemo(() => userData || [], [userData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page - 1, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (initial) {
      setInitial(false);
    }
  }, [initial]);

  return (
    <>
      <div className="flex flex-row justify-between items-center py-2">
        <div className="flex gap-4 w-[max-content]">
          <div className=" w-[235px]">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Enter Something"
              value={inputVal}
              rightIcon={<SearchIcon />}
            />
          </div>
          <div className="w-fit">
            <Link to={"/create-consultant"}>
              <Button label="Create consultant" leftIcon={<PlusIcon />} />
            </Link>
          </div>
        </div>

        <div className="w-[65%] flex flex-row justify-end items-center gap-6 ">
          <div className=" flex gap-2 items-center">
            <SelectDropDown
              options={options}
              onChange={handleSelectChange}
              heading="Filters"
              icon={<img src={FilterIcon} alt="filter-icon" />}
            />
            <DatePicker
              placeholder="Date"
              className="hover:border-gray-300  ring-opacity-5 focus:border-gray-300"
            />
          </div>

          <div
            className="flex items-center gap-1 text-xs pr-6"
            onClick={downloadReport}
          >
            <DownloadIcon />
            <p className=" underline text-pot-yellow">Download</p>
          </div>
        </div>
      </div>

      <div className="relative -z-0 h-fit">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>

      <div className="w-full flex gap-4 px-3">
        <Pagination
          currentPage={page}
          pageSize={pageLength}
          totalCount={totalCount}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </>
  );
}

export default DesignConsultantTable;
