import { useEffect, useState } from "react";
import EditIcon from "../../../assets/EditIcon";
import PlusIcon from "../../../assets/PlusIcon";
import AddProfessional from "./AddProfessional";
import EditProfessional from "./EditProfessional";

function TotalProfessionals({ data, orderDetails, setOrderDetails }: any) {
  const [openModal, setOpenModal] = useState(false);
  const [openModalEditProfessional, setOpenModalEditProfessional] =
    useState("");

  const handleAddProfessional = () => {
    setOpenModal(true);
  };
  const handleEditProfessional = (id: any) => {
    setOpenModalEditProfessional(id);
  };
  return (
    <div className="text-start px-3 pt-4 py-4  h-full">
      <div className="w-full flex justify-between items-center">
        <h1 className="font-medium text-sm">Professional Name:</h1>

        {orderDetails?.status === "DRAFT" ? (
          data !== undefined ? (
            <div
              onClick={() => handleEditProfessional(data?.user?.id)}
              className="cursor-pointer"
            >
              <EditIcon />
            </div>
          ) : (
            <div onClick={handleAddProfessional} className="cursor-pointer">
              <PlusIcon color="black" />
            </div>
          )
        ) : null}
      </div>
      <div className="text-start mt-3 text-[12px] leading-5">
        {data !== undefined ? (
          <>
            {data?.user?.name ? (
              <ul>
                <li className="list-disc ml-4"> {data?.user?.name}</li>
              </ul>
            ) : (
              <p>-</p>
            )}
            {data?.professional?.profession?.name ||
              data?.professional?.address_city(
                <div className="flex gap-2 text-start mt-1 text-[12px] leading-5">
                  <p> {data?.professional?.profession?.name}</p>
                  <ul>
                    <li className="list-disc ml-4">
                      {data?.professional?.address_city}
                    </li>{" "}
                  </ul>
                </div>
              )}
          </>
        ) : (
          <p className="flex justify-center items-center mt-10 ">
            No Professional added
          </p>
        )}
      </div>
      {openModal && (
        <AddProfessional
          setOpenModal={setOpenModal}
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
        />
      )}
      {openModalEditProfessional && (
        <EditProfessional
          setOpenModalEditProfessional={setOpenModalEditProfessional}
          openModalEditProfessional={openModalEditProfessional}
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
        />
      )}
    </div>
  );
}

export default TotalProfessionals;
