import React, { useState, useEffect } from "react";
import HourGlassIcon from "../../../assets/HourGlassIcon";
import SeriesCard from "./SeriesCard";
import SeriesIcon from "../../../assets/SeriesIcon";
import { useRecoilValue } from "recoil";
import { seriesDataCount } from "../../../atom";

const SeriesCounts = () => {
  const seriesCount = useRecoilValue(seriesDataCount);


  const cardValues = [
    {
      count: seriesCount,
      icon: <SeriesIcon color={'#AEAEAE'} />,
    },
  ];

  return (
    <div className="w-full flex items-center  gap-x-6 px-4 py-2">
      {cardValues.map((each, index) => (
        <SeriesCard
          key={index}
          {...each}
        />
      ))}
    </div>
  );
};

export default SeriesCounts;
