import React, { useEffect, useMemo, useRef, useState } from "react";
import DeleteIcon from "../../../assets/svg/trash.svg";
import Button from "../../../components/Button";
import { useRecoilState } from "recoil";
import { productPropertySuggestionAtom } from "../../../atom";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import Dropdown from "../productcomponents/Dropdown";
import useOnClickOutside from "../../../hooks/useClickOutside";

export const primaryInputStyle =
  "text-black font-normal border-2 border-[#DEDEDE] hover:border-2 hover:border-[#D4A15E] hover:border-opacity-50 outline-none focus:bg-white focus:border-pot-yellow placeholder-shown:text-pot-grey2 active:border-pot-yellow active:border-2 text-[13px] w-full rounded-md px-2 py-1.5";

type MultipleInputFieldProps = {
  properties: { [key: string]: string } | undefined;
  setFormData: React.Dispatch<any>;
};

export default function MultipleInputFieldTwo({
  properties = {},
  setFormData,
}: MultipleInputFieldProps) {
  const entries = useMemo(() => Object.entries(properties), [properties]);
  const sett = entries.length ? entries : [["", ""]];
  const [props, setProps] = useState(sett);

  const handlePropertyChange = (id: number, value: string) => {
    props[id][1] = value;
    const settableProperties = Object.fromEntries(props);
    setProps(structuredClone(props));
    setFormData((prevData: any) => ({
      ...prevData,
      properties: settableProperties,
    }));
  };

  const handleDeleteProperty = (id: number) => {
    const propsEntries = (props || []).filter((_, idx) => id !== idx);
    const settableProperties = Object.fromEntries(propsEntries);
    setProps(propsEntries);
    setFormData((prevData: any) => ({
      ...prevData,
      properties: settableProperties,
    }));
  };

  const handleAddProperties = () => {
    setProps((prev) => [...prev, ["", ""]]);
  };

  const handleKeyChange = (id: number, newKey: string) => {
    props[id][0] = newKey;
    const settableProperties = Object.fromEntries(props);
    setProps(structuredClone(props));
    setFormData((prevData: any) => ({
      ...prevData,
      properties: settableProperties,
    }));
  };

  const [productPropertySuggestion, setProductPropertySuggestion] =
    useRecoilState(productPropertySuggestionAtom);

  const [focusedId, setFocusedId] = useState<any>(null);

  const NewDropdown = React.memo(({ id, data }: any) => {
    const selectRef = useRef<HTMLInputElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    useOnClickOutside(selectRef, () => {
      if (id === focusedId) {
        setFocusedId(null);
      }
    });

    useEffect(() => {
      if (id === focusedId) {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    });

    const [optionIndex, setOptionIndex] = useState(-1);
    useEffect(() => {
      if (id === focusedId) {
        setOptionIndex(-1);
      }
    }, [props[id][0]]);

    useEffect(() => {
      const handleKeyPress = (event: any) => {
        const length = productPropertySuggestion["option-properties"].filter(
          (el: any) => el.toLowerCase().includes(props[id][0].toLowerCase())
        ).length;
        if (event.key === "Enter" && id === focusedId) {
          const el =
            productPropertySuggestion["option-properties"].filter((el: any) =>
              el.toLowerCase().includes(props[id][0].toLowerCase())
            )[optionIndex] || "";
          props[id][0] = el;
          handleKeyChange(id, el);
          setFocusedId(null);
          return;
        }

        if (event.key === "ArrowUp" && id === focusedId) {
          if (optionIndex <= 0) return;
          setOptionIndex((prev) => prev - 1);
          return;
        }
        if (event.key === "ArrowDown" && id === focusedId) {
          if (optionIndex >= length - 1) return;
          setOptionIndex((prev) => prev + 1);
          return;
        }
      };

      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }, [optionIndex, props[id][0]]);

    useEffect(() => {
      const container = document.getElementById(`dropdown-container1-${id}`);
      if (container) {
        const selectedOption = container.querySelector(
          `#option1-${optionIndex}`
        );
        if (selectedOption) {
          selectedOption.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }
    }, [optionIndex]);

    return (
      <div
        key={id}
        id={`dropdown-container1-${id}`}
        ref={selectRef}
        className="relative"
        onClick={() => {
          setFocusedId(id);
        }}
      >
        <input
          ref={inputRef}
          className="text-sm border-2 w-full border-c-white-3 px-2 py-[6px] rounded-md active:border-2 active:border-pot-yellow text-black font-normal  hover:border-2 hover:border-[#D4A15E] hover:border-opacity-50 outline-none focus:bg-white focus:border-pot-yellow placeholder-shown:text-pot-grey2"
          onChange={(e) => {
            // setInput(e.target.value);
            handleKeyChange(id, e.target.value);
          }}
          value={props[id][0]}
          onKeyDown={(e) => {
            if (e.key === "ArrowUp") {
              e.preventDefault();
            }
          }}
          placeholder="Enter key"
        />
        {id === focusedId &&
          productPropertySuggestion["option-properties"]?.filter((el: any) =>
            el?.toLowerCase()?.includes(props[id][0]?.toLowerCase())
          )?.length > 0 && (
            <ul className="absolute w-full rounded-md divide-y top-[100%] z-[100] py-1 border-2 border-c-white-3 bg-white text-sm flex flex-col  overflow-scroll max-h-[100px] custom-scrollbar overflow-x-hidden">
              {productPropertySuggestion["option-properties"]
                .filter((el: any) =>
                  el.toLowerCase().includes(props[id][0].toLowerCase())
                )
                .map((el: any, index: any) => (
                  <li
                    className={`cursor-pointer py-1.5 px-2 hover:bg-gray-100  ${
                      optionIndex === index ? "bg-c-white-2" : ""
                    }`}
                    key={el}
                    id={`option1-${index}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setProps((prevProps) => {
                        const newProps = [...prevProps];
                        newProps[id] = [el];
                        return newProps;
                      });
                      handleKeyChange(id, el);
                      setFocusedId(null);
                    }}
                  >
                    {el}
                  </li>
                ))}
            </ul>
          )}
      </div>
    );
  });
  NewDropdown.displayName = "NewDropdown";

  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        <p className="text-xs font-gilroy-bold">Properties:</p>

        <div className="flex flex-col gap-3">
          {(props || []).map((_, id) => (
            <div key={id} className="flex items-center gap-3 w-full">
              <div className="flex flex-col gap-2 w-full">
                <p className="text-xs font-gilroy-bold">Key</p>
                <NewDropdown id={id} key={`new-dropdown-${id}`} />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <p className="text-xs font-gilroy-bold">Value</p>
                <input
                  type="text"
                  className={`${primaryInputStyle}`}
                  value={props[id][1]}
                  placeholder="Value"
                  onChange={(e) => handlePropertyChange(id, e.target.value)}
                />
              </div>
              <div className="flex h-6 w-8 mt-7">
                <img
                  src={DeleteIcon}
                  alt="DeleteIcon"
                  onClick={() => handleDeleteProperty(id)}
                  className="cursor-pointer"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="pt-2 pb-6">
          <Button label="Add" variant="primary" onClick={handleAddProperties} />
        </div>
      </div>
    </>
  );
}
