import React, { useState, useEffect } from "react";
import tickIcon from "../assets/svg/tick.svg";
interface ProgressBarProps {
  data?: { id: number; status: boolean; step: string }[];
  width?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ data, width }) => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (data) {
      const trueIndex = data.findIndex((item) => !item.status);
      const endIndex = trueIndex !== -1 ? trueIndex : data.length;

      const currentProgress = (endIndex - 1) * 25; // Adjust the width of the progress bar
      setProgress(currentProgress);
    }
  }, [data]);

  return (
    <div className={`w-full`}>
      <div className="bg-gray-300 h-2 w-full relative rounded-s-full">
        <div className="flex justify-between items-center w-full">
          {data?.map((item) => {
            return (
              <>
                <div className="relative rounded-full h-2 w-2 bg-pot-yellow">
                  <div className="absolute -top-7  w-[1.5vw] -left-1.5">
                    <img
                      src={tickIcon}
                      alt="tickIcon"
                      className={`${item.status ? "" : "opacity-15"}`}
                    />
                  </div>

                  <div
                    className={`absolute top-4 -left-10 font-semibold text-pot-yellow text-[12px] w-[7vw]  ${
                      item.status ? "" : "text-opacity-20"
                    }`}
                  >
                    {item.step}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div
          className="bg-pot-yellow h-2 rounded-s-full transition-all duration-500 ease-in-out absolute top-0"
          style={{ width: `${progress}%`, overflow: "hidden" }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
