import { useState } from "react";
import DocumentIcon from "../../../assets/DocumentIcon";
import ProjectDetailsCards from "./ProjectDetailsCards";

const ProjectDetailCounts = ({orderData}:any) => {


  const cardValues = [
    // {
    //   count: "0",
    //   color: "#D4A15E",
    //   title: "Total spend",
    // },
    {
      count: orderData.length,
      color: "#D4A15E",
      title: "Total orders",
    },
    // {
    //   count: "0",
    //   color: "#D4A15E",
    //   title: "Average order value",
    // },
    // {
    //   count: "0",
    //   color: "#D4A15E",
    //   title: "Ongoing orders",
    // },
    // {
    //   count: "0",
    //   color: "#D4A15E",
    //   title: "Inactive orders",
    // },
  ];

  return (
    <div className="w-full flex  gap-x-6 py-2">
      {cardValues.map((each, index) => (
        <ProjectDetailsCards
          key={index}
          {...each}
        />
      ))}
    </div>
  );
};

export default ProjectDetailCounts;
