import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { filterByStatusAtom, titleAtom } from "../../atom";

import Table from "./components/OrderTable";
import Counts from "./components/OrderCounts";

import { getOrderList, getOrderStatusSummary } from "../../api/orderManagement";
function OrderList() {
  const setTitle = useSetRecoilState(titleAtom);

  const [orderList, setOrderList] = useState([]);
  const [orderStatusSummary, setOrderStatusSummary] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [pageLength, setPageLength] = useState<number>(25);
  const [totalCount, setTotalCount] = useState<number>(100);
  const [searchValue, setSearchValue] = useState("");
  const [orderStatus, setOrderStatus] = useRecoilState(filterByStatusAtom);

  // console.log(orderStatus , 'orderStatus');

  useEffect(() => {
    setTableLoading(true);

    const params = {
      page: page,
      length: pageLength,
      search: searchValue,
      order_status: orderStatus,
      exclude_draft: true,
    };
    if (orderStatus === "DRAFT") {
      params.exclude_draft = false;
    }
    getOrderList(params)
      .then((res: any) => {
        // console.log(res);
        setOrderList(res?.data?.order);
        setTotalCount(res?.data.pagination.total_count);
      })
      .catch((error) => {
        console.error("Error fetching order list:", error);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }, [page, pageLength, searchValue, orderStatus]);

  useEffect(() => {
    setIsLoading(true);

    getOrderStatusSummary({})
      .then((res: any) => {
        setOrderStatusSummary(res.data.order_status);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching order status summary:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="h-full flex flex-col justify-start items-start">
      <div className="h-[13vh] w-full flex items-center justify-start">
        <Counts
          orderStatus={orderStatus}
          orderStatusSummary={orderStatusSummary}
        />
      </div>
      <div className="h-[calc(100%-13vh)] w-full">
        <Table
          orderStatusSummary={orderStatusSummary}
          orderList={orderList}
          isLoading={tableLoading || isLoading}
          page={page}
          pageLength={pageLength}
          setPage={setPage}
          setPageLength={setPageLength}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
          setOrderStatus={setOrderStatus}
        />
      </div>
    </div>
  );
}

export default OrderList;
