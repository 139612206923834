const WebsiteIcon = ({ size = "20", color = "#AEAEAE" }: any) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3334 10.0013C18.3334 5.4013 14.6001 1.66797 10.0001 1.66797C5.40008 1.66797 1.66675 5.4013 1.66675 10.0013C1.66675 14.6013 5.40008 18.3346 10.0001 18.3346"
        stroke="#AEAEAE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66667 2.5H7.5C5.875 7.36667 5.875 12.6333 7.5 17.5H6.66667"
        stroke="#AEAEAE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 2.5C13.3083 4.93333 13.7167 7.46667 13.7167 10"
        stroke="#AEAEAE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 13.3333V12.5C4.93333 13.3083 7.46667 13.7167 10 13.7167"
        stroke="#AEAEAE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 7.5C7.36667 5.875 12.6333 5.875 17.5 7.5"
        stroke="#AEAEAE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1667 17.8334C16.6394 17.8334 17.8333 16.6394 17.8333 15.1667C17.8333 13.6939 16.6394 12.5 15.1667 12.5C13.6939 12.5 12.5 13.6939 12.5 15.1667C12.5 16.6394 13.6939 17.8334 15.1667 17.8334Z"
        stroke="#AEAEAE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 18.3333L17.5 17.5"
        stroke="#AEAEAE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WebsiteIcon;
