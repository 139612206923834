"use client";
import axios from "axios";

// Base URL for API requests
export const BASE_URL = process.env.REACT_APP_API_DOMAIN; //eslint-disable-line

// Axios instance for unprotected (public) endpoints
export const unprotectedAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Axios instance for protected endpoints, with Authorization header
export const protectedAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("auth_token") || ""}`,
  },
});

// Request interceptor for protectedAxiosInstance
protectedAxiosInstance.interceptors.request.use(
  (config) => {
    // Attach Authorization header with the auth token from local storage
    const auth_token = localStorage.getItem("auth_token");
    if (auth_token) {
      config.headers["Authorization"] = `Bearer ${auth_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for protectedAxiosInstance
protectedAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Handle 401 errors (Unauthorized)
    if (error.response && error.response.status === 401) {
      const refresh_token = localStorage.getItem("refresh_token");

      // Check if refresh token is available and valid
      if (refresh_token && refresh_token !== "undefined") {
        try {
          // Attempt to refresh the auth token
          const refreshResponse = await axios.post(
            `${BASE_URL}/admin/accounts/login/refresh`,
            {
              refresh_token,
            }
          );

          // Retrieve new auth token and refresh token
          const newAuthToken = refreshResponse.data.data.auth_token;
          const newRefreshToken = refreshResponse.data.data.refresh_token;

          // Update auth tokens in local storage
          localStorage.removeItem("auth_token");
          localStorage.removeItem("refresh_token");
          localStorage.setItem("auth_token", newAuthToken);
          localStorage.setItem("refresh_token", newRefreshToken);

          // Update default headers with new auth token
          protectedAxiosInstance.defaults.headers[
            "Authorization"
          ] = `Bearer ${newAuthToken}`;

          // Update the Authorization header in the original request
          error.config.headers["Authorization"] = `Bearer ${newAuthToken}`;

          // Retry the original request with the new auth token
          return axios(error.config)
            .then((responseAfterRefresh) => {
              // Return data from the response after successful refresh
              return responseAfterRefresh;
            })
            .catch((error) => {
              // Handle errors from the retry
              const customError2 = {
                status: error?.response?.status || 400,
                data: error?.response?.data || null,
              };
              return Promise.reject(customError2);
            });
        } catch (refreshError) {
          // If refresh token fails, remove tokens and redirect to login
          localStorage.removeItem("auth_token");
          localStorage.removeItem("refresh_token");
          const customError = {
            message: "Refresh token failed",
            status: refreshError.response ? refreshError.response.status : 400,
            data: refreshError.response ? refreshError.response.data : null,
          };
          window.location.href = "/login";

          return Promise.reject(customError);
        }
      } else {
        // If refresh token is missing or invalid, remove tokens and redirect to login
        localStorage.removeItem("auth_token");
        const customError = {
          message: "Refresh token missing or invalid",
          status: 401,
          data: null,
        };
        window.location.href = "/login";
        return Promise.reject(customError);
      }
    }

    // Handle unexpected errors
    const customError = {
      message: "An unexpected error occurred",
      status: error.response ? error.response.status : 400,
      data: error.response ? error.response.data : null,
    };

    return Promise.reject(customError);
  }
);
