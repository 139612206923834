import DummyGallery from "../../../assets/svg/dummygallery.svg";

export default function MiniBlockWithImgPreview() {
  return (
    <div className="flex flex-col gap-3 text-left ">
      <div>
        <p className="text-[#AEAEAE] text-md">Preview:</p>
      </div>
      <div className="w-max flex flex-col">
        <div className="w-max flex flex-col justify-center items-center p-4 bg-[#ECECEC] ">
          <img src={DummyGallery} alt="DummyGallery" className="w-10" />
        </div>
        <div className="p-2">
          <p className=" h-6 bg-[#DEDEDE]  m-auto"></p>
        </div>
      </div>
    </div>
  );
}
