import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";
import {
  Bar,
  Line,
  Bubble,
  Chart,
  Pie,
  Doughnut,
  Radar,
  PolarArea,
} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  RadialLinearScale,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  RadialLinearScale
);

function Dashboard() {
  const setTitle = useSetRecoilState(titleAtom);

  // const chartData = {
  //   labels: [""],
  //   datasets: [
  //     {
  //       label: "Active",
  //       data: [""],
  //       fill: false,
  //       borderColor: "rgb(255, 99, 132)",
  //       backgroundColor: "rgba(255, 99, 132, 0.5)",
  //       borderWidth: 0.5,
  //       tension: 1,
  //       showLine: true,
  //     },
  //     {
  //       label: "Deaths",
  //       data: [""],
  //       fill: false,
  //       borderColor: "rgb(53, 162, 235)",
  //       backgroundColor: "rgba(53, 162, 235, 0.5)",
  //       borderWidth: 0.5,
  //       tension: 0.1,
  //     },
  //     {
  //       label: "Recovered",
  //       data: [""],
  //       fill: false,
  //       borderColor: "rgb(0, 255, 255)",
  //       backgroundColor: "rgba(0, 255, 255, 0.5)",
  //       borderWidth: 0.5,
  //       tension: 0.1,
  //     },
  //   ],
  // };

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "top" as const,
  //     },
  //     title: {
  //       display: true,
  //       text: "World",
  //       position: "bottom" as const,
  //     },
  //   },
  // };
  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Visits",
        data: [12, 19, 3, 5, 2, 3],
        borderColor: "rgb(200, 200, 200)",
        backgroundColor: ["red", "blue", "yellow", "green", "purple", "orange"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <div className="flex flex-col gap-4 p-5">
      <p>Dashboard 1</p>
      {/* <Line data={chartData} options={options} itemType="line" /> */}
      <div className="flex flex-end  h-[200px] gap-4 justify-center">
        <div className="w-[40%]">
          <Bar data={data} options={options} />
        </div>
        <div className=" w-[40%]">
          <Line data={data} options={options} itemType="line" />
        </div>
      </div>

      <div className="flex flex-end  h-[200px] gap-4 justify-center">
        <div className="w-[40%]">
          <p>Pie</p>
          <Pie data={data} options={options} />
        </div>
        <div className="w-[40%]">
          <p>DoughNut</p>
          <Doughnut data={data} options={options} />
        </div>
      </div>
      <div className="flex flex-end  h-[200px] gap-4 justify-center">
        <div className="w-[40%]">
          <p>Radar</p>
          <Radar data={data} options={options} />
        </div>
        <div className="w-[40%]">
          <p>PolarArea</p>
          <PolarArea data={data} options={options} />
        </div>
      </div>
      {/*  <div className="flex flex-end  h-[200px] gap-4 justify-center">
        <div className="w-[40%]">
          <p>Bubble</p>
          <Chart data={data} options={options} type="bubble" />
        </div>
        <div className="w-[40%]">
          <p>Scatter</p>
          <Chart data={data} options={options} type="scatter" />
        </div>
      </div> */}
    </div>
  );
}

export default Dashboard;
