import React, { useState, useMemo } from "react";
import InputField from "../../../../components/InputField";
import Button from "../../../../components/Button";
import {
  VariantDataProps,
  VariantSmallBlockWithImageProps,
} from "../../../../types";
import { validatePrice } from "../../../../utils/validations";
import ImageUpload from "../../../../components/ImageUpload";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../../../components/common/Modal";
import { removeExtraSpacesfromObject } from "../../../../utils/helper";

type SmallBlockWithImgPopupProps = {
  handleClose: () => void;
  setVariantData: React.Dispatch<React.SetStateAction<VariantDataProps>>;
  data?: VariantSmallBlockWithImageProps;
  task?: any;
  setVariantPopupError?: any;
};

// SMALL_BLOCK_WITH_IMAGE Option Schema
// {
//   "name": "Option #1",
//   "image": "products/bd0b7d82-24b4-46cc-a19b-57cc12a41368.jpg",
//   "product_image": "products/bd0b7d82-24b4-46cc-a19b-57cc12a41368.jpg",
//   "price": 20,
//   "code": "A354"
// }

const initState = {
  name: "",
  image: "",
  product_image: "",
  price: 0,
  code: null,
  type: "SMALL_BLOCK_WITH_IMAGE",
};
export default function SmallBlockWithImgPopup({
  handleClose,
  setVariantData,
  data = initState,
  task,
  setVariantPopupError,
}: SmallBlockWithImgPopupProps) {
  const [smallBlockWithImgData, setSmallBlockWithImgData] =
    useState<VariantSmallBlockWithImageProps>(structuredClone(data));

  const [errorMessage, setErrorMessage] = useState<any>({
    name: [],
    image: [],
    product_image: [],
    price: [],
    code: [],
  });

  const handleSubmitData = () => {
    if (!smallBlockWithImgData?.name || !smallBlockWithImgData?.image) {
      if (!smallBlockWithImgData?.name) {
        setErrorMessage((e: any) => ({ ...e, name: ["Please enter a name."] }));
      }

      if (!smallBlockWithImgData?.image) {
        setErrorMessage((e: any) => ({
          ...e,
          image: ["Please select an image."],
        }));
      }
      return;
    }

    const clone: any = removeExtraSpacesfromObject(
      structuredClone(smallBlockWithImgData)
    );

    if (
      clone.price === "" ||
      (clone.price && +clone.price == 0) ||
      !clone.price
    ) {
      clone.price = "0";
    }
    if (clone.price) {
      clone.price = parseFloat(clone.price);
    }

    if (data.id && task !== "copy") {
      // Edit Option Functionality
      setVariantData((prev) => ({
        ...prev,
        options: prev.options.map((el) => (el.id === data.id ? clone : el)),
      }));
    } else {
      // Add Option Functionality
      const copyID = data.id;
      const newData = { ...clone, id: uuidv4(), added: true };
      setVariantData((prev) => {
        if (task === "copy") {
          const findIndex = prev.options.findIndex(
            (option) => option?.id === copyID
          );
          const updatedOptions = [...prev.options];
          updatedOptions.splice(findIndex + 1, 0, newData);
          return {
            ...prev,
            options: updatedOptions,
          };
        } else {
          const deleteIndex = prev.options.findIndex(
            (option) => option?.to_delete
          );

          if (deleteIndex === -1) {
            return {
              ...prev,
              options: [...prev.options, newData],
            };
          }

          const updatedOptions = [...prev.options];
          updatedOptions.splice(deleteIndex, 0, newData);
          return {
            ...prev,
            options: updatedOptions,
          };
        }
      });
    }
    setSmallBlockWithImgData(initState);
    setVariantPopupError((prev: any) => ({ ...prev, options: [] }));
    handleClose();
  };

  const imageList = useMemo(
    () => (smallBlockWithImgData.image ? [smallBlockWithImgData.image] : []),
    [smallBlockWithImgData.image]
  );

  const productImageList = useMemo(
    () =>
      smallBlockWithImgData.product_image
        ? [smallBlockWithImgData.product_image]
        : [],
    [smallBlockWithImgData.product_image]
  );

  return (
    <Modal
      allowScroll
      header={
        task === "copy"
          ? "Add an Option"
          : data.name
          ? "Edit Option"
          : "Add an Option"
      }
      footer={
        <Button
          label={
            task === "copy"
              ? "Add an Option"
              : data.name
              ? "Update"
              : "Add Option"
          }
          variant="secondary"
          onClick={handleSubmitData}
        />
      }
      handleCancel={handleClose}
    >
      <div className="w-full h-fit flex flex-col gap-3">
        {/* code */}
        <InputField
          onChange={(e) => {
            setSmallBlockWithImgData((prevData) => ({
              ...prevData,
              code: e?.toUpperCase(), // Set code to null if empty, otherwise to uppercase value
            }));
            setErrorMessage((e: any) => ({ ...e, code: [] }));
          }}
          value={smallBlockWithImgData.code}
          placeholder="Code"
          label="Code"
          hint={errorMessage?.code}
        />

        {/* name */}
        <InputField
          onChange={(e) => {
            setSmallBlockWithImgData((prevData) => ({
              ...prevData,
              name: e,
            }));
            setErrorMessage((e: any) => ({ ...e, name: [] }));
          }}
          hint={errorMessage?.name}
          value={smallBlockWithImgData?.name}
          placeholder="Name"
          label="Name:"
          required
        />

        {/* price */}
        <InputField
          onChange={(e: any) => {
            if (e > 99999) {
              setErrorMessage((prevError: any) => ({
                ...prevError,
                price: ["Price should be less than or equal to 99999."],
              }));
            } else {
              setSmallBlockWithImgData((prevData) => ({
                ...prevData,
                price: e,
              }));
              setErrorMessage((prevError: any) => ({
                ...prevError,
                price: [],
              }));
            }
          }}
          validate={validatePrice}
          value={smallBlockWithImgData.price}
          placeholder="Price"
          label="Price:"
          type="price"
          hint={errorMessage.price}
        />

        {/* image */}
        <ImageUpload
          required
          imageList={imageList}
          label="Upload image:"
          setter={(value) => {
            smallBlockWithImgData.image = value as string;
            setSmallBlockWithImgData(structuredClone(smallBlockWithImgData));
          }}
          kind="PRODUCT"
          errors={errorMessage?.image}
          removeError={() => setErrorMessage((e: any) => ({ ...e, image: [] }))}
        />

        {/* product_image */}
        <ImageUpload
          label="Upload Product Image:"
          imageList={productImageList}
          setter={(value) => {
            setSmallBlockWithImgData((prev) => ({
              ...prev,
              product_image: value as string,
            }));
          }}
          kind="PRODUCT"
          errors={errorMessage?.product_image}
          removeError={() =>
            setErrorMessage((e: any) => ({ ...e, product_image: [] }))
          }
        />
      </div>
    </Modal>
  );
}
