import {
  protectedAxiosInstance,
  unprotectedAxiosInstance,
} from "./axiosManagement";

// POST /admin/accounts/login --not-protected
export const postLogin = (payload) =>
  unprotectedAxiosInstance.post(`/admin/accounts/login`, payload);

// POST /admin/accounts/login/refresh --not-protected
export const postLoginRefresh = (payload) =>
  unprotectedAxiosInstance.post(`/admin/accounts/login/refresh`, payload);

// GET /admin/accounts/info --protected
export const getUserInfo = () =>
  protectedAxiosInstance.get(`/admin/accounts/info`);
