import React from "react";

type VariantCardPropType = {
  count: number;
  title: string;
  icon: React.ReactNode;
};

const VariantCard = ({ count, title, icon }: VariantCardPropType) => {
  return (
    <div className="bg-[#F9F9F9] w-full flex flex-col max-w-[14vw]">
      <div className="border-l-2 py-3 px-3">
        <div className="flex justify-between items-center">
          <p className="font-medium text-[20px]">{count}</p>
          <div>{icon}</div>
        </div>
        <p className="text-[11px] text-[#AEAEAE] text-left">{title}</p>
      </div>
    </div>
  );
};

export default VariantCard;
