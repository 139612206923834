import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";

import CategoryCounts from "./categorycomponents/Counts";
import Table from "./categorycomponents/Table";

export default function CategoryList() {
  const setTitle = useSetRecoilState(titleAtom);

  return (
    <div className="h-full flex flex-col justify-start items-start">
      <div className="h-[13vh] w-full flex items-center justify-start">
        <CategoryCounts />
      </div>
      <div className="h-[calc(100%-13vh)] w-full">
        <Table />
      </div>
    </div>
  );
}
