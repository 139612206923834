import React, { useState, useMemo, useEffect } from "react";
import Modal from "../../../components/common/Modal";
import InputField from "../../../components/InputField";

import Button from "../../../components/Button";

import { VariantDataProps, VariantTrackTypeProps } from "../../../types";
import { v4 as uuidv4 } from "uuid";
import {
  validateNumber,
  validatePrice,
  validatePriceAccept0,
} from "../../../utils/validations";
import ImageUpload from "../../../components/ImageUpload";
import ErrorMessage from "../../../components/ErrorMessage";
import Checkbox from "../../../components/Checkbox";
import SelectField2 from "../../../components/SelectedField2";
import { removeExtraSpacesfromObject } from "../../../utils/helper";

type TrackTypePopupProps = {
  handleClose: () => void;
  setVariantData: React.Dispatch<React.SetStateAction<VariantDataProps>>;
  data?: VariantTrackTypeProps;
  task?: any;
};

// TRACK_TYPE Option Schema
// {
//   "name": "Option #1",
//   "id": "ccf6f29d-6b8c-46fc-ba0a-4d0d6ae48ddd",
//   "to_delete": true,
//   "position": 1,
//   "image": "products/bd0b7d82-24b4-46cc-a19b-57cc12a41368.jpg",
//   "product_image": "products/bd0b7d82-24b4-46cc-a19b-57cc12a41368.jpg",
//   "price": 19.99,
//   "allow_a": true,
//   "allow_b": true,
//   "allow_c": true,
//   "length_formula": "2*(a*b*c)",
//   "allow_file_upload": false,
// "description": "This is description.",
// "driver_cost": 100,
// "driver_length": 30,
// }

const initState = {
  name: "",
  image: "",
  product_image: "",
  price: 0,
  allow_a: true,
  allow_b: false,
  allow_c: false,
  allow_file_upload: false,
  type: "TRACK_TYPE",
  length_formula: "",
  description: "",
};
export default function TrackTypePopup({
  handleClose,
  setVariantData,
  data = initState,
  task,
}: TrackTypePopupProps) {
  const [trackTypeData, setTrackTypeData] = useState<VariantTrackTypeProps>({
    ...data,
    allow_a: true,
    allow_b: data?.allow_b || false,
    allow_c: data?.allow_c || false,
    allow_file_upload: data?.allow_file_upload || false,
  });
  const [errorMessage, setErrorMessage] = useState({
    name: [],
    image: [],
    product_image: [],
    price: [],
    allow_a: [],
    allow_b: [],
    allow_c: [],
    allow_file_upload: [],
    length_formula: [],
    description: [],
    driver_cost: [],
    driver_length: [],
  });

  const handleSubmitData = () => {
    if (
      !trackTypeData?.allow_a ||
      !trackTypeData?.image ||
      !trackTypeData?.name ||
      !trackTypeData?.price ||
      !trackTypeData?.length_formula ||
      trackTypeData?.driver_cost === undefined ||
      trackTypeData?.driver_length === undefined
    ) {
      if (!trackTypeData?.allow_a) {
        setErrorMessage((e: any) => ({
          ...e,
          allow_length: ["Allow length will be always true."],
        }));
      }
      if (!trackTypeData?.image) {
        setErrorMessage((e: any) => ({
          ...e,
          image: ["Please select an image."],
        }));
      }
      if (!trackTypeData?.name) {
        setErrorMessage((e: any) => ({
          ...e,
          name: ["Name is required."],
        }));
      }
      if (!trackTypeData?.price) {
        setErrorMessage((e: any) => ({
          ...e,
          price: ["Price is required."],
        }));
      }
      if (!trackTypeData?.length_formula) {
        setErrorMessage((e: any) => ({
          ...e,
          length_formula: ["Length formula is required."],
        }));
      }
      if (trackTypeData?.driver_cost === undefined) {
        setErrorMessage((e: any) => ({
          ...e,
          driver_cost: ["Driver cost is required."],
        }));
      }
      if (trackTypeData?.driver_length === undefined) {
        setErrorMessage((e: any) => ({
          ...e,

          driver_length: ["Driver length is required."],
        }));
      }
      return;
    }

    const clone: any = removeExtraSpacesfromObject(
      structuredClone(trackTypeData)
    );

    if (
      clone.price === "" ||
      (clone.price && +clone.price == 0) ||
      !clone.price
    ) {
      clone.price = "0";
    }
    if (clone.price) {
      clone.price = parseFloat(clone.price);
    }
    if (typeof clone.driver_cost === "string") {
      clone.driver_cost = parseFloat(clone.driver_cost);
    }
    if (typeof clone.driver_length === "string") {
      clone.driver_length = parseFloat(clone.driver_length);
    }
    if (!clone.description) {
      clone.description = "";
    }

    if (data.id && task !== "copy") {
      // Edit Option Functionality
      setVariantData((prev) => ({
        ...prev,
        options: prev.options.map((el: any) =>
          el.id === data.id ? clone : el
        ),
      }));
    } else {
      // Add Option Functionality
      const copyID = data.id;
      const newData = { ...clone, id: uuidv4(), added: true };
      setVariantData((prev) => {
        if (task === "copy") {
          const findIndex = prev.options.findIndex(
            (option) => option?.id === copyID
          );
          const updatedOptions = [...prev.options];
          updatedOptions.splice(findIndex + 1, 0, newData);
          return {
            ...prev,
            options: updatedOptions,
          };
        } else {
          const deleteIndex = prev.options.findIndex(
            (option) => option?.to_delete
          );

          if (deleteIndex === -1) {
            return {
              ...prev,
              options: [...prev.options, newData],
            };
          }

          const updatedOptions = [...prev.options];
          updatedOptions.splice(deleteIndex, 0, newData);
          return {
            ...prev,
            options: updatedOptions,
          };
        }
      });
    }
    setTrackTypeData(initState);
    handleClose();
  };

  const imageList = useMemo(
    () => (trackTypeData?.image ? [trackTypeData?.image] : []),
    [trackTypeData.image]
  );

  const productImageList = useMemo(
    () => (trackTypeData?.product_image ? [trackTypeData?.product_image] : []),
    [trackTypeData?.product_image]
  );

  const formulaOptions =
    trackTypeData?.allow_a && trackTypeData?.allow_b && trackTypeData?.allow_c
      ? [{ value: "a+b+c", label: "a+b+c" }]
      : trackTypeData?.allow_a && trackTypeData?.allow_b
      ? [
          { value: "a+b", label: "a+b" },
          { value: "2*(a+b)", label: "2 x (a+b)" },
        ]
      : trackTypeData?.allow_a
      ? [{ value: "a", label: "a" }]
      : [];

  useEffect(() => {
    if (
      trackTypeData.length_formula &&
      !formulaOptions.find(
        (option) => option.value === trackTypeData.length_formula
      )
    ) {
      setTrackTypeData((prevData) => ({
        ...prevData,
        length_formula: "",
      }));
    }
    /* eslint-disable */
  }, [
    trackTypeData?.allow_a,
    trackTypeData?.allow_b,
    trackTypeData?.allow_c,
    formulaOptions,
  ]);
  return (
    <Modal
      allowScroll
      header={
        task === "copy"
          ? "Add an Option"
          : data.name
          ? "Edit Option"
          : "Add an Option"
      }
      footer={
        <Button
          label={
            task === "copy"
              ? "Add Option"
              : trackTypeData.id
              ? "Update"
              : "Add Option"
          }
          variant="secondary"
          onClick={handleSubmitData}
        />
      }
      handleCancel={handleClose}
    >
      <div className="w-full h-full flex flex-col gap-3">
        {/* name */}
        <InputField
          onChange={(e) => {
            setTrackTypeData((prevData) => ({
              ...prevData,
              name: e,
            }));
            setErrorMessage((e: any) => ({ ...e, name: [] }));
          }}
          value={trackTypeData.name}
          placeholder="Name"
          label="Name:"
          hint={errorMessage.name}
          required
        />

        {/* price */}
        <InputField
          onChange={(e: any) => {
            if (e > 99999) {
              setErrorMessage((prevError: any) => ({
                ...prevError,
                price: ["Price should be less than or equal to 99999."],
              }));
            } else {
              setTrackTypeData((prevData) => ({
                ...prevData,
                price: e,
              }));
              setErrorMessage((prevError: any) => ({
                ...prevError,
                price: [],
              }));
            }
          }}
          value={trackTypeData.price}
          placeholder="Price"
          label="Price (per foot):"
          type="price"
          validate={validatePrice}
          hint={errorMessage?.price}
          required
        />

        {/* There is no need of error here either it is true or false */}
        <div className="flex justify-between">
          <Checkbox
            borderType="square"
            label="Allow length A"
            disabled
            checkValue={trackTypeData.allow_a === true ? 1 : 0}
          />
          <Checkbox
            borderType="square"
            label="Allow length B"
            onClick={() => {
              setErrorMessage((prev: any) => ({ ...prev, allow_b: [] }));
              setTrackTypeData((prevData) => ({
                ...prevData,
                allow_b: !prevData?.allow_b,
              }));
            }}
            checkValue={trackTypeData?.allow_b ? 1 : 0}
          />
          <Checkbox
            borderType="square"
            label="Allow length C"
            onClick={() => {
              setErrorMessage((prev: any) => ({ ...prev, allow_c: [] }));
              setTrackTypeData((prevData) => ({
                ...prevData,
                allow_c: !prevData?.allow_c,
              }));
            }}
            checkValue={trackTypeData?.allow_c ? 1 : 0}
          />
          <Checkbox
            borderType="square"
            label="Allow file upload"
            onClick={() => {
              setErrorMessage((prev: any) => ({
                ...prev,
                allow_file_upload: [],
              }));
              setTrackTypeData((prevData) => ({
                ...prevData,
                allow_file_upload: !prevData?.allow_file_upload,
              }));
            }}
            checkValue={trackTypeData?.allow_file_upload ? 1 : 0}
          />
        </div>
        <ErrorMessage
          error={[
            ...(errorMessage?.allow_a || ""),
            ...(errorMessage?.allow_b || ""),
            ...(errorMessage?.allow_c || ""),
            ...(errorMessage?.allow_file_upload || ""),
          ]}
        />

        <SelectField2
          label="Length Formula"
          placeholder="Select a formula"
          value={trackTypeData?.length_formula}
          onChange={(e) => {
            setTrackTypeData((prevData) => ({
              ...prevData,
              length_formula: e,
            }));
            setErrorMessage((e: any) => ({ ...e, length_formula: [] }));
          }}
          options={formulaOptions}
          required
          hint={errorMessage?.length_formula}
        />

        {/* driver_cost,driver_length */}
        <div className="flex items-center w-[70%] gap-x-2">
          <p className="whitespace-nowrap font-gilroy-semi-bold text-xs">
            Controller price of Rs.&nbsp;
          </p>
          <InputField
            onChange={(e: any) => {
              if (e > 99999) {
                setErrorMessage((prevError: any) => ({
                  ...prevError,
                  driver_cost: [
                    "Price should be less than or equal to 99999.",
                  ],
                }));
              } else {
                setTrackTypeData((prevData) => ({
                  ...prevData,
                  driver_cost: e,
                }));
                setErrorMessage((prevError: any) => ({
                  ...prevError,
                  driver_cost: [],
                }));
              }
            }}
            value={trackTypeData.driver_cost}
            placeholder="Driver cost"
            // hint={errorMessage.driver_cost}

            validate={validatePriceAccept0}
            required
          />
          <p className="whitespace-nowrap font-gilroy-semi-bold text-xs">
            &nbsp;per&nbsp;
          </p>
          <InputField
            onChange={(e) => {
              setTrackTypeData((prevData) => ({
                ...prevData,
                driver_length: e,
              }));
              setErrorMessage((e: any) => ({ ...e, driver_length: [] }));
            }}
            value={trackTypeData.driver_length}
            placeholder="Driver length"
            // hint={errorMessage.driver_length}
            maxLength={3}
            validate={validateNumber}
            required
          />
          <p className="whitespace-nowrap font-gilroy-semi-bold text-xs">
            &nbsp;feet<span className="text-red-500 text-xs ml-1">*</span>
          </p>
        </div>
        <ErrorMessage
          error={[
            ...(errorMessage?.driver_cost || ""),
            ...(errorMessage?.driver_length || ""),
          ]}
        />

        {/* description */}
        <InputField
          onChange={(e) => {
            setTrackTypeData((prevData) => ({
              ...prevData,
              description: e,
            }));
            setErrorMessage((e: any) => ({ ...e, description: [] }));
          }}
          value={trackTypeData.description}
          placeholder="Description"
          label="Description:"
          hint={errorMessage.description}
        />

        {/* image */}
        <ImageUpload
          required
          label="Upload Image:"
          imageList={imageList}
          setter={(value) => {
            trackTypeData.image = value as string;
            setTrackTypeData(structuredClone(trackTypeData));
          }}
          kind="PRODUCT"
          errors={errorMessage?.image}
          removeError={() => setErrorMessage((e: any) => ({ ...e, image: [] }))}
        />

        {/* product_image */}
        <ImageUpload
          label="Upload Product Image:"
          imageList={productImageList}
          setter={(value) => {
            setTrackTypeData((prev) => ({
              ...prev,
              product_image: value as string,
            }));
          }}
          kind="PRODUCT"
          errors={errorMessage?.product_image}
          removeError={() =>
            setErrorMessage((e: any) => ({ ...e, product_image: [] }))
          }
        />
      </div>
    </Modal>
  );
}
