import React, { useEffect, useState } from "react";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import editPen from "../../../assets/svg/pen.svg";
// import close from "../../../assets/svg/close.svg";
import close from "../../../assets/svg/close.svg";
import { states } from "../../../utils/states";
import { useRecoilValue } from "recoil"; // Remove unused import
import { stateAtom } from "../../../atom";
import Button from "../../../components/Button";
import PlusIcon from "../../../assets/PlusIcon";
import OrderAddressInput from "./OrderAddressInput";
import ScaleLoading from "../../../components/ScaleLoading";
import { toast } from "react-toastify";
import TrashIcon from "../../../assets/TrashIcon";
import OrderAddressDelete from "./OrderAddressDelete";

const OrderAddressPopup = ({
  orderDetails,
  setShowAddressPopup,
  currentAddress,
  title,
  setOrderDetails,
}: any) => {
  const [address, setAddress] = useState([]);
  const [openInputAddress, setOpenInputAddress] = useState(false);
  const [openDeleteAddress, setOpenDeleteAddress] = useState(false);
  const [targetAddress, setTargetAddress] = useState(null); // Initialize with null
  const [loading, setLoading] = useState(true);

  const getAddressList = () => {
    protectedAxiosInstance
      .get(`/admin/accounts/customers/${orderDetails.ordered_for}/address`)
      .then((res) => {
        setAddress(res.data.data.address);
        setLoading(false); // Move setLoading(false) inside the .then block
      })
      .catch((error) => {
        console.error("Error fetching addresses:", error);
        setLoading(false); // Handle errors and set loading to false
      });
  };

  useEffect(() => {
    setLoading(true);

    if (orderDetails && orderDetails?.ordered_for) {
      getAddressList();
    } else {
      setLoading(false);
    }
  }, [orderDetails?.ordered_for, orderDetails?.owner_id]);

  const stateData = useRecoilValue(stateAtom);

  const handleSubmit = (el: any) => {
    if (title === "Shipping Address" && el?.kind !== "SHIPPING") {
      const payload = el;
      delete payload.id;
      payload.kind = "SHIPPING";
      protectedAxiosInstance
        .post(`/admin/accounts/customers/${orderDetails.ordered_for}/address`, {
          ...payload,
        })
        .then((res: any) => {
          protectedAxiosInstance
            .patch(`/admin/orders/${orderDetails.id}`, {
              address_delivery: res?.address?.id,
            })
            .then((res) => {
              setShowAddressPopup(false);
              toast.success("Successfully Added to Shipping Addresses");
            });
        })
        .catch((err) => {
          console.error("Something is wrong in creating shipping address", err);
        });
    } else if (title === "Billing Address" && el?.kind !== "BILLING") {
      const payload = el;
      delete payload.id;
      payload.kind = "BILLING";
      protectedAxiosInstance
        .post(`/admin/accounts/customers/${orderDetails.ordered_for}/address`, {
          ...payload,
        })
        .then((res: any) => {
          protectedAxiosInstance
            .patch(`/admin/orders/${orderDetails.id}`, {
              address_billing: res?.address?.id,
            })
            .then((res) => {
              setShowAddressPopup(false);
              toast.success("Successfully Added to Shipping Addresses");
            });
        })
        .catch((err) => {
          console.error("Something is wrong in creating shipping address", err);
        });
    } else {
      const payload: any = {};
      if (title === "Shipping Address") {
        payload.address_delivery = el.id;
      } else if (title === "Billing Address") {
        payload.address_billing = el.id;
      }
      protectedAxiosInstance
        .patch(`/admin/orders/${orderDetails.id}`, {
          ...payload,
        })
        .then((res: any) => {
          setShowAddressPopup(false);
          toast.success("Successfully Edited Address");

          setOrderDetails(res.data.data.order);
        });
    }
  };

  const handleDeleteAddress = (id: any) => {
    protectedAxiosInstance
      .delete(
        `/admin/accounts/customers/${orderDetails?.ordered_for}/address/${id}`
      )
      .then((res) => {
        toast.success("Successfully deleted address");
        getAddressList();
      });
  };

  return (
    <div
      className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center backdrop-brightness-50 z-50"
      onClick={() => {
        if (openInputAddress) {
          setOpenInputAddress(false);
          setTargetAddress(null);
        } else if (openDeleteAddress) {
          setOpenDeleteAddress(false);
          setTargetAddress(null);
        } else {
          setShowAddressPopup(false);
        }
      }}
    >
      {!openInputAddress && !openDeleteAddress && (
        <>
          {loading ? (
            <div
              className="flex flex-col justify-center items-center gap-4 relative min-w-[80vw] bg-white h-[80vh]"
              onClick={(e) => e.stopPropagation()}
            >
              <ScaleLoading />
            </div>
          ) : (
            <div
              className="flex flex-col justify-center items-center gap-4 relative min-w-[60vw] w-1/2 h-[80vh] "
              onClick={(e) => e.stopPropagation()}
            >
              <div className="bg-white  overflow-y-auto no-scrollbar overflow-x-hidden  w-full">
                <div className="sticky top-0 bg-white py-2 px-5 flex justify-between items-center border-b border-c-white-3 overflow-hidden">
                  <p className="text-2xl font-gilroy-bold">Addresses</p>
                  <button
                    className="p-4 bg-white transform scale-150"
                    onClick={() => setShowAddressPopup(false)}
                  >
                    <img src={close} alt="close" />
                  </button>
                </div>
                <div className="px-5 py-3">
                  <div className="flex items-center justify-start gap-5">
                    <p className="text-[18px] leading-[20px] font-gilroy-semi-bold">
                      Shipping Addresses
                    </p>
                    <Button
                      variant="secondary"
                      leftIcon={<PlusIcon />}
                      label="Add new"
                      onClick={() => setOpenInputAddress(true)}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-6 my-5 ">
                    {address.filter((el: any) => el?.kind === "SHIPPING")
                      .length === 0 && (
                      <p className="min-h-[20vh] min-w-[80vw]">
                        No address added
                      </p>
                    )}
                    {address
                      .filter((el: any) => el?.kind === "SHIPPING")
                      .map((el: any) => (
                        <div
                          key={el?.id}
                          onClick={() => handleSubmit(el)}
                          className={`border ${
                            currentAddress?.postal_code === el?.postal_code &&
                            currentAddress?.full_name === el?.full_name &&
                            currentAddress?.kind === el?.kind &&
                            currentAddress?.phone === el?.phone &&
                            currentAddress?.street_1 === el?.street_1 &&
                            currentAddress?.street_2 === el?.street_2
                              ? "border-c-yellow-5"
                              : "border-c-white-3"
                          } p-4`}
                        >
                          <div className="flex justify-between mb-5">
                            <p className="bg-[#EDEDED] p-1 rounded-lg text-sm capitalize leading-[20px] font-gilroy-bold">
                              {el?.tag?.toLowerCase()}
                            </p>
                            <div
                              onClick={(e) => e.stopPropagation()}
                              className="flex gap-2"
                            >
                              <button
                                onClick={() => {
                                  setTargetAddress(el);
                                  setOpenInputAddress(true);
                                }}
                              >
                                <img src={editPen} alt="edit-pen" />
                              </button>
                              <button
                                onClick={() => {
                                  setOpenDeleteAddress(true);
                                  setTargetAddress(el);
                                }}
                              >
                                <TrashIcon />
                              </button>
                            </div>
                          </div>
                          <div>
                            <p className=" text-[15px] leading-[20px] font-gilroy-medium">
                              {el?.full_name}
                            </p>
                            <p className="text-[12px] leading-[20px] font-gilroy-medium">
                              {el?.street_1},
                            </p>
                            <p className="text-[12px] leading-[20px] font-gilroy-medium">
                              {el?.street_2 && `${el?.street_2}`},
                            </p>
                            <p className="text-[12px] leading-[20px] font-gilroy-medium">
                              {el?.city} - {el?.postal_code},
                            </p>
                            <p className="text-[12px] leading-[20px] font-gilroy-medium">
                              {stateData &&
                                stateData?.find(
                                  (state: any) => state?.state_code == el?.state
                                )?.name}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="px-5 py-3">
                  <div className="flex items-center justify-start gap-5">
                    <p className="text-[18px] leading-[20px] font-gilroy-semi-bold">
                      Billing Addresses
                    </p>
                    <Button
                      variant="secondary"
                      leftIcon={<PlusIcon />}
                      label="Add new"
                      onClick={() => setOpenInputAddress(true)}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3  gap-6 my-5">
                    {address.filter((el: any) => el?.kind === "BILLING")
                      .length === 0 && (
                      <p className="min-h-[25vh] min-w-[80vw]">
                        No address added
                      </p>
                    )}
                    {address
                      .filter((el: any) => el?.kind === "BILLING")
                      .map((el: any) => (
                        <div
                          key={el?.id}
                          onClick={() => handleSubmit(el)}
                          className={`border ${
                            currentAddress?.postal_code === el?.postal_code &&
                            currentAddress?.full_name === el?.full_name &&
                            currentAddress?.kind === el?.kind &&
                            currentAddress?.phone === el?.phone &&
                            currentAddress?.street_1 === el?.street_1 &&
                            currentAddress?.street_2 === el?.street_2
                              ? "border-c-yellow-5"
                              : "border-c-white-3"
                          } p-4`}
                        >
                          <div className="flex justify-between mb-5">
                            <p className="bg-[#EDEDED] p-1 rounded-lg text-sm capitalize leading-[20px] font-gilroy-bold">
                              {el.tag.toLowerCase()}
                            </p>
                            <div
                              onClick={(e) => e.stopPropagation()}
                              className="flex gap-2"
                            >
                              <button
                                onClick={() => {
                                  setTargetAddress(el);
                                  setOpenInputAddress(true);
                                }}
                              >
                                <img src={editPen} alt="edit-pen" />
                              </button>
                              <button
                                onClick={() => {
                                  setOpenDeleteAddress(true);
                                  setTargetAddress(el);
                                }}
                              >
                                <TrashIcon />
                              </button>
                            </div>
                          </div>
                          <div>
                            <p className=" text-[15px] leading-[20px] font-gilroy-medium">
                              {el?.full_name}
                            </p>
                            <p className="text-[12px] leading-[20px] font-gilroy-medium">
                              {el?.street_1},
                            </p>
                            <p className="text-[12px] leading-[20px] font-gilroy-medium">
                              {el?.street_2 && `${el?.street_2}`},
                            </p>
                            <p className="text-[12px] leading-[20px] font-gilroy-medium">
                              {el?.city} - {el?.postal_code},
                            </p>
                            <p className="text-[12px] leading-[20px] font-gilroy-medium">
                              {stateData &&
                                stateData?.find(
                                  (state: any) => state?.state_code == el?.state
                                )?.name}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {openInputAddress && (
        <OrderAddressInput
          orderDetails={orderDetails}
          getAddressList={getAddressList}
          targetAddress={targetAddress}
          setTargetAddress={setTargetAddress}
          setOpenInputAddress={setOpenInputAddress}
        />
      )}
      {openDeleteAddress && (
        <OrderAddressDelete
          targetAddress={targetAddress}
          setOpenDeleteAddress={setOpenDeleteAddress}
          handleDeleteAddress={handleDeleteAddress}
          setTargetAddress={setTargetAddress}
        />
      )}
    </div>
  );
};

export default OrderAddressPopup;
