import React, { useState, useEffect } from "react";
import PasswordIcon from "../assets/PasswordIcon";
import SelectCountryCode from "./SelectCountry";
import ErrorMessage from "./ErrorMessage";
import InfoIcon from "../assets/svg-tsx/InfoIcon";
import ToolTip from "./ToolTip";

export const primaryInputStyle =
  "text-black font-normal border-2 border-[#DEDEDE] hover:border-2 hover:border-[#D4A15E] hover:border-opacity-50 outline-none focus:bg-white focus:border-pot-yellow placeholder-shown:text-pot-grey2 active:border-pot-yellow active:border-2 ";

export const InputDisabledStyle =
  "text-[#4F4F4F] disabled:appearance-none disabled:font-gilroy-regular focus:outline-none disabled:border-2 disabled:border-[#DEDEDE] disabled:opacity-30 cursor-not-allowed disabled:bg-white";

type AuthInputFieldProps = {
  name?: string;
  heading?: string;
  placeholder?: string;
  value: string | number;
  setCode?: (value: string) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onWheel?: (e: React.WheelEvent<HTMLInputElement>) => void;
  type?: "text" | "Password" | "telephone";
  errorMessage?: string;
  variant?: "primary";
  required?: boolean;
  hint?: string | string[];
  country_code_value?: string;
  disabled?: boolean;
  maxLength?: any;
  validate?: any;
  targetRef?: any;
  infoIcon?: boolean;
  infoMessage?: string;
};

const AuthInputField = ({
  country_code_value,
  name,
  heading,
  placeholder,
  value,
  required = false,
  setCode = () => {},
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  onWheel = () => {},
  type = "text",
  errorMessage = "",
  variant = "primary",
  hint = [],
  disabled = false,
  maxLength = 250,
  validate,
  targetRef,
  infoIcon = false,
  infoMessage = "",
}: AuthInputFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const newPage = false;
  const [countryCode, setCountryCode] = useState("+91");

  const typeStyle = variant === "primary" ? primaryInputStyle : "";

  const disabledStyle = InputDisabledStyle;

  useEffect(() => {
    if (setCode) {
      setCode(countryCode);
    }
  }, [countryCode]);

  const [infoIsHovered, setInfoIsHovered] = useState(false);

  return (
    <div className={` w-full`} ref={targetRef}>
      <p
        className={`font-gilroy-semi-bold  text-[12px]  pb-2 flex justify-start items-center`}
      >
        {heading}
        {required && <span className="text-red-500 text-xs ml-1">*</span>}
        {infoIcon && (
          <div
            className="relative ml-2"
            onMouseEnter={() => setInfoIsHovered(true)}
            onMouseLeave={() => setInfoIsHovered(false)}
          >
            <span className="text-gray-500 cursor-pointer">
              <InfoIcon />
            </span>
            {infoIsHovered && (
              <div className="absolute -left-1/2 w-56 p-2 bg-gray-700 text-white text-xs rounded-md transition-opacity z-40">
                {infoMessage}
              </div>
            )}
          </div>
        )}
      </p>
      <div
        className={`w-full transition flex gap-3 ease-in-out duration-400 bg-white rounded-lg text-xs 2xl:text-sm relative items-center`}
      >
        {type === "telephone" ? (
          <SelectCountryCode
            disabled={disabled}
            onChange={(e: any) => e}
            setCountryCode={(countryCode: any) => setCountryCode(countryCode)}
            country_code_value={country_code_value}
          />
        ) : (
          ""
        )}

        <input
          required
          name={name}
          type={showPassword ? "text" : type === "telephone" ? "tel" : type}
          value={value}
          onChange={(e) => {
            const inputValue: any = e.target.value.toString();
            if (validate) {
              const newValue = validate(inputValue);
              if (newValue !== false) {
                onChange(newValue);
              } else {
                return;
              }
            } else {
              onChange(inputValue);
            }
          }}
          disabled={disabled}
          maxLength={maxLength}
          onFocus={() => {
            onFocus && onFocus();
            setIsFocused(true);
          }}
          onBlur={() => {
            onBlur && onBlur();
            setIsFocused(false);
          }}
          onWheel={onWheel}
          placeholder={placeholder}
          // placeholder={showPassword ? "Password" : placeholder}
          className={`${
            disabled ? disabledStyle : typeStyle
          } text-[13px] w-full rounded-md px-4 py-2 h-[36px]`}
        />
        {type === "Password" && (
          <button
            onClick={() => setShowPassword((prev) => !prev)}
            className={`absolute right-0 flex items-center mr-3 place-content-around ${
              showPassword ? "stroke-gray-300" : "stroke-pot-dark-blue"
            } px-2 py-3`}
          >
            <PasswordIcon />
          </button>
        )}
      </div>
      <ErrorMessage error={hint} />
    </div>
  );
};

export default AuthInputField;
