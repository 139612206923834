interface JPEGPreviewIconProps {
    width?: string;
    height?: string;
    color?: string;
  }
  
  const JPEGPreviewIcon = ({
    width = "20",
    height = "20",
    color = "#AEAEAE",
  }: JPEGPreviewIconProps) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.48901 14.51C5.81567 14.8366 6.20817 15 6.66651 15H16.6665C17.1243 15.0005 17.5165 14.8375 17.8432 14.5108C18.1698 14.1841 18.3332 13.7916 18.3332 13.3333V3.3333C18.3337 2.87552 18.1707 2.4833 17.844 2.15663C17.5173 1.82996 17.1248 1.66663 16.6665 1.66663H6.66651C6.20873 1.66607 5.81651 1.82913 5.48984 2.1558C5.16317 2.48246 4.99984 2.87496 4.99984 3.3333V13.3333C4.99928 13.7911 5.16234 14.1833 5.48901 14.51ZM16.6665 13.3333H6.66651V3.3333H16.6665V13.3333ZM2.15567 17.8433C2.48234 18.17 2.87484 18.3333 3.33317 18.3333H14.9998V16.6666H3.33317V4.99996H1.66651V16.6666C1.66595 17.1244 1.82901 17.5166 2.15567 17.8433Z"
          fill={color}
        />
        <path
          d="M9.21234 10.033C8.98234 10.033 8.79934 9.971 8.66334 9.847C8.52934 9.721 8.46234 9.552 8.46234 9.34H8.91234C8.91234 9.432 8.93934 9.506 8.99334 9.562C9.04734 9.616 9.12034 9.643 9.21234 9.643C9.30434 9.643 9.37734 9.617 9.43134 9.565C9.48534 9.511 9.51234 9.438 9.51234 9.346V8.23H9.01734V7.81H9.96234V9.346C9.96234 9.558 9.89534 9.726 9.76134 9.85C9.62734 9.972 9.44434 10.033 9.21234 10.033ZM10.3992 10V7.81H11.1462C11.3002 7.81 11.4342 7.839 11.5482 7.897C11.6642 7.955 11.7532 8.036 11.8152 8.14C11.8792 8.244 11.9112 8.366 11.9112 8.506C11.9112 8.644 11.8792 8.766 11.8152 8.872C11.7512 8.976 11.6622 9.057 11.5482 9.115C11.4342 9.173 11.3002 9.202 11.1462 9.202H10.8492V10H10.3992ZM10.8492 8.797H11.1462C11.2422 8.797 11.3182 8.771 11.3742 8.719C11.4322 8.665 11.4612 8.594 11.4612 8.506C11.4612 8.418 11.4322 8.348 11.3742 8.296C11.3182 8.242 11.2422 8.215 11.1462 8.215H10.8492V8.797ZM12.24 10V7.81H13.59V8.194H12.681V8.686H13.485V9.07H12.681V9.616H13.59V10H12.24ZM14.7108 10.03C14.5648 10.03 14.4368 10.003 14.3268 9.949C14.2168 9.893 14.1308 9.816 14.0688 9.718C14.0088 9.618 13.9788 9.502 13.9788 9.37V8.44C13.9788 8.306 14.0088 8.19 14.0688 8.092C14.1308 7.994 14.2168 7.918 14.3268 7.864C14.4368 7.808 14.5648 7.78 14.7108 7.78C14.8588 7.78 14.9868 7.808 15.0948 7.864C15.2048 7.918 15.2898 7.994 15.3498 8.092C15.4118 8.19 15.4428 8.306 15.4428 8.44H14.9928C14.9928 8.352 14.9678 8.285 14.9178 8.239C14.8698 8.193 14.8008 8.17 14.7108 8.17C14.6208 8.17 14.5508 8.193 14.5008 8.239C14.4528 8.285 14.4288 8.352 14.4288 8.44V9.37C14.4288 9.456 14.4528 9.523 14.5008 9.571C14.5508 9.617 14.6208 9.64 14.7108 9.64C14.8008 9.64 14.8698 9.617 14.9178 9.571C14.9678 9.523 14.9928 9.456 14.9928 9.37V9.181H14.6688V8.8H15.4428V9.37C15.4428 9.502 15.4118 9.618 15.3498 9.718C15.2898 9.816 15.2048 9.893 15.0948 9.949C14.9868 10.003 14.8588 10.03 14.7108 10.03Z"
          fill={color}
        />
      </svg>
    );
  };
  
  export default JPEGPreviewIcon;
  