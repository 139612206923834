import DummyGallery from "../../../assets/svg/dummygallery.svg";

export default function SmallBlockWithImgPreview() {
  return (
    <div className="flex flex-col gap-3 text-left ">
      <div>
        <p className="text-[#AEAEAE] text-md">Preview:</p>
      </div>
      <div className="border w-[65%] flex flex-row">
        <div className="w-[100px] flex flex-col justify-center items-center bg-[#ECECEC] py-4">
          <img src={DummyGallery} alt="DummyGallery" className="w-10 h-10" />
        </div>
        <div className="flex flex-col w-[80%]">
          <div className="p-4  border-b">
            <p className=" h-6 m-auto bg-[#DEDEDE] "></p>
          </div>
          <div className="p-4 ">
            <p className=" h-6 bg-[#DEDEDE] w-28"></p>
          </div>
        </div>
      </div>
    </div>
  );
}
