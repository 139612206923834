export const ProfessionalSalutationOptions = [
  {
    value: "Ar",
    label: "Ar",
  },
  {
    value: "ID",
    label: "ID",
  },
  {
    value: "PMC",
    label: "PMC",
  },
  {
    value: "Mr.",
    label: "Mr.",
  },
  {
    value: "Mrs.",
    label: "Mrs.",
  },
  {
    value: "Miss",
    label: "Miss",
  },
];
