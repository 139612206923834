import DeleteIcon from "../../../assets/svg/trash.svg";
import EditIcon from "../../../assets/svg/pen.svg";
import CopyIcon from "../../../assets/svg/copyIcon.svg";
import {
  VariantBigBlockWithImageProps,
  VariantDataProps,
} from "../../../types";
import PasswordIcon from "../../../assets/PasswordIcon";
import React, { useState } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/common/Modal";

type Card1Props = {
  el: VariantBigBlockWithImageProps;
  variantData?: VariantDataProps;
  setVariantData?: (data: VariantDataProps) => void;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSeletedOption?: React.Dispatch<React.SetStateAction<any>>;
};
function Card1({
  el,
  variantData,
  setVariantData,
  setOpenModal,
  setSeletedOption,
}: Card1Props) {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(null);

  const handleEdit = (elid: any) => {
    setOpenModal?.(true);
    setSeletedOption?.({ elid: elid, task: "edit" });
  };

  const handleCopy = (elid: any) => {
    setOpenModal?.(true);
    setSeletedOption?.({ elid: elid, task: "copy" });
  };

  const handleDelete = (elid: any) => {
    if (!variantData) return;
    // Delete Option Functionality
    const deletedOptions = variantData.options.find(
      (each) => each.id === elid.id
    );
    // @ts-ignore
    if (deletedOptions?.added) {
      const updatedOptions = variantData.options.filter(
        (each) => each.id !== elid.id
      );
      setVariantData?.({ ...variantData, options: updatedOptions });
    } else {
      if (deletedOptions) {
        deletedOptions.to_delete = true;
        const updatedOptions = variantData.options.filter(
          (each) => each.id !== elid.id
        );
        setVariantData?.({
          ...variantData,
          options: [...updatedOptions, deletedOptions],
        });
      }
    }
    setShowDeleteModal(null);
  };
  const handleImageClick = () => {
    if (el.image) {
      window.open(`${process.env.REACT_APP_BUCKET}/${el.image}`, "_blank");
    }
  };
  return (
    <>
      <div className="border-2 text-md leading-tight w-full flex flex-col p-4 gap-2">
        <div className="flex justify-between">
          <p>
            Name: <span>{el.name}</span> &ensp; | &ensp;Code:{" "}
            <span>{el.code}</span>
          </p>

          {variantData && (
            <div className="flex gap-5">
              <img
                src={CopyIcon}
                alt="CopyIcon"
                onClick={() => handleCopy(el)}
                className="cursor-pointer"
              />

              <img
                src={EditIcon}
                alt="EditIcon"
                onClick={() => handleEdit(el)}
                className="cursor-pointer"
              />
              <img
                src={DeleteIcon}
                alt="DeleteIcon"
                onClick={() => setShowDeleteModal(el)}
                className="cursor-pointer"
              />
            </div>
          )}
        </div>
        <p>
          Price: <span>Rs {el.price}</span>
        </p>
        {Object.entries(el.properties || {}).map(([key, val],i) => (
          <div key={i} className="flex flex-col gap-2 py-1">
            <p className="leading-3">
              {key} : <span>{val as string}</span>
            </p>
          </div>
        ))}
        <div className={`flex items-center gap-2`}>
          <p>Image: </p>
          <div
            className={`bg-[#F4F4F4] p-2 py-1 flex items-center gap-4 ${
              !el.image ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
            }`}
            onClick={handleImageClick}
          >
            <p>{el.image?.slice(-8)}</p>
            <span>
              <PasswordIcon />
            </span>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <Modal
          top
          header="Delete Option"
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => handleDelete(showDeleteModal)}
              />
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setShowDeleteModal(null);
                }}
              />
            </div>
          }
          handleCancel={() => setShowDeleteModal(null)}
        >
          <p className="text-left">
            Are you sure you want to delete the option?
          </p>
        </Modal>
      )}
    </>
  );
}

export default Card1;
