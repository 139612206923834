import React, { useState } from "react";
import LoadingSpinner from "../assets/images/Spinner4.gif";
type MustButtonPropsType = {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
};

export type ButtonPropsType = {
  variant?:
    | "primary"
    | "primary-dark"
    | "primary-outline"
    | "secondary"
    | "secondary-outline"
    | "secondary-outline-dark"
    | "secondary-text";
  disabled?: boolean;
  leftIcon?: React.ReactNode | null;
  rightIcon?: React.ReactNode | null;
  label?: string;
  removeHover?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  multiClickAllowed?: boolean;
  colorKind?: any;
  noStroke?: boolean;
  rounded?: boolean;
};
// onClick?, label?, leftIcon?, rightIcon?, variant? = "primary"
// The buttons should have enumerated variants which are: primary, primary-dark, secondary, secondary-outline
export const primaryStyle = (removeHover: boolean) =>
  `bg-pot-yellow text-white stroke-white ${
    !removeHover && "hover:bg-pot-grey5 hover:border-pot-grey5"
  } text-sm  active:bg-pot-grey2 border-2 border-pot-yellow  ease-in-out transition `;

export const primaryOutlineStyle = (
  removeHover: boolean,
  noStroke: boolean,
  colorKind?: string
) =>
  `  ${!removeHover && "hover:border-[#3F3F3F]"}  border-2 border-pot-yellow ${
    colorKind ? `text-${colorKind}` : "text-black"
  } ease-in-out transition disabled:opacity-75 `;

export const primaryDarkStyle = (removeHover: boolean) =>
  `text-sm bg-pot-yellow text-white font-semibold stroke-white border-2 border-pot-yellow ${
    !removeHover && "hover:bg-pot-grey5"
  } active:bg-pot-grey2 ease-in-out transition `;

export const secondaryStyle = (removeHover: boolean) =>
  `text-sm bg-pot-grey5 text-white border-2 border-pot-grey5  ${
    !removeHover &&
    "hover:bg-white hover:text-black hover:border-2 hover:stroke-black hover:border-pot-grey5 "
  } active:bg-pot-grey2 active:text-white active:stroke-white ease-in-out transition`;

export const secondaryOutlineStyle = (removeHover: boolean) =>
  `text-sm bg-white text-black stroke-black border-2 border-pot-grey5 ${
    !removeHover && "hover:bg-pot-grey5 hover:text-white hover:stroke-white"
  } active:bg-pot-grey2  active:text-white ease-in-out transition disabled:opacity-75 `;

export const secondaryOutlineDarkStyle = (
  removeHover: boolean,
  noStroke: boolean,
  colorKind?: string
) =>
  ` ${!noStroke && "stroke-white"} ${
    !removeHover && "hover:border-pot-yellow"
  }  border-2 border-[#3F3F3F] ${
    colorKind ? `text-${colorKind}` : "text-black"
  } ease-in-out transition`;

export const secondaryTextStyle = (removeHover: boolean) =>
  `text-sm text-pot-yellow stroke-pot-yellow ${
    !removeHover && "hover:underline"
  } active:bg-pot-grey2 active:text-white `;

export const secondaryDisabledStyle = `text-sm bg-pot-lightgrey text-pot-grey1 stroke-pot-grey1 cursor-not-allowed disabled:hover `;

export const secondaryOutlineStyleDisabled = `text-sm bg-pot-white  text-pot-lightgrey stroke-pot-grey-1 border-2 border-pot-lightgrey cursor-not-allowed disabled:hover `;
// export const primaryOutlineDisabledStyle: string =
//     "shadow-[inset_0_0_0_1px_rgba(203,203,203,1)] text-pot-textgrey stroke-pot-textgrey cursor-not-allowed";
// export const tertiaryDisabledStyle: string =
//     "text-pot-textgrey stroke-pot-textgrey cursor-not-allowed";

const Button = ({
  variant = "primary",
  disabled = false,
  leftIcon = null,
  rightIcon = null,
  onClick = () => {},
  label = "",
  removeHover = false,
  fullWidth = false,
  loading = false,
  multiClickAllowed = false,
  colorKind,
  noStroke = false,
  rounded = true,
}: ButtonPropsType & MustButtonPropsType) => {
  // const fullWidthStyle = fullWidth
  //     ? "w-full justify-center mx-5"
  //     : "w-fit justify-between";
  const typeStyle =
    variant === "primary"
      ? primaryStyle(removeHover)
      : variant === "primary-dark"
      ? primaryDarkStyle(removeHover)
      : variant === "primary-outline"
      ? primaryOutlineStyle(removeHover, noStroke, colorKind)
      : variant === "secondary"
      ? secondaryStyle(removeHover)
      : variant === "secondary-outline"
      ? secondaryOutlineStyle(removeHover)
      : variant === "secondary-outline-dark"
      ? secondaryOutlineDarkStyle(removeHover, noStroke, colorKind)
      : variant === "secondary-text"
      ? secondaryTextStyle(removeHover)
      : "";

  const disabledStyle =
    variant === "primary" || variant === "secondary"
      ? secondaryDisabledStyle
      : variant === "secondary-outline" ||
        variant === "primary-dark" ||
        variant === "secondary-text"
      ? secondaryOutlineStyleDisabled
      : "";

  const iconHoverStyle = `
      button:hover ${variant === "primary" ? secondaryStyle(removeHover) : ""} 
      button:hover ${
        variant === "secondary-outline"
          ? secondaryOutlineStyle(removeHover)
          : ""
      } 
    `;

  // For 1 second disable after click
  const [isClickDisabled, setIsClickDisabled] = useState(false);
  const handleClick = () => {
    if (loading || disabled || (isClickDisabled && !multiClickAllowed)) return;
    
    if (!multiClickAllowed) setIsClickDisabled(true);
    onClick();
    if (!multiClickAllowed) {
      setTimeout(() => {
        setIsClickDisabled(false);
      }, 1000);
    }
  };

  return (
    <>
      <button
        onClick={handleClick}
        // ${disabled ? disabledStyle : typeStyle}
        className={`${disabled ? disabledStyle : typeStyle} ${
          fullWidth && "w-full justify-center "
        } ${
          loading ? "px-0 py-0" : "px-2.5 py-1.5"
        } flex justify-center gap-2 items-center font-gilroy-semi-bold ${
          rounded ? "rounded-md" : ""
        }`}
      >
        {loading ? (
          <img
            src={LoadingSpinner}
            alt="LoadingSpinner2"
            className="h-8 w-8 object-fit"
          />
        ) : (
          <>
            {leftIcon}
            {label && <p className="md:max-w-[40vw] whitespace-nowrap">{label}</p>}
            {rightIcon && (
              <div className={`pl-2 flex items-center ${iconHoverStyle}`}>
                {rightIcon}
              </div>
            )}
          </>
        )}
      </button>
    </>
  );
};

export default Button;
