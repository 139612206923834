import React from "react";

function GstCard({ title, content }: any) {
  return (
    <div className="my-5 py-2 px-1.5 w-[32%] h-16 flex flex-col justify-evenly bg-slate-100 border-[#D4A15E] border-l-[3px]">
      <div className="text-left flex flex-col gap-1 items-start justify-start ">
        <h1
          className={`${
            content == "Company name" ? "text-[10px] font-semibold" : "text-xs font-semibold"
          } `}
        >
          {title}
        </h1>
        <h2 className="text-xs text-[#aeaeae]">{content}</h2>
      </div>
    </div>
  );
}

export default GstCard;
