import React, { useState, useMemo } from "react";
import InputField from "../../../../components/InputField";
import Button from "../../../../components/Button";
import Modal from "../../../../components/common/Modal";
import { VariantDataProps } from "../../../../types";
import { VariantColorPickerProps } from "../../../../types";
import { v4 as uuidv4 } from "uuid";
import ImageUpload from "../../../../components/ImageUpload";
import {
  checkHexCode,
  validateHexCode,
  validatePrice,
} from "../../../../utils/validations";
import { removeExtraSpacesfromObject } from "../../../../utils/helper";

type ColorPickerPopupProps = {
  handleClose: () => void;
  setVariantData: React.Dispatch<React.SetStateAction<VariantDataProps>>;
  data?: VariantColorPickerProps;
  task?: "copy" | "edit";
  setVariantPopupError?: any;
};

// COLOR_PICKER Option Schema
// {
//   "name": "Option #1",
//   "product_image": "products/bd0b7d82-24b4-46cc-a19b-57cc12a41368.jpg",
//   "hex_code": "#000000",
//   "price": 50,
//   "code": "A354"
// }

const initState: VariantColorPickerProps = {
  name: "",
  product_image: "",
  hex_code: "",
  price: 0,
  code: null,
  type: "COLOR_PICKER",
};

export default function ColorPickerPopup({
  handleClose,
  setVariantData,
  data = initState,
  task,
  setVariantPopupError,
}: ColorPickerPopupProps) {
  const [colorPickerData, setColorPickerData] =
    useState<VariantColorPickerProps>(structuredClone(data));
  const [errorMessage, setErrorMessage] = useState<any>({
    name: [],
    product_image: [],
    hex_code: [],
    price: [],
    code: [],
  });

  const handleAddOption = () => {
    if (
      colorPickerData?.name?.length === 0 ||
      (colorPickerData?.hex_code && !checkHexCode(colorPickerData?.hex_code))
    ) {
      if (colorPickerData?.name?.length === 0) {
        setErrorMessage((e: any) => ({
          ...e,
          name: ["Please enter the color name."],
        }));
      }
      if (
        colorPickerData?.hex_code &&
        !checkHexCode(colorPickerData?.hex_code)
      ) {
        setErrorMessage((e: any) => ({
          ...e,
          hex_code: ["Not a valid hex code."],
        }));
      }
      return;
    }
    const clone: any = removeExtraSpacesfromObject(structuredClone(colorPickerData));

    if (
      clone.price === "" ||
      (clone.price && +clone.price == 0) ||
      !clone.price
    ) {
      clone.price = "0";
    }
    if (clone.price) {
      clone.price = parseFloat(clone.price);
    }

    if (data.id && task !== "copy") {
      // Edit Option Functionality
      setVariantData((prev) => ({
        ...prev,
        options: prev.options.map((el) => (el.id === data.id ? clone : el)),
      }));
    } else {
      // Add Option Functionality
      const copyID = data.id;
      const newData = { ...clone, id: uuidv4(), added: true };
      setVariantData((prev) => {
        if (task === "copy") {
          const findIndex = prev.options.findIndex(
            (option) => option?.id === copyID
          );
          const updatedOptions = [...prev.options];
          updatedOptions.splice(findIndex + 1, 0, newData);
          return {
            ...prev,
            options: updatedOptions,
          };
        } else {
          const deleteIndex = prev.options.findIndex(
            (option) => option?.to_delete
          );

          if (deleteIndex === -1) {
            return {
              ...prev,
              options: [...prev.options, newData],
            };
          }

          const updatedOptions = [...prev.options];
          updatedOptions.splice(deleteIndex, 0, newData);
          return {
            ...prev,
            options: updatedOptions,
          };
        }
      });
    }
    setColorPickerData(initState);
    setVariantPopupError((prev: any) => ({ ...prev, options: [] }));
    handleClose();
  };

  const productImageList = useMemo(
    () =>
      colorPickerData.product_image ? [colorPickerData.product_image] : [],
    [colorPickerData.product_image]
  );

  return (
    <Modal
      allowScroll
      header={
        task === "copy"
          ? "Add an Option"
          : data.name
          ? "Edit Option"
          : "Add an Option"
      }
      footer={
        <Button
          label={
            task === "copy"
              ? "Add Option"
              : colorPickerData.id
              ? "Update Option"
              : "Add Option"
          }
          variant="secondary"
          onClick={handleAddOption}
        />
      }
      handleCancel={handleClose}
    >
      <div className="w-full h-full flex flex-col gap-3 ">
        {/* code */}
        <InputField
          onChange={(e) => {
            setColorPickerData((prevData) => ({
              ...prevData,
              code: e?.toUpperCase(), // Set code to null if empty, otherwise to uppercase value
            }));
            setErrorMessage((e: any) => ({ ...e, code: [] }));
          }}
          value={colorPickerData.code}
          placeholder="Code"
          label="Code:"
          hint={errorMessage?.code}
          maxLength={10}
        />
        {/* name */}
        <InputField
          onChange={(e) => {
            setColorPickerData((prevState) => ({
              ...prevState,
              name: e,
            }));
            setErrorMessage((e: any) => ({ ...e, name: [] }));
          }}
          value={colorPickerData.name}
          placeholder="Name"
          label="Name:"
          hint={errorMessage?.name}
          required
        />
        {/* price */}
        <InputField
          onChange={(e: any) => {
            if (e > 99999) {
              setErrorMessage((prevError: any) => ({
                ...prevError,
                price: ["Price should be less than or equal to 99999."],
              }));
            } else {
              setColorPickerData((prevData) => ({ ...prevData, price: e }));
              setErrorMessage((prevError: any) => ({
                ...prevError,
                price: [],
              }));
            }
          }}
          value={colorPickerData.price || 0}
          placeholder="Price"
          label="Price:"
          type="price"
          validate={validatePrice}
          hint={errorMessage?.price}
        />

        {/* hex_code */}

        <InputField
          maxLength={7}
          required
          onChange={(e) => {
            setColorPickerData((prevData) => ({
              ...prevData,
              hex_code: e,
            }));
            setErrorMessage((err: any) => ({ ...err, hex_code: [] }));
          }}
          validate={validateHexCode}
          value={colorPickerData?.hex_code}
          placeholder="Hex code"
          label="Color Picker:"
          hint={errorMessage?.hex_code}
          leftIcon={
            checkHexCode(colorPickerData?.hex_code) ? (
              <div
                style={{
                  backgroundColor: colorPickerData?.hex_code,
                  width: "1.5rem",
                  height: "60%",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: "0.625rem",
                }}
              ></div>
            ) : (
              <div className="font-gilroy-medium">NA</div>
            )
          }
        />

        {/* product_image */}
        <ImageUpload
          label="Upload Product Image:"
          imageList={productImageList}
          setter={(value) => {
            setColorPickerData((prev) => ({
              ...prev,
              product_image: value as string,
            }));
          }}
          kind="PRODUCT"
          errors={errorMessage?.product_image}
          removeError={() =>
            setErrorMessage((e: any) => ({ ...e, product_image: [] }))
          }
        />
      </div>
    </Modal>
  );
}
