import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { motion } from "framer-motion";
import { protectedAxiosInstance } from "../api/axiosManagement";
import ErrorMessage from "./ErrorMessage";
import CrossIcon from "../assets/CrossIcon";
import StarIcon from "../assets/StarIcon";
import LoadingSpinner from "../assets/images/LoadingSpinner.gif";
import UploadFileIcon from "../assets/svg/uploadfile.svg";
import Modal from "./common/Modal";
import Button from "./Button";
import Cropper from "react-easy-crop";
import getCroppedImg from "../utils/getCroppedImg";
// kind
type ProfileImageUploadPropType = {
  isMulti?: boolean;
  kind: "PI-PHOTO" | "PC-LOGO";
  setter: (values: string | string[]) => void;
  imageList: string[];
  primary?: string;
  primarySetter?: (value: string) => void;
  errors?: string[];
  setErrors?: any;
  disabled?: boolean;
  removeError?: () => void;
  maxSize?: number;
  label?: string;
  subLabel?: string;
  targetRef?: any;
};

/**
 *
 * for imageList in single upload memoize it with an array because it only accept as an array
 * ie. const imageList = useMemo(() => [image], [image])
 */
const ProfileImageUpload = ({
  isMulti = false,
  kind,
  setter,
  imageList,
  primary = "",
  primarySetter = () => {},
  errors = [],
  setErrors,
  disabled,
  removeError = () => {},
  maxSize = 1,
  label,
  subLabel,
  targetRef,
}: ProfileImageUploadPropType) => {
  const [showUploadDiv, setShowUploadDiv] = useState(true);
  const [mouseOnImage, setMouseOnImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState<any>(null);

  const get_signed = async (extension: string) => {
    const payload = {
      extension,
      kind,
    };
    const response: any = await protectedAxiosInstance
      .post(`/admin/accounts/generate-upload-url`, payload)
      .then((res: any) => res)
      .catch((res: any) => {
        return res;
      });

    return response?.data?.data;
  };

  const signed_to_s3 = async (acceptedFile: File) => {
    setUploading(true);
    const extension = acceptedFile.name.split(".").at(-1)?.toLowerCase();

    try {
      const { file_path, upload_url } = await get_signed(extension as string);

      const file = new FormData();
      file.append("file", acceptedFile);

      let contentType: string;

      if (extension === "jpeg" || extension === "jpg") {
        contentType = "image/jpeg";
      } else if (extension === "png") {
        contentType = "image/png";
      } else if (extension === "webp") {
        contentType = "image/webp";
      } else {
        contentType = "";
      }

      const response = await fetch(upload_url, {
        method: "PUT",
        body: acceptedFile,
        headers: {
          "Content-Type": contentType,
        },
      });

      return { response, file_path };
    } catch (error) {
      console.error("Error uploading file to S3:", error);
      // Handle the error appropriately, e.g., show an error message to the user
      return { response: null, file_path: null, error };
    } finally {
      setUploading(false);
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    removeError();
    if (fileInputRef0.current) {
      fileInputRef0.current.value = null;
    }
    const supportedExtensions = [".jpg", ".jpeg", ".png", ".webp"];
    if (
      !acceptedFiles.every((file) => {
        const fileNameParts = file.name.split(".");
        const extension =
          fileNameParts.length > 1 ? fileNameParts.pop() : undefined;
        return extension && supportedExtensions.includes(`.${extension}`);
      })
    ) {
      setErrors && setErrors("Only jpg, jpeg, png, webp formats are supported");
      return;
    }

    if (!acceptedFiles.length) {
      setErrors && setErrors("Only jpg, jpeg, png, webp formats are supported");
      return;
    }
    const maxSizeBytes = maxSize * 1024 * 1024;

    const oversizedFiles = acceptedFiles.filter(
      (file) => file.size > maxSizeBytes
    );

    if (oversizedFiles.length > 0) {
      setErrors && setErrors("File exceed the maximum size limit.");
      return;
    }

    const imageFile = acceptedFiles[0];
    setImage(imageFile);
    return;
    // if (imageFile) {
    //   const reader = new FileReader();

    //   reader.onload = (event: ProgressEvent<FileReader>) => {
    //     if (event.target && event.target.result) {
    //       const img: HTMLImageElement = new window.Image();

    //       img.onload = async () => {
    //         const { naturalWidth, naturalHeight } = img;
    //         console.log(naturalWidth, naturalHeight);

    //         if (naturalWidth !== naturalHeight) {
    //           setErrors &&
    //             setErrors(
    //               `Please upload square image${
    //                 isMulti ? "s" : ""
    //               } with a 1:1 ratio. The best size is 1080 x 1080 pixels.`
    //             );
    //           return;
    //         } else {
    //           if (!showUploadDiv) return;
    //           if (!isMulti) {
    //             const { response, file_path } = await signed_to_s3(
    //               acceptedFiles[0]
    //             );
    //             setter(file_path);
    //             setShowUploadDiv(false);
    //           } else {
    //             const pathList = await Promise.all(
    //               acceptedFiles.map(async (acceptedFile) => {
    //                 const { response, file_path } = await signed_to_s3(
    //                   acceptedFile
    //                 );
    //                 return file_path;
    //               })
    //             );
    //             // console.log(pathList, imageList);
    //             setter([...pathList, ...(imageList || [])]);
    //             if (!primary) {
    //               primarySetter(pathList[0]);
    //             }
    //           }
    //         }
    //       };

    //       img.src = event.target.result as string;
    //     }
    //   };

    //   reader.readAsDataURL(imageFile);
    // }
  };

  const handleDelete = (image: string) => {
    removeError();
    if (isMulti) {
      const filteredList = imageList.filter((each) => each !== image);
      // console.log(filteredList, "filtered");
      if (primary === image) {
        if (filteredList.length) {
          primarySetter(filteredList[0]);
        } else {
          primarySetter("");
        }
      }
      setter(filteredList);
    } else {
      setter("");
      setShowUploadDiv(true);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      ".jpeg": [],
      ".jpg": [],
      ".png": [],
      ".webp": [],
    },
    onDrop,
    multiple: isMulti,
  });

  useEffect(() => {
    if (imageList?.length && !isMulti) {
      setShowUploadDiv(false);
    }
  }, [imageList]);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleFileSelect(event.target.files);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const handleFileSelect = (files: FileList | null) => {
    if (files) {
      // Call the onDrop function with the selected files
      onDrop(Array.from(files));
    }
  };
  // crop functionality
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const zoomInputRef = useRef<any>(null);
  useEffect(() => {
    if (zoomInputRef.current) {
      zoomInputRef.current.style.setProperty(
        "--thumb-position",
        `${((zoom - 1) / (3 - 1)) * 100}%`
      );
    }
  }, [zoom]);
  const rotationInputRef = useRef<any>(null);
  useEffect(() => {
    if (rotationInputRef.current) {
      rotationInputRef.current.style.setProperty(
        "--thumb-position",
        `${(rotation / 360) * 100}%`
      );
    }
  }, [rotation]);
  useEffect(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
  }, [image]);
  // const [flip, setFlip] = useState({ horizontal: false, vertical: false });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const fileInputRef = React.useRef<any>(null);
  const fileInputRef0 = React.useRef<any>(null);
  const onCropComplete = useCallback(
    (croppedAreaPercentage: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCropConfirm = async () => {
    if (image && croppedAreaPixels) {
      try {
        const croppedImageBlob: any = await getCroppedImg(
          URL.createObjectURL(image),
          croppedAreaPixels,
          rotation
        );

        if (croppedImageBlob) {
          if (croppedImageBlob.size === 0) {
            throw new Error("Cropped image blob is empty.");
          }

          const croppedFile = new File([croppedImageBlob], image.name, {
            type: image.type,
          });

          const { response, file_path } = await signed_to_s3(croppedFile);

          if (!isMulti) {
            setter(file_path);
            setShowUploadDiv(false);
          } else {
            const pathList = await Promise.all(
              [croppedFile].map(async (file) => {
                const { response, file_path } = await signed_to_s3(file);
                return file_path;
              })
            );
            setter([...pathList, ...(imageList || [])]);
            if (!primary) {
              primarySetter(pathList[0]);
            }
          }
          setImage(null); // Close the cropper modal
        }
      } catch (error) {
        console.error("Error during cropping and uploading:", error);
      }
    }
  };
  const handleChangeImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <div ref={targetRef}>
        <div className="pb-2">
          <p className="font-medium text-xs pb-1">{label}</p>
          {subLabel && imageList?.length === 0 && !uploading && (
            <p className="font-light italic text-xs pb-2">{subLabel}</p>
          )}
        </div>

        <div className={`${disabled ? "opacity-50 pointer-events-none" : ""}`}>
          {uploading && (
            <img
              style={{ height: "auto" }}
              src={LoadingSpinner}
              alt="picture"
              className="w-20 h-20 object-contain"
              width={10}
              height={10}
            />
          )}
          {showUploadDiv && !uploading && (
            <div
              {...getRootProps()}
              className="flex items-center justify-center w-full"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <label className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed cursor-pointer bg-white hover:bg-gray-100">
                <div className="flex flex-col items-center justify-center pt-5 pb-6 gap-1">
                  <img
                    style={{ height: "auto" }}
                    src={UploadFileIcon}
                    alt="upload file"
                    width={15}
                    height={15}
                  />
                  <p className=" text-sm text-gray-500">
                    Drag & Drop or{" "}
                    <span className="font-gilroy-bold text-pot-yellow">
                      Choose
                    </span>{" "}
                    file to upload{" "}
                  </p>
                  <p className="text-xs text-gray-500">
                    Upload JPEG, JPG, PNG, WEBP (Max file size: {maxSize} MB)
                  </p>
                </div>
                <input {...getInputProps()} ref={fileInputRef0} />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }} // Hide the input
                  accept=".jpg, .jpeg, .png, .webp"
                  onChange={handleFileInputChange}
                  multiple={isMulti} // Handle multiple files if needed
                />
              </label>
            </div>
          )}
          <ErrorMessage error={errors} />
          <div className="grid grid-cols-2 sm:grid-cols-5 w-full gap-3">
            {imageList?.map((each) => {
              return (
                <button
                  onMouseEnter={() => setMouseOnImage(each)}
                  onMouseLeave={() => setMouseOnImage("")}
                  onClick={() => {
                    primarySetter(each);
                  }}
                  key={each}
                  className={`p-1 relative border-2 border-white`}
                >
                  {isMulti && each === primary && (
                    <motion.div
                      layoutId="primary_image"
                      className="absolute z-10 top-0 left-0 w-full h-full shadow-[0px_0px_0px_4px_#D4A15E]"
                    >
                      <div className="absolute top-[1px] left-[1px] w-fit h-fit bg-pot-yellow text-white rounded-full text-[7px] px-1 py-1">
                        <StarIcon color="white" />
                      </div>
                    </motion.div>
                  )}
                  {mouseOnImage === each && (
                    <div className="absolute top-0 left-0 w-full h-full">
                      <motion.button
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleDelete(each);
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="scale-75 absolute z-20 -top-1 -right-1 w-fit h-fit stroke-white p-2 bg-black rounded-full"
                      >
                        <CrossIcon />
                      </motion.button>
                    </div>
                  )}
                  <img
                    style={{ height: "auto" }}
                    src={process.env.REACT_APP_BUCKET + "/" + each} //eslint-disable-line
                    alt="picture"
                    className="w-full h-full object-contain"
                    width={500}
                    height={500}
                  />
                </button>
              );
            })}
          </div>
        </div>
      </div>
      {image && (
        <Modal
          // minWidth="max-w-[90vw]  lg:max-w-[60vw]"
          header="Resize your image"
          handleCancel={() => setImage(null)}
          footer={
            <div className="flex gap-x-4 gap-y-2 justify-between w-full">
              <div className="flex gap-x-4 gap-y-2">
                <Button
                  label="Upload"
                  onClick={handleCropConfirm}
                  disabled={uploading}
                />
                <Button
                  label="Cancel"
                  variant="secondary"
                  onClick={() => {
                    setImage(null);
                  }}
                  disabled={uploading}
                />
              </div>

              <Button
                variant="secondary-text"
                label="Change Image"
                onClick={handleChangeImage}
                disabled={uploading}
              />
            </div>
          }
        >
          <div className="w-full">
            <div className="relative w-full h-[calc(80dvh-180px)] bg-black">
              <Cropper
                image={URL.createObjectURL(image)}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
              />
            </div>
            <div className="flex justify-between mt-4 mb-8 gap-x-5">
              {/* Zoom controls */}
              <div className="flex flex-col items-start flex-1 ">
                <label className="text-sm font-gilroy-medium mb-4">Zoom</label>
                <input
                  ref={zoomInputRef}
                  type="range"
                  min="1"
                  max="3"
                  step="0.01"
                  value={zoom}
                  onChange={(e) => setZoom(parseFloat(e.target.value))}
                  className="w-full slider-circle"
                />
              </div>

              {/* Rotation controls */}
              <div className="flex flex-col items-start flex-1">
                <label className="text-sm font-gilroy-medium mb-4">
                  Rotation
                </label>
                <input
                  ref={rotationInputRef}
                  type="range"
                  min="0"
                  max="360"
                  step="1"
                  value={rotation}
                  onChange={(e) => setRotation(parseFloat(e.target.value))}
                  className="w-full slider-circle"
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProfileImageUpload;
