import { Link } from "react-router-dom";
import CircledTickIcon from "../../../../../assets/CircledTickIcon";
import CircledCrossIcon from "../../../../../assets/CircledCrossIcon";

const DetailSection = ({ data, loading }: any) => {
  // Helper function to render value or "NA" if value is null or undefined
  const renderValue = (value: any) => {
    if (typeof value === "string" && value.includes("T")) {
      const date = new Date(value);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    // Otherwise, return the value itself
    return value;
  };

  console.log(data);

  return (
    <div className="w-[25%] border-r-2">
      <div className="text-start px-4 py-2">
        <div className="flex justify-between">
          <h1 className="text-pot-yellow font-medium text-lg">
            Customer Profile:{" "}
          </h1>
        </div>

        <div
          className={`flex flex-col  ${
            loading ? "gap-3" : "gap-2 "
          }  text-start mt-4 text-[12px] leading-6`}
        >
          <div className="font-gilroy-medium flex flex-col">
            {loading ? (
              <div className="w-24 h-4 bg-skeleton-text"></div>
            ) : (
              <p className="text-[#AEAEAE] leading-[20px] text-[12px]">Name:</p>
            )}
            {loading ? (
              <div className="w-[60%] h-5 bg-skeleton-text mt-1.5"></div>
            ) : (
              <p className="leading-[20px] text-[12px]">
                {data?.customer?.name}
              </p>
            )}
          </div>

          <div className="font-gilroy-medium flex flex-col">
            <div className="flex justify-start items-center flex-row gap-4">
              {loading ? (
                <div className="w-24 h-4 bg-skeleton-text "></div>
              ) : (
                <p className="text-[#AEAEAE] leading-[20px] text-[12px]">
                  Email ID:
                </p>
              )}

              {loading ? (
                ""
              ) : data?.customer?.is_email_verified === true ? (
                <CircledTickIcon color="green" size={15} />
              ) : (
                <CircledCrossIcon color="red" size={15} />
              )}
            </div>

            <p className="text-pot-yellow leading-[20px] text-[12px] underline">
              {loading ? (
                <div className="w-[80%] h-5 bg-skeleton-text mt-1.5"></div>
              ) : data?.customer?.email ? (
                <Link to={`mailto:${data?.customer?.email}`}>
                  {data?.customer?.email}
                </Link>
              ) : (
                ""
              )}
            </p>
          </div>

          <div className="font-gilroy-medium flex flex-col">
            <div className="flex justify-start items-center flex-row gap-4">
              {loading ? (
                <div className="w-24 h-4 bg-skeleton-text "></div>
              ) : (
                <p className="text-[#AEAEAE] leading-[20px] text-[12px]">
                  Contact number:{" "}
                </p>
              )}

              <span>
                {loading ? (
                  ""
                ) : data?.customer?.is_phone_verified == true ? (
                  <CircledTickIcon color="green" size={15} />
                ) : (
                  <CircledCrossIcon color="red" size={15} />
                )}
              </span>
            </div>
            <p className="text-pot-yellow leading-[20px] text-[12px] underline">
              {loading ? (
                <div className="w-40 h-5 bg-skeleton-text mt-1.5"></div>
              ) : data?.customer?.phone ? (
                data?.customer?.phone
              ) : (
                "-"
              )}
            </p>
          </div>

          <div className="font-gilroy-medium flex flex-col ">
            {loading ? (
              <div className="w-24 h-4 bg-skeleton-text "></div>
            ) : (
              <p className="text-[#AEAEAE] leading-[20px] text-[12px]">
                Date registered:
              </p>
            )}

            <p className="leading-[20px] text-[12px]">
              {loading ? (
                <div className="w-32 h-4 bg-skeleton-text mt-1.5"></div>
              ) : (
                renderValue(data?.customer?.date_joined)
              )}
            </p>
          </div>

          <div className="font-gilroy-medium flex flex-col gap-1">
            {loading ? (
              <div className="w-24 h-4 bg-skeleton-text "></div>
            ) : (
              <p className="text-[#AEAEAE] leading-[20px] text-[12px]">
                Gender:
              </p>
            )}
            <p className="leading-[20px] text-[12px]">
              {loading ? (
                <div className="w-28 h-5 bg-skeleton-text mt-1.5"></div>
              ) : data?.customer?.gender ? (
                data?.customer?.gender
              ) : (
                "-"
              )}
            </p>
          </div>
          <div className="font-gilroy-medium flex flex-col">
            {loading ? (
              <div className="w-24 h-4 bg-skeleton-text "></div>
            ) : (
              <p className="text-[#AEAEAE] leading-[20px] text-[12px]">
                Professional status:
              </p>
            )}

            {loading ? (
              <div className="w-32 h-5 bg-skeleton-text mt-1.5"></div>
            ) : data?.customer?.p_status ? (
              <p
                className={`font-gilroy-semi-bold  rounded-md text-sm m-0.5 px-3 py-0.5 cursor-pointer border-none max-w-fit ${
                  data?.customer?.p_status === "DRAFT"
                    ? "bg-[#EDEDED]"
                    : data?.customer?.p_status === "IN_REVIEW"
                    ? "bg-[#FFC5EF]"
                    : data?.customer?.p_status === "VERIFIED"
                    ? "bg-[#B6FDC6]"
                    : data?.customer?.p_status === "REJECTED"
                    ? "bg-[#FFB4A1]"
                    : data?.customer?.p_status === "CHANGE_REQUESTED"
                    ? "bg-[#BFB2FF]"
                    : data?.customer?.p_status ===
                      "AWAITING_EXPERIENCE_APPROVAL"
                    ? "bg-[#FFDCB2]"
                    : ""
                }`}
              >
                {data?.customer?.p_status?.replace(/_/g, " ")}
              </p>
            ) : (
              "-"
            )}
          </div>

          {data?.p_company && data?.customer?.is_professional && (
            <div className="font-gilroy-medium">
              {loading ? (
                <div className="w-24 h-4 bg-skeleton-text "></div>
              ) : (
                <p className="text-[#AEAEAE] leading-[20px] text-[12px]">
                  Company Name:
                </p>
              )}

              <p className="text-pot-yellow underline font-gilroy-semi-bold">
                {loading ? (
                  <div className="w-36 h-5 bg-skeleton-text mt-1.5"></div>
                ) : (
                  <Link
                    to={`/customers/professionals/company/${data?.customer?.id}`}
                  >
                    <a>{data?.p_company?.name}</a>
                  </Link>
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailSection;
