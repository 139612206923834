import React from "react";

const Title = ({ text, variant = "primary", size = "sm" }: any) => {
  const textStyle =
    variant === "primary"
      ? "text-pot-yellow font-semi-bold font-gilroy-medium"
      : variant === "secondary"
      ? "text-black font-semi-bold font-gilroy-medium"
      : "";

  const sizeClass = size ? `text-${size}` : "";

  return <p className={`${textStyle} ${sizeClass}`}>{text}</p>;
};

export default Title;
