import React, { useState } from "react";
import close from "../../../assets/svg/close.svg";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";

const OrderAddressDelete = ({
  targetAddress,
  setOpenDeleteAddress,
  handleDeleteAddress,
  setTargetAddress,
}: any) => {
  console.log(targetAddress);

  const [formData, setFormData] = useState(targetAddress);
  return (
    <div
      className="bg-white max-w-md max-h-[80vh] p-5 flex flex-col gap-2"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <p>Are you sure to delete the address?</p>

      <div className="flex gap-2">
        <Button
          label="Yes"
          variant="primary"
          onClick={() => {
            console.log(targetAddress);

            handleDeleteAddress(targetAddress?.id);
            setTargetAddress(null);
            setOpenDeleteAddress(false);
          }}
        />
        <Button
          label="No"
          variant="secondary-outline"
          onClick={() => {
            setTargetAddress(null);
            setOpenDeleteAddress(false);
          }}
        />
      </div>
    </div>
  );
};

export default OrderAddressDelete;
