import React from "react";
import editPen from "../../assets/svg/pen.svg";

const DetailSection = () => {
  return (
    <div className="text-start px-4 pt-2 py-4 border-b">
      <div className="w-full flex justify-between">
        <h1 className="text-pot-yellow font-medium text-lg">Details: </h1>
        <button>
          <img src={editPen} alt="edit-pen" />
        </button>
      </div>
      <div className="flex flex-col space-y-1  text-start mt-4 text-[12px] leading-6">
        <div>
          <p className="text-[#AEAEAE]">Name:</p>
          <p>{"Aishwarya Chatterjee"}</p>
        </div>
        <div>
          <p className="text-[#AEAEAE]">Email id:</p>
          <p>{"aishwaryachatterjee@gmail.com"}</p>
        </div>
        <div>
          <p className="text-[#AEAEAE]">Contact number:</p>
          <p>{7463856738}</p>
        </div>
        <div>
          <p className="text-[#AEAEAE]">Location:</p>
          <p>{"Vadodara, Gujrat"}</p>
        </div>
        <div>
          <p className="text-[#AEAEAE]">Open date:</p>
          <p>{"25th March, 2023"}</p>
        </div>
        <div>
          <p className="text-[#AEAEAE]">Closed date:</p>
          <p>{"25th March, 2023"}</p>
        </div>
        <div>
          <p className="text-[#AEAEAE]">Last active:</p>
          <p>{"05 Feb, 2023"}</p>
        </div>
        {/* <div>
          <p className="text-[#AEAEAE]">Email confirmation:</p>
          <p className="w-fit text-pot-yellow underline">Send email</p>
        </div>
        <div>
          <p className="text-[#AEAEAE]">Password reset:</p>
          <p className="w-fit text-pot-yellow underline">Password reset link</p>
        </div> */}
      </div>
    </div>
  );
};

export default DetailSection;
