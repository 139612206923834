import React from "react";

export default function ColorpickerPreview() {
  return (
    <div className="flex flex-col gap-3 text-left w-[14%]">
      <div>
        <p className="text-[#AEAEAE] text-md">Preview:</p>
      </div>
      <div className="w-auto flex flex-col gap-4">
        <div className="h-12 w-14 bg-[#DEDEDE] ml-2"></div>
        <p className="text-[#AEAEAE] text-md">Color name</p>
      </div>
    </div>
  );
}
