import React from "react";
import editPen from "../../../../../assets/svg/pen.svg";
import { Accordian } from "../../../../../components/Accordian";

const address = [
  {
    heading: "Home",
    address: "Whitney Blushing, Address", // TEMPORARY CONTENT
    postalCode: 411032,
    city: "Vadodara",
    state: "Gujrat",
  },
  {
    heading: "Work",
    address: "Whitney Blushing, Address", // TEMPORARY CONTENT
    postalCode: 847302,
    city: "Dbg",
    state: "Bihar",
  },
  {
    heading: "Office",
    address: "Whitney Blushing, Address", // TEMPORARY CONTENT
    postalCode: 123456,
    city: "Vadodara",
    state: "Gujrat",
  },
  {
    heading: "Home",
    address: "Whitney Blushing, Address", // TEMPORARY CONTENT
    postalCode: 847302,
    city: "Dbg",
    state: "Bihar",
  },
  {
    heading: "Work",
    address: "Whitney Blushing, Address", // TEMPORARY CONTENT
    postalCode: 123456,
    city: "Vadodara",
    state: "Gujrat",
  },
];
function Address() {
  const handleAddAddress = () => {
    // Handle dropdown click logic here
    console.log("handleAddAddress");
  };
  return (
    <>
      <div>
        <Accordian
          label="Add address"
          heading="+ Add address"
          onClick={handleAddAddress}
        >
          <div>
            <div className="grid grid-cols-3">
              {address?.map((el, i) => {
                return (
                  <div key={i} className=" border-x border-y px-5 py-5">
                    <div className="flex justify-between pb-3 ">
                      <p className="bg-[#EDEDED] text-[14px] font-medium px-2 py-0.5 text-[#4E4E4E] rounded-md">
                        {" "}
                        {el.heading}
                      </p>
                      <div>
                        <img src={editPen} alt="edit-pen" />
                      </div>
                    </div>

                    <div className="flex flex-col text-[11px] leading-5 tracking-[-0.08px] text-start">
                      <div className="flex space-x-6">
                        <p className="">{el.address}</p>
                      </div>
                      <div className="flex  space-x-9">
                        <p className="">
                          {el.city} , {el.state}
                        </p>
                      </div>
                      <div className="flex  space-x-6">
                        <span className="text-start"> {el.postalCode}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Accordian>
      </div>
    </>
  );
}

export default Address;
