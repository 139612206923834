import React from "react";
import { IconProp } from "./ArrowIcons";

const EditIcon = ({ color, width }: IconProp) => {
  return (
    <svg
      width={width || "17"}
      height="18"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.349888 13.0237C0.322296 13.1587 0.328607 13.2984 0.368256 13.4304C0.407906 13.5623 0.479656 13.6824 0.577086 13.7798C0.674515 13.8773 0.794582 13.949 0.926539 13.9887C1.0585 14.0283 1.19823 14.0346 1.33322 14.007L4.50822 13.357L0.999888 9.8487L0.349888 13.0237ZM5.28406 10.8404L3.51739 9.0737L10.5882 1.9987H10.5899L12.3574 3.7662L5.28322 10.8412L5.28406 10.8404ZM13.5366 2.58787L11.7699 0.819534C11.615 0.6646 11.431 0.54178 11.2285 0.458123C11.026 0.374465 10.809 0.331616 10.5899 0.332034C10.1632 0.332034 9.73656 0.494534 9.41072 0.819534L1.27989 8.9512L5.40489 13.0762L13.5357 4.94453C13.8482 4.63199 14.0237 4.20814 14.0237 3.7662C14.0237 3.32426 13.8482 2.90041 13.5357 2.58787H13.5366Z"
        fill={color || "currentColor"}
      />
    </svg>
  );
};

export default EditIcon;
