import { useState } from "react";
import AddIcon from "../../../assets/svg-tsx/AddIcon";

import DownloadIcon from "../../../assets/svg-tsx/DownloadIcon";
import WatchIcon from "../../../assets/svg-tsx/WatchIcon";

import "../styles/photos.css";
import { Accordian } from "../../../components/Accordian";
import Button from "../../../components/Button";

import FileIcon from "../../../assets/svg-tsx/FileIcon";
import {
  formatDate1,
  formatFileSize,
  truncateFilename,
} from "../../../utils/helper";
import JPEGPreviewIcon from "../../../assets/svg-tsx/JPEGPreviewIcon";
import JPGPreviewIcon from "../../../assets/svg-tsx/JPGPreviewIcon";
import PNGPreviewIcon from "../../../assets/svg-tsx/PNGPreviewIcon";
import MP4PreviewIcon from "../../../assets/svg-tsx/MP4PreviewIcon";
import WEBPPreviewIcon from "../../../assets/svg-tsx/WEBPPreviewIcon";
import { Link, useParams } from "react-router-dom";
import PlayIcon from "../../../assets/svg-tsx/PlayIcon";
import PDFFileIcon from "../../../assets/svg-tsx/PDFFileIcon";
import DWGFileIcon from "../../../assets/svg-tsx/DWGFileIcon";
import CDRFileIcon from "../../../assets/svg-tsx/CDRFileIcon";

type PhotosVideosPdfsType = {
  allMediaFiles: any;
  allPdfFiles: any;
};

const PhotosVideosPdfs = ({
  allMediaFiles,
  allPdfFiles,
}: PhotosVideosPdfsType) => {
  const { id: projectID } = useParams();
  const sortedMediaFiles = [...allMediaFiles];
  sortedMediaFiles.sort((a, b) => a.order - b.order); // Sort the array based on the 'order' property

  const [showAllImageAndVideo, setShowAllImageAndVideo] = useState(false);
  const itemsToShow = showAllImageAndVideo ? allMediaFiles.length : 9;

  const [showAllDoc, setShowAllDoc] = useState(false);
  const docToShow = showAllDoc ? allPdfFiles?.length : 7;

  const findExtension = (file: any) => {
    const extension = file?.split(".")?.pop()?.toLowerCase();
    return extension;
  };

  return (
    <div className="flex flex-col gap-6">
      {allMediaFiles?.length > 0 && (
        <Accordian
          label="Photos and Videos"
          heading={
            <div className="flex items-center gap-5">
              <Link to={`/projects/${projectID}/edit?add-media=true`}>
                <Button
                  leftIcon={<AddIcon color="white" />}
                  label="Add File"
                  variant="secondary"
                />
              </Link>
            </div>
          }
        >
          <div className="grid grid-cols-5 gap-5 p-5 border-t border-c-white-3">
            {sortedMediaFiles
              .slice(0, itemsToShow)
              .map((el: any, index: any) => (
                <div
                  key={index}
                  className="h-full grid-item relative border border-c-white-3 group"
                >
                  <div className="absolute top-2 left-2 z-[1]">
                    {el?.order <= 10 && (
                      <p className="w-5 h-5 bg-white text-black border-2 border-gray-350/100 rounded-full flex items-center justify-center text-[10px]">
                        {el?.order}
                      </p>
                    )}
                  </div>
                  <ImageVideoComponent el={el} findExtension={findExtension} />
                  <div
                    className="flex space-x-2 items-center py-1 px-2 border-t border-c-3"
                    title={el?.filename || "-"}
                  >
                    <div>
                      <FileIcon color="#242424" />
                    </div>

                    <div>
                      <p className="text-xs text-pot-black font-gilroy-medium font-bold">
                        {el?.filename
                          ? truncateFilename(el?.filename, 10)
                          : "-"}
                      </p>
                      <div className="flex items-center gap-1 text-[8px] text-pot-black font-gilroy-regular whitespace-nowrap">
                        <p>
                          {el?.file_size
                            ? formatFileSize(el?.file_size, "KB")
                            : "-"}
                        </p>
                        <p>|</p>
                        <p>
                          {el?.created_at ? formatDate1(el?.created_at) : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {sortedMediaFiles.length > itemsToShow && !showAllImageAndVideo && (
              <div
                className="max-sm:h-[300px] border border-c-white-3 h-full flex justify-center  cursor-pointer text-center text-pot-yellow text-[14px] leading-[16.98px] font-gilroy-medium"
                onClick={() => setShowAllImageAndVideo(true)}
              >
                <p className="m-auto text-center">
                  +{sortedMediaFiles?.length - itemsToShow} More{" "}
                  <span className="block">Photos & Videos</span>
                </p>
              </div>
            )}
          </div>
        </Accordian>
      )}

      {allPdfFiles?.length > 0 && (
        <Accordian
          label="Other Files"
          heading={
            <Link to={`/projects/${projectID}/edit?add-other-files=true`}>
              <Button
                leftIcon={<AddIcon color="white" />}
                label="Add File"
                variant="secondary"
              />
            </Link>
          }
        >
          <div className="grid grid-cols-4 gap-3 p-5 border-t border-c-white-3">
            {allPdfFiles.slice(0, docToShow).map((el: any, i: any) => (
              <div
                key={i}
                className="flex justify-between p-2 items-center gap-2 border border-c-white-3 h-full"
              >
                <div
                  className="flex gap-2 items-center"
                  title={el?.filename || "-"}
                >
                  {findExtension(el?.file) === "pdf" ? (
                    <PDFFileIcon width="18" />
                  ) : findExtension(el?.file) === "dwg" ? (
                    <DWGFileIcon width="20" height="21" />
                  ) : findExtension(el?.file) === "cdr" ? (
                    <CDRFileIcon width="20" height="21" />
                  ) : null}

                  <div>
                    <p className="text-xs text-pot-black font-gilroy-medium font-bold z-[1000]">
                      {/* {el?.filename ? truncateFilename(el?.filename, 10) : "-"} */}
                      {el?.filename ? truncateFilename(el?.filename, 15) : "-"}
                    </p>
                    <div className="flex gap-1 divide-x font-gilroy-regular text-[8px] leading-[9.6px] ">
                      <p className="whitespace-nowrap">
                        {el?.file_size
                          ? formatFileSize(el?.file_size, "KB")
                          : "-"}
                      </p>
                      <div className="w-[1px]  h-auto bg-c-white-3"></div>
                      <p className="whitespace-nowrap">
                        {formatDate1(el.created_at)}
                      </p>
                    </div>
                  </div>
                </div>
                <Link
                  to={`${el?.download_url}`}
                  download
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <DownloadIcon color="black" size="14" />
                </Link>
              </div>
            ))}
            {allPdfFiles.length > docToShow && !showAllDoc && (
              <div
                className="max-sm:h-[43.59px] border border-c-white-3 h-full grid-item flex justify-center items-center cursor-pointer text-[10.45px] text-pot-yellow font-gilroy-medium leading-[12.67px]"
                onClick={() => {
                  setShowAllDoc(true);
                }}
              >
                +{allPdfFiles?.length - docToShow} More Documents
              </div>
            )}
          </div>
        </Accordian>
      )}
    </div>
  );
};

export default PhotosVideosPdfs;

const ImageVideoComponent = ({ el, findExtension }: any) => {
  const applyIcon = () => {
    switch (findExtension(el?.file)) {
      case "jpeg":
        return <JPEGPreviewIcon height="25" width="25" />;
      case "jpg":
        return <JPGPreviewIcon height="25" width="25" />;
      case "png":
        return <PNGPreviewIcon height="25" width="25" />;
      case "mp4":
        return <MP4PreviewIcon height="25" width="25" />;
      case "webp":
        return <WEBPPreviewIcon height="25" width="25" />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="absolute top-2 right-2 z-[1] flex items-center space-x-3">
        <Link
          to={`${el?.download_url}`}
          download
          rel="noopener noreferrer"
          target="_blank"
        >
          <DownloadIcon color="gray" />
        </Link>

        <Link
          to={`${process.env.REACT_APP_BUCKET}/${el?.file}`} //eslint-disable-line
          target="_blank"
        >
          {findExtension(el?.file) === "mp4" ? (
            <PlayIcon color="gray" />
          ) : (
            <WatchIcon color="gray" />
          )}
        </Link>
      </div>

      {/* icon */}
      <div className="absolute h-[calc(50%+12px)]  inset-0 flex justify-center items-end  opacity-100 group-hover:opacity-0 transition-opacity duration-300 ">
        {applyIcon()}
      </div>

      <div
        className={`relative w-[100%] pb-[100%] opacity-50 group-hover:opacity-100`}
      >
        {findExtension(el?.file) === "jpg" ||
        findExtension(el?.file) === "jpeg" ||
        findExtension(el?.file) === "png" ||
        findExtension(el?.file) === "webp" ? (
          <img
            src={`${process.env.REACT_APP_BUCKET}/${el?.file}`} //eslint-disable-line
            alt="picture"
            className="object-contain w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
            width={100}
            height={100}
          />
        ) : (
          <video
            muted
            playsInline
            className="object-contain w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "

            // onMouseEnter={(event: any) => {
            //   event.target.play();
            // }}
            // onMouseLeave={(event: any) => {
            //   event.target.pause();
            // }}
          >
            <source
              src={`${process.env.REACT_APP_BUCKET}/${el?.file}`} //eslint-disable-line
              type="video/mp4"
              width={100}
              height={100}
            />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </div>
  );
};
