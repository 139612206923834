import React, { useState, useEffect, useRef, useCallback } from "react";
import { debounce } from "lodash";
import ErrorMessage from "./ErrorMessage";
import InfoIcon from "../assets/svg-tsx/InfoIcon";

export type InputFieldType = {
  label?: string;
  value?: string | number;
  hint?: string | string[];
  error?: string;
  long?: boolean;
  inputType?: string;
  maxLength?: number;
  type?: string;
  onChange?: (value: string) => void; // callback function

  rightIcon?: React.ReactNode | null;
  leftIcon?: React.ReactNode | null;
  className?: string;
  placeholder?: string;
  variant?: "primary";

  onFocus?: () => void;
  onBlur?: (text: string | number) => void;
  disabled?: boolean;
  withDebounce?: boolean;
  doNotChangeInput?: boolean;
  required?: boolean;
  validate?: (value?: any) => string | undefined | false;
  rightOnClick?: () => void;
  targetRef?: any;

  infoIcon?: boolean;
  infoMessage?: string;
  autoComplete?: any; // new-street-address new-city new-state new-postal-code new-country new-password new-email
};

export const primaryInputStyle =
  "text-black font-normal border-2 border-[#DEDEDE] hover:border-2 hover:border-[#D4A15E] hover:border-opacity-50 outline-none focus:bg-white focus:border-pot-yellow placeholder-shown:text-pot-grey2 active:border-pot-yellow active:border-2";

export const InputDisabledStyle =
  "text-[#4F4F4F] disabled:appearance-none disabled:font-gilroy-regular focus:outline-none disabled:border-2 disabled:border-[#DEDEDE] disabled:opacity-30 cursor-not-allowed disabled:bg-white";

const InputField = ({
  variant = "primary",
  error = "",
  label = "",
  value,
  long = false,
  onChange,
  onBlur,
  inputType = "text",
  hint = [],
  maxLength = 255,
  leftIcon = null,
  rightIcon = null,
  type,
  className = "",
  placeholder = "",
  disabled = false,
  withDebounce = false,
  doNotChangeInput = false,
  required = false,
  validate,
  onFocus,
  rightOnClick,
  targetRef,
  infoIcon = false,
  infoMessage = "",
  autoComplete = "off",
}: InputFieldType) => {
  const [inputVal, setInputVal] = useState<string | number>(value || "");
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const cursorPositionRef = useRef<number | null>(null);

  useEffect(() => {
    if (value !== undefined && value !== inputVal) {
      setInputVal(value);
    }
  }, [value]);

  const optimisedCall = useCallback(
    debounce((inputVal) => onChange && onChange(inputVal), 1000),
    []
  );

  useEffect(() => {
    if (withDebounce && value !== undefined) {
      optimisedCall(value);
    }
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;

    cursorPositionRef.current = selectionStart;

    if (!withDebounce) {
      if (doNotChangeInput) return;
      if (validate) {
        const newValue: any = validate(inputValue);
        if (newValue !== false) {
          if (onChange) {
            onChange(newValue);
          } else {
            setInputVal(newValue);
          }
        } else {
          return;
        }
      } else {
        if (onChange) {
          onChange(inputValue);
        } else {
          setInputVal(inputValue);
        }
      }
    } else {
      if (onChange) {
        onChange(inputValue);
      } else {
        setInputVal(inputValue);
      }
    }
  };

  useEffect(() => {
    if (cursorPositionRef.current !== null && inputRef.current) {
      inputRef.current.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current
      );
    }
  }, [inputVal]);

  const typeStyle = variant === "primary" ? primaryInputStyle : "";

  const disabledStyle = InputDisabledStyle;
  const [infoIsHovered, setInfoIsHovered] = useState(false);

  return (
    <div className={`w-full ${className}`} ref={targetRef}>
      {label && (
        <div className="flex items-center pb-2">
          <p className="font-gilroy-semi-bold text-[12px] flex justify-start">
            {label}
            {required && <span className="text-red-500 text-xs ml-1">*</span>}
          </p>
          {infoIcon && (
            <div
              className="relative ml-2"
              onMouseEnter={() => setInfoIsHovered(true)}
              onMouseLeave={() => setInfoIsHovered(false)}
            >
              <span className="text-gray-500 cursor-pointer">
                <InfoIcon />
              </span>
              {infoIsHovered && (
                <div className="absolute -left-1/2 w-56 p-2 bg-gray-700 text-white text-xs rounded-md transition-opacity z-40">
                  {infoMessage}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div
        className={`relative w-full flex justify-between items-center  ${
          focusInput && "border-pot-darkmaroon"
        }  ease-in-out transition `}
      >
        {leftIcon && (
          <div
            className={`absolute inset-y-0 -left-2 flex items-center pl-2 ml-2 pointer-events-none `}
          >
            {leftIcon}
          </div>
        )}
        <input
          required
          disabled={disabled}
          ref={inputRef}
          value={value !== undefined ? value : inputVal}
          type={inputType}
          maxLength={maxLength}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onFocus={() => {
            setFocusInput(true);
            onFocus && onFocus();
          }}
          onBlur={() => {
            setFocusInput(false);
            onBlur && onBlur(inputVal);
          }}
          onKeyDown={(e) => {
            focusInput && e.key === "Enter" && inputRef.current?.blur();
          }}
          onChange={handleInputChange}
          className={`${typeStyle} text-[13px] w-full rounded-md ${
            leftIcon ? "pl-10" : rightIcon ? "pr-10" : ""
          }  px-2 py-1.5 ${disabled ? disabledStyle : typeStyle} `}
        />
        {rightIcon && (
          <div
            className={`absolute right-0 flex items-center place-content-around p-4 ${
              rightOnClick && "cursor-pointer "
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (rightOnClick) {
                rightOnClick();
              }
            }}
          >
            {rightIcon}
          </div>
        )}
      </div>

      <ErrorMessage error={hint} />
    </div>
  );
};

export default InputField;
