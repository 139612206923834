import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import { useSetRecoilState } from "recoil";
import { productBrandAtom, titleAtom } from "../../atom";
import { validateTextNumberSpaceHyphen } from "../../utils/validations";
import Notfound from "../404/components/Notfound";
import { removeExtraSpacesfromObject } from "../../utils/helper";

const initState = {
  name: "",
};
const CreateEditBrand = () => {
  const { id } = useParams();
  const [errors, setErrors] = useState<any>();
  const navigate = useNavigate();
  const [form, setForm] = useState(initState);
  const [loading, setLoading] = useState(true);
  const setProductBrandState = useSetRecoilState(productBrandAtom);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (id) {
      protectedAxiosInstance
        .get(`/admin/products/brand/${id}`)
        .then((res) => {
          setForm(res.data.data.brand);
          setLoading(false);
        })
        .catch((err: any) => {
          console.error("Error in getting brand data", err);
          if (err?.status === 404) {
            setNotFound(true);
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [id]);

  const handleSubmitForm = async () => {
    setLoading(true); // Set loading state to true before making the API call

    if (id) {
      try {
        const res = await protectedAxiosInstance.patch(
          `/admin/products/brand/${id}`,
          removeExtraSpacesfromObject({
            name: form.name,
          })
        );

        if (res) {
          toast.success("Brand updated successfully");
          navigate("/products/brands");
          setProductBrandState([]);
        }
      } catch (e: any) {
        // console.error(e?.data?.errors);
        if (e?.data?.errors?._schema?.length === 1) {
          toast.error(e?.data?.errors?._schema?.[0]);
        } else if (e?.data?.errors?._schema?.length > 1) {
          toast.error(
            <ul className="list-disc pl-4">
              {e?.data?.errors?._schema?.map((errorMessage: any, i: number) => (
                <li key={i}>{errorMessage}</li>
              ))}
            </ul>
          );
        } else {
          toast.error("Brand update failed");
        }
        setErrors(e?.data?.errors);
      } finally {
        setLoading(false); // Reset loading state to false after API call completes
      }
    } else {
      try {
        const res = await protectedAxiosInstance.post(
          "/admin/products/brand",
          removeExtraSpacesfromObject({
            name: form.name,
          })
        );

        if (res) {
          toast.success("Brand created successfully");
          navigate("/products/brands");
          setProductBrandState([]);
        }
      } catch (e: any) {
        // console.error(e?.data?.errors, "err");
        if (e?.data?.errors?._schema?.length === 1) {
          toast.error(e?.data?.errors?._schema?.[0]);
        } else if (e?.data?.errors?._schema?.length > 1) {
          toast.error(
            <ul className="list-disc pl-4">
              {e?.data?.errors?._schema?.map((errorMessage: any, i: number) => (
                <li key={i}>{errorMessage}</li>
              ))}
            </ul>
          );
        } else {
          toast.error("Brand creation failed");
        }
        setErrors(e?.data?.errors);
      } finally {
        setLoading(false); // Reset loading state to false after API call completes
      }
    }
  };

  const handleCancleForm = () => {
    setForm(initState);
    navigate("/products/brands");
  };

  const setTitle = useSetRecoilState(titleAtom);
  useEffect(() => {
    if (id) {
      if (form.name) {
        document.title = `Brands - ${form.name}`;
      } else {
        document.title = `Brnads - ...`;
      }
    }
  }, [form.name]);

  if (notFound) {
    return <Notfound />;
  }
  return (
    <div className="h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  px-4">
        <h2 className="font-semibold mb-0">{id ? "Edit" : "Create"} Brand</h2>
      </div>
      <div className="flex w-full gap-3  flex-1  overflow-y-auto custom-scrollbar  py-3">
        <div className="w-[55%] overflow-y-auto custom-scrollbar px-4">
          <InputField
            required
            label="Brand name:"
            placeholder="Brand name"
            value={form?.name}
            onChange={(e) => {
              setForm((prev) => ({ ...prev, name: e }));
              delete errors?.name;
            }}
            hint={errors?.name || ""}
            validate={validateTextNumberSpaceHyphen}
          />
        </div>

        {/* <div className="px-4 border-r border-[#ECECEC]"></div> */}
      </div>

      <div className="flex gap-x-4 w-[100%] py-3 border-t  border-[#ECECEC] px-4">
        <Button
          label={id ? "Edit Brand" : "Create Brand"}
          onClick={handleSubmitForm}
          disabled={loading}
        />
        <Button
          variant="secondary-outline"
          label="Cancel"
          onClick={handleCancleForm}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default CreateEditBrand;
