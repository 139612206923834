import React from "react";

const FilterIcon = () => {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.29158 0.333252H1.70825C1.27728 0.333252 0.86395 0.543966 0.559204 0.919038C0.254457 1.29411 0.0832521 1.80282 0.0832521 2.33325V3.11325C0.0831744 3.38855 0.129275 3.6609 0.218669 3.91325V3.95325C0.295195 4.16723 0.403589 4.36169 0.538252 4.52659L3.87492 8.60659V12.9999C3.87473 13.1132 3.89801 13.2247 3.94256 13.3238C3.9871 13.423 4.05144 13.5065 4.1295 13.5666C4.2157 13.6323 4.31517 13.667 4.41658 13.6666C4.50138 13.666 4.58487 13.6408 4.66033 13.5933L6.827 12.2599C6.91633 12.2045 6.99145 12.1198 7.04403 12.015C7.09662 11.9103 7.12461 11.7897 7.12492 11.6666V8.60659L10.4399 4.52659C10.5746 4.36169 10.683 4.16723 10.7595 3.95325V3.91325C10.8564 3.66287 10.9099 3.39044 10.9166 3.11325V2.33325C10.9166 1.80282 10.7454 1.29411 10.4406 0.919038C10.1359 0.543966 9.72256 0.333252 9.29158 0.333252ZM6.19867 7.85992C6.14847 7.92221 6.10875 7.99609 6.08179 8.07731C6.05484 8.15854 6.04117 8.24551 6.04158 8.33325V11.2533L4.95825 11.9199V8.33325C4.95866 8.24551 4.945 8.15854 4.91804 8.07731C4.89109 7.99609 4.85137 7.92221 4.80117 7.85992L1.93034 4.33325H9.0695L6.19867 7.85992ZM9.83325 2.99992H1.16659V2.33325C1.16659 2.15644 1.22365 1.98687 1.32524 1.86185C1.42682 1.73682 1.56459 1.66659 1.70825 1.66659H9.29158C9.43524 1.66659 9.57302 1.73682 9.6746 1.86185C9.77618 1.98687 9.83325 2.15644 9.83325 2.33325V2.99992Z"
        fill="#DEDEDE"
      />
    </svg>
  );
};

export default FilterIcon;
