type HipchatChevronLeftIconProps = {
  color?: string;
  width?: string;
};
function HipchatChevronLeftIcon({ color, width }: HipchatChevronLeftIconProps) {
  return (
    <svg
      width={width || "32"}
      height={width || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="white" fillOpacity="0.01" />
      <rect
        width="24"
        height="24"
        transform="translate(4 4)"
        fill="white"
        fillOpacity="0.01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0049 14.9951L17.5979 10.4021C17.6895 10.3086 17.7987 10.2342 17.9192 10.1832C18.0397 10.1322 18.1692 10.1057 18.3 10.105C18.4309 10.1043 18.5605 10.1296 18.6816 10.1794C18.8026 10.2292 18.9125 10.3024 19.0051 10.3949C19.0976 10.4875 19.1708 10.5974 19.2206 10.7184C19.2704 10.8395 19.2957 10.9691 19.295 11.1C19.2943 11.2308 19.2677 11.3602 19.2168 11.4808C19.1658 11.6013 19.0914 11.7105 18.9979 11.8021L15.0979 15.7021L18.9979 19.6021C19.1805 19.7883 19.2822 20.0392 19.2809 20.3C19.2795 20.5608 19.1753 20.8106 18.9909 20.995C18.8065 21.1795 18.5567 21.2837 18.2959 21.285C18.035 21.2863 17.7842 21.1846 17.5979 21.0021L13.0049 16.4101C12.8175 16.2225 12.7122 15.9682 12.7122 15.7031C12.7122 15.4379 12.8175 15.1836 13.0049 14.9961V14.9951Z"
        fill={color || "black"}
      />
    </svg>
  );
}

export default HipchatChevronLeftIcon;
