import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useRecoilState,
  useRecoilStateLoadable,
  useSetRecoilState,
} from "recoil";
import { singleProjectAtom, titleAtom } from "../../atom";

import ProjectDetailCounts from "./projectdetails/ProjectDetailCounts";
import ProjectCreateDetails from "./projectdetails/ProjectCreateDetails";
import ProjectOrderOngoingDetails from "./projectdetails/ProjectOrderOngoingDetails";
import ProjectDetailsFloor from "./projectdetails/ProjectDetailsFloor";
import ProjectOrderDetails from "./projectdetails/ProjectOrderDetails";
import PhotosVideosPdfs from "./components/PhotosVideosPdfs";
import ProjectDetailsTimeline from "./projectdetails/ProjectDetailsTimline";
import { getSingleProject } from "../../api/project";
import ProjectDetails from "./projectdetails/ProjectDetails";
import loadingSpinner from "../../assets/images/LoadingSpinner.gif";
import Setting from "./projectdetails/Setting";
import ProfessionalTable from "./projectdetails/ProfessionalTable";
import Button from "../../components/Button";
import PlusIcon from "../../assets/PlusIcon";
import Modal from "../../components/common/Modal";
import { getProfessionalList, getProfessions } from "../../api/professional";
import ProfessionalInfo from "./projectdetails/ProfessionalInfo";
import EditIcon from "../../assets/EditIcon";
import axios from "axios";
import { toast } from "react-toastify";
import ProfessionalDropdown from "../../components/ProfessionalDropdown";
import ProfessionalDirectoryDropdown from "../../components/ProfessionalDirectoryDropdown";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import { getOrderList } from "../../api/orderManagement";
import Notfound from "../404/components/Notfound";
import Label from "../../components/Label";
import SelectField2 from "../../components/SelectedField2";
import AuthInputField from "../../components/AuthInputField";
import InviteModal from "./components/InviteModal";

const SingleProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialRender, setInitialRender] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [initialError, setInitialError] = useState(false);
  const [singleProjectData, setSingleProjectData] =
    useRecoilState<any>(singleProjectAtom);
  const setTitle = useSetRecoilState(titleAtom);
  const [actionLoading, setActionLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const getSingleProjectData = async () => {
    setPageLoading(true);

    try {
      const { data } = await getSingleProject(id);
      setSingleProjectData(data?.project);
    } catch (err: any) {
      if (err?.status === 404) {
        setNotFound(true);
      }

      setInitialError(true);
    } finally {
      setPageLoading(false);
      setInitialRender(false);
    }
  };

  useEffect(() => {
    getSingleProjectData();
  }, [id]);

  useEffect(() => {
    if (singleProjectData?.name) {
      document.title = `Project - ${singleProjectData?.name}`;
    } else {
      document.title = `Project - ...`;
    }
  }, [singleProjectData?.name]);

  const [orderList, setOrderList] = useState<any>([]);

  const [collabDeleteModal, setCollabDeleteModal] = useState(null);
  const handleCollabDeleteModal = (memberId: any) => {
    setCollabDeleteModal(memberId);
  };

  const handleDeleteCollab = (memberId: any) => {
    setActionLoading(true);
    if (id) {
      protectedAxiosInstance
        .delete(`/admin/projects/${id}/members/${memberId}`)
        .then(() => {
          toast.success("Professional deleted successfully.");
          setCollabDeleteModal(null);
          getSingleProjectData();
        })
        .catch((err) => {
          console.error(err);
          toast.error("Professional deletion failed.");
        })
        .finally(() => {
          setActionLoading(false);
        });
    }
  };
  // delete /projects/{projectId}
  const handleDeleteProject = () => {
    setActionLoading(true);
    protectedAxiosInstance
      .delete(`/admin/projects/${id}`)
      .then(() => {
        navigate(`/projects`);
        toast.success("Project deleted successfully.");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Project deletion failed.");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handlePublicPrivate = async () => {
    if (pageLoading || actionLoading) {
      return;
    }
    setActionLoading(true);
    try {
      const newIsPublic = !singleProjectData?.is_public;
      await protectedAxiosInstance.post(`/admin/projects/${id}/visibility`, {
        is_public: newIsPublic,
      });
      await getSingleProjectData();
      if (newIsPublic) {
        toast.success("This project is now Public.");
      } else {
        toast.success("This project is now Private.");
      }
    } catch (error: any) {
      console.error("Error updating project status:", error?.data?.errors);
      toast.error(error?.data?.errors?.is_public?.[0]);
    } finally {
      setActionLoading(false);
    }
  };

  const handleHiddenVisibility = async (
    memberId: any,
    currentVisibility: boolean
  ) => {
    if (pageLoading || actionLoading) {
      return;
    }
    setActionLoading(true);
    const memberData = singleProjectData?.members?.find(
      (member: any) => member.id === memberId
    );

    try {
      const newIsVisible = !currentVisibility; // Toggle the visibility
      await protectedAxiosInstance.post(
        `/admin/projects/${id}/members/${memberId}/visibility`,
        {
          is_visible: newIsVisible,
        }
      );
      await getSingleProjectData();
      if (newIsVisible) {
        toast.success(
          `This Project is now showcased on ${memberData?.professional?.name}'s profile!`
        );
      } else {
        toast.success(
          `This Project is now hidden from ${memberData?.professional?.name}'s profile!`
        );
      }
    } catch (error: any) {
      console.error("Error updating project visibility:", error);
      toast.error(error?.data?.errors?.is_visible?.[0]);
    } finally {
      setActionLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      const fetchOrderList = async () => {
        try {
          const response: any = await getOrderList({ project: id });

          setOrderList(response?.data?.order);
        } catch (error) {
          // Handle error as needed
          console.error("Error fetching order list:", error);
        }
      };

      fetchOrderList();
    }
  }, [id]);

  if (notFound) {
    return (
      <Notfound redirectURL="/projects" redirectLabel="Go back to Projects" />
    );
  }
  return (
    <div className="w-full flex flex-col h-auto">
      {showInviteModal && (
        <InviteModal
          setShowInviteModal={setShowInviteModal}
          getSingleProjectData={getSingleProjectData}
        />
      )}
      {/* First Section */}
      {pageLoading && initialRender && (
        <div className=" w-full min-h-[90vh] flex justify-center items-center">
          <img
            src={loadingSpinner}
            alt="Loading"
            height={"auto"}
            width={"10%"}
          />
        </div>
      )}
      {!(pageLoading && initialError) && singleProjectData && (
        <div className="w-full  border-b">
          <div className="flex justify-between">
            <div className="flex items-center border-r">
              <p className="px-4 py-1  max-w-fit font-gilroy-medium text-[14px] leading-[20px]">
                Project Details
              </p>
            </div>
            <div className="px-4 py-1 flex items-center">
              <Button
                label="Edit"
                leftIcon={<EditIcon />}
                variant="secondary"
                onClick={() => {
                  navigate(`/projects/${id}/edit`);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!(pageLoading && initialError) && singleProjectData && (
        <div className="flex flex-row">
          {/* First section */}
          <div className="w-[80%] border-r-2 py-3 px-4 flex flex-col gap-5">
            <ProjectDetailCounts orderData={orderList} />
            <div>
              <ProjectDetails data={singleProjectData} />
            </div>
            {/* <div>
              <ProjectOrderOngoingDetails />
            </div> */}
            <div>
              <PhotosVideosPdfs
                allMediaFiles={
                  !singleProjectData ? [] : singleProjectData?.media_files
                }
                allPdfFiles={
                  !singleProjectData ? [] : singleProjectData?.document_files
                }
              />
            </div>
            <div>
              <ProjectOrderDetails data={orderList} />
            </div>
            <div>
              <div className="flex justify-between items-center bg-[#FBFBFB] h-[48px] px-5 py-2 border-t border-t-c-white-3 border-r border-r-c-white-3 border-l border-l-c-white-3">
                <p className="font-medium text-[14px] leading-5">
                  Collaborations
                </p>
                <Button
                  leftIcon={<PlusIcon />}
                  label="Add Professional"
                  variant="secondary-text"
                  onClick={() => {
                    setShowInviteModal(true);
                  }}
                />
              </div>
              <ProfessionalTable
                data={singleProjectData?.members}
                handleDeleteCollab={handleDeleteCollab}
                handleCollabDeleteModal={handleCollabDeleteModal}
                collabDeleteModal={collabDeleteModal}
                setCollabDeleteModal={setCollabDeleteModal}
                handleHiddenVisibility={handleHiddenVisibility}
                actionLoading={actionLoading}
              />
            </div>

            <div>{/* <ProjectDetailsTimeline /> */}</div>
          </div>
          {/* Second section */}
          <div className="w-[20%]">
            <ProjectCreateDetails
              data={singleProjectData}
              setShowInviteModal={setShowInviteModal}
            />
            <ProfessionalInfo
              data={singleProjectData}
              setShowInviteModal={setShowInviteModal}
            />
            <Setting
              handleDeleteProject={handleDeleteProject}
              data={singleProjectData}
              initialLoading={pageLoading}
              actionLoading={actionLoading}
              handlePublicPrivate={handlePublicPrivate}
            />
            <div>
              <div className="w-full border border-l-0 flex items-center">
                <p className="font-gilroy-semi-bold text-[12px] p-4">
                  Floors and Areas:
                </p>
              </div>
              <ProjectDetailsFloor data={singleProjectData} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleProjectDetails;
