
import { useState } from "react";
import ProjectCard from "./ProjectCard";
import DocumentIcon from "../../../assets/DocumentIcon";

const ProjectDraftsCounts = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    // setIsHovered(true);
  };

  const handleMouseLeave = () => {
    // setIsHovered(false);
  };

  const cardValues = [
    {
      count: 10,
      icon: <DocumentIcon color={!isHovered ? "#C3E1F8" : "#ffffff"} />,
      color: "#C3E1F8",
      title:"Total drafts"
    },
  ];

  return (
    <div className="w-full flex  gap-x-6 px-4 py-2" >
      {cardValues.map((each, index) => (
        <ProjectCard
          key={index}
          {...each}
          setIsHovered={setIsHovered}
          isHovered={isHovered}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      ))}
    </div>
  );
};

export default ProjectDraftsCounts;
