import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";
import Button from "../../components/Button";
import OrderDetails from "./components/employeedetails/OrderDetails";
import ProjectDetails from "./components/employeedetails/ProjectDetails";
import Address from "./components/employeedetails/Address";
import Timeline from "./components/employeedetails/Timeline";
import Documents from "./components/employeedetails/Documents";
import DetailSection from "./components/employeedetails/DetailSection";
import BusinessSection from "./components/employeedetails/BusinessSection";
import BankSection from "./components/employeedetails/BankSection";
import CouponsSection from "./components/employeedetails/CouponsSection";
import CustomersSection from "./components/employeedetails/CustomersSection";
import DetailsHeader from "../Customer/components/DetailsHeader";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import Notfound from "../404/components/Notfound";

const EmployeeDetail = () => {
  const { id: customerID } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [details, setDetails] = useState<any>();
  const [notFound, setNotFound] = useState(false);
  const fetchDetails = () => {
    return protectedAxiosInstance
      .get(`/admin/accounts/professional/${customerID}`)
      .then((res) => res.data.data)
      .catch((err) => {
        console.error(err, "Error in getting professional details");
        throw err;
      });
  };

  useEffect(() => {
    setPageLoading(true);

    Promise.all([fetchDetails()])
      .then(([details]) => {
        setDetails(details);
        if (!details?.customer?.is_professional) {
          setNotFound(true);
        }
      })
      .catch((err) => {
        if (err.status === 404) {
          setNotFound(true);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  }, [customerID]);

  if (notFound) {
    return <Notfound />;
  }

  return (
    <>
      {/* Navigation */}
      <DetailsHeader data={details} />
      <div
        className={`flex flex-row relative ${
          pageLoading ? "animate-pulse" : ""
        }`}
      >
        {/* First Column  */}
        <div className="w-[75%] border-r-2 px-5 py-6">
          <div className="flex flex-row justify-between items-start ">
            <div className="flex flex-row gap-4">
              <div className=" w-[150px] h-16 flex flex-col items-start pl-4 justify-center border-l-2 border-l-pot-yellow bg-[#F9FAFB]">
                <p className="font-medium text-[14px]">25,00,000</p>
                <p className="text-[12px] text-[#AEAEAE]">Total spend</p>
              </div>
              <div className=" w-[150px] h-16 flex flex-col items-start pl-4 justify-center border-l-2 border-l-pot-yellow bg-[#F9FAFB]">
                <p className="font-medium text-[14px]">25</p>
                <p className="text-[12px] text-[#AEAEAE]">Total orders</p>
              </div>
              <div className=" w-[150px] h-16 flex flex-col items-start pl-4 justify-center border-l-2 border-l-pot-yellow bg-[#F9FAFB]">
                <p className="font-medium text-[14px]">100,000</p>
                <p className="text-[12px] text-[#AEAEAE]">
                  Average order value
                </p>
              </div>
            </div>
          </div>

          <Documents />

          <OrderDetails />

          {/* Project Details */}
          <ProjectDetails />

          {/* Address */}
          <Address />

          {/* Timeline */}
          <Timeline />
        </div>

        {/* Second Column */}
        <div className="max-h-full sticky top-10 overflow-y-auto w-[25%] border-r-2 ">
          <DetailSection data={details} loading={pageLoading} />
          {/* <BusinessSection />
          <BankSection />
          <CouponsSection />
          <CustomersSection /> */}
        </div>
      </div>
    </>
  );
};

export default EmployeeDetail;
