import tickIcon from "../../../assets/svg/tick.svg";
import loadingSpinner from "../../../assets/images/LoadingSpinner.gif";
import { ordinalSuffix } from "../../../utils/formatDateTime";
import useWindowWidth from "../../../hooks/useWindowWidth";

const ProfessionalHistory = ({ history, statusLoading }: any) => {
  const windowWidth = useWindowWidth();

  return (
    <div className="border border-[#ECECEC]  h-full w-auto px-4 ">
      <p className="text-md font-gilroy-medium py-4 sticky top-0 bg-white ">
        History
      </p>

      {/* <div className="h-[50vh]"> */}
      {statusLoading ? (
        <div className="min-h-[70dvh] flex justify-center items-center ">
          <img src={loadingSpinner} alt="spinner" className="h-[20%] w-[20%]" />
        </div>
      ) : (
        <div
          className={` flex flex-col ${
            history.length === 0
              ? "h-[100%]"
              : "h-[calc(100%-4.5rem)]  overflow-y-auto custom-scrollbar"
          }`}
        >
          <div className="relative pt-[10px]">
            {history.length > 0 &&
              history?.map((el: any, i: any) => {
                const date = new Date(el.created_at);
                const day = String(date.getDate()).padStart(2, "0");
                const month = date.toLocaleString("default", {
                  month: "short",
                });
                const year = date.getFullYear().toString().slice(-2);

                const hours = String(date.getHours() % 12 || 12).padStart(
                  2,
                  "0"
                );
                const minutes = String(date.getMinutes()).padStart(2, "0");
                const ampm = date.getHours() < 12 ? "AM" : "PM";

                const formattedDate = `${day}${ordinalSuffix(
                  day
                )} ${month} ‘${year} | ${hours}:${minutes} ${ampm}`;

                return (
                  <div
                    key={i}
                    className="flex  justify-start items-start text-start w-full  gap-3 relative "
                  >
                    <div className="flex flex-col items-center z-10">
                      <>
                        <img
                          src={tickIcon}
                          alt="tick-icon"
                          className="w-[20px] h-[20px] "
                        />
                      </>
                    </div>

                    <div className="text-sm flex flex-col gap-3 w-[90%]  h-auto">
                      <div
                        className={`flex justify-between text-sm leading-5  ${
                          windowWidth > 1240
                            ? "flex-row flex flex-wrap"
                            : "flex-col"
                        }`}
                      >
                        <div className="flex gap-1 ">
                          <p className="font-gilroy-medium font-sm text-[#1C1C1C] leading-[20px]">
                            Status changed to{" "}
                          </p>
                          <p
                            className={`w-[110px] truncate border-none pt-0.5 text-black  rounded-md text-[12px] px-2 cursor-pointer font-gilroy-regular text-center ${
                              el.new_status === "CHANGE_REQUESTED"
                                ? "bg-[#BFB2FF]"
                                : el.new_status === "IN_REVIEW"
                                ? "bg-[#FFC5EF]"
                                : el.new_status === "DRAFT"
                                ? "bg-[#EDEDED]"
                                : el.new_status === "REJECTED"
                                ? "bg-[#FFB4A1]"
                                : el.new_status === "VERIFIED"
                                ? "bg-[#B6FDC6]"
                                : el.new_status ===
                                  "AWAITING_EXPERIENCE_APPROVAL"
                                ? "bg-[#FFDCB2]"
                                : ""
                            } `}
                            title={el.new_status.replace(/_/g, " ")} // Add the title attribute
                          >
                            {el.new_status.replace(/_/g, " ")}
                          </p>
                        </div>

                        <div className="">
                          <p
                            key={el.id}
                            className="text-[#AEAEAE] text-[12px] font-gilroy-regular leading-[20px]"
                          >
                            {formattedDate}
                          </p>
                        </div>
                      </div>

                      <p
                        className={`text-sm font-gilroy-regular text-[#1C1C1C] leading-5 w-[100%] h-auto break-words pb-6
                       `}
                      >
                        {el.comment} {/* Render the comment content */}
                      </p>
                    </div>
                  </div>
                );
              })}

            {history.length > 0 && (
              <div className="absolute -top-[10px] left-[9px] w-[2px] h-[calc(100%-20px)] bg-[#DEDEDE]"></div>
            )}
          </div>

          {history.length === 0 && (
            <div className="min-h-[80%]  grid place-items-center">
              <p className="text-center">No History</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfessionalHistory;
