import React, { useEffect, useState } from "react";
import AuthInputField from "../../components/AuthInputField";
import arczLogo from "../../assets/images/Arcz_Black.png";
import copyrightsign from "../../assets/svg/copyrightsign.svg";
import Checkbox from "../../components/Checkbox";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import HipchatChevronRightIcon2 from "../../assets/HipchatChevronRightIcon2";
import {
  removeNullValues,
  validateEmail,
  validatePhone,
} from "../../utils/validations";
import { toast } from "react-toastify";

import { useRecoilState } from "recoil";
import { authAtom, targetPathAtom } from "../../atom";
import { postLogin } from "../../api/accounts";

function Login() {
  const utcDate = new Date();
  const istOffset = 5.5 * 60 * 60 * 1000;
  const istDate = new Date(utcDate.getTime() + istOffset);
  const currentYearInIST = istDate.getUTCFullYear();
  const navigate = useNavigate();
  const location = useLocation();
  const [authState, setAuthState] = useRecoilState(authAtom);
  const [pathName, setPathName] = useRecoilState(targetPathAtom);
  const [buttonHovered, setButtonHovered] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    username: "",
    password: "",
  });
  const [checkNumber, setCheckNumber] = useState(false);
  const [rememberUser, setRememberUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    setErrorMessage({});
    const { username } = formData;
    let usernameData = username;
    if (validateEmail(username)) {
      setCheckNumber(false);
    } else if (validatePhone(username)) {
      if (!username.startsWith("+91")) {
        usernameData = "+91" + username;
      }
    } else {
      setErrorMessage((prev: any) => ({
        ...prev,
        username: "Invalid email or phone number",
      }));
      setLoading(false);
      return;
    }

    const payload = {
      username: usernameData,
      password: formData.password,
    };
    removeNullValues(payload);
    // try {
    setLoading(true);
    postLogin(payload)
      .then((res) => {
        const data = res.data.data;
        localStorage.setItem("auth_token", data.auth_token);
        localStorage.setItem("refresh_token", data.refresh_token);
        toast.success("Login successfully");
        setAuthState({
          is_auth: true,
          auth_token: data.auth_token,
          refresh_token: data.refresh_token,
        });
        if (pathName === "") {
          navigate("/dashboard");
        } else {
          navigate(pathName);
        }
      })
      .catch((error) => {
        console.error("Error creating customer:", error.response.data.errors);
        const errors = error.response.data.errors;
        if (errors) {
          if (errors?.general) {
            toast.error(errors?.general[0]); // Display the error message in a toast
            setErrorMessage((prev: any) => ({
              ...prev,
              password: errors?.general,
            }));
          } else if (errors?.password) {
            setErrorMessage((prev: any) => ({
              ...prev,
              password: errors?.password,
            }));
          } else if (errors?.username) {
            setErrorMessage((prev: any) => ({
              ...prev,
              username: errors?.username,
            }));
          }
        }
      })
      .finally(() => setLoading(false));
    // } catch (error) {
    //   setLoading(false);
    //   console.error("Error fetching OTP:", error);
    // }
  };

  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div className="relative h-[90svh] w-full m-auto flex flex-col justify-center items-center">
        <div className="absolute top-0 w-[max-content] mx-auto flex flex-col">
          <img
            src={arczLogo}
            alt=""
            srcSet=""
            className="w-24 h-10 object-contain m-auto"
          />
        </div>
        <div className="w-[80%] sm:w-[400px] flex flex-col justify-center items-center">
          <h1 className="text-5xl mx-auto w-max font-mangoGrotesque-black my-4 sm:my-10">
            Login
          </h1>
          <AuthInputField
            onChange={(e) => {
              const newValue = e;
              setFormData((prev: any) => ({
                ...prev,
                username: newValue,
              }));
              delete errorMessage?.username;
            }}
            value={formData?.username || ""}
            placeholder="Enter email"
            heading="Email ID"
            name="username"
            errorMessage={errorMessage?.username}
          />

          <AuthInputField
            onChange={(e) => {
              setFormData((prev: any) => ({
                ...prev,
                password: e,
              }));
              delete errorMessage?.password;
            }}
            value={formData?.password || ""}
            placeholder="Enter password"
            heading="Password"
            name="username"
            errorMessage={errorMessage?.password}
            type="Password"
          />
          <div className="flex justify-between items-center mt-2 w-full">
            <div
              className="flex gap-1"
              onClick={() => setRememberUser(!rememberUser)}
            >
              <Checkbox checkValue={rememberUser ? 1 : 0} />{" "}
              <span className="text-xs text-[#A2A2A2] cursor-pointer hover:text-black">
                Remember me
              </span>
            </div>
            <Link to={"/forgot-password"}>
              <p className="text-sm text-pot-yellow ">Forgot Password?</p>
            </Link>
          </div>
          <div
            className="w-full mt-6"
            onClick={handleSubmit}
            onMouseEnter={() => setButtonHovered(true)}
            onMouseLeave={() => setButtonHovered(false)}
          >
            <Button
              variant="secondary"
              label="Proceed"
              rightIcon={
                <HipchatChevronRightIcon2
                  color={buttonHovered ? "black" : "white"}
                />
              }
              fullWidth
              loading={loading}
            />
          </div>
        </div>
        <div className="absolute bottom-0 text-center w-[94.2%] xl:w-full lg:w-full  flex flex-col justify-center items-center">
          <div className="flex justify-center items-center gap-1 mx-auto">
            <div className="text-[12px] font-gilroy-bold flex justify-center items-center gap-1">
              Copyright{" "}
              <img
                width={15}
                height={15}
                src={copyrightsign}
                alt="copyrightsign"
              />{" "}
              {currentYearInIST} ARCZ. All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
