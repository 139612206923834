import React,{useEffect} from 'react'
import { useSetRecoilState } from 'recoil';
import { titleAtom } from '../../atom';


import ConsultantCounts from './components/ConsultantCounts';
import DesignConsultantTable from './components/DesignConsultantTable';

function ConsultancyList() {
  const setTitle = useSetRecoilState(titleAtom);


  return (
    <div>
      <ConsultantCounts />
      <DesignConsultantTable />
    </div>
  );
}

export default ConsultancyList;
