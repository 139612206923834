import React from "react";

interface AddIconProps {
  size?: string;
  color?: string;
}

const AddIcon = ({ color = "black", size = "12" }: AddIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 5.33334V0.662003C6.66596 0.485838 6.59535 0.317159 6.47034 0.193028C6.34534 0.0688982 6.17617 -0.000528475 6 3.0294e-06C5.62933 3.0294e-06 5.33333 0.29667 5.33333 0.662003V5.33334H0.662003C0.485838 5.33404 0.317159 5.40465 0.193028 5.52966C0.0688982 5.65466 -0.000528475 5.82384 3.0294e-06 6C3.0294e-06 6.37134 0.29667 6.66667 0.662003 6.66667H5.33333V11.338C5.33333 11.7033 5.632 12 6 12C6.37067 12 6.66667 11.7033 6.66667 11.338V6.66667H11.338C11.5142 6.66596 11.6828 6.59535 11.807 6.47034C11.9311 6.34534 12.0005 6.17617 12 6C12 5.62934 11.7033 5.33334 11.338 5.33334H6.66667Z"
        fill={color}
      />
    </svg>
  );
};

export default AddIcon;
