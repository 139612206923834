import React, { useEffect, useState } from "react";
import PlusIcon from "../../../assets/PlusIcon";
import Button from "../../../components/Button";
import SelectField2 from "../../../components/SelectedField2";
import { useRecoilValue } from "recoil";
import { MainAppSideBarExpandAtom } from "../../../atom";
import TrashIcon from "../../../assets/TrashIcon";

const NewIncompatibility = ({
  setIncompatibility,
  incompatibility,
  formData,
}: any) => {
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);
  const [incompatibleOptions, setIncompatibleOptions] = useState([]);

  function processIncompatibilityData(formData: any) {
    const newArray = formData?.stages
      ?.flatMap((stage: any) =>
        stage?.variants?.flatMap((variant: any) =>
          variant?.options?.map((option: any) => ({
            ...option,
            stageName: stage?.name,
            variantName: variant?.name,
            variantId: variant?.id,
            variantToDelete: variant?.to_delete,
            stageToDelete: stage?.to_delete,
            stageToAdded: stage?.added,
            variantToAdded: variant?.added,
            optionToAdded: option?.added,
            label: `${option?.code ? `[${option?.code}]` : ""} ${
              option?.variantName
            } - ${option?.name}`,
          }))
        )
      )
      ?.filter(
        (el: any) =>
          !el?.to_delete &&
          !el?.variantToDelete &&
          !el?.stageToDelete &&
          !el?.optionToAdded &&
          !el?.variantToAdded &&
          !el?.stageToAdded
      );

    newArray?.sort((a: any, b: any) => {
      const leftA = a.label.replace(/(\d+)$/i, "");
      const leftB = b.label.replace(/(\d+)$/i, "");

      if (leftA.toLowerCase() < leftB.toLowerCase()) return -1;
      if (leftA.toLowerCase() > leftB.toLowerCase()) return 1;

      const numAArray = a.label.match(/(\d+)$/);
      const numBArray = b.label.match(/(\d+)$/);

      const numA = numAArray ? parseInt(numAArray[0]) : 0;
      const numB = numBArray ? parseInt(numBArray[0]) : 0;

      return numA - numB;
    });

    return newArray;
  }

  useEffect(() => {
    const newArray = processIncompatibilityData(formData);
    setIncompatibleOptions(newArray);
  }, [formData?.stages]);

  useEffect(() => {
    // For deletion of options in stage data
    const updatedIncompatibility = incompatibility
      ?.filter((el: any) => {
        const key = Object.keys(el)[0];
        return incompatibleOptions?.some((option: any) => option?.id === key);
      })
      ?.map((el: any) => {
        const key = Object.keys(el)[0];
        const values = el[key];
        const filteredValues = values?.filter((value: any) =>
          incompatibleOptions?.some((option: any) => option?.id === value)
        );
        return filteredValues?.length > 0 ? { [key]: filteredValues } : null;
      })
      ?.filter(Boolean);
    setIncompatibility(updatedIncompatibility);
  }, [incompatibleOptions]);

  // Just to add a blank select field
  useEffect(() => {
    setIncompatibility((prev: any) => [...prev, {}]);
  }, [incompatibleOptions]);

  const addNewVariantLeft = () => {
    setIncompatibility((prev: any) => [...prev, {}]);
  };

  const handleSelectLeftChange = (index: number, selectedId: string) => {
    const data = Object.values(incompatibility?.[index])?.[0] || [""];
    setIncompatibility((prev: any) =>
      prev?.map((el: any, i: any) =>
        i === index ? { [selectedId]: data } : el
      )
    );
  };

  const handleSelectRightChange = (
    index: number,
    i: number,
    selectedId: string
  ) => {
    setIncompatibility((prev: any) =>
      prev?.map((el: any, i1: number) =>
        i1 === index
          ? {
              [Object.keys(el)[0]]: (Object.values(el)[0] as any[])?.map(
                (el2: any, i2: number) => (i2 === i ? selectedId : el2)
              ),
            }
          : el
      )
    );
  };

  const addNewOptionRight = (index: number) => {
    setIncompatibility((prev: any) =>
      prev?.map((el: any, i1: number) =>
        i1 === index
          ? {
              [Object.keys(el)[0]]: [...(Object.values(el)[0] as string[]), ""],
            }
          : el
      )
    );
  };

  const handleDeleteRightOptions = (index: number, i: number) => {
    setIncompatibility((prev: any) => {
      const updatedPrev = prev?.map((el: any, i1: number) =>
        i1 === index
          ? (Object.values(el)[0] as any[])?.length === 1
            ? undefined
            : {
                [Object.keys(el)[0]]: (Object.values(el)[0] as any[])
                  .slice(0, i)
                  .concat((Object.values(el)[0] as any[]).slice(i + 1)),
              }
          : el
      );
      return updatedPrev?.filter(Boolean);
    });
  };

  return (
    <div className="mt-[2rem] w-full ">
      <h2 className="font-semibold mb-6 flex justify-start">Incompatibility</h2>

      <div className="flex flex-col gap-4">
        {incompatibility?.map((el: any, index: number) => (
          <div key={index} className="flex flex-col gap-4">
            <div className="flex">
              <div
                className={`w-[20rem] lg:w-full ${
                  sidebarExpand
                    ? "min-w-[13.5rem] max-w-[13.5rem] lg:max-w-full  "
                    : "min-w-[13.5rem] max-w-full "
                }`}
              >
                <p
                  className={`font-gilroy-semi-bold text-[12px] pb-2 px-1 flex justify-start ${
                    index > 0 ? "hidden" : "block"
                  }`}
                >
                  Select Variant-Option
                </p>
                <SelectField2
                  value={Object.keys(el)[0] || ""}
                  onChange={(selectedOption: any) => {
                    handleSelectLeftChange(index, selectedOption);
                  }}
                  searchPlaceHolder="Search options"
                  options={() => {
                    const newArrayOptions =
                      processIncompatibilityData(formData);

                    // Filter options that are present in the incompatibility list
                    const secondOptionArray = newArrayOptions?.filter(
                      (opt: any) =>
                        (
                          Object.values(
                            el as Record<string, any>
                          )[0] as string[]
                        )?.includes(opt?.id)
                    );

                    // Exclude the secondOptionArray[].variantId from the updated arrayOptions
                    const updatedArrayOptions = newArrayOptions?.filter(
                      (opt: any) =>
                        !secondOptionArray?.some(
                          (secondOpt: any) =>
                            secondOpt.variantId === opt?.variantId
                        )
                    );

                    return updatedArrayOptions?.map((option: any) => ({
                      value: option?.id,
                      label: `${option?.code ? `[${option?.code}]` : ""}  ${
                        option?.variantName
                      } - ${option?.name}`,
                    }));
                  }}
                  filterLogic={(e) => {
                    return e?.filter((option: any) => {
                      const xArr = incompatibility?.map(
                        (el: any) => Object.keys(el)[0]
                      );

                      return !xArr.includes(option.value);
                    });
                  }}
                  placeholder="Variant - Option"
                />
              </div>
              <span
                className={`${
                  index > 0 ? "m-[1rem]" : "mt-[2.6rem]"
                } m-[1rem] flex items-center h-[0.1rem] bg-[rgba(0,0,0,0.5)] min-w-[0.8rem]`}
              ></span>
              <div
                className={`w-[20rem] lg:w-full ${
                  sidebarExpand
                    ? "min-w-[13.5rem] max-w-[13.5rem] lg:max-w-full  "
                    : "min-w-[13.5rem] max-w-full "
                }`}
              >
                <p
                  className={`font-gilroy-semi-bold text-[12px] pb-2 px-1 flex justify-start ${
                    index > 0 ? "hidden" : "block"
                  }`}
                >
                  Select Variant-Option
                </p>

                <div className="flex flex-col gap-2 ">
                  {((Object.values(el)?.[0] as any[]) || [])?.map(
                    (rightOption: string, i: number) => (
                      <div key={i} className="relative ">
                        <SelectField2
                          value={rightOption || ""}
                          onChange={(selectedOption: any) => {
                            handleSelectRightChange(index, i, selectedOption);
                          }}
                          searchPlaceHolder="Search options"
                          options={incompatibleOptions
                            ?.filter((el: any) => {
                              const leftID = Object.keys(
                                incompatibility[index]
                              )[0];
                              const leftValue: any = incompatibleOptions.find(
                                (el: any) => el?.id === leftID
                              );
                              return el?.variantId !== leftValue?.variantId;
                            })

                            ?.map((option: any) => ({
                              value: option?.id,
                              label: `${
                                option?.code ? `[${option?.code}]` : ""
                              } ${option?.variantName} - ${option?.name}`,
                            }))}
                          filterLogic={(e) =>
                            e?.filter((option: any) => {
                              const yArr: any = Object.values(
                                incompatibility[index]
                              )[0];
                              const xArr = incompatibility
                                ?.filter((el: any) => {
                                  const values = Object.values(el) as any[];
                                  return (
                                    values[0] &&
                                    values[0].includes(
                                      Object.keys(incompatibility[index])[0]
                                    )
                                  );
                                })
                                ?.map((el: any) => Object.keys(el)[0]);

                              return !(
                                yArr.includes(option.value) ||
                                xArr.includes(option.value)
                              );
                            })
                          }
                          placeholder="Variant - Option"
                        />

                        <button
                          className="absolute  scale-75 md:-right-[40px] lg:-right-[50px] top-1/2 transform -translate-y-1/2 hover:bg-c-white-3 md:p-[10px] lg:p-[14.5px] rounded-lg"
                          onClick={() => handleDeleteRightOptions(index, i)}
                        >
                          <TrashIcon color="black" />
                        </button>
                      </div>
                    )
                  )}
                  <button
                    disabled={
                      typeof el !== "object" ||
                      !el ||
                      !Array.isArray(Object.values(el)) ||
                      Object.values(el)?.length === 0 ||
                      (!!Object.values(el)?.[0] &&
                        (Object.values(el)?.[0] as any)?.length === 0)
                    }
                    className={`border-2 border-c-white-4  flex items-center gap-2 px-4 py-[0.4vw] font-gilroy-regular text-[14px]  w-full rounded-md text-[#242424] ${
                      (!!Object.values(el)?.[0] &&
                        (Object.values(el)?.[0] as any)?.length === 0) ||
                      !Object.values(el)?.[0]
                        ? "cursor-not-allowed opacity-50"
                        : "cursor-pointer hover:border-black"
                    }`}
                    onClick={() => addNewOptionRight(index)}
                  >
                    <span>
                      <PlusIcon color="#242424" />
                    </span>
                    <span>Add New</span>
                  </button>
                </div>
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>

      <Button
        variant="secondary-text"
        onClick={addNewVariantLeft}
        label="Add New Variant"
        leftIcon={<PlusIcon />}
      />
    </div>
  );
};

export default NewIncompatibility;
