
interface PlayIconProps {
  color: string;
}

const PlayIcon = ({ color = "black" }: PlayIconProps) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 13C1.36739 13 1.24021 12.9474 1.14645 12.8536C1.05268 12.7598 1 12.6326 1 12.5V1.50004C0.999994 1.41315 1.02263 1.32775 1.06568 1.25228C1.10873 1.1768 1.17071 1.11384 1.2455 1.06962C1.32029 1.02539 1.40532 1.00142 1.4922 1.00006C1.57908 0.998705 1.66481 1.02001 1.74095 1.06189L11.7409 6.56189C11.8194 6.60503 11.8848 6.66845 11.9304 6.74552C11.976 6.82259 12 6.91048 12 7.00001C12 7.08955 11.976 7.17744 11.9304 7.25451C11.8848 7.33158 11.8194 7.39499 11.7409 7.43814L1.74095 12.9381C1.66713 12.9787 1.58425 13 1.5 13ZM2 2.34549V11.6544L10.4624 7.00004L2 2.34549Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default PlayIcon;
