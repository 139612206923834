import FileDownloadIcon from "../../../../assets/svg-tsx/FileDownloadIcon";

const BankSection = ({ data, loading }: any) => {
  const renderValue = (value: any) => {
    return value ?? "-";
  };

  return (
    <div className="text-start px-4 pt-2 py-4 border-b">
      <div className="w-full flex justify-between">
        <h1 className="text-pot-yellow font-medium text-lg">Bank Details: </h1>
      </div>
      <div
        className={`flex flex-col  ${
          loading ? "gap-3" : "space-y-1 "
        }   text-start mt-4 text-[12px] leading-6`}
      >
        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Beneficiary: </p>
          )}
          {loading ? (
            <div className="w-[70%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p>{renderValue(data?.p_company?.bank_beneficiary)}</p>
          )}
        </div>

        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Account No.</p>
          )}
          {loading ? (
            <div className="w-[70%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p>{renderValue(data?.p_company?.bank_acc_no)}</p>
          )}
        </div>

        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">IFSC code:</p>
          )}
          {loading ? (
            <div className="w-[70%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p>{renderValue(data?.p_company?.bank_ifsc)}</p>
          )}
        </div>

        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Cancelled Cheque:</p>
          )}
          {loading ? (
            <div className="w-[60%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <a
              className="flex gap-3 items-center cursor-pointer underline text-pot-yellow"
              href={data?.p_company?.bank_cancel_chq_doc}
              download
            >
              <FileDownloadIcon color="#D4A15E" /> Download
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default BankSection;
