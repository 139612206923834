import { useRecoilValue } from "recoil";
import calenderIcon from "../../../assets/svg/clenderIcon.svg";
import { formatDateTime } from "../../../utils/formatDateTime";
import { stateAtom } from "../../../atom";

export default function ProjectDetails({ data }: any) {
  const stateList = useRecoilValue(stateAtom);
  return (
    <div className="border-2 h-auto">
      <div
        className={`bg-[#FBFBFB] px-5 h-[40px] py-2 border-b-2 flex justify-between`}
      >
        <p className="font-medium text-[14px] leading-5">Project Details</p>
      </div>
      <div>
        <div className={`w-full px-5`}>
          <div className="flex flex-col items-baseline justify-between">
            <div className="flex items-center gap-8 my-2">
              <p className="text-[14px] flex font-gilroy-bold">
                {data?.name ?? ""}
              </p>
              <div className="flex items-center gap-3">
                <img src={calenderIcon} alt="calender-icon" />
                <p className="text-[#AEAEAE] text-[12px]">
                  {formatDateTime(data?.created_at ?? "")}
                </p>
              </div>
            </div>

            {data?.description && (
              <div className="flex flex-col my-2">
                {/* <p className="text-[14px] flex font-gilroy-bold">
                  {"Project Description"}
                </p> */}
                <p className="font-gilroy-regular text-[12px] my-2">
                  {data?.description ?? ""}
                </p>
              </div>
            )}
            <div className="flex gap-8">
              {(data?.address_state || data?.city) && (
                <div className="flex items-center gap-2 my-2 text-[12px] font-gilroy-bold">
                  <p className="text-[12px] flex font-gilroy-bold text-[#AEAEAE]">
                    Location:
                  </p>
                  <p>
                    {data?.address_city},{" "}
                    {
                      stateList?.find(
                        (state: any) =>
                          state?.state_code === data?.address_state
                      )?.name
                    }
                  </p>
                </div>
              )}
              {/* <div className="flex items-center gap-2 my-2 text-[12px] font-gilroy-bold">
                <p className="text-[12px] flex font-gilroy-bold text-[#AEAEAE]">
                  Total Floors:
                </p>
                <p>{data?.floors?.length || 0}</p>
              </div>
              <div className="flex items-center gap-2 my-2 text-[12px] font-gilroy-bold">
                <p className="text-[12px] flex font-gilroy-bold text-[#AEAEAE]">
                  Total Areas:
                </p>
                <p>
                  {(data?.floors || []).reduce(
                    (total, floor) => total + (floor?.areas?.length || 0),
                    0
                  )}
                </p>
              </div>
              <div className="flex items-center gap-2 my-2 text-[12px] font-gilroy-bold">
                <p className="text-[12px] flex font-gilroy-bold text-[#AEAEAE]">
                  Professional:
                </p>
                <p>{"Thomas Herring"}</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
