import React from "react";

function GstTableCard({ label, content }: any) {
  return (
    <div className="flex py-2">
      <h1 className="text-sm text-[#aeaeae] w-[30%]">{label}</h1>
      <h2 className="text-sm font-semibold w-[70%]">{content}</h2>
    </div>
  );
}

export default GstTableCard;
