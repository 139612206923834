import { useEffect } from "react";
import Checkbox from "../../../components/Checkbox";
import ErrorMessage from "../../../components/ErrorMessage";
import InputField from "../../../components/InputField";
import { validateNumber } from "../../../utils/validations";

const Specification = ({
  formData,
  setFormData,
  errors,
  setErrors,
  loading,
}: any) => {
  useEffect(() => {
    if (!formData?.specification?.color_type) {
      setFormData((prev: any) => ({
        ...prev,
        specification: {
          ...prev.specification,
          color_type: [],
        },
      }));
    }
    if (!formData?.specification?.control_type) {
      setFormData((prev: any) => ({
        ...prev,
        specification: {
          ...prev.specification,
          control_type: [],
        },
      }));
    }
    if (!formData?.specification?.watt_range) {
      setFormData((prev: any) => ({
        ...prev,
        specification: {
          ...prev.specification,
          watt_range: null,
        },
      }));
    }
  }, [formData?.id]);

  const handleChangeControlType = (value: any) => {
    delete errors?.specification?.control_type;
    setFormData((prev: any) => {
      const controlType = prev?.specification?.control_type || [];
      const hasValue = controlType.includes(value);
      const updatedControlType = hasValue
        ? controlType.filter((type: any) => type !== value)
        : [...controlType, value];
      return {
        ...prev,
        specification: {
          ...prev.specification,
          control_type: updatedControlType,
        },
      };
    });
  };
  const handleChangeColorType = (value: any) => {
    delete errors?.specification?.color_type;
    setFormData((prev: any) => {
      const colorType = prev?.specification?.color_type || [];
      const hasValue = colorType.includes(value);
      const updatedColorType = hasValue
        ? colorType.filter((type: any) => type !== value)
        : [...colorType, value];
      return {
        ...prev,
        specification: {
          ...prev.specification,
          color_type: updatedColorType,
        },
      };
    });
  };

  useEffect(() => {
    if (Array.isArray(errors?.specification)) {
      setErrors((prevErrors: any) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors.specification;
        return updatedErrors;
      });
    }
  }, [formData?.specification]);

  const handleChangeWarrantyPeriod = (period: any) => {
    setErrors({
      ...errors,
      warranty_period: null,
    });
    setFormData({
      ...formData,
      warranty_period: period,
    });
  };

  return (
    <div className="flex flex-col w-full gap-6 ">
      <div>
        <p className="font-gilroy-semi-bold text-base">Specification</p>
        <ErrorMessage
          error={
            Array.isArray(errors?.specification) ? errors?.specification : []
          }
        />
      </div>

      <div className="w-full">
        <p className="font-gilroy-semi-bold text-xs">Control type:</p>
        <div className="flex justify-start gap-x-6 gap-y-2 w-full py-2 flex-wrap">
          <Checkbox
            borderType="square"
            label="Dali"
            checkValue={
              formData?.specification?.control_type?.includes("DALI") ? 1 : 0
            }
            onClick={() => {
              handleChangeControlType("DALI");
            }}
            disabled={loading}
          />
          <Checkbox
            borderType="square"
            label="Phase cut dim"
            checkValue={
              formData?.specification?.control_type?.includes("PHASE_CUT_DIM")
                ? 1
                : 0
            }
            onClick={() => {
              handleChangeControlType("PHASE_CUT_DIM");
            }}
            disabled={loading}
          />
          <Checkbox
            borderType="square"
            label="RF Bluetooth"
            checkValue={
              formData?.specification?.control_type?.includes("RF_BLUETOOTH")
                ? 1
                : 0
            }
            onClick={() => {
              handleChangeControlType("RF_BLUETOOTH");
            }}
            disabled={loading}
          />
        </div>
        <ErrorMessage error={errors?.specification?.control_type} />
      </div>

      <div>
        <p className="font-gilroy-semi-bold text-xs">Color type:</p>
        <div className="flex justify-start  gap-8 w-full py-2">
          <Checkbox
            borderType="square"
            label="RGB"
            checkValue={
              formData?.specification?.color_type?.includes("RGB") ? 1 : 0
            }
            onClick={() => {
              handleChangeColorType("RGB");
            }}
            disabled={loading}
          />
          <Checkbox
            borderType="square"
            label="Tunable white"
            checkValue={
              formData?.specification?.color_type?.includes("TUNABLE_WHITE")
                ? 1
                : 0
            }
            onClick={() => {
              handleChangeColorType("TUNABLE_WHITE");
            }}
            disabled={loading}
          />
        </div>
        <ErrorMessage error={errors?.specification?.color_type} />
      </div>

      <div>
        <p className="font-gilroy-semi-bold text-xs">Watt Range:</p>
        <div className="flex justify-start gap-2 w-full py-2">
          <InputField
            placeholder="From"
            value={
              formData?.specification?.watt_range?.from_val?.toString() || ""
            }
            onChange={(e) => {
              delete errors?.specification?.watt_range;
              delete errors?.specification?.watt_range?.from_val;
              setFormData((prev: any) => ({
                ...prev,
                specification: {
                  ...prev.specification,
                  watt_range: {
                    ...prev.specification?.watt_range,
                    from_val: e,
                  },
                },
              }));
            }}
            validate={validateNumber}
            disabled={loading}
            hint={errors?.specification?.watt_range?.from_val}
            maxLength={5}
          />

          <span className="mt-1">-</span>
          <InputField
            placeholder="To"
            value={
              formData?.specification?.watt_range?.to_val?.toString() || ""
            }
            onChange={(e) => {
              delete errors?.specification?.watt_range;
              delete errors?.specification?.watt_range?.to_val;
              setFormData((prev: any) => ({
                ...prev,
                specification: {
                  ...prev.specification,
                  watt_range: {
                    ...prev.specification?.watt_range,
                    to_val: e,
                  },
                },
              }));
            }}
            validate={validateNumber}
            disabled={loading}
            hint={errors?.specification?.watt_range?.to_val}
            maxLength={5}
          />
        </div>
        <ErrorMessage
          error={
            Array.isArray(errors?.specification?.watt_range)
              ? errors?.specification?.watt_range
              : []
          }
        />
        <ErrorMessage error={errors?.specification?.watt_range?._schema} />
      </div>

      {/* Warranty Section */}
      <div className="flex flex-col w-full gap-6 ">
        <p className="font-gilroy-semi-bold text-base">Warranty</p>

        <div>
          <p className="font-gilroy-semi-bold text-xs pb-2">Warranty Period:<span className="text-red-500 text-xs ml-1">*</span></p>
          <div className="flex flex-col gap-2">
            <div className="flex justify-start gap-x-5 w-full [&>div]:w-[150px]">
              <Checkbox
                borderType="circle"
                label="1 Year"
                checkValue={formData?.warranty_period === "1y" ? 1 : 0}
                onClick={() => handleChangeWarrantyPeriod("1y")}
                disabled={loading}
              />
              <Checkbox
                borderType="circle"
                label="2 Years"
                checkValue={formData?.warranty_period === "2y" ? 1 : 0}
                onClick={() => handleChangeWarrantyPeriod("2y")}
                disabled={loading}
              />
            </div>

            <div className="flex justify-start gap-x-5 w-full [&>div]:w-[150px]">
              <Checkbox
                borderType="circle"
                label="3 Years"
                checkValue={formData?.warranty_period === "3y" ? 1 : 0}
                onClick={() => handleChangeWarrantyPeriod("3y")}
                disabled={loading}
              />
              <Checkbox
                borderType="circle"
                label="5 Years"
                checkValue={formData?.warranty_period === "5y" ? 1 : 0}
                onClick={() => handleChangeWarrantyPeriod("5y")}
                disabled={loading}
              />
            </div>
            <ErrorMessage error={errors?.warranty_period} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specification;
