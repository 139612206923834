import React from "react";

const PdfIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49984 9.42444H7.33317V7.75777H8.16651C8.40262 7.75777 8.60067 7.67777 8.76067 7.51777C8.92067 7.35777 9.00039 7.16 8.99984 6.92444V6.09111C8.99984 5.855 8.91984 5.65694 8.75984 5.49694C8.59984 5.33694 8.40206 5.25722 8.16651 5.25777H6.49984V9.42444ZM7.33317 6.92444V6.09111H8.16651V6.92444H7.33317ZM9.83317 9.42444H11.4998C11.7359 9.42444 11.934 9.34444 12.094 9.18444C12.254 9.02444 12.3337 8.82666 12.3332 8.59111V6.09111C12.3332 5.855 12.2532 5.65694 12.0932 5.49694C11.9332 5.33694 11.7354 5.25722 11.4998 5.25777H9.83317V9.42444ZM10.6665 8.59111V6.09111H11.4998V8.59111H10.6665ZM13.1665 9.42444H13.9998V7.75777H14.8332V6.92444H13.9998V6.09111H14.8332V5.25777H13.1665V9.42444ZM5.66651 14.0078C5.20817 14.0078 4.81567 13.8444 4.48901 13.5178C4.16234 13.1911 3.99928 12.7989 3.99984 12.3411V2.34111C3.99984 1.88277 4.16317 1.49027 4.48984 1.16361C4.81651 0.83694 5.20873 0.673884 5.66651 0.67444H15.6665C16.1248 0.67444 16.5173 0.837773 16.844 1.16444C17.1707 1.49111 17.3337 1.88333 17.3332 2.34111V12.3411C17.3332 12.7994 17.1698 13.1919 16.8432 13.5186C16.5165 13.8453 16.1243 14.0083 15.6665 14.0078H5.66651ZM5.66651 12.3411H15.6665V2.34111H5.66651V12.3411ZM2.33317 17.3411C1.87484 17.3411 1.48234 17.1778 1.15567 16.8511C0.829005 16.5244 0.66595 16.1322 0.666505 15.6744V4.00777H2.33317V15.6744H13.9998V17.3411H2.33317Z"
        fill="#AEAEAE"
      />
    </svg>
  );
};

export default PdfIcon;
