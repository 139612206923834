import React, { useEffect } from "react";

import ProjectDraftsCounts from "./components/ProjectDraftsCounts";
import ProjectDraftTable from "./components/ProjectDraftTable";

export default function ProjectDrafts() {
  return (
    <div className="h-full flex flex-col justify-start items-start">
      <div className="h-[13vh] w-full flex items-center justify-start">
        <ProjectDraftsCounts />
      </div>
      <div className="h-[calc(100%-13vh)] w-full">
        <ProjectDraftTable />
      </div>
    </div>
  );
}
