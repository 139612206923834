import React from "react";

const TimerIcon = ({ color, width }: any) => {
  return (
    <svg
      width={width || "11"}
      height={width || "13"}
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.70781 4.83104H4.83997V8.73629H5.70781V4.83104Z"
        fill="white"
      />
      <path
        d="M6.57564 0.925781H3.97214V1.79362H6.57564V0.925781Z"
        fill="white"
      />
      <path
        d="M10.4809 3.9632L9.86473 3.35138L8.88842 4.32769C8.08923 3.40476 6.96518 2.82522 5.74982 2.70949C4.53446 2.59375 3.32123 2.95071 2.36221 3.70621C1.40319 4.46171 0.772116 5.55765 0.600104 6.76633C0.428092 7.97501 0.728371 9.2035 1.43855 10.1965C2.14873 11.1896 3.2142 11.8708 4.4136 12.0988C5.613 12.3267 6.85409 12.0837 7.87903 11.4204C8.90396 10.7571 9.63393 9.72436 9.91726 8.53683C10.2006 7.3493 10.0155 6.09827 9.40044 5.04366L10.4809 3.9632ZM5.27389 11.3398C4.5015 11.3398 3.74646 11.1108 3.10424 10.6816C2.46203 10.2525 1.96148 9.64261 1.6659 8.92902C1.37032 8.21542 1.29299 7.43021 1.44367 6.67266C1.59436 5.91512 1.9663 5.21927 2.51246 4.67311C3.05862 4.12695 3.75447 3.75501 4.51201 3.60432C5.26956 3.45364 6.05477 3.53097 6.76836 3.82655C7.48196 4.12213 8.09187 4.62268 8.52099 5.2649C8.9501 5.90711 9.17914 6.66215 9.17914 7.43454C9.17914 8.47028 8.7677 9.46359 8.03532 10.196C7.30294 10.9283 6.30963 11.3398 5.27389 11.3398Z"
        fill={color || "white"}
      />
    </svg>
  );
};

export default TimerIcon;
