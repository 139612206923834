import React from "react";
type ThumbsuoProps = {
  color?: string
}
const Thumbsup = ({color}:ThumbsuoProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7501 7.39987C16.5159 7.11875 16.2229 6.89244 15.8917 6.73693C15.5606 6.58142 15.1993 6.5005 14.8334 6.49987H11.0334L11.5001 5.30821C11.6942 4.78644 11.7588 4.22538 11.6885 3.67314C11.6181 3.1209 11.4148 2.59398 11.0961 2.13757C10.7773 1.68115 10.3526 1.30887 9.85837 1.05266C9.36413 0.796449 8.81511 0.663954 8.25841 0.666541C8.09811 0.666876 7.94131 0.713437 7.80681 0.800643C7.6723 0.88785 7.5658 1.012 7.50008 1.15821L5.12508 6.49987H3.16675C2.50371 6.49987 1.86782 6.76327 1.39898 7.23211C0.93014 7.70095 0.666748 8.33683 0.666748 8.99987V14.8332C0.666748 15.4962 0.93014 16.1321 1.39898 16.601C1.86782 17.0698 2.50371 17.3332 3.16675 17.3332H13.7751C14.3599 17.333 14.9262 17.1278 15.3753 16.7532C15.8245 16.3787 16.1281 15.8585 16.2334 15.2832L17.2917 9.44987C17.3572 9.08936 17.3426 8.71886 17.249 8.3646C17.1554 8.01035 16.9851 7.681 16.7501 7.39987ZM4.83341 15.6665H3.16675C2.94573 15.6665 2.73377 15.5787 2.57749 15.4225C2.42121 15.2662 2.33341 15.0542 2.33341 14.8332V8.99987C2.33341 8.77886 2.42121 8.5669 2.57749 8.41062C2.73377 8.25434 2.94573 8.16654 3.16675 8.16654H4.83341V15.6665ZM15.6667 9.14987L14.6084 14.9832C14.5729 15.1773 14.4697 15.3526 14.3171 15.4777C14.1645 15.6028 13.9724 15.6698 13.7751 15.6665H6.50008V7.50821L8.76675 2.40821C9.00007 2.47623 9.21675 2.59188 9.40313 2.74786C9.5895 2.90384 9.74152 3.09676 9.84958 3.31445C9.95764 3.53214 10.0194 3.76987 10.031 4.01262C10.0425 4.25538 10.0036 4.4979 9.91675 4.72487L9.47508 5.91654C9.38097 6.16842 9.3492 6.43933 9.38248 6.70615C9.41576 6.97297 9.5131 7.22778 9.66619 7.44882C9.81929 7.66987 10.0236 7.8506 10.2617 7.97557C10.4997 8.10055 10.7645 8.16607 11.0334 8.16654H14.8334C14.9558 8.16634 15.0768 8.19312 15.1877 8.24497C15.2986 8.29682 15.3967 8.37247 15.4751 8.46654C15.5554 8.55931 15.6142 8.66866 15.6473 8.78678C15.6804 8.90491 15.6871 9.02889 15.6667 9.14987Z"
        fill={color || "#81C784"}
      />
    </svg>
  );
};

export default Thumbsup;
