import { toast } from "react-toastify";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import CrossIcon from "../../../assets/CrossIcon";
import EditIcon from "../../../assets/EditIcon";
import TickIcon from "../../../assets/TickIcon";
import Button from "../../../components/Button";
import { useParams } from "react-router-dom";
import { useState } from "react";

const ExperienceCard = ({
  experience,
  setShowExperienceModal,
  setSelectedXp,
  length,
  reRender,
  data,
}: any) => {


  const [workExperienceLoading, setWorkExperienceLoading] = useState(false);
  const getMonthAbbreviation = (numericMonth: any) => {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return months[numericMonth - 1] || "";
  };

  // /admin/accounts/professional/{userId}/workx/{workxId}/approval
  const handleApprove = async (xid: string, type: "APPROVED" | "DECLINED") => {
    setWorkExperienceLoading(true);
    protectedAxiosInstance
      .post(
        `/admin/accounts/professional/${data?.customer?.id}/workx/${xid}/approval`,
        {
          status: type,
        }
      )
      .then(() => {
        toast.success(
          `Experience has been ${
            type === "APPROVED" ? "approved" : "declined"
          }!`
        );
        reRender();
      })
      .catch((err) => {
        console.log(err);

        toast.error(
          `Error ${
            type === "APPROVED" ? "approving" : "declining"
          } the experience`,
          err
        );
      })
      .finally(() => setWorkExperienceLoading(false));
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 items-center">
        <div className="border border-black bg-c-gray-1 h-10 w-10 flex justify-center items-center">
          <img
            alt=""
            width={100}
            height={100}
            className="w-full h-full object-contain"
            src={`${process.env.REACT_APP_BUCKET}/${experience?.p_company?.profile_picture}`} //eslint-disable-line
          />
        </div>

        <div>
          <p className="text-[32px] font-mangoGrotesque-black leading-1">
            {experience?.p_company?.name}
          </p>
          <p className=" text-[#777777] font-gilroy-medium text-[16px]">
            {experience?.p_company?.category}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-4 relative">
        {experience?.workx?.map((x: any, index: number) => {
          // const displayLine = index < experience?.workx?.length - 1;
          return (
            <div
              key={index}
              className={`flex flex-wrap items-center justify-start text-xs gap-4 w-full ${
                x?.status !== "PENDING" ? "py-1" : ""
              }`}
            >
              <div className="flex items-center gap-2 min-w-fit sm:w-[78%] md:w-[60%] ">
                <div className="flex justify-center items-center ml-[14.31px]">
                  <div className="w-2 h-2 rounded-full bg-[#777777] outline  outline-4 outline-white z-10 "></div>
                </div>
                <div className="flex flex-col sm:flex-row flex-wrap justify-between w-full">
                  <p className="font-gilroy-bold truncate">{x?.title}</p>
                  <p>
                    {getMonthAbbreviation(x?.start_m)} {x?.start_y} -{" "}
                    {x?.end_m && x?.end_y
                      ? getMonthAbbreviation(x?.end_m) + " " + x?.end_y
                      : "PRESENT"}
                  </p>
                </div>
              </div>
              <div className="flex justify-start items-center gap-2 text-[8px] sm:[10px] max-sm:text-[8px] max-lg:text-[10px] lg:text-xs w-fit sm:w-[22%] md:w-[30%] ">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    if (!workExperienceLoading) {
                      setSelectedXp(x?.id);
                      setShowExperienceModal(true);
                    }
                  }}
                >
                  <EditIcon />
                </div>
                <p
                  className={`text-black rounded-md text-sm m-0.5 px-3 py-0.5 cursor-pointer border-none min-w-[50%] text-center ${
                    x?.status === "DRAFT"
                      ? "bg-[#EDEDED]"
                      : x?.status === "IN_REVIEW"
                      ? "bg-[#FFC5EF]"
                      : x?.status === "APPROVED"
                      ? "bg-[#B6FDC6]"
                      : x?.status === "DECLINED"
                      ? "bg-[#FFB4A1]"
                      : x?.status === "CHANGE_REQUESTED"
                      ? "bg-[#DBD6F6]"
                      : x?.status === "PENDING"
                      ? "bg-[#FFDCB2]"
                      : ""
                  }`}
                >
                  {x?.status.replace(/_/g, " ")}
                </p>

                {x?.status === "PENDING" ? (
                  <div className="flex gap-2 ">
                    <Button
                      rounded={false}
                      leftIcon={
                        <div className="py-0.5">
                          <TickIcon width="16" />
                        </div>
                      }
                      variant="primary-outline"
                      onClick={() => handleApprove(x?.id, "APPROVED")}
                      disabled={workExperienceLoading}
                    />
                    <Button
                      rounded={false}
                      leftIcon={
                        <div className="px-0.5">
                          <CrossIcon width="12" />
                        </div>
                      }
                      onClick={() => handleApprove(x?.id, "DECLINED")}
                      variant="secondary-outline"
                      disabled={workExperienceLoading}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}

        {experience?.workx?.length > 1 && (
          <div className="absolute w-[2px] bg-[#777777] h-[87%] top-1/2 transform -translate-y-1/2 left-[17px] opacity-50"></div>
        )}
      </div>
    </div>
  );
};

export default ExperienceCard;
