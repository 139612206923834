import Modal from "../../../components/common/Modal";

const PaymentDetails = ({ data, setPaymentModal }: any) => {
  console.log(data, "data");

  return (
    <Modal
      header="Payment History"
      bigText
      handleCancel={() => setPaymentModal(false)}
    >
      <div className="w-[90%] m-auto my-3">
        {data?.length > 0 ? (
          <table className="w-full border py-2 ">
            <thead className="bg-[#F6F6F6] text-left py-2 ">
              <tr>
                <th className="w-1/2 border-r border-[#F6F6F6] py-2 px-2">
                  Date
                </th>
                <th className="w-1/2 py-2  px-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: any) => (
                <tr key={index}>
                  <td className="w-1/2 text-start border-2 border-[#F6F6F6] py-2 px-2">
                    {new Date(item.action_at)
                      .toLocaleDateString("en-IN", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })
                      .replace(/\//g, "-")}
                  </td>

                  <td className="w-1/2 text-start py-2  px-2 border-2 border-[#F6F6F6]">
                    ₹ {(item.amount / 100).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="flex justify-center items-center py-6 ">
            No Payment History Available
          </p>
        )}
      </div>
    </Modal>
  );
};

export default PaymentDetails;
