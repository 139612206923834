import React, { useEffect, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";

import { useNavigate } from "react-router-dom";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import SelectCheckboxOption from "../../components/SelectCheckboxOption";
import { getCustomerQuicklist } from "../../api/customers";
import EmailIcon from "../../assets/EmailIcon";
import PhoneIcon from "../../assets/PhoneIcon";
import { getProjectQuicklist } from "../../api/project";
import crossIcon from "../../assets/svg/close.svg";
import useOnClickOutside from "../../hooks/useClickOutside";
import SearchIcon from "../../assets/SearchIcon";
import { DownwardArrowIcon } from "../../assets/ArrowIcons";
import { motion } from "framer-motion";
import { postOrderDraft } from "../../api/orderManagement";
import ProjectDropdownSearchable from "./components/ProjectDropdownSearchable";
import CustomerDropdownSearchable from "./components/CustomerDropdownSearchable";
import { toast } from "react-toastify";
import ProfessionalDropdown from "../../components/ProfessionalDropdown";
import { getProfessionalList } from "../../api/professional";

export default function CreateOrder() {
  const setTitle = useSetRecoilState(titleAtom);
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const containerRef = useRef(null);
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [customerList, setCustomerList] = useState<any>();
  const [customerSearch, setCustomerSearch] = useState("");
  const [searchProject, setSearchProject] = useState("");
  const [seletedtype, setSelectedType] = useState("customer");
  const [selectedProject, setSelectedProject] = useState<any>();
  const [projectList, setProjectList] = useState<any>();
  const [openProfessionalDropdown, setOpenProfessionalDropdown] =
    useState(false);
  useOnClickOutside(buttonRef, () => setOpenProfessionalDropdown(false));
  const debounceTimeoutRef = useRef<any>(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLength, setPageLength] = useState(200);
  const [professionalList, setProfessionalList] = useState<any>([]);
  const [professionalId, setProfessionalId] = useState("");
  const [professionalKind, setProfessionalKind] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [totalPages, setTotalPages] = useState(200); // Initial total number of pages

  const handleOrderType = (type: string) => {
    setSelectedType(type);
  };

  useEffect(() => {
    const params = {
      page: 1,
      length: 100,
      search: customerSearch,
    };
    getCustomerQuicklist(params)
      .then((res) => {
        setCustomerList(res?.data?.customers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [customerSearch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfessionalList({
          search: debouncedSearchTerm || "",
          page: currentPage || "",
          length: totalPages || "",
        });
        setProfessionalList(response?.data?.professional);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, [debouncedSearchTerm, currentPage, totalPages]);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchTerm(searchQuery);
    }, 400);
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchQuery]);

  const handleClear = () => {
    setProfessionalId("");
    delete errors?.p_individual_id;
  };

  useEffect(() => {
    delete errors?.p_individual_id;
  }, [searchQuery]);

  useEffect(() => {
    if (selectedCustomer) {
      const params = {
        customer_id: selectedCustomer?.id,
        search: searchProject,
      };
      getProjectQuicklist(params)
        .then((res) => {
          setProjectList(res?.data?.project);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [searchProject, selectedCustomer]);

  const handleSubmitOrderDraft = () => {
    const payload = {
      professional_id: professionalId, // Include if selectedProfessional is defined
      professional_kind: professionalKind,
      owner: seletedtype === "customer" ? "USER" : "PROJECT",
      owner_id:
        seletedtype === "customer" ? selectedCustomer?.id : selectedProject?.id,
    };

    // Only send professional_id if it's defined
    if (professionalId) {
      payload.professional_id = professionalId;
    }

    postOrderDraft(payload)
      .then((res) => {
        // console.log(res);
        if (res) {
          const id = res?.data?.order?.id;
          toast.success("Order draft successfully");
          navigate(`/orders/${id}`);
        }
      })
      .catch((err) => {
        console.log(err?.data?.errors);
        // toast.error("Something went wrong");
        setErrors(err?.data?.errors);
      });
  };

  //   console.log(professionalKind,
  //   'professional'
  // );

  return (
    <div className="h-full overflow-y-auto  custom-scrollbar">
      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  pl-4">
        <h2 className="font-semibold">Create new Order</h2>
      </div>
      <div className="overflow-y-auto custom-scrollbar h-[calc(100%-150px)] pl-4">
        <div className="flex flex-col divide-y-2 gap-4   pr-5 md:pr-14 lg:pr-0 lg:w-[70%] lg:max-w-[50vw] ">
          <div>
            <p className="font-gilroy-semi-bold py-4">Order for:</p>
            <div className="flex justify-start gap-4 ">
              <SelectCheckboxOption
                kind="customer"
                isSelected={seletedtype === "customer"}
                handleSelection={handleOrderType}
              />
              <SelectCheckboxOption
                kind="project"
                isSelected={seletedtype === "project"}
                handleSelection={handleOrderType}
              />
            </div>
            <div>
              <CustomerDropdownSearchable
                options={customerList}
                onSelect={(value: any) => {
                  setSelectedCustomer(value);
                  setSearchProject("");
                  setSelectedProject(null);
                  delete errors?.owner_id;
                }}
                onChange={(value: any) => {
                  setCustomerSearch(value);
                }}
                value={selectedCustomer ? selectedCustomer?.name : ""}
                label="Select Customer:"
                placeholder="Search customer here..."
                hint={errors?.owner_id}
              />
            </div>

            {selectedCustomer && (
              <div className="mt-2 flex gap-8">
                {selectedCustomer?.email && (
                  <div className="flex gap-2 items-center">
                    <EmailIcon width={"18"} />
                    <p>{selectedCustomer?.email} </p>
                  </div>
                )}

                {selectedCustomer?.phone && (
                  <div className="flex gap-2 items-center">
                    {" "}
                    <PhoneIcon />
                    <p>{selectedCustomer?.phone} </p>
                  </div>
                )}
              </div>
            )}

            {seletedtype === "project" && (
              <div className="cursor-pointer">
                <ProjectDropdownSearchable
                  options={projectList ? projectList : ""}
                  onSelect={(value: any) => {
                    setSelectedProject(value);
                  }}
                  onChange={(value: any) => {
                    setSearchProject(value);
                  }}
                  value={selectedProject ? selectedProject?.name : ""}
                  label="Select Project:"
                  placeholder="Search project here..."
                  hint={errors?.owner_id}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <div className="mt-4">
              <ProfessionalDropdown
                smallText
                width="100%"
                label="Add Professional:"
                Xsearch={searchQuery}
                setXSearch={setSearchQuery}
                options={professionalList.map((el: any) => ({
                  value: el.p_individual_id,
                  label: el,
                }))}
                onChange={(e: any) => {
                  setProfessionalId(e);
                  delete errors?.professional_id;
                }}
                hint={errors?.professional_id}
                value={professionalId}
                searchPlaceHolder="Search employee"
                // placeholder="Choose employee"
                onClear={handleClear}
                // disabled={}
                setProfessionalKind={setProfessionalKind}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-x-4 pt-4 pl-4 border-t  border-[#ECECEC]">
        <Button
          label="Create"
          variant="primary"
          onClick={handleSubmitOrderDraft}
        />
        <Button
          label="Cancel"
          variant="secondary-outline"
          onClick={() => navigate("/orders")}
        />
      </div>
    </div>
  );
}
