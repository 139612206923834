import HipchatChevronLeftIcon from "../../../assets/HipchatChevronLeftIcon";
import InfoIcon from "../../../assets/svg-tsx/InfoIcon";
import ActiveStatusIcon from "../../../assets/svg/activestatus.svg";
import DeactiveStatusIcon from "../../../assets/svg/deactivatestatus.svg";
import React from "react";
import Button from "../../../components/Button";
import TrashIcon from "../../../assets/TrashIcon";

const Setting = ({
  data,
  handleDeleteProject,
  initialLoading,
  actionLoading,
  handlePublicPrivate,
}: // handleHiddenVisibility,
any) => {
  return (
    <div className="w-full p-4">
      <p className="font-gilroy-semi-bold text-[12px] mb-2">Settings</p>

      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <p
              className={`font-gilroy-semi-bold text-[16px] ${
                !data?.is_public ? "text-black" : "text-[#898989]"
              }`}
            >
              Private
            </p>
          </div>
          <div
            className={
              initialLoading || actionLoading
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }
            onClick={handlePublicPrivate}
          >
            {data?.is_public ? (
              <div className="cursor-pointer h-8 w-8">
                <img
                  src={ActiveStatusIcon}
                  alt="status-icon"
                  className="w-full object-contain h-full"
                />
              </div>
            ) : (
              <div className="cursor-pointer  h-8 w-8">
                <img
                  src={DeactiveStatusIcon}
                  alt="status-icon"
                  className="w-full object-contain h-full"
                />
              </div>
            )}
          </div>
          <div className="flex gap-4 items-center">
            <p
              className={`font-gilroy-semi-bold text-[16px] ${
                data?.is_public ? "text-black" : "text-[#898989]"
              }`}
            >
              Public
            </p>
            <div className="ml-1 ursor-pointer">
              <InfoIcon />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full my-5">
        <Button
          leftIcon={<TrashIcon width="12" height="12" />}
          label="Delete Project"
          variant="secondary-outline"
          fullWidth={true}
          onClick={handleDeleteProject}
        />
      </div>
    </div>
  );
};

export default Setting;
