import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../components/common/Modal";
import useOnClickOutside from "../../../hooks/useClickOutside";
import { DownwardArrowIcon } from "../../../assets/ArrowIcons";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import crossIcon from "../../../assets/svg/close.svg";
import { motion } from "framer-motion";
import { getCustomerQuicklist } from "../../../api/customers";
import Button from "../../../components/Button";
import { updateSpecificOrder } from "../../../api/orderManagement";

const AddProfessional = ({
  setOpenModal,
  orderDetails,
  setOrderDetails,
}: any) => {
  const buttonRef = useRef(null);
  const containerRef = useRef(null);

  const [particulatProfessional, setParticularProfessional] = useState() as any;
  const [professionalList, setProfessionalList] = useState<any>();
  const [openProfessionalDropdown, setOpenProfessionalDropdown] =
    useState(false);
  useOnClickOutside(buttonRef, () => setOpenProfessionalDropdown(false));
  const [searchProfessional, setSearchProfessional] = useState() as any;
  const [selectedProfessional, setSelectedProfessional] = useState() as any;

  useEffect(() => {
    const params = {
      is_professional: true,
      search: searchProfessional,
    };
    getCustomerQuicklist(params)
      .then((res) => {
        // console.log(res?.data?.customers);
        setProfessionalList(res?.data?.customers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const specificProfessional = professionalList?.filter((el: any, i: any) => {
      if (el?.id == selectedProfessional) {
        return el;
      }
    });
    setParticularProfessional(specificProfessional);
  }, [selectedProfessional]);

  const handleSubmitProfessional = () => {
    const orderId = orderDetails?.id;
    const payload = {
      professional_id: selectedProfessional,
    };
    updateSpecificOrder(orderId, payload)
      .then((res) => {
        console.log(res);
        if (res?.data) {
          setOrderDetails(res?.data?.order);
          setOpenModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      header="Select Professional"
      footer={
        <Button
          label="Save"
          variant="primary"
          onClick={handleSubmitProfessional}
        />
      }
      handleCancel={() => setOpenModal(false)}
    >
      <div className="flex flex-col w-full">
        <div>
          {particulatProfessional?.length > 0 ? (
            <div className="flex justify-between items-center pr-4 border-2 border-pot-yellow/60 ">
              <div className="flex items-center px-3 hover:bg-[#FCFCFC]">
                <img
                  width={20}
                  height={20}
                  src={`${process.env.REACT_APP_BUCKET}/${particulatProfessional[0]?.profile_picture}`} //eslint-disable-line
                  alt="user"
                  className="w-6 h-6 object-cover"
                />

                <div className="flex items-center text-xs gap-x-1">
                  <p>
                    {particulatProfessional[0]?.name.toUpperCase()[0] +
                      particulatProfessional[0]?.name.slice(1).toLowerCase()}
                  </p>
                  <div className="w-[4px] h-[4px] rounded-full bg-pot-yellow"></div>
                  <p>
                    {particulatProfessional[0]?.professional?.profession?.name}
                  </p>
                  <div className="w-[4px] h-[4px] rounded-full bg-pot-yellow"></div>
                  <p>{particulatProfessional[0]?.professional?.address_city}</p>
                </div>
              </div>

              <button
                onClick={() => {
                  setOpenProfessionalDropdown(false);
                  setSelectedProfessional();
                  setParticularProfessional();
                }}
              >
                <img src={crossIcon} alt="" />
              </button>
            </div>
          ) : (
            <div className="relative" ref={buttonRef}>
              <button
                onClick={() => {
                  setOpenProfessionalDropdown(true);
                }}
                className="w-full"
              >
                <InputField
                  // value={searchText}
                  placeholder="Search Professionals"
                  onChange={(value) => {
                    setSearchProfessional(value);
                  }}
                  leftIcon={<SearchIcon />}
                  rightIcon={<DownwardArrowIcon />}
                />
              </button>

              {openProfessionalDropdown && (
                <motion.div
                  ref={containerRef}
                  className="w-full bg-white shadow-lg border-2 border-pot-yellow/60 "
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <div
                    ref={containerRef}
                    className="overflow-y-scroll max-h-[20vh] "
                  >
                    {professionalList?.map((user: any, idx: number) => {
                      return (
                        <button
                          key={idx}
                          onClick={() => {
                            setOpenProfessionalDropdown(false);
                            setSelectedProfessional(user?.id);
                          }}
                          className="flex items-center gap-x-2 my-2 px-3"
                        >
                          <img
                            width={20}
                            height={20}
                            src={`${process.env.REACT_APP_BUCKET}/${user?.profile_picture}`} //eslint-disable-line
                            alt="user"
                            className="w-7 h-7 object-cover"
                          />
                          <div className="flex items-center text-xs gap-x-1">
                            <p className="font-gilroy-bold">
                              {user?.name.toUpperCase()[0] +
                                user?.name.slice(1).toLowerCase()}
                            </p>
                            <div className="w-[4px] h-[4px] rounded-full bg-pot-yellow"></div>
                            <p>{user?.professional?.profession?.name}</p>

                            <div className="w-[4px] h-[4px] rounded-full bg-pot-yellow"></div>
                            <p>{user?.professional?.address_city}</p>
                          </div>
                        </button>
                      );
                    })}
                  </div>

                  {false && <p>Loading...</p>}
                </motion.div>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddProfessional;
