import { useEffect, useState } from "react";
import UpTriangle from "../../../assets/svg-tsx/UpTriangle";
import DownTriangle from "../../../assets/svg-tsx/DownTriangle";
import EditIcon from "../../../assets/EditIcon";
import TrashIcon from "../../../assets/TrashIcon";
import { motion } from "framer-motion";
import HipchatChevronDownIcon from "../../../assets/HipchatChevronDownIcon";
import Button from "../../../components/Button";
import PlusIcon from "../../../assets/PlusIcon";
import Card1 from "./OptionCards/Card1";
import Card2 from "./OptionCards/Card2";
import Card3 from "./OptionCards/Card3";
import Card4 from "./OptionCards/Card4";
import Card5 from "./OptionCards/Card5";
import TrackTypeOptionCard from "./OptionCards/TrackTypeOptionCard";
import ErrorMessage from "../../../components/ErrorMessage";

export const StageComponent = ({
  stage,
  index,
  isStageListDropDownOpen,
  setIsStageListDropDownOpen,
  setFormData,
  formData,
  length,
  setDeleteStageIndex,
  setAddEditStageIndex,
  setStageSelectIndex,
  setFormPage,
  setVariantID,
  setDeleteVariantIndex,
  setPendingVariantData,
  errors,
  setErrors,
}: any) => {
  const [isStageDropdownOpen, setIsStageDropdownOpen] = useState(
    isStageListDropDownOpen[index]
  );

  useEffect(() => {
    setIsStageDropdownOpen(isStageListDropDownOpen[index]);
  }, [isStageListDropDownOpen, index]);

  // functionality to open dropdown
  const handleDropdownClick = () => {
    setIsStageListDropDownOpen((prev: any) => {
      const updatedVariants = [...prev];
      updatedVariants[index] = !isStageDropdownOpen;
      return updatedVariants;
    });
  };

  // function to stage up
  const handleStageUp = () => {
    if (index > 0) {
      setFormData((prev: any) => {
        const updatedStages = [...(prev?.stages || [])];
        const temp = updatedStages[index];
        updatedStages[index] = updatedStages[index - 1];
        updatedStages[index - 1] = temp;
        return { ...prev, stages: updatedStages };
      });

      setErrors((prev: any) => {
        const updatedErrors = { ...prev };
        if (updatedErrors.stages) {
          const temp = updatedErrors.stages[index];
          updatedErrors.stages[index] = updatedErrors.stages[index - 1];
          updatedErrors.stages[index - 1] = temp;
        }
        return updatedErrors;
      });
      setIsStageListDropDownOpen((prev: any) => {
        const updatedVariantsOpen = [...prev];
        const temp = updatedVariantsOpen[index];
        updatedVariantsOpen[index] = updatedVariantsOpen[index - 1];
        updatedVariantsOpen[index - 1] = temp;
        return updatedVariantsOpen;
      });
    }
  };

  // function to stage down
  const handleStageDown = () => {
    if (index < formData?.stages.length - 1) {
      setFormData((prev: any) => {
        const updatedStages = [...(prev?.stages || [])];
        const temp = updatedStages[index];
        updatedStages[index] = updatedStages[index + 1];
        updatedStages[index + 1] = temp;
        return { ...prev, stages: updatedStages };
      });

      setErrors((prev: any) => {
        const updatedErrors = { ...prev };
        if (updatedErrors.stages) {
          const temp = updatedErrors.stages[index];
          updatedErrors.stages[index] = updatedErrors.stages[index + 1];
          updatedErrors.stages[index + 1] = temp;
        }
        return updatedErrors;
      });

      setIsStageListDropDownOpen((prev: any) => {
        const updatedVariantsOpen = [...prev];
        const temp = updatedVariantsOpen[index];
        updatedVariantsOpen[index] = updatedVariantsOpen[index + 1];
        updatedVariantsOpen[index + 1] = temp;
        return updatedVariantsOpen;
      });
    }
  };

  const handleStageEdit = (e: any) => {
    e.stopPropagation();
    setAddEditStageIndex(index);
  };

  const handleStageDelete = (e: any) => {
    e.stopPropagation();
    setDeleteStageIndex(index);
  };

  const clearVariantErrors = (stageIndex: any) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors.stages && updatedErrors.stages[stageIndex]) {
        if (updatedErrors.stages[stageIndex].variants) {
          delete updatedErrors.stages[stageIndex].variants;
        } else {
          delete updatedErrors.stages[stageIndex];
        }
      }
      return updatedErrors;
    });
  };
  return (
    <div key={stage.name}>
      <div
        className="flex justify-between items-center cursor-pointer "
        onClick={handleDropdownClick}
      >
        <div
          className="flex justify-center items-center gap-4"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex">
            <UpTriangle
              stroke="black"
              onClick={handleStageUp}
              disabled={index === 0}
            />

            <DownTriangle
              stroke="black"
              onClick={handleStageDown}
              disabled={index === length - 1}
            />
          </div>

          <p onClick={handleDropdownClick}>
            Stage {index + 1}: {stage.name}
          </p>

          {errors?.stages?.[index]?.variants && (
            <p className="bg-red-100 px-2 py-1 text-xs rounded-lg text-red-500">
              Error found
            </p>
          )}
        </div>

        <div className="flex gap-x-3">
          <button
            onClick={(e) => {
              handleStageEdit(e);
            }}
          >
            <EditIcon color="black" />
          </button>
          <button
            className="scale-75"
            onClick={(e) => {
              handleStageDelete(e);
            }}
          >
            <TrashIcon color="black" />
          </button>
          <motion.div
            animate={{ rotate: isStageDropdownOpen ? 180 : 0 }}
            className="w-[22px]  h-[38px] flex items-center justify-center cursor-pointer"
            onClick={handleDropdownClick}
          >
            <HipchatChevronDownIcon color="black" width="22" />
          </motion.div>
        </div>
      </div>

      {isStageDropdownOpen && (
        <>
          <ErrorMessage
            error={
              errors?.stages?.[index]?.variants &&
              Array.isArray(errors?.stages?.[index]?.variants)
                ? errors?.stages?.[index]?.variants
                : ""
            }
          />

          {stage?.variants
            ?.filter((variant: any) => !variant.to_delete)
            ?.map((variant: any, vidx: any) => {
              return (
                <div key={vidx}>
                  <div className="w-full flex justify-between items-center mt-8">
                    <div className="flex items-center gap-4">
                      <p className={`text-left font-medium `}>{variant.name}</p>
                      {errors?.stages?.[index]?.variants?.[vidx] && (
                        <p className="bg-red-100 px-2 py-1 text-xs rounded-lg text-red-500">
                          Error found
                        </p>
                      )}
                    </div>
                    <div className="stoke-black flex gap-x-3 items-center ">
                      <button
                        onClick={() => {
                          delete errors.stages;
                          setVariantID(variant.id);
                          setPendingVariantData(variant);
                          setFormPage(1);
                          setStageSelectIndex(index);
                        }}
                      >
                        <EditIcon />
                      </button>
                      <button
                        onClick={() => {
                          delete errors.stages;
                          setDeleteVariantIndex({
                            sidx: index,
                            vid: variant.id,
                          });
                        }}
                        className="scale-75"
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                  {/* {
                  <div className="text-left">
                    {errors?.stages?.[idx]?.variants?.[vidx] &&
                      Object.entries(errors.stages[idx].variants[vidx])
                        ?.filter((each) => !each[0].includes("option"))
                        ?.map((each) => (
                          <div className="text-xs text-red-700">
                            {each[0] + " : " + JSON.stringify(each[1])}
                          </div>
                        ))}
                    {Array.isArray(
                      errors?.stages?.[idx]?.variants?.[vidx]?.options &&
                        errors?.stages?.[idx]?.variants?.[vidx]?.options
                    ) &&
                      errors?.stages?.[idx]?.variants?.[vidx]?.options &&
                      errors?.stages?.[idx]?.variants?.[vidx]?.options?.map(
                        (each: any) => (
                          <div className="text-xs text-red-700" key={each}>
                            {each}
                          </div>
                        )
                      )}
                  </div>
                } */}
                  <div
                    className={`w-full grid gap-2 
                       grid-cols-2 auto-rows-fr`}
                  >
                    {variant.options.map((el: any, i: any) => {
                      if (el.to_delete) {
                        return null;
                      }
                      return (
                        <div
                          className="flex flex-col gap-2 h-full text-sm text-left w-full mt-4 "
                          key={i}
                        >
                          {variant.kind === "BIG_BLOCK_WITH_IMAGE" && (
                            <Card1 el={el} index={i} />
                          )}
                          {variant.kind === "BIG_BLOCK_WITHOUT_IMAGE" && (
                            <Card2 el={el} index={i} />
                          )}
                          {variant.kind === "COLOR_PICKER" && <Card3 el={el} />}
                          {variant.kind === "SMALL_BLOCK_WITH_IMAGE" && (
                            <Card4 el={el} index={i} />
                          )}
                          {variant.kind === "MINI_BLOCK_WITH_IMAGE" && (
                            <Card5 el={el} index={i} />
                          )}
                          {/* kind TRACK_TYPE */}
                          {variant.kind === "TRACK_TYPE" && (
                            <TrackTypeOptionCard el={el} index={i} />
                          )}
                          {/* <div>
                          {errors?.stages?.[idx]?.variants?.[vidx]?.options &&
                            errors?.stages?.[idx]?.variants?.[
                              vidx
                            ]?.options.map((each: any, i: any) => (
                              <div className="text-xs text-red-700" key={i}>
                                {each[0] + " : " + JSON.stringify(each[1])}
                              </div>
                            ))}
                        </div> */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          <div className="mt-6">
            <Button
              onClick={() => {
                setStageSelectIndex(index);
                setFormPage(0);
                clearVariantErrors(index);
              }}
              variant="secondary-text"
              leftIcon={<PlusIcon />}
              label="Add a variant"
            />
          </div>
        </>
      )}
    </div>
  );
};
