import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { useSticky } from "react-table-sticky";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import ManagementTableLayout from "../../../components/MangementTable";
import ShowEntries from "../../../components/ShowEntries";
import Pagination from "../../../components/Pagination";
import PlusIcon from "../../../assets/PlusIcon";
import EditIcon from "../../../assets/EditIcon";
import TrashIcon from "../../../assets/TrashIcon";
import loadingSpinner from "../../../assets/svg/spinner.svg";
import activeStatus from "../../../assets/svg/activestatus.svg";
import DeActiveStatus from "../../../assets/svg/deactivatestatus.svg";
import {
  deleteProductCategory,
  getProductCategory,
  updateProductCategory,
} from "../../../api/product_category";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRecoilState, useSetRecoilState } from "recoil";
import { categoryDataCount, productCategoryAtom } from "../../../atom";
import CrossIcon from "../../../assets/CrossIcon";
import Pagination2 from "../../../components/Pagination";
import Modal from "../../../components/common/Modal";
import _debounce from "lodash/debounce";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import Warning from "../../../assets/svg-tsx/Warning";
const Table = () => {
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [totalCount, setTotalCount] = useState<number>(100);
  const [page, setPage] = useState(1);
  const [pageLength, setPageLength] = useState<number>(25);
  const [startIndex, setStartIndex] = useState(0);
  const [deleteModal, setDeleteModal] = useState<any>("");
  const [categoryCount, setCategoryCount] = useRecoilState(categoryDataCount);
  const setProductCategoryState = useSetRecoilState(productCategoryAtom);

  const fetchProductCategory = async () => {
    setLoading(true);
    await getProductCategory({
      page: page,
      length: pageLength,
      search: inputVal,
    })
      .then((res) => {
        const { category, pagination } = res.data;
        setCategoryData(category);
        setCategoryCount(pagination.total_count);
        setTotalCount(pagination.total_count);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const [time, setTime] = useState(0);
  const debouncedFetchCategoryData = _debounce(fetchProductCategory, time);
  useEffect(() => {
    setTime(500);
    setPage(1);
    const fetchData = async () => {
      await debouncedFetchCategoryData();
    };
    fetchData();
    return () => {
      debouncedFetchCategoryData.cancel();
    };
  }, [inputVal, pageLength]);

  useEffect(() => {
    setTime(0);
    const fetchData = async () => {
      await debouncedFetchCategoryData();
    };
    fetchData();
    return () => {
      debouncedFetchCategoryData.cancel();
    };
  }, [page]);

  const handleDeleteCategory = async (id: any) => {
    try {
      await protectedAxiosInstance.delete(`/admin/products/category/${id}`);
      let settablePage: any;
      if ((totalCount - 1) % pageLength === 0) {
        settablePage = page - 1;
      } else {
        settablePage = page;
      }
      setPage(settablePage === 0 ? 1 : settablePage);
      setDeleteModal("");
      toast.success("Category deleted successfully");
      setProductCategoryState([]);
      fetchProductCategory();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting category");
    }
  };

  const handleToggleStatus = (id: any, data: any) => {
    const payload = {
      name: data.name,
      description: data.description,
      image: data.image,
      series_id: data?.series?.id,
      is_active: !data.is_active,
    };

    updateProductCategory(id, payload)
      .then(() => {
        return getProductCategory({ page: page, length: pageLength });
      })
      .then((res) => {
        setCategoryData(res.data.category);
        toast.success("Status updated successfully");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to update status");
      });
  };

  const COLUMN = [
    {
      id: "sr",
      Header: () => <p className="whitespace-nowrap">Sr. No.</p>,
      width: "80px",
      accessor: "sr",
      Cell: ({ row }: { row: any }) => <p>{startIndex + row.index + 1}</p>,
    },
    {
      id: "name",
      width: "250px",
      Header: () => <p className="whitespace-nowrap">Category Name</p>,
      accessor: "name",
      Cell: ({ value }: { value: any }) => (
        <p className="line-clamp-1" title={value}>
          {value}
        </p>
      ),
    },
    {
      id: "slug",
      width: "250px",
      Header: () => <p className="whitespace-nowrap">Category Slug</p>,
      accessor: "slug",
      Cell: ({ value }: { value: any }) => (
        <p className="line-clamp-1" title={value}>
          {value}
        </p>
      ),
    },
    {
      id: "image",
      Header: () => <div>Picture</div>,
      accessor: "image",
      width: "80px",
      Cell: ({ row }: { row: any }) => (
        <div className="font-semibold w-10 h-10 m-auto">
          <img
            src={process.env.REACT_APP_BUCKET + "/" + row.original.image} //eslint-disable-line
            alt={row.original.name}
            className="w-full h-full object-contain"
          />
        </div>
      ),
    },
    {
      id: "product_count",
      Header: () => (
        <p className="whitespace-nowrap">Total products assigned</p>
      ),
      accessor: "product_count",
      width: "200px",
      Cell: ({ cell }: { cell: any }) => (
        <div className="px-2 ">{cell.value}</div>
      ),
    },
    {
      id: "is_active",
      Header: () => <p>Actions</p>,
      accessor: "is_active",
      width: "150px",
      Cell: ({ row }: { row: any }) => (
        <div className="flex gap-x-5 px-2 items-center">
          <Link
            to={`/products/categories/${row.original.id}`}
            className="cursor-pointer"
          >
            <EditIcon />
          </Link>
          <div
            onClick={() => setDeleteModal(row.original.id)}
            className="cursor-pointer"
          >
            <TrashIcon />
          </div>
          <div
            onClick={() => {
              setCategoryData((prevData: any) =>
                prevData.map((product: any) => {
                  if (product.id === row.original.id) {
                    return {
                      ...product,
                      is_active: !row.original.is_active,
                    };
                  }
                  return product;
                })
              );
              handleToggleStatus(row.original.id, row.original);
            }}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white h-8 w-12"
          >
            {row.original.is_active ? (
              <img
                src={activeStatus}
                alt="status-icon"
                className="w-full object-contain h-full"
              />
            ) : (
              <img
                src={DeActiveStatus}
                alt="status-icon"
                className="w-full object-contain h-full"
              />
            )}
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const lastIndex = (page - 1) * pageLength;
    setStartIndex(lastIndex);
  }, [page, pageLength, totalCount]);

  const columns = useMemo(() => COLUMN, [categoryData]);
  const data = useMemo(() => categoryData || [], [categoryData]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
      useSticky,
    },
    useSortBy,
    usePagination
  );
  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };
  return (
    <div className="h-full">
      <div className="flex flex-row justify-between items-center py-2 w-full px-3 h-[3rem] ">
        <div className="flex gap-4 w-[max-content]">
          <div className=" w-[235px]">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Enter Something"
              value={inputVal}
              rightIcon={<SearchIcon />}
            />
          </div>
          <div className="w-fit">
            <Link to={"/products/categories/create"}>
              <Button label="Create Category" leftIcon={<PlusIcon />} />
            </Link>
          </div>
        </div>
      </div>

      <div className="h-[calc(100%-5.5rem)] relative -z-0 ">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>

      {loading ? (
        ""
      ) : (
        <div className="w-full">
          <Pagination2
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}

      {deleteModal && (
        <Modal
          top
          header="Delete Category"
          handleCancel={() => setDeleteModal("")}
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => handleDeleteCategory(deleteModal)}
              />
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setDeleteModal("");
                }}
              />
            </div>
          }
        >
          <div className="flex flex-col gap-1">
            <p className="text-left">
              Are you sure you want to delete this category?
            </p>
            {/* <div className="flex gap-2 text-red-600 text-sm items-center">
              <Warning size="14" />
              <p>
                All the products associated with this category will be deleted.
              </p>
            </div> */}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Table;
