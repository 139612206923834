import { useState } from "react";
import BrandsCounts from "./brandcomponents/BrandsCounts";
import BrandsTable from "./brandcomponents/BrandsTable";

export default function BrandsList() {
  const [count, setCount] = useState(0);

  return (
    <div className="h-full flex flex-col justify-start items-start">
      <div className="h-[13vh] w-full flex items-center justify-start">
        <BrandsCounts count={count} />
      </div>
      <div className="h-[calc(100%-13vh)] w-full">
        <BrandsTable setCount={setCount} />
      </div>
    </div>
  );
}
