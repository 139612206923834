import { useState } from "react";
import ProjectCard from "./ProjectCard";
import DocumentIcon from "../../../assets/DocumentIcon";

const ProjectCounts = ({ projectCount }: any) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    // setIsHovered(true);
  };

  const handleMouseLeave = () => {
    // setIsHovered(false);
  };

  const cardValues = [
    {
      count: projectCount,
      icon: <DocumentIcon color={!isHovered ? "#C3E1F8" : "#ffffff"} />,
      color: "#C3E1F8",
      title: "Total projects",
    },
    // {
    //   count: 0,
    //   icon: <DocumentIcon color={!isHovered ? "#C3E1F8" : "#ffffff"} />,
    //   color: "#AEAEAE",
    //   title: "Professional",
    // },
    // {
    //   count: 0,
    //   icon: <DocumentIcon color={!isHovered ? "#C3E1F8" : "#ffffff"} />,
    //   color: "#AEAEAE",
    //   title: "Customer",
    // },
    // {
    //   count: 0,
    //   icon: <DocumentIcon color={!isHovered ? "#C3E1F8" : "#ffffff"} />,
    //   color: "#AEAEAE",
    //   title: "Hidden",
    // },
    // {
    //   count: 0,
    //   icon: <DocumentIcon color={!isHovered ? "#C3E1F8" : "#ffffff"} />,
    //   color: "#AEAEAE",
    //   title: "Visible",
    // },
  ];

  return (
    <div className="w-full flex  gap-x-6 px-4 py-2">
      {cardValues.map((each, index) => (
        <ProjectCard
          key={index}
          {...each}
          setIsHovered={setIsHovered}
          isHovered={isHovered}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      ))}
    </div>
  );
};

export default ProjectCounts;
