import React, { useState, useMemo } from "react";
import InputField from "../../../components/InputField";

import Button from "../../../components/Button";

import { v4 as uuidv4 } from "uuid";

import {
  VariantBigBlockWithImageProps,
  VariantDataProps,
} from "../../../types";
import { validatePrice } from "../../../utils/validations";
import ImageUpload from "../../../components/ImageUpload";
import MultipleInputFieldTwo from "../components/MultipleInputFieldTwo";
import Modal from "../../../components/common/Modal";
import { removeExtraSpacesfromObject } from "../../../utils/helper";

type BigBlockWithImgPopupProps = {
  handleClose: () => void;
  setVariantData: React.Dispatch<React.SetStateAction<VariantDataProps>>;
  data?: VariantBigBlockWithImageProps | undefined;
  task?: "copy" | "edit";
};

// BIG_BLOCK_WITH_IMAGE Option Schema
// {
//   "name": "Option #1",
//   "image": "products/bd0b7d82-24b4-46cc-a19b-57cc12a41368.jpg",
//   "product_image": "products/bd0b7d82-24b4-46cc-a19b-57cc12a41368.jpg",
//   "price": 20,
//   "code": "A354",
//   "properties": {
//     "key": "value"
//   }
// }

const initState = {
  name: "",
  image: "",
  product_image: "",
  code: null,
  price: 0,
  properties: {},
  type: "BIG_BLOCK_WITH_IMAGE",
};

export default function BigBlockWithImgPopup({
  handleClose,
  setVariantData,
  data = initState,
  task,
}: BigBlockWithImgPopupProps) {
  const [bigBlockWithImgData, setBigBlockWithImgData] =
    useState<VariantBigBlockWithImageProps>(data);

  const [errorMessage, setErrorMessage] = useState<any>({
    name: [],
    image: [],
    product_image: [],
    code: [],
    price: [],
  });

  const handleSubmitData = () => {
    if (!bigBlockWithImgData?.name || !bigBlockWithImgData?.image) {
      if (!bigBlockWithImgData?.name) {
        setErrorMessage((e: any) => ({
          ...e,
          name: ["Please enter the name."],
        }));
      }
      if (!bigBlockWithImgData?.image) {
        setErrorMessage((e: any) => ({
          ...e,
          image: ["Please select an image."],
        }));
      }
      return;
    }

    if (bigBlockWithImgData.properties) {
      const entries = Object.entries(bigBlockWithImgData.properties).filter(
        ([key, value]) => !!key
      );
      if (entries.length) {
        bigBlockWithImgData.properties = Object.fromEntries(entries);
      }
    }

    const clone: any = removeExtraSpacesfromObject(
      structuredClone(bigBlockWithImgData)
    );

    if (
      clone.price === "" ||
      (clone.price && +clone.price == 0) ||
      !clone.price
    ) {
      clone.price = "0";
    }
    if (clone.price) {
      clone.price = parseFloat(clone.price);
    }

    if (data.id && task !== "copy") {
      // Edit Option Functionality
      setVariantData((prev) => ({
        ...prev,
        options: prev.options.map((el: any) =>
          el.id === data.id ? clone : el
        ),
      }));
    } else {
      // Add Option Functionality
      const copyID = data.id;
      const newData = { ...clone, id: uuidv4(), added: true };
      setVariantData((prev) => {
        if (task === "copy") {
          const findIndex = prev.options.findIndex(
            (option) => option?.id === copyID
          );
          const updatedOptions = [...prev.options];
          updatedOptions.splice(findIndex + 1, 0, newData);
          return {
            ...prev,
            options: updatedOptions,
          };
        } else {
          const deleteIndex = prev.options.findIndex(
            (option) => option?.to_delete
          );

          if (deleteIndex === -1) {
            return {
              ...prev,
              options: [...prev.options, newData],
            };
          }

          const updatedOptions = [...prev.options];
          updatedOptions.splice(deleteIndex, 0, newData);
          return {
            ...prev,
            options: updatedOptions,
          };
        }
      });
    }
    setBigBlockWithImgData(initState);
    handleClose();
  };

  const imageList = useMemo(
    () => (bigBlockWithImgData.image ? [bigBlockWithImgData.image] : []),
    [bigBlockWithImgData.image]
  );

  const productImageList = useMemo(
    () =>
      bigBlockWithImgData.product_image
        ? [bigBlockWithImgData.product_image]
        : [],
    [bigBlockWithImgData.product_image]
  );
  return (
    <Modal
      allowScroll
      header="Add an Option"
      footer={
        <Button
          label={
            task === "copy"
              ? "Add Option"
              : bigBlockWithImgData.id
              ? "Update"
              : "Add Option"
          }
          variant="secondary"
          onClick={handleSubmitData}
        />
      }
      handleCancel={handleClose}
    >
      <div className="w-full h-full flex flex-col gap-3">
        {/* code */}
        <InputField
          onChange={(e) => {
            setBigBlockWithImgData((prevData) => ({
              ...prevData,
              code: e?.toUpperCase(), // Set code to null if empty, otherwise to uppercase value
            }));

            setErrorMessage((e: any) => ({ ...e, code: [] }));
          }}
          value={bigBlockWithImgData?.code}
          placeholder="Code"
          label="Code:"
          hint={errorMessage?.code}
          maxLength={10}
        />

        {/* name */}
        <InputField
          onChange={(e) => {
            setBigBlockWithImgData((prevData) => ({
              ...prevData,
              name: e,
            }));
            setErrorMessage((e: any) => ({ ...e, name: [] }));
          }}
          value={bigBlockWithImgData?.name}
          placeholder="Name"
          label="Name:"
          required
          hint={errorMessage?.name}
        />
        {/* price */}
        <InputField
          onChange={(e: any) => {
            if (e > 99999) {
              setErrorMessage((prevError: any) => ({
                ...prevError,
                price: ["Price should be less than or equal to 99999."],
              }));
            } else {
              setBigBlockWithImgData((prevData) => ({
                ...prevData,
                price: e,
              }));
              setErrorMessage((prevError: any) => ({
                ...prevError,
                price: [],
              }));
            }
          }}
          validate={validatePrice}
          value={bigBlockWithImgData.price}
          placeholder="Price"
          label="Price:"
          type="price"
          hint={errorMessage.price}
        />

        {/* image */}
        <ImageUpload
          required
          label="Upload image:"
          imageList={imageList}
          setter={(value) => {
            setBigBlockWithImgData((prev) => ({
              ...prev,
              image: value as string,
            }));
          }}
          kind="PRODUCT"
          errors={errorMessage?.image}
          removeError={() => setErrorMessage((e: any) => ({ ...e, image: [] }))}
        />

        {/*properties */}
        <MultipleInputFieldTwo
          properties={bigBlockWithImgData?.properties}
          setFormData={setBigBlockWithImgData}
        />

        {/* product_image */}
        <ImageUpload
          label="Upload Product Image:"
          imageList={productImageList}
          setter={(value) => {
            setBigBlockWithImgData((prev) => ({
              ...prev,
              product_image: value as string,
            }));
          }}
          kind="PRODUCT"
          errors={errorMessage?.product_image}
          removeError={() =>
            setErrorMessage((e: any) => ({ ...e, product_image: [] }))
          }
        />
      </div>
    </Modal>
  );
}
