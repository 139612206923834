import React from "react";

interface JPGreviewIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const JPGPreviewIcon = ({
  width = "20",
  height = "20",
  color = "#AEAEAE",
}: JPGreviewIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.48901 14.51C5.81567 14.8366 6.20817 15 6.66651 15H16.6665C17.1243 15.0005 17.5165 14.8375 17.8432 14.5108C18.1698 14.1841 18.3332 13.7916 18.3332 13.3333V3.3333C18.3337 2.87552 18.1707 2.4833 17.844 2.15663C17.5173 1.82996 17.1248 1.66663 16.6665 1.66663H6.66651C6.20873 1.66607 5.81651 1.82913 5.48984 2.1558C5.16317 2.48246 4.99984 2.87496 4.99984 3.3333V13.3333C4.99928 13.7911 5.16234 14.1833 5.48901 14.51ZM16.6665 13.3333H6.66651V3.3333H16.6665V13.3333ZM2.15567 17.8433C2.48234 18.17 2.87484 18.3333 3.33317 18.3333H14.9998V16.6666H3.33317V4.99996H1.66651V16.6666C1.66595 17.1244 1.82901 17.5166 2.15567 17.8433Z"
        fill={color}
      />
      <path
        d="M9.48234 10.044C9.17568 10.044 8.93168 9.96133 8.75034 9.796C8.57168 9.628 8.48234 9.40267 8.48234 9.12H9.08234C9.08234 9.24267 9.11834 9.34133 9.19034 9.416C9.26234 9.488 9.35968 9.524 9.48234 9.524C9.60501 9.524 9.70234 9.48933 9.77434 9.42C9.84634 9.348 9.88234 9.25067 9.88234 9.128V7.64H9.22234V7.08H10.4823V9.128C10.4823 9.41067 10.393 9.63467 10.2143 9.8C10.0357 9.96267 9.79168 10.044 9.48234 10.044ZM11.0648 10V7.08H12.0608C12.2661 7.08 12.4448 7.11867 12.5968 7.196C12.7514 7.27333 12.8701 7.38133 12.9528 7.52C13.0381 7.65867 13.0808 7.82133 13.0808 8.008C13.0808 8.192 13.0381 8.35467 12.9528 8.496C12.8674 8.63467 12.7488 8.74267 12.5968 8.82C12.4448 8.89733 12.2661 8.936 12.0608 8.936H11.6648V10H11.0648ZM11.6648 8.396H12.0608C12.1888 8.396 12.2901 8.36133 12.3648 8.292C12.4421 8.22 12.4808 8.12533 12.4808 8.008C12.4808 7.89067 12.4421 7.79733 12.3648 7.728C12.2901 7.656 12.1888 7.62 12.0608 7.62H11.6648V8.396ZM14.4152 10.04C14.2206 10.04 14.0499 10.004 13.9032 9.932C13.7566 9.85733 13.6419 9.75467 13.5592 9.624C13.4792 9.49067 13.4392 9.336 13.4392 9.16V7.92C13.4392 7.74133 13.4792 7.58667 13.5592 7.456C13.6419 7.32533 13.7566 7.224 13.9032 7.152C14.0499 7.07733 14.2206 7.04 14.4152 7.04C14.6126 7.04 14.7832 7.07733 14.9272 7.152C15.0739 7.224 15.1872 7.32533 15.2672 7.456C15.3499 7.58667 15.3912 7.74133 15.3912 7.92H14.7912C14.7912 7.80267 14.7579 7.71333 14.6912 7.652C14.6272 7.59067 14.5352 7.56 14.4152 7.56C14.2952 7.56 14.2019 7.59067 14.1352 7.652C14.0712 7.71333 14.0392 7.80267 14.0392 7.92V9.16C14.0392 9.27467 14.0712 9.364 14.1352 9.428C14.2019 9.48933 14.2952 9.52 14.4152 9.52C14.5352 9.52 14.6272 9.48933 14.6912 9.428C14.7579 9.364 14.7912 9.27467 14.7912 9.16V8.908H14.3592V8.4H15.3912V9.16C15.3912 9.336 15.3499 9.49067 15.2672 9.624C15.1872 9.75467 15.0739 9.85733 14.9272 9.932C14.7832 10.004 14.6126 10.04 14.4152 10.04Z"
        fill={color}
      />
    </svg>
  );
};

export default JPGPreviewIcon;
