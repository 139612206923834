type HipchatChevronRightIconProps = {
  color?: string;
  width?: string;
};
function HipchatChevronRightIcon({
  color,
  width,
}: HipchatChevronRightIconProps) {
  return (
    <svg
      width={width || "32"}
      height={width || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="white" fillOpacity="0.01" />
      <rect
        width="24"
        height="24"
        transform="translate(4 4)"
        fill="white"
        fillOpacity="0.01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9949 14.9949C19.1824 15.1825 19.2877 15.4368 19.2877 15.7019C19.2877 15.9671 19.1824 16.2214 18.9949 16.4089L14.4019 21.0019C14.2156 21.1845 13.9648 21.2862 13.704 21.2849C13.4432 21.2835 13.1934 21.1793 13.009 20.9949C12.8245 20.8105 12.7203 20.5607 12.719 20.2999C12.7177 20.039 12.8194 19.7882 13.0019 19.6019L16.9019 15.7019L13.0019 11.8019C12.8194 11.6157 12.7177 11.3648 12.719 11.104C12.7203 10.8432 12.8245 10.5934 13.009 10.409C13.1934 10.2245 13.4432 10.1203 13.704 10.119C13.9648 10.1177 14.2156 10.2194 14.4019 10.4019L18.9949 14.9949Z"
        fill={color || "black"}
      />
    </svg>
  );
}

export default HipchatChevronRightIcon;
