import React from "react";
import { IconProp } from "./ArrowIcons";

const StarIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="10"
      height="10"
      fill={color || "currentColor"}
      viewBox="0 0 24 24"
    >
      <path d="M22.425 9.131a1.537 1.537 0 0 0-1.369-1.069l-5.569-.384-2.062-5.203A1.537 1.537 0 0 0 12 1.5a1.538 1.538 0 0 0-1.425.975l-2.1 5.231-5.531.356a1.547 1.547 0 0 0-1.369 1.07 1.575 1.575 0 0 0 .488 1.696l4.256 3.6-1.266 4.978a1.734 1.734 0 0 0 .656 1.838 1.688 1.688 0 0 0 1.885.056l4.397-2.784h.018l4.735 2.99c.243.158.526.243.815.244a1.546 1.546 0 0 0 1.482-1.95L17.7 14.353l4.238-3.525a1.575 1.575 0 0 0 .487-1.697Z"></path>
    </svg>
  );
};

export default StarIcon;
