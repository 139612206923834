import { useEffect, useRef, useState } from "react";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import { toast } from "react-toastify";
import {
  formatDate1,
  formatFileSize,
  getCurrentTimeISO,
  truncateFilename,
} from "../../../utils/helper";
import { useDropzone } from "react-dropzone";
import ErrorMessage from "../../../components/ErrorMessage";
import FileIcon from "../../../assets/svg-tsx/FileIcon";
import TrashIcon from "../../../assets/TrashIcon";
import UploadIcon from "../../../assets/UploadIcon";
import LoadingSpinner from "../../../assets/images/Spinner4.gif";
import useOnClickOutside from "../../../hooks/useClickOutside";
import DownloadIcon from "../../../assets/svg-tsx/DownloadIcon";
import EyeIcon from "../../../assets/svg-tsx/EyeIcon";
import PlayIcon from "../../../assets/svg-tsx/PlayIcon";
import WatchIcon from "../../../assets/svg-tsx/WatchIcon";

type ImageUploadPropType = {
  isMulti?: boolean;
  kind: "PROJECT";
  setter: (values: any[]) => void;
  imageList: string[];
  errors?: string[];
  removeError?: () => void;
  disabled?: boolean;
  required?: boolean;
  label?: string | undefined;
  targetRef?: any;
  setMediaLoading?: any;
};

/**
 *
 * for imageList in single upload memoize it with an array because it only accept as an array
 * ie. const imageList = useMemo(() => [image], [image])
 */
const ProjectMediaUpload = ({
  isMulti = false,
  kind,
  setter,
  imageList,
  errors = [],
  removeError = () => {},
  disabled = false,
  required = false,
  label,
  targetRef,
  setMediaLoading,
}: ImageUploadPropType) => {
  const [mouseOnImage, setMouseOnImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const projectMediaRef = useRef<any>(null);
  useOnClickOutside(projectMediaRef, () => {
    if (arrange) {
      let currentArrangeOrder = arrangeOrder;

      const updatedFiles = imageList
        .map((el: any) => {
          if (el?.order === null) {
            const updatedEl = {
              ...el,
              order: currentArrangeOrder,
            };
            currentArrangeOrder += 1; // Increment arrangeOrder for the next item
            return updatedEl;
          }
          return el;
        })
        .sort((a, b) => a.order - b.order);

      setter(updatedFiles);
      setArrangeOrder(1);
      setArrange(false);
    }
  });
  const [arrange, setArrange] = useState(false);
  const [arrangeOrder, setArrangeOrder] = useState(1);
  const fileInputRef = useRef<any>(null);

  const get_signed = async (extension: string) => {
    const payload = {
      extension,
      kind,
    };
    const response = protectedAxiosInstance
      .post(`/admin/projects/generate-upload-url`, payload)
      .then((res: any) => res?.data?.data)
      .catch((res: any) => {
        return res?.data?.errors;
      });
    return response;
  };

  const signed_to_s3 = async (acceptedFile: File) => {
    const extension = acceptedFile.name.split(".").at(-1)?.toLowerCase();
    const { file_path, upload_url } = await get_signed(extension as string);

    const file = new FormData();
    file.append("file", acceptedFile);

    let contentType: string;

    if (extension === "jpeg" || extension === "jpg") {
      contentType = "image/jpeg";
    } else if (extension === "png") {
      contentType = "image/png";
    } else if (extension === "webp") {
      contentType = "image/webp";
    } else if (extension === "mp4") {
      contentType = "video/mp4";
    } else {
      contentType = "";
    }

    const response = await fetch(upload_url, {
      method: "PUT",
      body: acceptedFile,
      headers: {
        "Content-Type": contentType,
      },
    });

    return { response, file_path };
  };

  const onDrop = async (acceptedFiles: File[], rejectedFiles: any) => {
    removeError();
    if (
      rejectedFiles.some(
        (el: any) =>
          el.errors &&
          el.errors.some((error: any) => error.code === "file-too-large")
      )
    ) {
      toast.error("File size exceeded.");
    }
    if (
      rejectedFiles.some(
        (el: any) =>
          el.errors &&
          el.errors.some((error: any) => error.code === "file-invalid-type")
      )
    ) {
      toast.error(
        "Invalid file format selected. Only .jpg, .jpeg, .webp, .png, .mp4 are accepted."
      );
    }

    if (acceptedFiles.length === 0) {
      return;
    }
    setUploading(true);
    setMediaLoading(true);
    if (!acceptedFiles.length) return;

    const pathList = await Promise.all(
      acceptedFiles.map(async (acceptedFile) => {
        const { response, file_path } = await signed_to_s3(acceptedFile);
        return file_path;
      })
    );

    const insertIndex = imageList.findIndex(
      (item: any) => item?.to_delete === true
    );
    const order = insertIndex !== -1 ? insertIndex : imageList?.length || 0;

    const transformedPathList = pathList.map((file, index) => ({
      file: file,
      order: order + index + 1,
      filename: acceptedFiles[index]?.name,
      file_size: (acceptedFiles[index]?.size / 1024).toFixed(2),
      added: true,
      created_at: getCurrentTimeISO(),
    }));

    let updatedFiles;
    if (insertIndex !== -1) {
      // Insert the new items before the first 'to_delete' item
      updatedFiles = [
        ...imageList.slice(0, insertIndex),
        ...transformedPathList,
        ...imageList.slice(insertIndex),
      ];
    } else {
      // If no 'to_delete' item is found, simply append the new items
      updatedFiles = [...(imageList || []), ...transformedPathList];
    }

    // Update order for existing items in imageList
    updatedFiles = updatedFiles.map((item: any, index) => ({
      ...item,
      order: index + 1,
    }));

    setter(updatedFiles);
    setUploading(false);
    setMediaLoading(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleRemove = (each: any, index: number) => {
    if (each?.added === true) {
      const updatedFiles = imageList
        .filter((el: any) => el?.file !== each?.file)
        .map((el: any, idx) => ({
          ...el,
          order: idx + 1,
        }));
      setter(updatedFiles);
    } else {
      // Update the 'to_delete' property to true
      const updatedFiles = imageList
        .map((el: any, idx) => {
          if (el?.file === each?.file) {
            return {
              ...el,
              to_delete: true,
            };
          } else if (each?.order < el?.order) {
            return {
              ...el,
              order: el?.order - 1,
            };
          }
          return el;
        })
        .filter((el: any) => el?.file !== each?.file); // Filter out the element to be moved

      // Append the element to the end
      updatedFiles.push({
        ...each,
        order: imageList?.length,
        to_delete: true,
      });

      setter(updatedFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      ".jpg": [],
      ".jpeg": [],
      ".webp": [],
      ".png": [],
      ".mp4": [],
    },
    onDrop,

    maxSize: 20 * 1024 * 1024,
  });

  const findExtension = (file: any) => {
    const extension = file?.split(".")?.pop()?.toLowerCase();
    return extension;
  };

  useEffect(() => {
    if (
      arrangeOrder >= 11 ||
      (arrangeOrder > imageList?.length && imageList?.length <= 10)
    ) {
      setArrange(false);
      let currentArrangeOrder = arrangeOrder;
      const updatedFiles = imageList
        .map((el: any) => {
          if (el?.order === null) {
            const updatedEl = {
              ...el,
              order: currentArrangeOrder,
            };
            currentArrangeOrder += 1; // Increment arrangeOrder for the next item
            return updatedEl;
          }
          return el;
        })
        .sort((a, b) => a.order - b.order);
      setter(updatedFiles);
    }
  }, [arrangeOrder]);

  return (
    <div ref={targetRef} className="min-h-[140px]">
      <div ref={projectMediaRef}>
        {label && (
          <div className="flex items-center justify-between text-center pb-1 gap-2">
            <h4 className="text-xs font-bold font-gilroy-regular">
              {label}
              {required && <span className="text-red-500 text-xs ml-1">*</span>}
            </h4>
            {imageList?.filter((el: any) => !el?.to_delete)?.length > 1 &&
              !disabled && (
                <>
                  {!arrange && (
                    <p
                      className="cursor-pointer text-xs font-bold font-gilroy-regular text-pot-yellow"
                      onClick={() => {
                        setArrange(true);
                        const updatedFiles = imageList.map((el: any) => ({
                          ...el,
                          order: null,
                        }));
                        setter(updatedFiles);
                        setArrangeOrder(1);
                      }}
                    >
                      Arrange
                    </p>
                  )}
                  {arrange && (
                    <p
                      className="cursor-pointer text-xs font-bold font-gilroy-regular text-pot-yellow"
                      onClick={() => {
                        let currentArrangeOrder = arrangeOrder;

                        const updatedFiles = imageList
                          .map((el: any) => {
                            if (el?.order === null) {
                              const updatedEl = {
                                ...el,
                                order: currentArrangeOrder,
                              };
                              currentArrangeOrder += 1; // Increment arrangeOrder for the next item
                              return updatedEl;
                            }
                            return el;
                          })
                          .sort((a, b) => a.order - b.order);

                        setter(updatedFiles);
                        setArrangeOrder(1);
                        setArrange(false);
                      }}
                    >
                      Save
                    </p>
                  )}
                </>
              )}
          </div>
        )}

        <div
          {...getRootProps()}
          className={`flex items-center justify-center w-full mb-2 ${
            disabled || uploading ? " opacity-50" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <label
            className={`flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed  bg-white  ${
              disabled || uploading
                ? "cursor-not-allowed"
                : "cursor-pointer hover:bg-gray-100"
            }`}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <UploadIcon color="#D4A15E" />
              <p className="mb-2 text-sm text-gray-500">
                Drag & Drop or{" "}
                <span className="font-gilroy-bold text-pot-yellow">Choose</span>{" "}
                file to upload{" "}
              </p>
              <p className="text-xs text-gray-500 text-center">
                Upload JPEG, JPG, PNG, WEBP, MP4 file (Max file size: 20 MB)
              </p>
            </div>
            <input
              {...getInputProps({ disabled: disabled || uploading })}
              ref={fileInputRef}
            />
          </label>
        </div>

        <ErrorMessage error={errors} />

        {(imageList?.filter((each: any) => !each?.to_delete)?.length > 0 ||
          uploading) && (
          <div className="p-2 my-2 border-2 border-c-gray-2">
            {arrange && (
              <p className="font-gilroy-semi-bold font-bold py-1 text-black text-sm">
                Rearrange Images & Videos
              </p>
            )}

            <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 w-full gap-3 ">
              {imageList
                ?.filter((each: any) => !each?.to_delete)
                ?.map((each: any, index: number) => {
                  return (
                    <div
                      key={each?.file + each?.index}
                      className={`flex flex-col relative`}
                      onMouseEnter={() => setMouseOnImage(each?.file)}
                      onMouseLeave={() => setMouseOnImage("")}
                      onClick={(e) => {
                        e?.stopPropagation();
                        if (arrange) {
                          if (each?.order) {
                            const updatedFiles = imageList?.map((el: any) => {
                              if (el?.file === each?.file) {
                                return { ...el, order: null };
                              } else if (each?.order < el?.order) {
                                return { ...el, order: el.order - 1 };
                              }
                              return el;
                            });

                            setter(updatedFiles);
                            setArrangeOrder(arrangeOrder - 1);
                          } else {
                            const updatedFiles = imageList?.map((el: any) =>
                              el?.file === each?.file
                                ? { ...el, order: arrangeOrder }
                                : el
                            );
                            setter(updatedFiles);
                            setArrangeOrder(arrangeOrder + 1);
                          }
                        }
                      }}
                    >
                      <ImageVideoComponent
                        each={each}
                        arrange={arrange}
                        handleRemove={handleRemove}
                        index={index}
                        mouseOnImage={mouseOnImage}
                        findExtension={findExtension}
                      />
                      <div
                        className="flex space-x-2 items-center py-2"
                        title={each?.filename || "-"}
                      >
                        <div>
                          <FileIcon color="#242424" />
                        </div>
                        {/* <ToolTip label={each?.filename} position="top"> */}
                        <div>
                          <p className="text-xs text-pot-black font-gilroy-medium font-bold">
                            {each?.filename
                              ? truncateFilename(each?.filename, 10)
                              : "-"}
                          </p>
                          <div className="flex items-center gap-1 text-[8px] text-pot-black font-gilroy-regular">
                            <p>
                              {each?.file_size
                                ? formatFileSize(each?.file_size, "KB")
                                : "-"}
                            </p>
                            <p>|</p>
                            <p>
                              {each?.created_at
                                ? formatDate1(each?.created_at)
                                : "-"}
                            </p>
                          </div>
                        </div>
                        {/* </ToolTip> */}
                      </div>
                    </div>
                  );
                })}

              {uploading && (
                <div className="flex justify-center items-center border-2 border-x-c-gray-2 cursor-pointer py-14">
                  <img alt="" width={100} height={100} src={LoadingSpinner} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectMediaUpload;

const ImageVideoComponent = ({
  each,
  arrange,
  handleRemove,
  index,
  mouseOnImage,
  findExtension,
}: any) => {
  return (
    <>
      <div className="absolute top-2 left-2 z-[1]">
        {each?.order <= 10 && (
          <p className="w-5 h-5 bg-white text-black border-2 border-gray-350/100 rounded-full flex items-center justify-center text-[10px]">
            {each?.order}
          </p>
        )}
      </div>
      {!arrange && (
        <div className="absolute top-2 right-2 z-[1] flex items-center space-x-2">
          <button onClick={() => handleRemove(each, index)}>
            <TrashIcon width="15" height="15" color={`gray`} />
          </button>
          {!each?.added && (
            <a
              href={`${each?.download_url}`}
              download
              rel="noopener noreferrer"
              target="_blank"
            >
              <DownloadIcon color="gray" />
            </a>
          )}

          <a
            href={`${process.env.REACT_APP_BUCKET}/${each?.file}`} //eslint-disable-line
            target="_blank"
            rel="noreferrer"
          >
            {findExtension(each?.file) === "mp4" ? (
              <PlayIcon color="gray" />
            ) : (
              <WatchIcon color="gray" />
            )}
          </a>
        </div>
      )}

      <div
        className={`relative w-[100%] pb-[100%]  ${
          mouseOnImage === each?.file ? "opacity-95" : "opacity-50"
        }`}
      >
        {findExtension(each?.file) === "jpg" ||
        findExtension(each?.file) === "jpeg" ||
        findExtension(each?.file) === "png" ||
        findExtension(each?.file) === "webp" ? (
          <img
            src={`${process.env.REACT_APP_BUCKET}/${each?.file}`} //eslint-disable-line
            alt="picture"
            className="object-contain w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
            width={100}
            height={100}
          />
        ) : (
          <video
            muted
            playsInline
            className="object-contain w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
          >
            <source
              src={`${process.env.REACT_APP_BUCKET}/${each?.file}`} //eslint-disable-line
              type="video/mp4"
              width={100}
              height={100}
            />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </>
  );
};
