import HipchatChevronLeftIcon from "../assets/HipchatChevronLeftIcon";
import HipchatChevronRightIcon from "../assets/HipchatChevronRightIcon";

import React from "react";

type Pagination2Props = {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
};

const Pagination: React.FC<Pagination2Props> = ({
  currentPage,
  pageSize,
  totalCount,
  onPageChange,
  onPageSizeChange,
}: Pagination2Props) => {
  const totalPages = Math.ceil(totalCount / pageSize) || 0;
  const firstTask = (currentPage - 1) * pageSize + 1;
  const lastTask = Math.min(currentPage * pageSize, totalCount);

  const handlePageChange = (page: any) => {
    onPageChange(page);
  };

  const handlePageSizeChange = (size: any) => {
    onPageSizeChange(size);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const getPageButtons = () => {
    const buttons = [];
    const maxVisibleButtons = 3;

    if (totalPages <= maxVisibleButtons) {
      for (let page = 1; page <= totalPages; page++) {
        buttons.push(
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={` py-1.5 px-4 mx-[1px] cursor-pointer  ${
              currentPage === page
                ? "bg-black text-white rounded-md"
                : "text-grey bg-white"
            }`}
          >
            {page}
          </button>
        );
      }
    } else {
      const leftBoundary = Math.max(
        1,
        currentPage - Math.floor(maxVisibleButtons / 2)
      );
      const rightBoundary = Math.min(
        leftBoundary + maxVisibleButtons - 1,
        totalPages
      );

      if (leftBoundary > 1) {
        buttons.push(
          <button
            key={1}
            onClick={() => handlePageChange(1)}
            className="py-1.5 px-4  mx-[1px]"
          >
            1
          </button>
        );
        if (leftBoundary > 2) {
          buttons.push(
            <span key="ellipsis-left" className="py-2 px-2  mx-[1px]">
              ...
            </span>
          );
        }
      }

      for (let page = leftBoundary; page <= rightBoundary; page++) {
        buttons.push(
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`py-1.5 px-4  mx-[1px] cursor-pointer ${
              currentPage === page
                ? "bg-black text-white rounded-md"
                : "text-grey bg-white"
            }`}
          >
            {page}
          </button>
        );
      }

      if (rightBoundary < totalPages) {
        if (rightBoundary < totalPages - 1) {
          buttons.push(
            <span key="ellipsis-right" className="py-2 px-2 mx-[1px]">
              ...
            </span>
          );
        }
        buttons.push(
          <button
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
            className="py-1.5 px-4  mx-[1px]"
          >
            {totalPages}
          </button>
        );
      }
    }

    return buttons;
  };

  return (
    <div className={`flex justify-between px-4`}>
      <div className="flex justify-center items-center">
        <div className="flex items-center gap-2">
          <div className="font-semibold">Show: </div>
          <select
            defaultValue={pageSize}
            disabled={totalPages === 0}
            onChange={(e) => handlePageSizeChange(e.target.value)}
            className="text-grey px-3 outline-none border-2 bg-white py-0.5"
          >
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>

          <div className="font-semibold">entries</div>
          <span>
            {totalCount === 0
              ? `${totalCount} of ${totalCount}`
              : `${firstTask} - ${lastTask} of ${totalCount}`}
          </span>
        </div>
      </div>

      <div className={`flex items-center`}>
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`p-2 ${
            currentPage === 1
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer"
          }`}
        >
          <HipchatChevronLeftIcon width={"22"} />
        </button>
        {getPageButtons()}

        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages || totalPages === 0}
          className={` p-2 ${
            currentPage === totalPages || totalPages === 0
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer"
          }`}
        >
          <HipchatChevronRightIcon width={"22"} />
        </button>
      </div>
    </div>
  );
};
export default Pagination;
