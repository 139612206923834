import React from "react";
import { IconProp } from "./ArrowIcons";

const PlusIcon = ({ color, width }: IconProp) => {
  return (
    // <svg
    //   width={width || "10"}
    //   height={width || "10"}
    //   viewBox="0 0 12 12"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M6.66667 5.33334V0.662003C6.66596 0.485838 6.59535 0.317159 6.47034 0.193028C6.34534 0.0688982 6.17617 -0.000528475 6 3.0294e-06C5.62933 3.0294e-06 5.33333 0.29667 5.33333 0.662003V5.33334H0.662003C0.485838 5.33404 0.317159 5.40465 0.193028 5.52966C0.0688982 5.65466 -0.000528475 5.82384 3.0294e-06 6C3.0294e-06 6.37134 0.29667 6.66667 0.662003 6.66667H5.33333V11.338C5.33333 11.7033 5.632 12 6 12C6.37067 12 6.66667 11.7033 6.66667 11.338V6.66667H11.338C11.5142 6.66596 11.6828 6.59535 11.807 6.47034C11.9311 6.34534 12.0005 6.17617 12 6C12 5.62934 11.7033 5.33334 11.338 5.33334H6.66667Z"
    //     fill={color || "white"}
    //   />
    // </svg>
    <svg
      width={width || "20"}
      height={width || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 9.33334V4.662C10.666 4.48584 10.5953 4.31716 10.4703 4.19303C10.3453 4.0689 10.1762 3.99947 10 4C9.62933 4 9.33333 4.29667 9.33333 4.662V9.33334H4.662C4.48584 9.33404 4.31716 9.40465 4.19303 9.52966C4.0689 9.65466 3.99947 9.82384 4 10C4 10.3713 4.29667 10.6667 4.662 10.6667H9.33333V15.338C9.33333 15.7033 9.632 16 10 16C10.3707 16 10.6667 15.7033 10.6667 15.338V10.6667H15.338C15.5142 10.666 15.6828 10.5953 15.807 10.4703C15.9311 10.3453 16.0005 10.1762 16 10C16 9.62934 15.7033 9.33334 15.338 9.33334H10.6667Z"
        fill={color || "#D4A15E"}
      />
    </svg>
  );
};

export default PlusIcon;
