
import GstCard from "./GstCard";
import GstTableCard from "./GstTableCard";
import { getGstDetails } from "../../../api/gst";
import Modal from "../../../components/common/Modal";

type Address = {
  building_name: string;
  street: string;
  location: string;
  door_num: string;
  state: string;
  floor_num: string;
  lattitude: string; // You might want to change this to a number type if applicable
  longitude: string; // You might want to change this to a number type if applicable
  district: string;
  city: string;
  pin_code: string;
};

type PlaceOfBusiness = {
  address: Address;
  place_of_business_nature: string[]; // This is an array of strings
};

type GSTDetails = {
  gstin: string;
  legal_name: string;
  state_jurisdiction: string;
  centre_jurisdiction: string;
  registration_date: string;
  business_constitution: string;
  type: string;
  business_activity_nature: string[];
  status: string;
  cancellation_date: string;
  trade_name: string;
  state_jurisdiction_code: string;
  centre_jurisdiction_code: string;
  place_of_business_principal: PlaceOfBusiness;
};

type GSTPopupProps = {
  handleClose: () => void;
  gstDetails?: GSTDetails;
  onChange?: (text: string) => void;
  errorMessage?: string;
};

export default function GSTPopup({
  handleClose,
  gstDetails,
  onChange,
  errorMessage,
}: GSTPopupProps) {
  const gstData = [
    {
      title: errorMessage ? "" : gstDetails?.legal_name,
      content: "Company name",
    },
    {
      title: errorMessage ? "" : gstDetails?.registration_date,
      content: "Date of Registration",
    },
    {
      title: errorMessage ? "" : gstDetails?.status,
      content: "GSTIN status",
    },
  ];

  const gstTableDetails = [
    {
      label: "Taxpayer Type",
      content: errorMessage ? "" : gstDetails?.type,
    },
    {
      label: "Centre Jurisdiction",
      content: errorMessage ? "" : gstDetails?.centre_jurisdiction,
    },
    {
      label: "State Jurisdiction",
      content: errorMessage ? "" : gstDetails?.state_jurisdiction,
    },
    {
      label: "Constitution of Business",
      content: errorMessage ? "" : gstDetails?.business_constitution,
    },
    {
      label: "Business Trade Name",
      content: errorMessage ? "" : gstDetails?.trade_name,
    },
  ];

  return (
    <Modal header="Taxpayer details" handleCancel={handleClose}>
      <div className="flex flex-col w-full py-4">
        <h4 className="text-xs">
          Entered GSTIN:{" "}
          <span className="font-bold">
            {errorMessage ? "XXXXXXXXXXXXXXXX" : gstDetails?.gstin}
          </span>
        </h4>
        <div className="container flex justify-between">
          {gstData.map((e: any, i: any) => {
            return <GstCard key={i} title={e.title} content={e.content} />;
          })}
        </div>

        <div className="container flex flex-col justify-between">
          {gstTableDetails.map((e: any, i: number) => {
            return <GstTableCard key={i} label={e.label} content={e.content} />;
          })}
        </div>
      </div>
    </Modal>
  );
}
