import React from "react";

type ConsultantCardPropType = {
  count: number;
  color: string;
  icon: React.ReactNode;
  setIsHovered: (isHovered: boolean) => void;
  isHovered: boolean;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
};

const ConsultantCard = ({
  count,
  color,
  icon,
  setIsHovered,
  isHovered,
  handleMouseEnter,
  handleMouseLeave,
}: ConsultantCardPropType) => {
  const cardStyle = {
    backgroundColor: isHovered ? color : "transparent",
    color: isHovered ? "white" : "black",
  };

  return (
    <div
      className="bg-[#F9F9F9] w-full flex flex-col max-w-[14vw]"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{ backgroundColor: cardStyle.backgroundColor }}
        className="relative py-3 px-4"
      >
        <div
          style={{ backgroundColor: color }}
          className={`absolute z-10 top-0 w-1 h-full left-0`}
        ></div>
        <div className="relative z-20 flex justify-between items-center">
          <p
            className="font-medium text-[20px]"
            style={{ color: cardStyle.color }}
          >
            {count}
          </p>
          <div style={{ color: cardStyle.color }}>{icon}</div>
        </div>
        <p
          className="relative z-20 text-[11px] text-[#AEAEAE] text-left"
          style={{ color: cardStyle.color }}
        >
          confirmed order
        </p>
      </div>
    </div>
  );
};

export default ConsultantCard;
