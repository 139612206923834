import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";

import Button from "../../components/Button";
import SelectCheckboxOption from "../../components/SelectCheckboxOption";
import SelectField from "../../components/SelectField";
import Checkbox from "../../components/Checkbox";
import InputField from "../../components/InputField";
import AuthInputField from "../../components/AuthInputField";

const architect = [
  { value: "commercial-architect", label: "Commercial Architect" },
  {
    value: "sustainable-greenDesign-architect",
    label: "Sustainable / Green Design Architect",
  },
  { value: "industrial-architect", label: "Industrial Architect" },
  { value: "residential-architect", label: "Residential Architect" },
  { value: "conservation-architect", label: "Conservation Architect" },
  { value: "landscape-architect", label: "Landscape Architect" },
  { value: "urban-designer", label: "Urban Designer" },
  { value: "interior-architect", label: "Interior Architect" },
];

function CreateConsultant() {
  const setTitle = useSetRecoilState(titleAtom);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phonerNumber, setPhoneNumber] = useState("");
  const [location, setLocation] = useState("");
  const [selectedGender, setSelectedGender] = useState<string | null>(null);
  const [selectedArchitect, setSelectedArchitect] = useState<string>("");

  const handleSelection = (gender: string) => {
    setSelectedGender(gender);
  };

  const handleArchitectSelection = (profession: string) => {
    setSelectedArchitect(profession);
  };

  const [checkboxValues, setCheckboxValues] = useState<(0 | 1 | 2)[]>([]);
  const [selectAllValue, setSelectAllValue] = useState<0 | 1 | 2>(0);
  const handleCheckboxClick = (index: number) => {
    // const updatedValues = [...checkboxValues];
    // updatedValues[index] = value;
    // setCheckboxValues(updatedValues);
    // const allChecked = updatedValues.every((value) => value === 1);
    // const noneChecked = updatedValues.every((value) => value === 0);
    // if (allChecked) {
    //   setSelectAllValue(1);
    // } else if (noneChecked) {
    //   setSelectAllValue(0);
    // } else {
    //   setSelectAllValue(2);
    // }
  };

  const handleSelectAllClick = () => {
    // let updatedValues: (0 | 1 | 2)[] = [];
    // if (selectAllValue === 0 || selectAllValue === 2) {
    //   updatedValues = checkboxValues.map((value) => (value === 2 ? 2 : 1));
    //   setSelectAllValue(1);
    // } else {
    //   updatedValues = checkboxValues.map((value) => 0);
    //   setSelectAllValue(0);
    // }
    // setCheckboxValues(updatedValues);
  };

  return (
    <div className="flex px-14">
      <div className="w-7/12 px-3 h-full">
        <div className="w-[90%] flex flex-col h-[93.5vh]">
          <p className="flex font-medium mt-4">Create new User</p>

          <div className="flex gap-3 ">
            <AuthInputField
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              placeholder="First name"
              heading="Enter first name: "
            />

            <AuthInputField
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              placeholder="Last name"
              heading="Enter last name: "
            />
          </div>

          <div className=" flex flex-col space-y-6">
            <AuthInputField
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phonerNumber}
              placeholder="XXXXXXXXXX"
              heading="Phone number"
              errorMessage={"This field is required."}
              type="telephone"
            />
            <AuthInputField
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="eg. abc@xyz.com"
              heading="Enter email id:"
              errorMessage={"This field is required."}
              type="text"
            />
            <InputField
              label="Enter location:"
              onChange={(e) => setLocation(e)}
              inputType="text"
              value={location}
              placeholder="Text"
            />

            <div className="flex flex-col justify-start text-left space-y-2">
              <h2 className="text-[12px] font-medium"> Gender</h2>

              <div className="flex  items-center space-x-12 ">
                <SelectCheckboxOption
                  kind="female"
                  isSelected={selectedGender === "female"}
                  handleSelection={handleSelection}
                />
                <SelectCheckboxOption
                  kind="male"
                  isSelected={selectedGender === "male"}
                  handleSelection={handleSelection}
                />
                <SelectCheckboxOption
                  kind="other"
                  isSelected={selectedGender === "other"}
                  handleSelection={handleSelection}
                />
              </div>
            </div>
            <SelectField
              label="Select a profession:"
              options={architect}
              value={selectedArchitect}
              onChange={handleArchitectSelection}
              placeholder="Text"
            />
          </div>

          <div className="flex space-x-5 pt-5">
            <div>
              <Button label="Create" variant="primary" />
            </div>
            <div>
              <Button label="Cancel " variant="secondary-outline" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="w-5/12 px-8">
        <div className="w-full flex justify-between py-6">
          <p>Permissions</p>
          <div>
            <Checkbox onClick={handleSelectAllClick} label="Select all" />
          </div>
        </div>

        <div className="flex flex-col text-start">
          <p>Morem ipsum dolor sit amet consectetur ipsum dolor</p>
          <div className="flex flex-col gap-2 mt-2">
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Create" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Read" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Update" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Delete" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Create" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Read" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Update" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Delete" />
            </div>
          </div>
        </div>

        <div className="flex flex-col text-start mt-6">
          <p>Morem ipsum dolor sit amet </p>
          <div className="flex flex-col gap-2 mt-2">
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Create" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Read" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Update" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Delete" />
            </div>
          </div>
        </div>
        <div className="flex flex-col text-start mt-6">
          <p>Morem ipsum dolor sit amet </p>
          <div className="flex flex-col gap-2 mt-2">
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Create" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Read" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Update" />
            </div>
            <div>
              <Checkbox onClick={handleCheckboxClick} label="Delete" />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default CreateConsultant;
