import React, { useState } from "react";
import tickIcon from "../../../assets/svg/tick.svg";
import crossIcon from "../../../assets/svg/cross.svg";
import { Accordian } from "../../../components/Accordian";

export default function ProjectDetailsTimeline({
  orderTimeline,
  formatDate,
}: any) {
  return (
    <>
      <Accordian label="Timeline">
        <div className="px-4 py-3 h-[50vh] overflow-scroll no-scrollbar">
          {orderTimeline?.map((el: any, i: any) => {
            return (
              <div
                className="flex  justify-start items-start gap-4 w-[55%] text-start"
                key={i}
              >
                <div>
                  {/* If DELETED then only show cross else tick */}
                  {el?.action_type !== "DELETED" ? (
                    <>
                      <img src={tickIcon} alt="tick-icon" />
                    </>
                  ) : (
                    <>
                      <img src={crossIcon} alt="cross-icon" />
                    </>
                  )}
                  <div
                    className={`${
                      i !== orderTimeline?.length - 1
                        ? `h-[50px] border-2 w-0 ml-2`
                        : ""
                    } `}
                  ></div>
                </div>

                <div>
                  <div className="flex space-x-4 text-[12px] leading-5 ">
                    <p className="font-medium">{`${el?.object}  ${el?.action_type}`}</p>

                    <p className="text-[#AEAEAE]">
                      {el?.action_at ? formatDate(el?.action_at, "date") : "- "}
                    </p>
                  </div>
                  {el?.description?.map((el: any) => {
                    return (
                      <div className="flex flex-col gap-y-2" key={el}>
                        <p className="text-[12px] leading-5 ">{el}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Accordian>
    </>
  );
}
