import OrderDetailsDropDown from "../../components/OrderDetailsDropDown";
import React, { useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import UpandDownArrow from "../../../../../assets/svg/dateIcon.svg";
import calenderIcon from "../../../../../assets/svg/clenderIcon.svg";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import ProgressBar from "../../../../../components/Progressbar";

const orderData = [
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Ongoing",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Complete",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Shipped",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 500,
    totalAmount: 1500000,
    status: "Ongoing",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Cancel",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Return",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
  {
    orderId: "#123",
    date: "25/05/2023",
    numberOfItem: 700,
    totalAmount: 1000000,
    status: "Pending",
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
];

const outgoingStepProcess = [
  {
    id: 0,
    status: true,
    step: "Disabled Step",
  },
  {
    id: 1,
    status: true,
    step: "Visited Step",
  },
  {
    id: 2,
    status: true,
    step: "Current Step",
  },
  {
    id: 3,
    status: false,
    step: "Unvisited Step",
  },
  {
    id: 4,
    status: false,
    step: "Unvisited Step",
  },
];

export default function OrderDetails() {
  const handleAddOrder = () => {
    console.log("handleAddOrder");
  };
  return (
    <>
      <OrderDetailsDropDown
        label="Order Details"
        heading="+ Add order"
        onClick={handleAddOrder}
        staticData={<OrderFirstRow />}
        dropDown={<OrderSecondRow />}
      />

      {/* <div>
        <div className="w-full border-2 h-auto mt-4">
          <div className="flex justify-between items-center bg-[#FBFBFB] h-[40px] px-5 py-2">
            <p className="font-medium text-[14px] leading-5">Order Details</p>
            <div className="flex gap-2 items-center justify-center cursor-pointer text-pot-yellow">
              + <span className="text-sm"></span>
            </div>
          </div>

          <div className=" border-x border-y px-5 py-5">
            <OrderFirstRow />
            <OrderSecondRow />
          </div>
        </div>
      </div> */}
    </>
  );
}

const ongoingOrders = orderData.filter((order) => order.status === "Ongoing");

// console.log(ongoingOrders ,'ongoingOrders');

const OrderFirstRow = () => {
  return (
    <>
      <div>
        {ongoingOrders
          .filter((el, i) => i < 1)
          .map((el, i) => {
            return (
              <div
                key={i}
                className={`py-4 px-4 flex flex-col space-y-2 mb-10`}
              >
                <div className="flex justify-between ">
                  <div className="flex gap-7  font-medium leading-5 tracking-tighter">
                    <p className="text-[14px]">Order id: {el.orderId}</p>
                    <div className="flex items-center gap-3">
                      <img src={calenderIcon} alt="calender-icon" />
                      <p className="text-[#AEAEAE] text-[12px]">{el.time}</p>
                    </div>
                  </div>

                  <p className=" bg-[#CFF9FF] w-[80px] py-0.5  rounded-md text-[12px] font-medium leading-5 tracking-tighter">
                    {el.status}
                  </p>
                </div>

                <div className="flex flex-col space-y-2">
                  <div className="flex gap-4">
                    <p className="text-[#AEAEAE] text-[12px]">No. of items: </p>
                    <span className="text-[12px]">{el.numberOfItem}</span>
                  </div>
                  <div className="flex justify-between items-center ">
                    <div className="flex gap-2">
                      <p className="text-[#AEAEAE] text-[12px]">
                        Total amount:{" "}
                      </p>
                      <span className="text-[12px]">{el.totalAmount}</span>
                    </div>

                    <div className={`w-7/12 flex m-auto `}>
                      <ProgressBar data={outgoingStepProcess} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

// Second row Data
const OrderSecondRow = () => {
  const mainDivStyle = `{ text-[12px] font-medium leading-5 tracking-tighter }`;
  const tableStyle = `{ml-3 tracking-tighter w-full m-auto}`;
  const theadStyle =
    " bg-pot-grey1 text-black border-x border-y leading-[18px] tracking-tighter text-[14px]";
  const tdDivStyle = `px-4  border-x border-y text-start py-2 font-medium`;

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <>
            <span>Order id</span>
          </>
        ),
        accessor: "orderId",
        Cell: ({ value }: { value: any }) => (
          <Link to={"#"}>
            <p className="underline">Order{value}</p>
          </Link>
        ),
      },
      {
        Header: () => (
          <div className="flex justify-start items-center gap-2 ">
            <p>Date</p>
            <img src={UpandDownArrow} alt="UpandDownArrow" />
          </div>
        ),
        accessor: "date",
      },
      {
        Header: () => (
          <>
            <span>Number of Items</span>
          </>
        ),
        accessor: "numberOfItem",
        width: 120,
      },
      {
        Header: () => (
          <>
            <span>Total Amount</span>
          </>
        ),
        accessor: "totalAmount",
        width: 130,
      },
      {
        Header: () => (
          <>
            <span>Status</span>
          </>
        ),
        accessor: "status",
        Cell: ({ value }: { value: any }) => {
          return value != "Ongoing" ? (
            <p
              className={`w-[70px] p-0.5 rounded-md pl-1.5 ${
                value === "Complete"
                  ? "bg-[#79F2C0]"
                  : value === "Shipped"
                  ? "bg-[#EDEDED]"
                  : value === "Cancel"
                  ? "bg-[#FFB4A1]"
                  : value === "Return"
                  ? "bg-[#DBD6F6]"
                  : value === "Pending"
                  ? "bg-[#FFE9F9]"
                  : ""
              }`}
            >
              {value}
            </p>
          ) : (
            ""
          );
        },
      },
    ],
    []
  );
  const tableData = useMemo(() => {
    const filteredData = orderData.filter(
      (order) => order.status !== "Ongoing"
    );
    return filteredData;
  }, [orderData]);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageLength, setPageLength] = useState<number>(20);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
        initialState: { pageIndex: page - 1, pageSize: pageLength },
        manualPagination: true,
        pageCount: Math.ceil(totalCount / pageLength),
      },
      useSortBy,
      usePagination
    );

  return (
    <>
      <div>
        {ongoingOrders
          .filter((el, i) => i > 0)
          .map((el, i) => {
            return (
              <div
                key={i}
                className={`py-4 px-4 flex flex-col space-y-2 mb-10`}
              >
                <div className="flex justify-between ">
                  <div className="flex gap-7  font-medium leading-5 tracking-tighter">
                    <p className="text-[14px]">Order id: {el.orderId}</p>
                    <div className="flex items-center gap-3">
                      <img src={calenderIcon} alt="calender-icon" />
                      <p className="text-[#AEAEAE] text-[12px]">{el.time}</p>
                    </div>
                  </div>

                  <p className=" bg-[#CFF9FF] w-[80px] py-0.5  rounded-md text-[12px] font-medium leading-5 tracking-tighter">
                    {el.status}
                  </p>
                </div>

                <div className="flex flex-col space-y-2">
                  <div className="flex gap-4">
                    <p className="text-[#AEAEAE] text-[12px]">No. of items: </p>
                    <span className="text-[12px]">{el.numberOfItem}</span>
                  </div>
                  <div className="flex justify-between items-center ">
                    <div className="flex gap-2">
                      <p className="text-[#AEAEAE] text-[12px]">
                        Total amount:{" "}
                      </p>
                      <span className="text-[12px]">{el.totalAmount}</span>
                    </div>

                    <div className={`w-7/12 flex m-auto `}>
                      <ProgressBar data={outgoingStepProcess} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ stiffness: 60, duration: 0.2 }}
        exit={{ opacity: 0 }}
        className={mainDivStyle}
      >
        <table {...getTableProps()} className={tableStyle}>
          <thead className={theadStyle}>
            {headerGroups.map((headerGroup: any, headerIndex: number) => (
              <tr key={headerIndex} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, columnIndex: number) => (
                  <th
                    key={columnIndex}
                    {...column.getHeaderProps()}
                    style={{ width: column.width }}
                    className={tdDivStyle}
                  >
                    {column.render("Header")}{" "}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, rowIndex: number) => {
              prepareRow(row);
              return (
                <tr
                  key={rowIndex}
                  {...row.getRowProps()}
                  className="font-normal "
                >
                  {row.cells.map((cell: any, cellIndex: number) => (
                    <td
                      key={cellIndex}
                      {...cell.getCellProps()}
                      className={`${tdDivStyle} `}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </motion.div>

      <div className="w-full flex gap-4 px-3">
        {/* <Pagination
          tableInstance={" "}
          setPage={setPage}
          page={page}
          loading={loading}
          setLoading={setLoading}
          totalCount={totalCount}
          pageLength={pageLength}
          setPageLength={setPageLength}
        /> */}
      </div>
    </>
  );
};
