import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import HipchatChevronDownIcon from "../../../assets/HipchatChevronDownIcon";
import useOnClickOutside from "../../../hooks/useClickOutside";

type Option = {
  label: string;
  value: string;
};

type FilterDropDownProps = {
  heading?: string;
  title?: string;
  options?: Option[];
  textBg?: string;
  onChange: (value: string) => void;
  icon?: React.ReactNode;
  selectedValue?: string;
};

const OrderStatusCard = ({
  options,
  onChange,
  title,
  textBg,
  heading,
  icon,
  selectedValue,
}: FilterDropDownProps) => {
  const [selected, setSelected] = useState<Option | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useOnClickOutside(selectRef, () => setIsOpen(false));

  const handleOptionClick = (option: Option) => {
    setSelected(option);
    setIsOpen(false);
    onChange(option.value);
  };

  const mouseOut = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left w-full" ref={selectRef}>
      <div className="font-medium text-sm mb-1">{title ? title : ""}</div>
      <div className="w-full">
        <span className="rounded-md shadow-sm">
          <button
            type="button"
            className={` ${
              icon ? "min-w-[8vw]" : "min-w-[7vw]"
            }  inline-flex justify-between items-center gap-3 px-3 py-1.5 rounded-md border border-gray-300 bg-white  focus:outline-none text-sm w-full`}
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="flex">
              {icon}
              {selectedValue ? ( // Conditionally render selectedValue
                <span
                  className={`ml-2 text-ellipsis line-clamp-1 truncate ${textBg}`}
                >
                  {selectedValue}
                </span>
              ) : selected ? (
                <span
                  className={`ml-2 text-ellipsis line-clamp-1 truncate ${textBg}`}
                >
                  {selected.label}
                </span>
              ) : (
                <span
                  className={`ml-2 text-ellipsis line-clamp-1 truncate ${textBg}`}
                >
                  {heading ? heading : options ? options[0].label : ""}
                </span>
              )}
            </div>

            <motion.div
              animate={{ rotate: isOpen ? 180 : 0 }}
              className="w-fit h-fit flex items-center justify-center"
            >
              <HipchatChevronDownIcon color="black" />
            </motion.div>
          </button>
        </span>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options?.map((option) => (
              <button
                key={option.value}
                className={`${
                  selected?.value === option.value
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-700"
                } block w-full text-left px-4 py-2 text-sm truncate hover:bg-[#eee]`}
                role="menuitem"
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderStatusCard;
