interface CDRFileIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const CDRFileIcon = ({
  color = "black",
  width = "19",
  height = "21",
}: CDRFileIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.96341 3.80871C9.98931 3.86913 10.0018 3.93446 10 4.00017V6.00017H9V5.00017H6C5.73502 4.99939 5.48112 4.89379 5.29375 4.70642C5.10639 4.51905 5.00078 4.26515 5 4.00017V1.00017H1V13.0002H9V14.0002H1C0.734784 14.0002 0.48043 13.8948 0.292893 13.7073C0.105357 13.5197 0 13.2654 0 13.0002V1.00017C0.000778244 0.735195 0.106385 0.481293 0.293753 0.293925C0.481121 0.106557 0.735023 0.000950106 1 0.000171862H6C6.06572 -0.00163613 6.13105 0.0108391 6.19147 0.0367365C6.2519 0.0626338 6.30599 0.101336 6.35 0.150172L9.85 3.65017C9.89882 3.6942 9.93752 3.74828 9.96341 3.80871ZM6 1.20017V4.00017H8.8L6 1.20017Z"
        fill={color}
      />
      <path
        d="M3.3282 12.07C3.00153 12.07 2.71453 12.0093 2.4672 11.888C2.22453 11.762 2.03553 11.587 1.9002 11.363C1.76953 11.1343 1.7042 10.866 1.7042 10.558V8.332C1.7042 8.01933 1.76953 7.751 1.9002 7.527C2.03553 7.303 2.22453 7.13033 2.4672 7.009C2.71453 6.883 3.00153 6.82 3.3282 6.82C3.65953 6.82 3.9442 6.883 4.1822 7.009C4.42486 7.13033 4.61386 7.303 4.7492 7.527C4.88453 7.751 4.9522 8.01933 4.9522 8.332H4.0702C4.0702 8.08933 4.00486 7.905 3.8742 7.779C3.7482 7.653 3.5662 7.59 3.3282 7.59C3.0902 7.59 2.90586 7.653 2.7752 7.779C2.64453 7.905 2.5792 8.087 2.5792 8.325V10.558C2.5792 10.796 2.64453 10.9803 2.7752 11.111C2.90586 11.237 3.0902 11.3 3.3282 11.3C3.5662 11.3 3.7482 11.237 3.8742 11.111C4.00486 10.9803 4.0702 10.796 4.0702 10.558H4.9522C4.9522 10.8613 4.88453 11.1273 4.7492 11.356C4.61386 11.5847 4.42486 11.762 4.1822 11.888C3.9442 12.0093 3.65953 12.07 3.3282 12.07ZM5.72246 12V6.89H7.24146C7.58679 6.89 7.88546 6.95533 8.13746 7.086C8.39413 7.21667 8.59246 7.401 8.73246 7.639C8.87246 7.877 8.94246 8.15933 8.94246 8.486V10.397C8.94246 10.719 8.87246 11.0013 8.73246 11.244C8.59246 11.482 8.39413 11.6687 8.13746 11.804C7.88546 11.9347 7.58679 12 7.24146 12H5.72246ZM6.59746 11.195H7.24146C7.49346 11.195 7.69413 11.125 7.84346 10.985C7.99279 10.8403 8.06746 10.6443 8.06746 10.397V8.486C8.06746 8.24333 7.99279 8.052 7.84346 7.912C7.69413 7.76733 7.49346 7.695 7.24146 7.695H6.59746V11.195ZM9.72673 12V6.89H11.3437C11.6844 6.89 11.9784 6.953 12.2257 7.079C12.4777 7.205 12.6737 7.38233 12.8137 7.611C12.9537 7.835 13.0237 8.101 13.0237 8.409C13.0237 8.745 12.9374 9.04133 12.7647 9.298C12.5967 9.55467 12.3681 9.73667 12.0787 9.844L13.0937 12H12.1137L11.2247 9.97H10.6017V12H9.72673ZM10.6017 9.2H11.3437C11.5911 9.2 11.7847 9.13233 11.9247 8.997C12.0647 8.86167 12.1347 8.675 12.1347 8.437C12.1347 8.19433 12.0647 8.00533 11.9247 7.87C11.7847 7.73 11.5911 7.66 11.3437 7.66H10.6017V9.2Z"
        fill={color}
      />
    </svg>
  );
};

export default CDRFileIcon;
