import { useState } from "react";
import ProjectCounts from "./components/ProjectCounts";
import ProjectTable from "./projectdetails/ProjectTable";

export default function ProjectList() {
  const [projectCount, setProjectCount] = useState(0);
  return (
    <div className="h-full flex flex-col justify-start items-start">
      <div className="h-[13vh] w-full flex items-center justify-start">
        <ProjectCounts projectCount={projectCount} />
      </div>
      <div className="h-[calc(100%-13vh)] w-full">
        <ProjectTable setProjectCount={setProjectCount} />
      </div>
    </div>
  );
}
