import React from "react";
type HipchatChevronRightIconProps = {
  color?: string;
  width?: string;
};
function HipchatChevronRightIcon2({
  color = "white",
  width,
}: HipchatChevronRightIconProps) {
  return (
    <svg
      width={width || "16"}
      height={width || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12L10 8L6 4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HipchatChevronRightIcon2;
