import React, { useCallback, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, titleAtom, userInfoAtom } from "../atom";
import bellIcon from "../assets/svg/bell.svg";
import penIcon from "../assets/svg/pen.svg";
import logoutIcon from "../assets/svg/logout.svg";
import userIcon from "../assets/svg/user2.svg";
import HipchatChevronDownIcon from "../assets/HipchatChevronDownIcon";
import { v4 as uuidV4 } from "uuid";

import HipchatChevronRightIcon from "../assets/HipchatChevronRightIcon";
import useOnClickOutside from "../hooks/useClickOutside";
type TopbarProps = {
  initial?: boolean | undefined;
  setInitial?: any;
};

const Topbar = ({ initial }: TopbarProps) => {
  const [authState, setAuthState] = useRecoilState(authAtom);
  const userInfo = useRecoilValue(userInfoAtom);
  const navigate = useNavigate();
  const title = useRecoilValue(titleAtom);
  const [isOpen, setIsOpen] = useState(false);
  const headerOptionRef = useRef(null);
  useOnClickOutside(headerOptionRef, () => setIsOpen(false));

  const tables = [
    {
      icon: <img src={penIcon} alt="penIcon" />,
      name: "Edit profile",
    },
    {
      icon: <img src={logoutIcon} alt="logoutIcon" />,
      name: "Log out",
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refresh_token");

    setAuthState({
      is_auth: false,
      auth_token: "",
      refresh_token: "",
    });

    navigate("/login");
  };

  // console.log(title , 'title');

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: "-100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.4 }}
        className="h-full  bg-[#fffefe]"
      >
        <section className=" h-full bg-pot-background-grey-2 border-b flex justify-between flex-row items-center text-left px-4">
          {/* heading navbar */}
          <div className="font-medium w-full text-sm flex">
            {typeof title === "string"
              ? title
              : title?.map((each, idx) => {
                  const formattedTitleKeys = Object.keys({}).map((e) =>
                    e.toLowerCase().replaceAll(" new ", "").replaceAll(" ", "")
                  );
                  const formattedTitleText = each
                    .toLowerCase()
                    .replaceAll(" new ", "")
                    .replaceAll(" ", "");
                  const titleIndex = formattedTitleKeys.findIndex(
                    (e) => e === formattedTitleText
                  );
                  // const titleItem = Object.keys(titleToPath)[
                  //   titleIndex
                  // ] as keyof typeof titleToPath;

                  // console.log("nope", formattedTitleKeys, formattedTitleText);

                  return (
                    <div key={idx} className="flex items-center">
                      {formattedTitleKeys.includes(formattedTitleText) ? (
                        // <Link className="mx-0" to={titleToPath[titleItem]}>
                        //   {each}
                        // </Link>
                        <div key={idx + "ramdom2"}>Hello</div>
                      ) : (
                        <p className="mx-0" key={idx + "ramdom2"}>
                          {each}
                        </p>
                      )}
                      {idx !== title.length - 1 && (
                        <HipchatChevronRightIcon width="20" color="" />
                      )}
                    </div>
                  );
                })}
          </div>
          {/* profile dropdown */}
          <div
            className="flex justify-end items-center w-2/12 gap-3 relative cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
            ref={headerOptionRef}
          >
            {/* <img src={bellIcon} alt="bell" /> */}
            <p className="text-black font-gilroy-bold text-sm">
              {userInfo?.name ? userInfo?.name : "..."}
            </p>
            <div>
              <motion.div animate={{ rotate: isOpen ? 180 : 0 }}>
                <HipchatChevronDownIcon color="#BEBEBE" width="27" />
              </motion.div>
            </div>
            {isOpen && (
              <div className=" bg-white shadow-lg absolute top-0 right-0 transform -translate-x-0 translate-y-[28%]  z-[51] mt-1.5">
                <div className="flex flex-col">
                  {tables?.map((el, i) => {
                    return (
                      <div
                        key={uuidV4()}
                        className="flex w-auto px-3 py-3 gap-3 justify-start items-center border border-[#DEDEDE] cursor-pointer"
                        onClick={() => {
                          if (el.name === "Log out") {
                            handleLogout();
                          }
                        }}
                      >
                        <div>{el.icon}</div>
                        <div>{el.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </section>
      </motion.div>
    </AnimatePresence>
  );
};

export default Topbar;
