import FilterIcon from "../../assets/svg/filtericon.svg";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { Link, Navigate } from "react-router-dom";
import UpandDownArrow from "../../assets/svg/dateIcon.svg";
import pen from "../../assets/svg/pen.svg";
import activeStatus from "../../assets/svg/activestatus.svg";
import DeActiveStatus from "../../assets/svg/deactivatestatus.svg";
import ManagementTableLayout from "../../components/MangementTable";
import InputField from "../../components/InputField";
import SearchIcon from "../../assets/SearchIcon";
import Button from "../../components/Button";
import PlusIcon from "../../assets/PlusIcon";
import SelectDropDown from "../../components/FilterDropDown";
import { useSticky } from "react-table-sticky";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { MainAppSideBarExpandAtom, titleAtom } from "../../atom";
import FilterCheckboxDropDown from "../../components/MultipleSelectCheckboxDropDown";
import loadingSpinner from "../../../assets/images/LoadingSpinner.gif";
import Pagination from "../../components/Pagination";
import { toast } from "react-toastify";
import EditIcon from "../../assets/EditIcon";
import EmailIcon from "../../assets/svg-tsx/EmailIcon";
import { getAdminUsers, updateAdminUsers } from "../../api/user";

function UserList() {
  const setTitle = useSetRecoilState(titleAtom);
  const [totalCount, setTotalCount] = useState<number>(100);
  const [pageLength, setPageLength] = useState<number>(25);
  const [page, setPage] = useState<any>(1);
  const [loading, setLoading] = useState(true);
  const [inputVal, setInputVal] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [adminUserData, setAdminUserData] = useState<Array<any>>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);
  const [filteredData, setFilteredData] = useState<
    { name: string; totalSpend: string }[]
  >([]); // State to store filtered data
  const [isTogglingStatus, setIsTogglingStatus] = useState("");

  useEffect(() => {
    setLoading(true);
    getAdminUsers({ page: page, length: pageLength, search: inputVal })
      .then((res) => {
        setAdminUserData(res?.data?.admin);
        setTotalCount(res.data.pagination.total_count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, pageLength, inputVal]);

  const handleStatusToggle = async (
    adminUserId: string,
    currentStatus: boolean
  ) => {
    if (isTogglingStatus) return;
    setIsTogglingStatus(adminUserId);

    const payload = {
      is_active: !currentStatus,
    };

    try {
      // Update user status via API PATCH request
      const response = await updateAdminUsers(adminUserId, payload);

      // Update local userData state to reflect the new status
      // setAdminUserData((prevData: any) =>
      //   prevData.map((adminuser: any) => {
      //     if (adminuser.id === adminUserId) {
      //       return {
      //         ...adminuser,
      //         is_active: !currentStatus,
      //       };
      //     }
      //     return adminuser;
      //   })
      // );
      console.log(response, "response");

      if (response.data.admin) {
        toast.success("Status updated successfully");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status. Reverting the toggle...");

      // Revert the toggle in the UI (assuming 'currentStatus' is the correct state)
      setAdminUserData((prevData: any) =>
        prevData.map((adminuser: any) => {
          if (adminuser.id === adminUserId) {
            return {
              ...adminuser,
              is_active: currentStatus,
            };
          }
          return adminuser;
        })
      );
    } finally {
      setIsTogglingStatus("");
    }
  };

  const COLUMN = [
    {
      id: "name",
      Header: ({ column }: { column: any }) => (
        <div className=" w-[100px]">
          <div className="flex justify-start items-center ">Name</div>
        </div>
      ),
      accessor: "name",
      Cell: ({ row }: { row: any }) => (
        <div>
          <Link to={`/users/${row.original.id}`}>
            {" "}
            <p>{row.original.name || "-"}</p>
          </Link>
        </div>
      ),
    },
    {
      id: "email",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Email id</div>
      ),
      accessor: "email",
      Cell: ({ value }: { value: any }) => (
        <p>{value.charAt(0).toLowerCase() + value.slice(1) || "-"}</p>
      ),
    },

    {
      id: "phone",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Phone Number</div>
      ),
      accessor: "phone",
      Cell: ({ value }: { value: any }) => (
        <div className="font-semibold">{value || "-"}</div>
      ),
    },
    {
      id: "gender",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Gender</div>
      ),
      accessor: "gender",
      Cell: ({ value }: { value: any }) => <p>{value || "-"}</p>,
    },
    {
      id: "created_at",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          <p>Creation date</p>
        </div>
      ),
      accessor: "created_at",
      Cell: ({ value }: { value: any }) => {
        const formattedDate = new Date(value)?.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        // "25-05-2023"
        return <p>{formattedDate || "-"}</p>;
      },
    },

    {
      id: "role",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-4 px-2">
          <p>Role</p>
        </div>
      ),
      accessor: "role",
      Cell: ({ value }: { value: any }) => {
        return <p>{value || "-"}</p>;
      },
    },
    {
      id: "actions",
      Header: () => <div className="flex w-full justify-start ">Action</div>,
      width: 200,
      Cell: ({ row }: { row: any }) => (
        <div className="flex justify-start items-center gap-3">
          <div
            onClick={() => {}}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
          >
            <Link to={`/users/${row.original.id}`} className="cursor-pointer">
              <EditIcon />
            </Link>
          </div>
          <div
            onClick={() => {}}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
          >
            <Link to={`#`} className="cursor-pointer">
              <EmailIcon />
            </Link>
          </div>
          <div
            onClick={() => {
              setAdminUserData((prevData: any) =>
                prevData.map((adminuser: any) => {
                  if (adminuser.id === row.original.id) {
                    return {
                      ...adminuser,
                      is_active: !row.original.is_active,
                    };
                  }
                  return adminuser;
                })
              );
              handleStatusToggle(row.original.id, row.original.is_active);
            }}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white h-8 w-12"
          >
            {row.original.is_active ? (
              <img
                src={activeStatus}
                alt="status-icon"
                className="w-full object-contain h-full"
              />
            ) : (
              <img
                src={DeActiveStatus}
                alt="status-icon"
                className="w-full object-contain h-full"
              />
            )}
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMN, [adminUserData]);
  const data = useMemo(() => adminUserData, [adminUserData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
      useSticky,
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  return (
    <div>
      <div className="flex flex-row justify-between items-center py-2 w-full px-3">
        <div className="flex gap-4 w-[max-content]">
          <div className=" w-[235px]">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Search here"
              value={inputVal}
              rightIcon={<SearchIcon />}
              withDebounce
            />
          </div>
          <div className="w-fit">
            <Link to={"/users/create"}>
              <Button label="Create User" leftIcon={<PlusIcon />} />
            </Link>
          </div>
        </div>

        {/* <div className=" flex flex-row justify-end items-center gap-2 w-[65%]">
          <div className="inline-flex gap-2">
            <SelectDropDown
              options={[
                { label: "Option 1", value: "option1" },
                { label: "Option 2", value: "option2" },
                { label: "Option 3", value: "option3" },
              ]}
              onChange={() => {}}
              heading="Filters"
              icon={<img src={FilterIcon} alt="filter-icon" />}
            />
          </div>
        </div> */}
      </div>

      <div className="max-h-full w-full relative -z-0 ">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>
      {loading ? (
        ""
      ) : (
        <div className="w-full">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}
    </div>
  );
}

export default UserList;
