import React, { useState } from "react";

const ToolTip = ({ children, label = "label goes here", position }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseOver = () => {
    setIsOpen(true);
  };

  const handleMouseOut = () => {
    setIsOpen(false);
  };

  const positionStyles =
    position === "top"
      ? "left-1/2 tranform -translate-x-1/2 bottom-[calc(100%+10px)]"
      : position === "right"
      ? "top-1/2 tranform -translate-y-1/2 left-[calc(100%+10px)]"
      : "";
  return (
    <>
      <div
        className="relative"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {children}
        {isOpen && (
          <div
            className={`absolute whitespace-nowrap bg-black text-white text-xs font-gilroy-medium p-2 rounded-md ${positionStyles}`}
          >
            {label}
          </div>
        )}
      </div>
    </>
  );
};

export default ToolTip;
