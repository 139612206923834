import React from "react";

interface EmployeeIconProps {
  size?: string;
}

const EmployeeIcon: React.FC<EmployeeIconProps> = ({
  size = "16",
}: EmployeeIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="16"
        height="16"
        fill="white"
        fillOpacity="0.01"
        style={{ mixBlendMode: "multiply" }}
      />
      <path d="M7.5 12H5.5V13H7.5V12Z" fill="#CC7400" />
      <path d="M4.5 12H2.5V13H4.5V12Z" fill="#CC7400" />
      <path d="M13.5 12H11.5V13H13.5V12Z" fill="#CC7400" />
      <path d="M10.5 12H8.5V13H10.5V12Z" fill="#CC7400" />
      <path
        d="M4.5 11C4.73066 10.6872 5.03217 10.4335 5.37981 10.2596C5.72746 10.0858 6.11134 9.99684 6.5 10H9.5C9.88663 10.0081 10.2666 10.102 10.6125 10.275C10.9584 10.4479 11.2615 10.6956 11.5 11H12.65C12.3735 10.3986 11.9291 9.88982 11.3702 9.53501C10.8114 9.18019 10.1619 8.9944 9.5 9H6.5C5.83806 8.9944 5.1886 9.18019 4.62977 9.53501C4.07093 9.88982 3.62652 10.3986 3.35 11H4.5Z"
        fill="#CC7400"
      />
      <path d="M13 14H12V15H13V14Z" fill="#CC7400" />
      <path d="M4 14H3V15H4V14Z" fill="#CC7400" />
      <path
        d="M8 8C7.30777 8 6.63108 7.79473 6.0555 7.41015C5.47993 7.02556 5.03133 6.47894 4.76642 5.83939C4.50152 5.19985 4.4322 4.49612 4.56725 3.81719C4.7023 3.13825 5.03564 2.51461 5.52513 2.02513C6.01461 1.53564 6.63825 1.2023 7.31718 1.06725C7.99612 0.932205 8.69985 1.00152 9.33939 1.26642C9.97893 1.53133 10.5256 1.97993 10.9101 2.55551C11.2947 3.13108 11.5 3.80777 11.5 4.5C11.5 5.42826 11.1313 6.3185 10.4749 6.97487C9.8185 7.63125 8.92826 8 8 8ZM8 2C7.33696 2 6.70107 2.26339 6.23223 2.73223C5.76339 3.20108 5.5 3.83696 5.5 4.5C5.5 5.16304 5.76339 5.79893 6.23223 6.26777C6.70107 6.73661 7.33696 7 8 7C8.66304 7 9.29893 6.73661 9.76777 6.26777C10.2366 5.79893 10.5 5.16304 10.5 4.5C10.5 3.83696 10.2366 3.20108 9.76777 2.73223C9.29893 2.26339 8.66304 2 8 2Z"
        fill="#CC7400"
      />
    </svg>
  );
};

export default EmployeeIcon;
