import React from "react";

type SelectCheckboxOptionProps = {
  kind?: string;
  type?: string;
  isSelected: boolean;
  handleSelection: (gender: string) => void;
  disabled?: boolean;
  isRadio?: boolean;
  big?: boolean;
};
const SelectCheckboxOption = ({
  kind,
  type,
  isSelected,
  handleSelection,
  disabled = false,
  isRadio = true,
  big = true,
}: SelectCheckboxOptionProps) => {
  const size = !big ? "w-3 h-3" : "lg:w-3.5 lg:h-3.5 w-3.5 h-3.5";
  const borderStyle = `cursor-pointer border-2 border-pot-grey rounded-lg ${
    isSelected ? "" : "hover:border-2 hover:border-pot-yellow hover:opacity-50"
  }  active:border-2 active:border-black active:opacity-100`;

  const InputDisabledStyle =
    " rounded-lg text-[#4F4F4F] disabled:appearance-none disabled:font-gilroy-bold focus:outline-none disabled:border-2 disabled:border-[#DEDEDE] disabled:opacity-30 cursor-not-allowed disabled:bg-white";

  const handleClick = () => {
    if (kind) {
      handleSelection(kind);
    } else {
      if (type) {
        handleSelection(type);
      }
    }
  };
  const capitalizedHeading = kind
    ? kind.charAt(0).toUpperCase() + kind.slice(1)
    : type && type.charAt(0).toUpperCase() + type.slice(1);
  return (
    <div
      className={` flex items-center ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={handleClick}
    >
      <label>
        {isRadio ? (
          <input
            type="radio"
            checked={isSelected}
            onChange={handleClick}
            disabled={disabled}
            className={`appearance-none ${size} ${
              disabled ? InputDisabledStyle : borderStyle
            } ${isSelected ? "bg-white border-4 border-pot-yellow" : ""}`}
          />
        ) : (
          ""
        )}
      </label>
      <p
        className={`ml-2 font-gilroy-medium text-[12px] leading-[20px] ${
          disabled && "text-[#4F4F4F] opacity-30"
        }`}
      >
        {capitalizedHeading}
      </p>
    </div>
  );
};

export default SelectCheckboxOption;
