interface PNGIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const PNGIcon = ({
  color = "black",
  width = "19",
  height = "21",
}: PNGIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.96341 3.80871C9.98931 3.86913 10.0018 3.93446 10 4.00017V6.00017H9V5.00017H6C5.73502 4.99939 5.48112 4.89379 5.29375 4.70642C5.10639 4.51905 5.00078 4.26515 5 4.00017V1.00017H1V13.0002H9V14.0002H1C0.734784 14.0002 0.48043 13.8948 0.292893 13.7073C0.105357 13.5197 0 13.2654 0 13.0002V1.00017C0.000778244 0.735195 0.106385 0.481293 0.293753 0.293925C0.481121 0.106557 0.735023 0.000950106 1 0.000171862H6C6.06572 -0.00163613 6.13105 0.0108391 6.19147 0.0367365C6.2519 0.0626338 6.30599 0.101336 6.35 0.150172L9.85 3.65017C9.89882 3.6942 9.93752 3.74828 9.96341 3.80871ZM6 1.20017V4.00017H8.8L6 1.20017Z"
        fill={color}
      />
      <path
        d="M1.7392 12V6.89H3.4402C3.78086 6.89 4.0772 6.95533 4.3292 7.086C4.58586 7.21667 4.7842 7.401 4.9242 7.639C5.0642 7.87233 5.1342 8.14767 5.1342 8.465C5.1342 8.77767 5.06186 9.053 4.9172 9.291C4.7772 9.529 4.5812 9.71333 4.3292 9.844C4.0772 9.97467 3.78086 10.04 3.4402 10.04H2.6142V12H1.7392ZM2.6142 9.249H3.4402C3.68286 9.249 3.87653 9.179 4.0212 9.039C4.17053 8.89433 4.2452 8.703 4.2452 8.465C4.2452 8.22233 4.17053 8.031 4.0212 7.891C3.87653 7.751 3.68286 7.681 3.4402 7.681H2.6142V9.249ZM5.70146 12V6.89H6.77246L8.19346 11.02C8.17946 10.8707 8.16313 10.6957 8.14446 10.495C8.13046 10.2897 8.11879 10.082 8.10946 9.872C8.10013 9.65733 8.09546 9.47067 8.09546 9.312V6.89H8.89346V12H7.82246L6.41546 7.87C6.42479 8.00533 6.43646 8.16867 6.45046 8.36C6.46446 8.55133 6.47613 8.74733 6.48546 8.948C6.49479 9.14867 6.49946 9.333 6.49946 9.501V12H5.70146ZM11.3227 12.07C10.9961 12.07 10.7091 12.0093 10.4617 11.888C10.2191 11.762 10.0301 11.587 9.89473 11.363C9.76406 11.1343 9.69873 10.866 9.69873 10.558V8.332C9.69873 8.01933 9.76406 7.751 9.89473 7.527C10.0301 7.303 10.2191 7.13033 10.4617 7.009C10.7091 6.883 10.9961 6.82 11.3227 6.82C11.6541 6.82 11.9387 6.883 12.1767 7.009C12.4194 7.13033 12.6084 7.303 12.7437 7.527C12.8791 7.751 12.9467 8.01933 12.9467 8.332H12.0647C12.0647 8.08933 11.9994 7.905 11.8687 7.779C11.7427 7.653 11.5607 7.59 11.3227 7.59C11.0847 7.59 10.9004 7.653 10.7697 7.779C10.6391 7.905 10.5737 8.087 10.5737 8.325V10.558C10.5737 10.796 10.6391 10.9803 10.7697 11.111C10.9004 11.2417 11.0847 11.307 11.3227 11.307C11.5607 11.307 11.7427 11.2417 11.8687 11.111C11.9994 10.9803 12.0647 10.796 12.0647 10.558V10.04H11.1967V9.284H12.9467V10.558C12.9467 10.866 12.8791 11.1343 12.7437 11.363C12.6084 11.587 12.4194 11.762 12.1767 11.888C11.9387 12.0093 11.6541 12.07 11.3227 12.07Z"
        fill={color}
      />
    </svg>
  );
};

export default PNGIcon;
