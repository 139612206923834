import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";

import { Link, useLocation, useNavigate } from "react-router-dom";

function Customers({ children, index }: any) {
  const setTitle = useSetRecoilState(titleAtom);
  const location = useLocation();

  // Extract the pathname from the location object
  const currentPath = location.pathname;
  const customerHeading = [
    {
      title: "Customers",
      url: "/customers",
    },
    {
      title: "Employee",
      url: "/customers/professionals/employee",
    },
    {
      title: "Company",
      url: "/customers/professionals/company",
    },
    {
      title: "Pending",
      url: "/customers/professionals/pending-requests",
    },
  ];

  return (
    <div className="w-full flex flex-col h-[calc(100vh-2.5rem)] ">
      <div className="flex flex-row justify-start items-center border-b min-h-[2.5rem] ">
        {customerHeading?.map((item, index) => {
          return (
            <Link to={item?.url} key={index}>
              <p
                key={index}
                className={`h-[2.5rem] flex items-center w-auto text-sm px-5 text-center cursor-pointer font-gilroy-bold border-r ${
                  currentPath == item?.url ? "bg-black text-white" : ""
                }`}
              >
                {item?.title}
              </p>
            </Link>
          );
        })}
      </div>

      <div className=" h-[calc(100%-2.5rem)] no-scrollbar overflow-y-scroll ">
        {children}
      </div>
    </div>
  );
}

export default Customers;
