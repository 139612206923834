import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { useSticky } from "react-table-sticky";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import Button from "../../../components/Button";
import ManagementTableLayout from "../../../components/MangementTable";
import ShowEntries from "../../../components/ShowEntries";
import PlusIcon from "../../../assets/PlusIcon";
import EditIcon from "../../../assets/EditIcon";
import TrashIcon from "../../../assets/TrashIcon";
import loadingSpinner from "../../../assets/svg/spinner.svg";
import { deleteSeries, getSeries } from "../../../api/product_series";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRecoilState, useSetRecoilState } from "recoil";
import { productSeriesAtom, seriesDataCount } from "../../../atom";
import CrossIcon from "../../../assets/CrossIcon";
import Pagination from "../../../components/Pagination";
import Modal from "../../../components/common/Modal";
import _debounce from "lodash/debounce";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import Warning from "../../../assets/svg-tsx/Warning";

const Table = () => {
  const [totalCount, setTotalCount] = useState<number>(100);
  const [page, setPage] = useState(1);
  const [pageLength, setPageLength] = useState<number>(25);
  const [loading, setLoading] = useState(true);
  const [inputVal, setInputVal] = useState("");
  const [seriesData, setSeriesData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [deleteModal, setDeleteModal] = useState<any>("");
  const [seriesCount, setSeriesCount] = useRecoilState(seriesDataCount);
  const setProductSeriesState = useSetRecoilState(productSeriesAtom);

  const fetchSeriesData = async () => {
    setLoading(true);
    getSeries({ page: page, length: pageLength, search: inputVal })
      .then((res) => {
        const { series, pagination } = res.data;
        setSeriesData(series);
        setSeriesCount(pagination.total_count);
        setTotalCount(pagination.total_count);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const [time, setTime] = useState(0);
  const debouncedFetchSeriesData = _debounce(fetchSeriesData, time);
  useEffect(() => {
    setTime(500);
    setPage(1);
    const fetchData = async () => {
      await debouncedFetchSeriesData();
    };
    fetchData();
    return () => {
      debouncedFetchSeriesData.cancel();
    };
  }, [inputVal, pageLength]);

  useEffect(() => {
    setTime(0);
    const fetchData = async () => {
      await debouncedFetchSeriesData();
    };
    fetchData();
    return () => {
      debouncedFetchSeriesData.cancel();
    };
  }, [page]);

  const handleDeleteSeries = async (id: any) => {
    try {
      await protectedAxiosInstance.delete(`/admin/products/series/${id}`);
      let settablePage: any;
      if ((totalCount - 1) % pageLength === 0) {
        settablePage = page - 1;
      } else {
        settablePage = page;
      }
      setPage(settablePage === 0 ? 1 : settablePage);
      setDeleteModal("");
      toast.success("Series deleted successfully");
      setProductSeriesState([]);
      fetchSeriesData();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting series");
    }
  };

  const COLUMN = [
    {
      id: "sr",
      Header: () => <div>Sr. No</div>,
      width: "4.5%",
      accessor: "sr",
      Cell: ({ row }: { row: any }) => <div>{startIndex + row.index + 1}</div>,
    },
    {
      id: "full_name",
      Header: () => <div>Series</div>,
      accessor: "full_name",
      width: "200px",
      enableResizing: false,
      Cell: ({ value }: { value: any }) => (
        <p className="break-words max-w-[200px]">{value}</p>
      ),
    },
    {
      id: "short_name",
      Header: () => <div>Short Name</div>,
      accessor: "short_name",
      width: "200px",
      Cell: ({ value }: { value: any }) => (
        <p className="break-words max-w-[200px]">{value}</p>
      ),
    },
    {
      id: "product_count",
      Header: () => <div>Total products assigned</div>,
      accessor: "product_count",
      Cell: ({ value }: { value: any }) => (
        <div className="py-1.5">{value}</div>
      ),
    },
    {
      id: "id",
      Header: () => <div>Action</div>,
      width: "8%",
      accessor: "id",
      Cell: ({ row }: { row: any }) => (
        <div className="flex gap-x-5 px-2 items-center">
          <Link
            to={`/products/series/${row.original.id}`}
            className="cursor-pointer"
          >
            <EditIcon />
          </Link>
          <div
            onClick={() => setDeleteModal(row.original.id)}
            className="cursor-pointer"
          >
            <TrashIcon />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const lastIndex = (page - 1) * pageLength;
    setStartIndex(lastIndex);
  }, [page, pageLength, totalCount]);

  const columns = useMemo(() => COLUMN, [seriesData]);
  const data = useMemo(() => seriesData, [seriesData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
      useSticky,
    },
    useSortBy,
    usePagination
  );
  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };
  return (
    <div className="h-full">
      <div className="flex flex-row justify-between items-center py-2 w-full px-3 h-[3rem] ">
        <div className="flex gap-4 w-[max-content]">
          <div className=" w-[235px]">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Search here"
              value={inputVal}
              rightIcon={<SearchIcon />}
            />
          </div>
          <div className="w-fit">
            <Link to={"/products/series/create"}>
              <Button label="Create Series" leftIcon={<PlusIcon />} />
            </Link>
          </div>
        </div>
      </div>

      <div className="h-[calc(100%-5.5rem)] w-full relative -z-0">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>
      {loading ? (
        ""
      ) : (
        <div className="w-full">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}

      {deleteModal && (
        <Modal
          top
          header="Delete Series"
          handleCancel={() => setDeleteModal("")}
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => handleDeleteSeries(deleteModal)}
              />
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setDeleteModal("");
                }}
              />
            </div>
          }
        >
          <div className="flex flex-col gap-1">
            <p className="text-left">
              Are you sure you want to delete this series?
            </p>
            {/* <div className="flex gap-2 text-red-600 text-sm items-center">
              <Warning size="14" />
              <p>
                All the products associated with this series will be deleted.
              </p>
            </div> */}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Table;
