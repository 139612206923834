import { protectedAxiosInstance } from "./axiosManagement";

export const getProfessional = (params) => {
  return protectedAxiosInstance
    .get("/admin/accounts/professional", {
      params: params,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getProfessionalList = (params) => {
  return protectedAxiosInstance
    .get("/admin/accounts/professional/quick-list", {
      params: params,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getSingleProfessional = (id) => {
  return protectedAxiosInstance
    .get(`/admin/accounts/professional/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateProfessional = (id, payload) => {
  return protectedAxiosInstance
    .patch(`/admin/accounts/professional/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postProfessional = (payload) => {
  return protectedAxiosInstance
    .post("/admin/accounts/customers", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getProfessions = () => {
  return protectedAxiosInstance
    .get("/admin/accounts/profession")
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const getInvitedProfessional = (id) => {
  return protectedAxiosInstance
    .get(`/admin/projects/${id}/invite-professionals`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateProfessionalChangeStatus = (id, payload) => {
  return protectedAxiosInstance
    .post(`/admin/accounts/customers/${id}/change-status`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getProfessionalStatusHistory = (id) => {
  return protectedAxiosInstance
    .get(`/admin/accounts/professional/${id}/status/history`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

