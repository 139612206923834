import SeriesIcon from '../../../assets/SeriesIcon';
import ProductCard from './Card';

const ProductCounts = ({ totalCount } : any) => {


  const cardValues = [
    {
      count: totalCount,
      icon: <SeriesIcon color='#AEAEAE'/>,
      color: "#AEAEAE",
      text: "Total Products"
    },
  ];

  return (
    <div className="w-full flex gap-x-6 px-4 py-2">
      {cardValues.map((each, index) => (
        <ProductCard
          key={index}
          {...each}
        />
      ))}
    </div>
  );
}

export default ProductCounts