import React from "react";
import { IconProp } from "./ArrowIcons";

const CrossIcon = ({ color, width }: IconProp) => {
  return (
    <>
      <svg
        width={width || "8"}
        height={width || "8"}
        viewBox={`0 0 8 8 `}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.0606 3.9995L7.56687 1.49906C7.67662 1.38931 7.73828 1.24045 7.73828 1.08524C7.73828 0.930023 7.67662 0.781166 7.56687 0.671412C7.45712 0.561659 7.30826 0.5 7.15304 0.5C6.99783 0.5 6.84897 0.561659 6.73922 0.671412L4.23878 3.17768L1.73834 0.671412C1.62859 0.561659 1.47973 0.5 1.32451 0.5C1.1693 0.5 1.02044 0.561659 0.910688 0.671412C0.800935 0.781166 0.739276 0.930023 0.739276 1.08524C0.739276 1.24045 0.800935 1.38931 0.910688 1.49906L3.41696 3.9995L0.910688 6.49994C0.856058 6.55413 0.812697 6.61859 0.783107 6.68962C0.753516 6.76064 0.738281 6.83682 0.738281 6.91377C0.738281 6.99071 0.753516 7.06689 0.783107 7.13792C0.812697 7.20894 0.856058 7.27341 0.910688 7.32759C0.964872 7.38222 1.02934 7.42558 1.10036 7.45517C1.17139 7.48477 1.24757 7.5 1.32451 7.5C1.40146 7.5 1.47764 7.48477 1.54867 7.45517C1.61969 7.42558 1.68416 7.38222 1.73834 7.32759L4.23878 4.82133L6.73922 7.32759C6.7934 7.38222 6.85787 7.42558 6.92889 7.45517C6.99992 7.48477 7.0761 7.5 7.15304 7.5C7.22999 7.5 7.30617 7.48477 7.37719 7.45517C7.44822 7.42558 7.51268 7.38222 7.56687 7.32759C7.6215 7.27341 7.66486 7.20894 7.69445 7.13792C7.72404 7.06689 7.73928 6.99071 7.73928 6.91377C7.73928 6.83682 7.72404 6.76064 7.69445 6.68962C7.66486 6.61859 7.6215 6.55413 7.56687 6.49994L5.0606 3.9995Z"
          fill={color || "#EBA5AF"}
        />
      </svg>
    </>
  );
};

export default CrossIcon;
