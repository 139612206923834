import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { productCategoryAtom, titleAtom } from "../../atom";

import ProductCounts from "./productcomponents/Counts";
import Table from "./productcomponents/Table";
import { protectedAxiosInstance } from "../../api/axiosManagement";

export default function ProductList() {
  const setTitle = useSetRecoilState(titleAtom);
  const [totalCount, setTotalCount] = useState(0);
  const [categoryList, setCategoryList] = useRecoilState(productCategoryAtom);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await protectedAxiosInstance.get(
          "/admin/products/category",
          {
            params: {
              page: 1,
              length: 200,
            },
          }
        );

        setCategoryList(response?.data?.data?.category);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (!categoryList || categoryList?.length === 0) {
      fetchCategories();
    }
  }, [categoryList, setCategoryList]);

  return (
    <div className="h-full flex flex-col justify-start items-start">
      <div className="h-[13vh] w-full flex items-center justify-start">
        <ProductCounts totalCount={totalCount} />
      </div>
      <div className="h-[calc(100%-13vh)] w-full">
        <Table setCount={setTotalCount} categoryList={categoryList} />
      </div>
    </div>
  );
}
