import React from "react";

const EyeIcon = ({ size = "14" }: any) => {
  return (
    <svg
      className="svg-icon"
      width={size}
      height={size}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M976.474353 571.934118c-8.402824 10.24-209.588706 249.946353-464.474353 249.946353S55.928471 582.174118 47.495529 571.934118l-17.468235-21.112471 17.468235-21.142588C55.928471 519.469176 257.084235 279.762824 512 279.762824s456.071529 239.706353 464.474353 249.916235l17.468235 21.142588L976.474353 571.934118zM512 344.816941c-184.470588 0-343.943529 149.564235-397.492706 205.974588 53.669647 56.350118 213.443765 206.034824 397.492706 206.034824 184.470588 0 343.943529-149.564235 397.492706-205.974588C855.823059 494.471529 696.048941 344.816941 512 344.816941zM512 691.772235c-75.203765 0-136.372706-63.247059-136.372706-140.950588s61.168941-140.950588 136.372706-140.950588 136.372706 63.247059 136.372706 140.950588S587.173647 691.772235 512 691.772235zM512 474.925176c-40.478118 0-73.426824 34.063059-73.426824 75.926588s32.948706 75.896471 73.426824 75.896471 73.426824-34.032941 73.426824-75.896471S552.478118 474.925176 512 474.925176z" />
    </svg>
  );
};

export default EyeIcon;
