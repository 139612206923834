import DummyGallery from "../../../assets/svg/dummygallery.svg";

export default function BigBlockWithImgPreview() {
  return (
    <div className="flex flex-col gap-3 text-left ">
      <div>
        <p className="text-[#AEAEAE] text-md">Preview:</p>
      </div>
      <div className="border w-[65%] flex flex-row">
        <div className="flex flex-col w-[70%]">
          <div className="p-2 border border-b">
            <p className=" h-6 m-auto bg-[#DEDEDE] "></p>
          </div>
          <div className="px-2 py-3 border border-b flex flex-col  justify-start items-start gap-4">
            <p className=" h-6 bg-[#ECECEC] w-40"></p>
            <p className=" h-6 bg-[#ECECEC] w-48"></p>
            <p className=" h-6 bg-[#ECECEC] w-36"></p>
          </div>
          {/* <div className="px-2 py-3 border border-b">
            <p className=" h-6 bg-[#DEDEDE] w-28"></p>
          </div> */}
        </div>
        <div className="w-[30%] flex flex-col items-center bg-[#ECECEC]">
          <div className="p-2 w-full border border-b bg-white">
            <p className=" h-6  m-auto bg-[#DEDEDE] "></p>
          </div>
          <img
            src={DummyGallery}
            alt="DummyGallery"
            className="w-10 h-10  m-auto"
          />
        </div>
      </div>
    </div>
  );
}
