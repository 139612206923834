import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import OrderDetails from "./OrderDetails";
import ConsultantFiles from "./ConsultantFiles";
import UserFiles from "./UserFiles";
import Timeline from "./Timeline";
import DetailSection from "./DetailSection";
import RejectionReason from "./RejectionReason";

const ConsultantDetail = () => {
  const id = useParams();
  console.log(id, "id");


  return(
    <div className="flex flex-row relative">
      {/* First Column  */}
      <div className="w-[75%] border-r-2 px-5 py-6">
            <OrderDetails />
            <ConsultantFiles />
            <UserFiles />
            <Timeline />
      </div>
      <div className="max-h-full sticky top-10 overflow-y-auto w-[25%] border-r-2 mr-3.5">
        <DetailSection />
        <RejectionReason />
      </div>
    </div>
  );
};

export default ConsultantDetail;
