import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../components/common/Modal";
import Label from "../../../components/Label";
import SelectField2 from "../../../components/SelectedField2";
import Button from "../../../components/Button";
import ProfessionalDropdown from "../../../components/ProfessionalDropdown";
import { useParams } from "react-router-dom";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import AuthInputField from "../../../components/AuthInputField";
import { validateNumber } from "../../../utils/validations";
import { toast } from "react-toastify";
import { getProfessionalList } from "../../../api/professional";
import ErrorMessage from "../../../components/ErrorMessage";

const InviteModal = ({ setShowInviteModal, getSingleProjectData }: any) => {
  const { id: projectID } = useParams();
  const [professionalList, setProfessionalList] = useState<any>([]);
  const [inviteProfessionalLoading, setInviteProfessionalLoading] =
    useState(false);
  const [customerInviteStage, setCustomerInviteStage] = useState(0);
  const [professionalInviteStage, setProfessionalInviteStage] = useState(0);
  const [inviteError, setInviteError] = useState("");
  const [inviteLoading, setInviteLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [invitePhone, setInvitePhone] = useState("");

  const debounceTimeoutRef = useRef<any>(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLength, setPageLength] = useState(200);
  const [professionalId, setProfessionalId] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [totalPages, setTotalPages] = useState(200); // Initial total number of pages

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfessionalList({
          search: debouncedSearchTerm || "",
          page: currentPage || "",
          length: totalPages || "",
          kind: "p_individual",
        });
        setProfessionalList(response?.data?.professional);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, [debouncedSearchTerm, currentPage, totalPages]);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchTerm(searchQuery);
    }, 400);
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchQuery]);

  const handleClear = () => {
    setProfessionalId("");
    delete errors?.p_individual_id;
  };

  useEffect(() => {
    delete errors?.p_individual_id;
  }, [searchQuery]);
  const handleAddProfessional = async () => {
    if (!professionalId) {
      setErrors({
        ...errors,
        p_individual_id: "Please select a professional.",
      });
      return;
    }

    setInviteProfessionalLoading(true);
    try {
      const response = await protectedAxiosInstance.post(
        `/admin/projects/${projectID}/invite-professionals`,
        { p_individual_id: professionalId }
      );

      setSearchQuery("");
      setProfessionalInviteStage(1);
    } catch (error: any) {
      console.error("Error adding professional:", error);
      setErrors({ ...errors, p_individual_id: error?.data?.errors });
    } finally {
      setInviteProfessionalLoading(false);
    }
    getSingleProjectData(projectID);
  };

  const handleSendInvite = () => {
    setInviteError("");
    if (inviteLoading) {
      return;
    }
    setInviteLoading(true);
    protectedAxiosInstance
      .post(`/accounts/otp/send`, {
        phone: countryCode + invitePhone,
      })
      .then((res: any) => {
        setOtpKey(res?.data?.data?.otp_key);
        setExpiryTime(25);
        setCustomerInviteStage(1);
      })
      .catch((err) => {
        setInviteError(err?.data?.errors?.phone);
      })
      .finally(() => {
        setInviteLoading(false);
      });
  };
  // otp modal
  const inputRefs = Array.from(
    { length: 6 },
    () => useRef<HTMLInputElement>(null) //eslint-disable-line
  );
  const [number, setNumber] = useState<string[]>([]);
  const [otpError, setOtpError] = useState<string>("");
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [expiryTime, setExpiryTime] = useState(25);
  const [otpKey, setOtpKey] = useState("");
  const [otpVerifyLoading, setOtpVerifyLoading] = useState(false);
  const [otpResendLoading, setOtpResendLoading] = useState(false);

  const handleInput = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    setOtpError("");
    const value = event.currentTarget.value;
    if (validateNumber(value) === false) {
      return;
    }
    const newNumber = [...number];
    newNumber[index] = value;
    setNumber(newNumber);

    if (value.length === 1 && index < 5) {
      inputRefs[index + 1].current?.focus();
    } else if (value.length === 1 && index === 5) {
      inputRefs[index].current?.blur();
    }
  };

  const handleBackspace = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && !event.currentTarget.value) {
      event.preventDefault();
      const newNumber = [...number];
      newNumber[index - 1] = "";
      setNumber(newNumber);
      inputRefs[index - 1].current?.focus();
      setOtpError("");
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (expiryTime > 0) {
        setExpiryTime((prevTime) => prevTime - 1);
      } else {
        setIsResendDisabled(false);
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [expiryTime]);

  useEffect(() => {
    setIsResendDisabled(true);
  }, [expiryTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setExpiryTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, [number]);

  const handleResendOTP = () => {
    setOtpResendLoading(true);
    setOtpError("");
    protectedAxiosInstance
      .post(`/accounts/otp/send`, {
        phone: countryCode + invitePhone,
      })
      .then((res: any) => {
        setOtpKey(res?.data?.data?.otp_key);
        toast.success("OTP sent successfully");
        setIsResendDisabled(true);
        setExpiryTime(25);
      })
      .catch((err) => {
        toast.error("OTP sent failed");
      })
      .finally(() => {
        setOtpResendLoading(false);
      });
  };
  const handleVerifyOtp = () => {
    setOtpError("");
    if (number.length !== 6) {
      setOtpError("Not a valid otp. An OTP is must be of 6 digits.");
      inputRefs[number.length].current?.focus();
      return;
    }
    setOtpVerifyLoading(true);
    protectedAxiosInstance
      .post(`/accounts/otp/verify`, {
        otp_key: otpKey,
        otp: Number(number.join("")),
      })
      .then((res: any) => {
        if (res?.data?.data?.is_verified) {
          protectedAxiosInstance
            .post(`/admin/projects/${projectID}/invite-customer`, {
              otp_key: otpKey,
            })
            .then((res) => {
              toast.success("Customer invited successfully.");
              setCustomerInviteStage(2);
              getSingleProjectData();
            })
            .catch((err) => {
              setCustomerInviteStage(0);
              setInviteError(
                err?.data?.errors || err?.message || "An error occurred"
              ); // here I have doubt
            })
            .finally(() => {
              setOtpVerifyLoading(false);
            });
        } else {
          setOtpVerifyLoading(false);
          setOtpError("Invalid OTP");
          inputRefs[0].current?.focus();
          setNumber([]);
        }
      })
      .catch(() => {
        setOtpVerifyLoading(false);
      });
  };

  return (
    <Modal
      header="Invite"
      handleCancel={() => {
        setProfessionalId("");
        setShowInviteModal(false);
      }}
    >
      <div className="flex flex-col w-full">
        <hr />
        <div className="py-3 flex flex-col gap-3">
          <p className="text-base font-gilroy-semi-bold ">
            Invite Professional
          </p>

          <p
            className={`font-gilroy-regular text-xs ${
              professionalInviteStage === 0 ||
              (professionalInviteStage === 1 && inviteProfessionalLoading)
                ? ""
                : "hidden"
            }`}
          >
            {professionalInviteStage === 0
              ? "Invite will be sent to the professional"
              : professionalInviteStage === 1 && inviteProfessionalLoading
              ? "Invite has been sent to professional"
              : ""}
          </p>
          <ProfessionalDropdown
            smallText
            width="100%"
            label="Professional Name"
            Xsearch={searchQuery}
            setXSearch={setSearchQuery}
            options={professionalList.map((el: any) => ({
              value: el.p_individual_id,
              label: el,
            }))}
            onChange={(e: any) => {
              setProfessionalId(e);
              setErrors({
                ...errors,
                p_individual_id: null,
              });
            }}
            hint={errors?.p_individual_id}
            value={professionalId}
            searchPlaceHolder="Search employee"
            onClear={() => {
              setProfessionalId("");
              setErrors({
                ...errors,
                p_individual_id: null,
              });
            }}
            disabled={
              inviteProfessionalLoading || professionalInviteStage === 1
            }
          />
          {professionalInviteStage === 1 && (
            <p className="font-gilroy-bold text-[0.7rem] text-[#0F9846] tracking-wider">
              Professional has been added
            </p>
          )}
          <div>
            {professionalInviteStage === 0 ? (
              <Button
                label="Add Professional"
                onClick={handleAddProfessional}
                disabled={inviteProfessionalLoading}
              />
            ) : professionalInviteStage === 1 ? (
              <>
                <Button label="Professional Added" disabled />
              </>
            ) : null}
          </div>
        </div>
        <hr />
        <div className="pt-3 flex flex-col gap-3">
          <p className="text-base font-gilroy-semi-bold ">Invite Customer</p>
          {customerInviteStage === 0 && (
            <>
              <p className="font-gilroy-regular text-xs">
                Invite will be sent to the customer
              </p>
              <AuthInputField
                heading="Customer Phone Number"
                value={invitePhone}
                onChange={(e: any) => {
                  setInvitePhone(e);
                }}
                type="telephone"
                placeholder="XXXXXXXXXX"
                validate={validateNumber}
                maxLength={15}
                country_code_value="+91"
                setCode={setCountryCode}
              />
            </>
          )}
          {customerInviteStage === 1 && (
            <>
              <p className="font-gilroy-regular text-xs">
                Invite has been sent to the customer
              </p>
              <div>
                <Label label="Enter OTP" />
                <div className="flex max-w-[100%] gap-2">
                  {inputRefs.map((ref, index) => (
                    <input
                      key={index}
                      type="text"
                      inputMode="numeric"
                      maxLength={1}
                      className="text-black flex-1 h-10 w-[15%]  lg:h-10  border border-[#4D4D4D]  text-center outline-none text-md  font-normal  hover:border-2 hover:border-[#A2A2A2] hover:border-opacity-50 focus:border-[#4D4D4D] placeholder:text-[#A2A2A2] placeholder:text-sm active:border-[#A2A2A2] active:border-2"
                      ref={ref}
                      autoFocus={index === 0}
                      value={number[index] || ""}
                      onChange={(event: any) => handleInput(index, event)}
                      onKeyDown={(
                        event: React.KeyboardEvent<HTMLInputElement>
                      ) => handleBackspace(index, event)}
                    />
                  ))}
                </div>
                <ErrorMessage error={otpError} />
              </div>
            </>
          )}

          {customerInviteStage === 2 && (
            <>
              <AuthInputField
                heading="Customer Phone Number"
                value={invitePhone}
                onChange={(e: any) => {
                  setInvitePhone(e);
                }}
                type="telephone"
                placeholder="XXXXXXXXXX"
                validate={validateNumber}
                maxLength={15}
                country_code_value="+91"
                setCode={setCountryCode}
                disabled
              />
              <p className="font-gilroy-bold text-[0.7rem] text-[#0F9846] tracking-wider">
                Customer has been added
              </p>
            </>
          )}

          <div className="flex gap-x-4">
            {customerInviteStage === 0 ? (
              <Button
                label="Send OTP"
                onClick={handleSendInvite}
                disabled={inviteLoading}
              />
            ) : customerInviteStage === 1 ? (
              <Button
                label="Continue"
                onClick={handleVerifyOtp}
                disabled={otpVerifyLoading || otpResendLoading}
              />
            ) : (
              <Button label="Customer Added" disabled />
            )}

            {customerInviteStage === 0 ? (
              <Button
                variant="secondary-outline"
                label="Cancel"
                onClick={() => setShowInviteModal(false)}
                disabled={inviteLoading}
              />
            ) : customerInviteStage === 1 ? (
              <Button
                variant="secondary-outline"
                label="Cancel"
                onClick={() => {
                  setCustomerInviteStage(0);
                  setOtpError("");
                  setOtpKey("");
                  setNumber([]);
                }}
                disabled={otpVerifyLoading || otpResendLoading}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InviteModal;
