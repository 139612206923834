import React from "react";

type IconProp = {
  color?: string;
  width?: string;
};
const TickIcon = ({ color, width }: IconProp) => {
  return (
    <svg
      width={width || "10"}
      height={width || "8"}
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.47322 0.806696C9.41124 0.74421 9.33751 0.694614 9.25627 0.660768C9.17503 0.626922 9.08789 0.609497 8.99989 0.609497C8.91188 0.609497 8.82474 0.626922 8.7435 0.660768C8.66226 0.694614 8.58853 0.74421 8.52655 0.806696L3.55989 5.78003L1.47322 3.6867C1.40887 3.62454 1.33291 3.57566 1.24967 3.54286C1.16644 3.51006 1.07755 3.49397 0.9881 3.49552C0.898646 3.49706 0.810373 3.51622 0.728321 3.55188C0.646269 3.58754 0.572044 3.63902 0.509885 3.70336C0.447726 3.76771 0.39885 3.84367 0.366047 3.92691C0.333245 4.01014 0.317158 4.09903 0.318705 4.18848C0.320253 4.27794 0.339405 4.36621 0.375067 4.44826C0.41073 4.53031 0.462204 4.60454 0.526552 4.6667L3.08655 7.2267C3.14853 7.28918 3.22226 7.33878 3.3035 7.37262C3.38474 7.40647 3.47188 7.42389 3.55989 7.42389C3.64789 7.42389 3.73503 7.40647 3.81627 7.37262C3.89751 7.33878 3.97124 7.28918 4.03322 7.2267L9.47322 1.7867C9.54089 1.72427 9.59489 1.6485 9.63183 1.56417C9.66877 1.47983 9.68784 1.38876 9.68784 1.2967C9.68784 1.20463 9.66877 1.11356 9.63183 1.02923C9.59489 0.944892 9.54089 0.869124 9.47322 0.806696Z"
        fill={color || "currentColor"}
        stroke={color || "inherit"}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default TickIcon;
