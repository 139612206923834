import React, { useState } from "react";
import { motion } from "framer-motion";
import HipchatChevronDownIcon from "../../assets/HipchatChevronDownIcon";
import PlusIcon from "../../assets/PlusIcon";
import UploadIcon from "../../assets/UploadIcon";
interface CustomDropdownProps {
  children: React.ReactNode;
  label: string;
  createButton?: string;
  sideType?: boolean;
  upload?: boolean;
}
export const CustomDropdown: React.FC<CustomDropdownProps> = ({
  children,
  label,
  createButton = "",
  sideType = false,
  upload = false,
}: CustomDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      <div className={`w-full ${!sideType && "border-2 mt-4"} h-auto`}>
        <div
          className={`flex justify-between items-center ${
            sideType ? "border-b px-4" : "bg-[#FBFBFB] px-5"
          } h-[40px] py-2`}
        >
          <p className="font-medium text-[14px] leading-5">{label}</p>
          <motion.div
            className="w-fit flex gap-x-5 items-center justify-center"
            onClick={handleDropdownClick}
          >
            {createButton && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!createButton) {
                    handleDropdownClick();
                  }
                }}
                className="w-fit flex gap-x-2 items-center"
              >
                <p
                  className={upload ? ` text-pot-yellow` : "stroke-pot-yellow"}
                >
                  {upload ? <UploadIcon /> : <PlusIcon />}
                </p>
                <p className="text-pot-yellow text-xs whitespace-nowrap">
                  {createButton}
                </p>
              </button>
            )}

            <motion.button animate={{ rotate: isDropdownOpen ? 180 : 0 }}>
              <HipchatChevronDownIcon color="black" width="22" />
            </motion.button>
          </motion.div>
        </div>

        {isDropdownOpen && (
          <motion.div
            layout
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{
              opacity: 0,
              y: -30,
              transition: { duration: 0.2 },
            }}
            transition={{ delay: 0.1, duration: 0.2 }}
          >
            {children}
          </motion.div>
        )}
      </div>
    </div>
  );
};
