import { CustomDropdown } from "../../DropDown";
import FileIcon from "../../../../assets/FileIcon";
import PdfIcon from "../../../../assets/PdfIcon";

const Documents = () => {
  const docs = [
    {
      name: "Unknown_file.pdf",
      size: "1.5 MB",
      date: "25th March ‘23",
    },
    {
      name: "Unknown_file.jpeg",
      image: "",
      size: "1.5 MB",
      date: "25th March ‘23",
    },
    {
      name: "Unknown_file.pdf",
      size: "1.5 MB",
      date: "25th March ‘23",
    },
  ];
  return (
    <CustomDropdown label="Documents">
      <div className="px-4 py-3 max-h-[50vh] overflow-scroll no-scrollbar flex flex-wrap gap-x-5">
        {docs.map((each, i) => (
          <div
            key={i}
            className="min-h-[20vh] flex flex-col gap-y-2 pb-2 overflow-hidden border"
          >
            {(!each.image || each.name.endsWith(".pdf")) && (
              <div className="w-full h-full bg-pot-grey4/70 flex justify-center items-center pt-[3vh]">
                {/* <div className=""> */}
                {each.name.endsWith(".pdf") && <PdfIcon />}
                {/* </div> */}
              </div>
            )}
            <div className="flex gap-x-1 px-2">
              <FileIcon />
              <div>
                <p className="text-xs text-left">{each.name}</p>
                <p className="text-[11px] text-left">
                  {[each.size, each.date].join(" | ")}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </CustomDropdown>
  );
};

export default Documents;
