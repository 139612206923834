import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";

import SeriesCounts from "./seriescomponents/Counts";
import SeriesTable from "./seriescomponents/Table";

export default function SeriesList() {
  return (
    <div className="h-full flex flex-col justify-start items-start">
      <div className="h-[13vh] w-full flex items-center justify-start">
        <SeriesCounts />
      </div>
      <div className="h-[calc(100%-13vh)] w-full">
        <SeriesTable />
      </div>
    </div>
  );
}
