
import Button from "../../../components/Button";
import TextArea from "../../../components/TextArea";
import Modal from "../../../components/common/Modal";

type ReSubmitFormType = {
  handleClose: () => void;
  handleReSubmitForm: any;
  setSubmitData?: any;
  submitData?: any;
  openSubmitModal?: any;
  errors?: any;
  setErrors?: any;
  loading?: any;
};

export default function ReSubmitForm({
  handleClose,
  handleReSubmitForm,
  submitData,
  setSubmitData,
  openSubmitModal,
  errors,
  setErrors,
  loading = false,
}: ReSubmitFormType) {
  console.log(submitData);

  return (
    <Modal
      header={
        openSubmitModal === "re-submit"
          ? "Re-submit form"
          : openSubmitModal === "reject"
          ? "Reject form"
          : ""
      }
      footer={
        <div className="flex gap-4 w-full">
          <Button
            label={"Send"}
            variant="secondary"
            onClick={handleReSubmitForm}
            disabled={loading}
          />
          <Button
            label={"Cancel"}
            variant="secondary-text"
            onClick={handleClose}
            disabled={loading}
          />
        </div>
      }
      handleCancel={handleClose}
    >
      <div className="flex flex-col w-full">
        <TextArea
          value={submitData?.comment || ""} // Remove leading and trailing whitespace
          onChange={(e: any) => {
            setSubmitData((prev: any) => ({
              ...prev,
              comment: e,
            }));
            setErrors((prev: any) => ({
              ...prev,
              comment: null,
            }));
          }}
          disabled={loading}
          placeholder="Add comments"
          label="Add comments"
          className="w-full"
          rows={4}
          required
          hint={errors?.comment}
        />
      </div>
    </Modal>
  );
}
