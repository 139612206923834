import React, { useRef, useState } from "react";
import InputField, { InputFieldType } from "../../../components/InputField";
import useClickOutside from "../../../hooks/useClickOutside";
import { motion } from "framer-motion";
import { DownwardArrowIcon } from "../../../assets/ArrowIcons";
import EmailIcon from "../../../assets/EmailIcon";
import PhoneIcon from "../../../assets/PhoneIcon";

type OptionType = {
  id: string;
  name: string;
  email: string;
  phone: string;
  hint?: any;
};

type DropdownPropType = {
  options: OptionType[];
  onSelect: (value: OptionType) => void;
  hint?: any;
};

const ProjectDropdownSearchable = ({
  options,
  onSelect,
  hint,
  ...props
}: InputFieldType & DropdownPropType) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const inputRef = useRef(null);
  useClickOutside(inputRef, () => setOpenDropdown(false));

  return (
    <div ref={inputRef} className="relative mt-3">
      <div
        className="flex justify-between"
        onClick={() => setOpenDropdown((prev) => !prev)}
      >
        <InputField
          rightIcon={
            <motion.div
              className="stroke-black"
              animate={{ rotate: openDropdown ? 180 : 0 }}
            >
              <DownwardArrowIcon />
            </motion.div>
          }
          {...props}
        />
      </div>
      {hint &&
        (typeof hint === "string" ? (
          <p className="flex px-1 text-left font-light text-xs text-red-700 h-0">
            {hint}
          </p>
        ) : hint.length ? (
          hint.length === 1 ? (
            <p className="px-1 text-left font-light text-xs text-red-700 h-0">
              {hint[0]}
            </p>
          ) : (
            <ul className="list-disc px-4">
              {hint.map((each: any) => (
                <li
                  key={each}
                  className="text-left font-light text-xs text-red-700 h-0"
                >
                  {each}
                </li>
              ))}
            </ul>
          )
        ) : (
          ""
        ))}

      {openDropdown && (
        <div className="absolute cursor-pointer text-sm z-10 top-[4.3rem] w-[100%] max-h-[40vh] overflow-y-auto custom-scrollbar bg-white rounded-md border min-h-[40px]">
          {options?.length === 0 ? (
            <p className="flex justify-center px-4 py-2">
              No Projects Available
            </p>
          ) : (
            options?.map((each) => (
              <div
                onClick={() => {
                  onSelect(each);
                  setOpenDropdown(false);
                }}
                className="px-3 text-left flex flex-col py-2"
                key={each?.id}
              >
                <div className="font-gilroy-medium text-[14px]">
                  {each?.name}
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectDropdownSearchable;
