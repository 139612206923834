interface JPEGIconProps {
    width?: string;
    height?: string;
    color?: string;
  }
  
  const JPEGIcon = ({
    color = "black",
    width = "19",
    height = "21",
  }: JPEGIconProps) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.96341 3.80871C9.98931 3.86913 10.0018 3.93446 10 4.00017V6.00017H9V5.00017H6C5.73502 4.99939 5.48112 4.89379 5.29375 4.70642C5.10639 4.51905 5.00078 4.26515 5 4.00017V1.00017H1V13.0002H9V14.0002H1C0.734784 14.0002 0.48043 13.8948 0.292893 13.7073C0.105357 13.5197 0 13.2654 0 13.0002V1.00017C0.000778244 0.735195 0.106385 0.481293 0.293753 0.293925C0.481121 0.106557 0.735023 0.000950106 1 0.000171862H6C6.06572 -0.00163613 6.13105 0.0108391 6.19147 0.0367365C6.2519 0.0626338 6.30599 0.101336 6.35 0.150172L9.85 3.65017C9.89882 3.6942 9.93752 3.74828 9.96341 3.80871ZM6 1.20017V4.00017H8.8L6 1.20017Z"
          fill={color}
        />
        <path
          d="M2.8202 12.066C2.3762 12.066 2.0242 11.944 1.7642 11.7C1.5082 11.452 1.3802 11.12 1.3802 10.704H2.1302C2.1302 10.924 2.1922 11.096 2.3162 11.22C2.4402 11.344 2.6082 11.406 2.8202 11.406C3.0322 11.406 3.2002 11.346 3.3242 11.226C3.4482 11.102 3.5102 10.93 3.5102 10.71V8.316H2.4902V7.62H4.2602V10.71C4.2602 11.13 4.1302 11.462 3.8702 11.706C3.6142 11.946 3.2642 12.066 2.8202 12.066ZM4.75985 12V7.62H6.21785C6.50985 7.62 6.76385 7.676 6.97985 7.788C7.19985 7.9 7.36985 8.058 7.48985 8.262C7.60985 8.462 7.66985 8.698 7.66985 8.97C7.66985 9.238 7.60785 9.474 7.48385 9.678C7.36385 9.882 7.19585 10.04 6.97985 10.152C6.76385 10.264 6.50985 10.32 6.21785 10.32H5.50985V12H4.75985ZM5.50985 9.642H6.21785C6.42585 9.642 6.59185 9.582 6.71585 9.462C6.84385 9.338 6.90785 9.174 6.90785 8.97C6.90785 8.762 6.84385 8.598 6.71585 8.478C6.59185 8.358 6.42585 8.298 6.21785 8.298H5.50985V9.642ZM7.92351 12V7.62H10.5515V8.274H8.66151V9.414H10.3415V10.062H8.66151V11.346H10.5515V12H7.92351ZM12.3172 12.06C12.0372 12.06 11.7912 12.008 11.5792 11.904C11.3712 11.796 11.2092 11.646 11.0932 11.454C10.9812 11.258 10.9252 11.028 10.9252 10.764V8.856C10.9252 8.588 10.9812 8.358 11.0932 8.166C11.2092 7.974 11.3712 7.826 11.5792 7.722C11.7912 7.614 12.0372 7.56 12.3172 7.56C12.6012 7.56 12.8452 7.614 13.0492 7.722C13.2572 7.826 13.4192 7.974 13.5352 8.166C13.6512 8.358 13.7092 8.588 13.7092 8.856H12.9532C12.9532 8.648 12.8972 8.49 12.7852 8.382C12.6772 8.274 12.5212 8.22 12.3172 8.22C12.1132 8.22 11.9552 8.274 11.8432 8.382C11.7312 8.49 11.6752 8.646 11.6752 8.85V10.764C11.6752 10.968 11.7312 11.126 11.8432 11.238C11.9552 11.35 12.1132 11.406 12.3172 11.406C12.5212 11.406 12.6772 11.35 12.7852 11.238C12.8972 11.126 12.9532 10.968 12.9532 10.764V10.32H12.2092V9.672H13.7092V10.764C13.7092 11.028 13.6512 11.258 13.5352 11.454C13.4192 11.646 13.2572 11.796 13.0492 11.904C12.8452 12.008 12.6012 12.06 12.3172 12.06Z"
          fill={color}
        />
      </svg>
    );
  };
  
  export default JPEGIcon;
  