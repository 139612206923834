import React, { useState } from "react";
import DeleteIcon from "../../../assets/svg/trash.svg";
import EditIcon from "../../../assets/svg/pen.svg";
import CopyIcon from "../../../assets/svg/copyIcon.svg";
import {
  VariantBigBlockWithoutImageProps,
  VariantDataProps,
} from "../../../types";
import Button from "../../../components/Button";
import Modal from "../../../components/common/Modal";

type Card2Props = {
  el: VariantBigBlockWithoutImageProps;
  variantData?: VariantDataProps;
  setVariantData?: (data: VariantDataProps) => void;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSeletedOption?: React.Dispatch<React.SetStateAction<any>>;
};

function Card2({
  el,
  variantData,
  setVariantData,
  setOpenModal,
  setSeletedOption,
}: Card2Props) {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(null);

  const handleEdit = (elid: any) => {
    setOpenModal?.(true);
    setSeletedOption?.({ elid: elid, task: "edit" });
  };

  const handleCopy = (elid: any) => {
    setOpenModal?.(true);
    setSeletedOption?.({ elid: elid, task: "copy" });
  };

  const handleDelete = (elid: any) => {
    if (!variantData) return;
    // Delete Option Functionality
    const deletedOptions = variantData.options.find(
      (each) => each.id === elid.id
    );
    // @ts-ignore
    if (deletedOptions?.added) {
      const updatedOptions = variantData.options.filter(
        (each) => each.id !== elid.id
      );
      setVariantData?.({ ...variantData, options: updatedOptions });
    } else {
      if (deletedOptions) {
        deletedOptions.to_delete = true;
        const updatedOptions = variantData.options.filter(
          (each) => each.id !== elid.id
        );
        setVariantData?.({
          ...variantData,
          options: [...updatedOptions, deletedOptions],
        });
      }
    }
    setShowDeleteModal(null);
  };
  return (
    <>
      <div className="border-2 text-md leading-tight w-full flex flex-col py-4 gap-2">
        <div className="flex justify-between px-4">
          <p>
            Name: <span>{el.name}</span> &ensp; | &ensp;Code:{" "}
            <span>{el.code}</span>
          </p>

          {variantData && (
            <div className="flex gap-5">
              <img
                src={CopyIcon}
                alt="CopyIcon"
                onClick={() => handleCopy(el)}
                className="cursor-pointer"
              />
              <img
                src={EditIcon}
                alt="EditIcon"
                onClick={() => handleEdit(el)}
                className="cursor-pointer"
              />
              <img
                src={DeleteIcon}
                alt="DeleteIcon"
                onClick={() => setShowDeleteModal(el)}
                className="cursor-pointer"
              />
            </div>
          )}
        </div>

        <hr className="border-[1px] border-[#F4F2EE]"></hr>
        <p className="px-4 leading-3">
          Price : <span>Rs {el.price}</span>
        </p>
      </div>
      {showDeleteModal && (
        <Modal
          top
          header="Delete Option"
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => handleDelete(showDeleteModal)}
              />
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setShowDeleteModal(null);
                }}
              />
            </div>
          }
          handleCancel={() => setShowDeleteModal(null)}
        >
          <p className="text-left">
            Are you sure you want to delete the option?
          </p>
        </Modal>
      )}
    </>
  );
}

export default Card2;
