import React, { useState } from "react";
import { Accordian } from "../../../../../components/Accordian";
import tickIcon from "../../../../../assets/svg/tick.svg";
import crossIcon from "../../../../../assets/svg/cross.svg";

const timelineDate = [
  {
    status: false,
    msg: "Proposal rejected",
    createdAt: new Date(),
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
    professionalMsg: "Aishwarya Chatterjee rejected the proposal ",
  },
  {
    status: true,
    msg: "Address changed",
    createdAt: new Date(),
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
    professionalMsg:
      "Admin Viraj changed this customer’s contact address from 7364758394 to 726473898 ",
  },
  {
    status: true,
    msg: "Files uploaded",
    createdAt: new Date(),
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
    professionalMsg: "Aishwarya Chatterjee has uploaded files",
  },
  {
    status: true,
    msg: "Email confirmation sent",
    createdAt: new Date(),
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
    professionalMsg: "Confirmation email was sent to id: 134567",
  },
  {
    status: true,
    msg: "Account created",
    createdAt: new Date(),
    time: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  },
];
export default function Timeline() {
  return (
    <>
      <Accordian label="Timeline">
        <div className="px-4 py-3 h-[50vh] overflow-scroll no-scrollbar">
          {timelineDate?.map((el, i) => {
            return (
              <div
                key={i}
                className="flex  justify-start items-start gap-4 w-[55%] text-start"
              >
                <div>
                  {el.status === true ? (
                    <>
                      <img src={tickIcon} alt="tick-icon" />
                    </>
                  ) : (
                    <>
                      <img src={crossIcon} alt="tick-icon" />
                    </>
                  )}
                  <div
                    className={`${
                      i !== timelineDate.length - 1
                        ? `h-[50px] border-2 w-0 ml-2`
                        : ""
                    } `}
                  ></div>
                </div>

                <div>
                  <div className="flex space-x-4 text-[12px] leading-5 ">
                    <p className="font-medium ">{el.msg}</p>

                    <p className="text-[#AEAEAE]">
                      {el.createdAt.toLocaleString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}{" "}
                      | {el.time}
                    </p>
                  </div>

                  <p className="text-[12px] leading-5 ">{el.professionalMsg}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Accordian>
    </>
  );
}

// const currentDate = new Date();
// const time = currentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
// console.log(time);

// const currentDate = new Date();
// const day = currentDate.getDate();
// const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(currentDate);
// const year = currentDate.getFullYear().toString().substr(-2);

// const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} '${year}`;
// console.log(formattedDate); // output: "8th May '23"

// function getOrdinalSuffix(day:any) {
//     if (day > 3 && day < 21) return 'th';
//     switch (day % 10) {
//         case 1: return "st";
//         case 2: return "nd";
//         case 3: return "rd";
//         default: return "th";
//     }
// }
