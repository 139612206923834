import React from "react";

interface MP4PreviewIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const MP4PreviewIcon = ({
  width = "20",
  height = "20",
  color = "#AEAEAE",
}: MP4PreviewIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.48901 14.51C5.81567 14.8366 6.20817 15 6.66651 15H16.6665C17.1243 15.0005 17.5165 14.8375 17.8432 14.5108C18.1698 14.1841 18.3332 13.7916 18.3332 13.3333V3.3333C18.3337 2.87552 18.1707 2.4833 17.844 2.15663C17.5173 1.82996 17.1248 1.66663 16.6665 1.66663H6.66651C6.20873 1.66607 5.81651 1.82913 5.48984 2.1558C5.16317 2.48246 4.99984 2.87496 4.99984 3.3333V13.3333C4.99928 13.7911 5.16234 14.1833 5.48901 14.51ZM16.6665 13.3333H6.66651V3.3333H16.6665V13.3333ZM2.15567 17.8433C2.48234 18.17 2.87484 18.3333 3.33317 18.3333H14.9998V16.6666H3.33317V4.99996H1.66651V16.6666C1.66595 17.1244 1.82901 17.5166 2.15567 17.8433Z"
        fill={color}
      />
      <path
        d="M8.57034 10V7.08H9.24634L9.51434 8C9.54368 8.096 9.56501 8.18 9.57834 8.252C9.59434 8.324 9.60368 8.37467 9.60634 8.404C9.60901 8.37467 9.61701 8.324 9.63034 8.252C9.64634 8.18 9.66768 8.096 9.69434 8L9.95834 7.08H10.6343V10H10.0863V9.2C10.0863 9.05333 10.089 8.9 10.0943 8.74C10.1023 8.57733 10.1117 8.416 10.1223 8.256C10.133 8.096 10.145 7.94667 10.1583 7.808C10.1717 7.66667 10.1837 7.544 10.1943 7.44L9.85834 8.76H9.35034L8.99834 7.44C9.01168 7.53867 9.02501 7.65733 9.03834 7.796C9.05168 7.932 9.06368 8.08 9.07434 8.24C9.08768 8.39733 9.09834 8.55867 9.10634 8.724C9.11434 8.88933 9.11834 9.048 9.11834 9.2V10H8.57034ZM11.0648 10V7.08H12.0608C12.2661 7.08 12.4448 7.11867 12.5968 7.196C12.7514 7.27333 12.8701 7.38133 12.9528 7.52C13.0381 7.65867 13.0808 7.82133 13.0808 8.008C13.0808 8.192 13.0381 8.35467 12.9528 8.496C12.8674 8.63467 12.7488 8.74267 12.5968 8.82C12.4448 8.89733 12.2661 8.936 12.0608 8.936H11.6648V10H11.0648ZM11.6648 8.396H12.0608C12.1888 8.396 12.2901 8.36133 12.3648 8.292C12.4421 8.22 12.4808 8.12533 12.4808 8.008C12.4808 7.89067 12.4421 7.79733 12.3648 7.728C12.2901 7.656 12.1888 7.62 12.0608 7.62H11.6648V8.396ZM14.6992 10V9.464H13.4192V8.628L14.5152 7.08H15.1952L13.9792 8.796V8.944H14.6992V8.32H15.2992V10H14.6992Z"
        fill={color}
      />
    </svg>
  );
};

export default MP4PreviewIcon;
