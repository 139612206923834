export function formatDateTime(dateTimeString: string) {
  const date = new Date(dateTimeString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const hour = date.getHours() % 12 || 12;
  const minute = date.getMinutes();
  const period = date.getHours() < 12 ? "AM" : "PM";
  const formattedDay =
    day +
    (day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th");

  return `${formattedDay} ${month}, ${hour}:${
    minute < 10 ? "0" : ""
  }${minute} ${period}`;
}
export function formatDate(dateString: string) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const formattedDay =
    day +
    (day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th");

  return `${formattedDay} ${month}, ${year}`;
}

export const ordinalSuffix = (day: any) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDateDDMMYYYYToDate = (dateString: any) => {
  const [day, month, year] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);
  return date;
};

export const formatDateToDDMMYYYY = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};

export function formatDateString(dateStr: string): string {
  const date = new Date(dateStr);
  const day = date.getUTCDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getUTCFullYear();

  const getOrdinalSuffix = (day: number): string => {
      if (day > 3 && day < 21) return 'th'; // covers 4th to 20th
      switch (day % 10) {
          case 1: return 'st';
          case 2: return 'nd';
          case 3: return 'rd';
          default: return 'th';
      }
  };

  const dayWithSuffix = day + getOrdinalSuffix(day);
  return `${dayWithSuffix} ${month} ${year}`;
}