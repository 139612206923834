import React from "react";
import { CustomDropdown } from "../ProfessionalDetail/DropDown";

const RejectionReason = () => {
  return (
    <CustomDropdown sideType label="Rejection Reason">
      {Array.from({ length: 2 }).map((_, idx) => (
        <div key={idx} className="flex text-xs text-left gap-2 px-3 py-2">
          <p>{idx + 1}.</p>
          <p>
            Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis.
          </p>
        </div>
      ))}
    </CustomDropdown>
  );
};

export default RejectionReason;
