import { protectedAxiosInstance } from "./axiosManagement";

export const getGstDetails = (id) => {
  return protectedAxiosInstance
    .get(`/admin/accounts/gst-info/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
