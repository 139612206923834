import { useEffect, useMemo, useState } from "react";
import UpandDownArrow from "../../../assets/svg/dateIcon.svg";
import { useTable, usePagination, useSortBy } from "react-table";
import { useSticky } from "react-table-sticky";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";

import ManagementTableLayout from "../../../components/MangementTable";

import PlusIcon from "../../../assets/PlusIcon";

import Pagination from "../../../components/Pagination";
import EditIcon from "../../../assets/EditIcon";
import { getProject, updateProject } from "../../../api/project";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { stateAtom } from "../../../atom";
import { formatDate } from "../../../utils/helper";

const ProjectTable = ({ setProjectCount }: any) => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageLength, setPageLength] = useState<number>(25);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [inputVal, setInputVal] = useState("");

  const [settableData, setSettableData] = useState<any>([]);
  const [isTogglingStatus, setIsTogglingStatus] = useState("");
  const [startIndex, setStartIndex] = useState(0);

  const stateList = useRecoilValue(stateAtom);

  useEffect(() => {
    setLoading(true);
    getProject({ page: page, length: pageLength, search: inputVal })
      .then((response) => {
        const projects = response.data.project;
        // console.log( response.data, 'projects');
        setSettableData(projects);
        setTotalCount(response.data.pagination.total_count);
        setProjectCount(response.data.pagination.total_count);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
        setLoading(false);
      });
  }, [page, pageLength, inputVal]);


  const COLUMN = [
    {
      id: "sr",
      Header: () => <div>Sr. No</div>,
      width: "5%",
      accessor: "sr",
      Cell: ({ row }: { row: any }) => <div>{startIndex + row.index + 1}</div>,
    },
    {
      id: "name",
      Header: ({ column }: { column: any }) => <div>Project name</div>,
      accessor: "name",
      width: 50,
      Cell: ({ value, row }: any) => (
        <Link to={`/projects/${row.original.id}`}>
          <div
            className="underline underline-offset-2 hover:scale-105 hover:pl-3 break-words w-[100px] truncate ... "
            title={value}
          >
            {value}
          </div>
        </Link>
      ),
    },
    {
      id: "location",
      Header: ({ column }: { column: any }) => <div>Location</div>,
      accessor: "location",
      width: 100,
      Cell: ({ row }: any) => {
        const { address_city, address_state } = row.original;

        const location =
          address_city && address_state
            ? `${address_city}, ${
                stateList?.find(
                  (state: any) => state?.state_code === address_state
                )?.name
              }`
            : "-";
        return <div className="w-[200px] break-words">{location}</div>;
      },
    },
    {
      id: "created_at",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Date</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      width: 90,
      accessor: "created_at",
      Cell: ({ value }: any) => (
        <div>
          {formatDate(value)} {/* Render the formatted date */}
        </div>
      ),
    },
    {
      id: "floor_count",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Floors</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      width: 50,
      accessor: "floor_count",
    },
    {
      id: "area_count",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Areas</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      width: 50,
      accessor: "area_count",
    },
    {
      id: "order_count",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Orders</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      width: 50,
      accessor: "order_count",
      Cell: ({ value }: any) => <div>{value} </div>,
    },
    {
      id: "member_count",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Professionals</p>
          <img src={UpandDownArrow} alt="UpandDownArrow" />
        </div>
      ),
      width: "4vw",
      accessor: "member_count",
      Cell: ({ value }: any) => <div>{value} </div>,
    },
    {
      id: "action",
      width: "12%",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center w-full">
          <p className="text-center">Action Item</p>
        </div>
      ),
      accessor: "",
      Cell: ({ row }: any) => (
        <div className="flex gap-x-2 px-2 items-center justify-center">
          <Link to={`/projects/${row.original.id}/edit`}>
            <div className="bg-pot-black p-1.5 rounded-md my-1">
              <EditIcon color="white" />
            </div>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const lastIndex = (page - 1) * pageLength;
    setStartIndex(lastIndex);
  }, [page, pageLength, totalCount]);

  const columns = useMemo(() => COLUMN, [settableData]);
  const data = useMemo(() => settableData || [], [settableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
      useSticky,
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };
  return (
    <div className="h-full">
      <div className="flex flex-row justify-between items-center py-2 w-full px-3 h-[3rem] ">
        <div className="flex gap-4 w-[max-content]">
          <div className=" w-[235px]">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Search"
              value={inputVal}
              rightIcon={<SearchIcon />}
            />
          </div>
          <div className="w-fit">
            <Link to={"/projects/create"}>
              <Button label="Create project" leftIcon={<PlusIcon />} />
            </Link>
          </div>
        </div>
      </div>

      <div className="h-[calc(100%-5.5rem)] w-full relative -z-0">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>

      {loading ? (
        ""
      ) : (
        <div className="w-full">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectTable;
