export const validatePrice = (val: string) => {
  const re = /^[-+]?\d*\.?\d{0,2}$/;
  const newValue = val.length > 0 ? val.replace(/^0+(?=\d)|^0+$/, "") : "";
  return re.test(newValue) || newValue === "" ? newValue : false;
};

export const validatePriceAccept0 = (val: string) => {
  if (val === "0") return "0"; // allow "0" as a valid input
  const re = /^[-+]?\d*\.?\d{0,2}$/;
  const newValue = val.length > 0 ? val.replace(/^0+(?=\d)|^0+$/, "") : "";
  return re.test(newValue) || newValue === "" ? newValue : false;
};
export const validatePhone = (
  val: string,
  includeCode = false
): string | undefined => {
  let realValue: string | undefined;
  if (includeCode) {
    realValue = val
      ?.match(
        /^\(?[+]?\(?\d{0,2}?\)?[-.\s]?\(?\d{0,3}?\)?[-.\s]?\d{0,4}[-.\s]?\d{0,4}[-.\s]?\d{0,9}$/
      )
      ?.join("");
  } else {
    realValue = val
      ?.match(/^\(?\d{0,3}?\)?[-.\s]?\d{0,4}[-.\s]?\d{0,4}[-.\s]?\d{0,9}$/)
      ?.join("");
  }
  if (!realValue) {
    if (val === "") return;
  }
  const stringLength = realValue?.replace(/[^0-9]/g, "").length;
  const condition = includeCode
    ? (stringLength ?? 0) > 12
    : (stringLength ?? 0) > 10;
  if (condition) {
    return;
  }
  return realValue;
};

export const validateEmail = (val: string): boolean => {
  const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; //eslint-disable-line
  const realValue = val?.match(re)?.join("");
  if (!realValue) {
    return false;
  }
  return true;
};

// accept 0-9 a-z A-Z
export const validateTextNumber = (val: string): string | false => {
  const re = /^[a-zA-Z0-9]+$/;
  return re.test(val) || val === "" ? val : false;
};
// a-z A-Z 0-9 space
export const validateTextNumberSpace = (val: string): string | false => {
  const re = /^[a-zA-Z0-9\s]+$/;
  return re.test(val) || val === "" ? val : false;
};

// a-z A-Z 0-9 space Hyphen
export const validateTextNumberSpaceHyphen = (val: string): string | false => {
  const re = /^[a-zA-Z0-9\s-]+$/;
  return re.test(val) || val === "" ? val : false;
};

// a-z A-Z 0-9 Hyphen
export const validateTextNumberHyphen = (val: string): string | false => {
  const re = /^[a-zA-Z0-9-]+$/;
  return re.test(val) || val === "" ? val : false;
};

// a-z A-Z . Space
export const validateTextSpaceDot = (val: string): string | false => {
  const re = /^[a-zA-Z\s.]+$/;
  return re.test(val) || val === "" ? val : false;
};

// 0-9 Hyphen
export const validateNumberHyphen = (val: string): string | false => {
  const re = /^[0-9-]+$/;
  return re.test(val) || val === "" ? val : false;
};

// space not allowed
export const validateSpaceNotAllowed = (val: string): string | false => {
  return !/\s/.test(val) || val === "" ? val : false;
};

// a-z A-Z space .

// hex code validation for input field
export const validateHexCode = (val: string): string | false => {
  let newValue = val.toUpperCase().replace(/\s/g, "");
  newValue = newValue.startsWith("#") ? newValue : "#" + newValue;
  newValue = newValue.replace(/^#+/, "#");
  const isValidHex = /^#[0-9A-F]*$/i.test(newValue);
  newValue = newValue === "#" ? "" : newValue;
  return isValidHex ? newValue : false;
};

// Hex code validation
export const checkHexCode = (val: string): string | false => {
  let newValue = val.trim().toUpperCase();
  const re = /^#[0-9A-F]{3}([0-9A-F]{3})?$/i;
  return re.test(newValue) || newValue === "" ? newValue : false;
};

// url without https://
export const validateHTTPURL = (val: string): string | false => {
  let cleanedVal = val.trim(); // Trim leading and trailing whitespace
  if (!cleanedVal.startsWith("https://") && !cleanedVal.startsWith("http://")) {
    cleanedVal = "https://" + cleanedVal; // Prepend "https://" if not present
  }
  const re = /^(?:[^\s/$.?#]|.[^\s]*)$/;

  return re.test(cleanedVal) ? cleanedVal : false;
};

// 0-9
export const validateNumber = (val: string): string | false => {
  const re = /^[0-9]+$/;
  return re.test(val) || val === "" ? val : false;
};

export const removeNullValues = (obj: any) => {
  for (let prop in obj) {
    if (obj[prop] === null || obj[prop] === "") {
      delete obj[prop];
    } else if (typeof obj[prop] === "object") {
      removeNullValues(obj[prop]);
      if (Object.keys(obj[prop]).length === 0) {
        delete obj[prop];
      }
    }
  }
};

// export const removeEmptyProperties = (obj: any) => {
//   for (let prop in obj) {
//     if (
//       (!obj[prop] && obj[prop] !== false && obj[prop] !== 0) ||
//       (Array.isArray(obj[prop]) && obj[prop].length === 0)
//     ) {
//       delete obj[prop];
//     } else if (typeof obj[prop] === "object") {
//       removeEmptyProperties(obj[prop]);
//     }
//   }
// };

export const removeEmptyProperties = (obj: any) => {
  for (let prop in obj) {
    if (
      (!obj[prop] && obj[prop] !== false && obj[prop] !== 0) ||
      (Array.isArray(obj[prop]) && obj[prop].length === 0)
    ) {
      delete obj[prop];
    } else if (typeof obj[prop] === "object") {
      removeEmptyProperties(obj[prop]);
      if (Object.keys(obj[prop]).length === 0) {
        delete obj[prop];
      }
    }
  }
};

// check pincode
export const checkPincode = (val: string): true | false => {
  const re = /^^[1-9]{1}[0-9]{2}[0-9]{3}$/;
  return re.test(val);
};

// Check IFSC code
export const checkIFSC = (val: string): boolean => {
  const re = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  return re.test(val);
};

// Check bank account number
export const checkBankAccountNumber = (val: string): boolean => {
  const re = /^[0-9]{9,18}$/;
  return re.test(val);
};

// Check PAN number
export const checkPAN = (val: string): boolean => {
  const re = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  return re.test(val);
};

// Check GST number
export const checkGST = (val: string): boolean => {
  const re = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[A-Z][0-9A-Z]{1}$/;
  return re.test(val);
};
