import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";
import Button from "../../components/Button";
import SelectCheckboxOption from "../../components/SelectCheckboxOption";
import SelectField from "../../components/SelectField";
import Checkbox from "../../components/Checkbox";
import AuthInputField from "../../components/AuthInputField";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAdminUserAvailablePermission,
  postAdminUsers,
} from "../../api/user";
import SingleSelection from "../../components/SingleSelection";
import { getCountryCallingCode, isSupportedCountry } from "libphonenumber-js";
import { toast } from "react-toastify";

function CreateUser() {
  const navigate = useNavigate();
  const setTitle = useSetRecoilState(titleAtom);
  const [errorMessage, setErrorMessage] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phonerNumber: "",
    gender: "",
    permissions: "",
    designConsultant: "",
  });
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phonerNumber, setPhoneNumber] = useState<string>("");
  const [isDesignConsultant, setIsDesignConsultant] = useState(false);
  const [countryCode, setCountryCode] = useState<any>();
  const [selectedGender, setSelectedGender] = useState<string | null>(null);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [availablePermission, setAvailablePermission] = useState<any>([]);
  const [permissions, setPermissions] = useState<any>([]);

  // const [checkboxValues, setCheckboxValues] = useState<(0 | 1 | 2)[]>(
  //   Array(availablePermission.length).fill(0)
  // );
  const [selectAllValue, setSelectAllValue] = useState<0 | 1 | 2>(0);

  // Select All Checkboxes functionality
  // useEffect(() => {
  //   const selectedAllValue = checkboxValues.every((item) => item === 0)
  //     ? 0
  //     : checkboxValues.every((item) => item === 1)
  //     ? 1
  //     : checkboxValues.some((item) => item === 1)
  //     ? 2
  //     : 1;
  //   setSelectAllValue(selectedAllValue);
  // }, [checkboxValues]);

  const handleSelection = (gender: string) => {
    setSelectedGender(gender);
    if (errorMessage.gender) {
      setErrorMessage({ ...errorMessage, gender: "" });
    }
  };

  const handleCheckboxClick = (id: any, value: any) => {
    const targetCheckBox = permissions.find((el: any) => el.id === id);

    if (targetCheckBox) {
      const updatedPermissions = permissions.map((el: any) => {
        if (el.id === id) {
          return { ...el, value: el.value === 0 ? 1 : 0 };
        }
        return el;
      });

      setPermissions(updatedPermissions);
    }
  };

  const handleSelectAllClick = () => {
    const countOfSelectedPermissions = permissions.filter(
      (item: any) => item.value === 1
    ).length;

    if (countOfSelectedPermissions !== permissions.length) {
      // Set all items to value 1
      const updatedPermissions = permissions.map((item: any) => ({
        ...item,
        value: 1,
      }));
      setPermissions(updatedPermissions);
    } else {
      // Set all items to value 0
      const updatedPermissions = permissions.map((item: any) => ({
        ...item,
        value: 0,
      }));
      setPermissions(updatedPermissions);
    }
  };

  const handleSubmit = async () => {
    if (!firstName || !lastName) {
      setErrorMessage({
        firstName: !firstName ? "Please enter first name" : "",
        lastName: !lastName ? "Please enter last name" : "",
      });
      return;
    }
    const selectedPermissions = permissions
      .filter((item: any) => item.value === 1)
      .map((item: any) => item.id);

    const contact = countryCode + phonerNumber;
    const gender =
      selectedGender === "female"
        ? "FEMALE"
        : selectedGender === "male"
        ? "MALE"
        : selectedGender === "male"
        ? "OTHERS"
        : "";
    // "Must be one of: MALE, FEMALE, OTHERS."
    console.log(selectedPermissions, "selectedPermissions");

    const payload = {
      name: firstName + " " + lastName,
      email: email,
      phone: contact,
      gender: gender,
      is_design_consultant: isDesignConsultant,
      permissions: selectedPermissions,
    };

    console.log(payload, "payload");
    console.log("payload:", payload);

    postAdminUsers(payload)
      .then((res) => {
        console.log(res);
        if (res.data) {
          navigate("/users");
          toast.success("Admin user created successfully");
        }
      })
      .catch((e) => {
        if (e.data && e.data.errors) {
          const errors = e.data.errors;
          setErrorMessage((prev: any) => ({
            ...prev,
            email: errors.email ? errors.email[0] : "",
            phonerNumber: errors.phone ? errors.phone[0] : "",
            gender: errors.gender ? errors.gender[0] : "",
            permissions: errors.permissions ? errors.permissions[0] : "",
            designConsultant: errors.is_design_consultant
              ? errors.is_design_consultant[0]
              : "",
          }));
        }
        console.log(e);
      });
  };

  console.log(errorMessage, "ee");

  useEffect(() => {
    getAdminUserAvailablePermission()
      .then((res) => {
        setAvailablePermission(res?.data);
        const allIds = [];
        for (const key in res?.data) {
          if (Object.prototype.hasOwnProperty.call(res?.data, key)) {
            const values = res?.data[key];
            for (const [id, name] of values) {
              allIds.push({ id: id, value: 0 });
            }
          }
        }
        setPermissions(allIds);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <div className="h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
      {/* heading */}
      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  px-4">
        <p className="flex font-gilroy-medium text-[14px] leading-[20p[x]]">
          Create new User
        </p>
      </div>

      <div className="flex w-full justify-between  flex-1  overflow-y-auto custom-scrollbar  py-3">
        <div className="w-[55%] overflow-y-auto custom-scrollbar px-4">
          <div className="flex flex-col">
            <div className="flex gap-3 ">
              <AuthInputField
                onChange={(e) => {
                  setFirstName(e?.target?.value);
                  if (errorMessage.firstName) {
                    setErrorMessage({ ...errorMessage, firstName: "" });
                  }
                }}
                value={firstName}
                placeholder="Enter first name"
                heading="First name"
                errorMessage={errorMessage?.firstName || ""}
              />

              <AuthInputField
                onChange={(e) => {
                  setLastName(e?.target?.value);
                  if (errorMessage.lastName) {
                    setErrorMessage({ ...errorMessage, lastName: "" });
                  }
                }}
                setCode={(code) => setCountryCode(code)}
                value={lastName}
                placeholder="Enter last name"
                heading="Last name"
                errorMessage={errorMessage?.lastName || ""}
              />
            </div>

            <div className=" flex flex-col mt-2 gap-2">
              <AuthInputField
                onChange={(e) => {
                  setEmail(e?.target?.value);
                  if (errorMessage.email) {
                    setErrorMessage({ ...errorMessage, email: "" });
                  }
                }}
                value={email}
                placeholder="eg. abc@xyz.com"
                heading="Email id"
                hint={errorMessage.email}
                type="text"
              />
              <AuthInputField
                onChange={(e: any) => {
                  setPhoneNumber(e?.target?.value);
                  if (errorMessage.phonerNumber) {
                    setErrorMessage({ ...errorMessage, phonerNumber: "" });
                  }
                }}
                country_code_value={
                  isSupportedCountry(countryCode)
                    ? `+${getCountryCallingCode(countryCode)}`
                    : `+${getCountryCallingCode("IN")}`
                }
                setCode={(e: any) => {
                  setCountryCode(e);
                }}
                value={phonerNumber}
                placeholder="XXXXXXXXXX"
                heading="Phone number"
                hint={errorMessage?.phonerNumber}
                type="telephone"
                required
              />
              <div className="flex flex-col justify-start text-left ">
                <h2 className="text-[12px] font-medium"> Gender</h2>
                <div className="flex  items-center space-x-12 mt-2">
                  <SelectCheckboxOption
                    kind="male"
                    isSelected={selectedGender === "male"}
                    handleSelection={handleSelection}
                  />
                  <SelectCheckboxOption
                    kind="female"
                    isSelected={selectedGender === "female"}
                    handleSelection={handleSelection}
                  />
                  <SelectCheckboxOption
                    kind="other"
                    isSelected={selectedGender === "other"}
                    handleSelection={handleSelection}
                  />
                </div>
                {errorMessage?.gender && (
                  <p className="flex text-left font-light text-xs text-red-700 relative h-0 m-0">
                    {errorMessage?.gender}
                  </p>
                )}
              </div>
              <div className="flex flex-col justify-start text-left ">
                <h2 className="text-[12px] font-medium">Role</h2>
                <div>
                  <div className="w-fit">
                    <SingleSelection
                      isSelected={isDesignConsultant}
                      onClick={() => setIsDesignConsultant(!isDesignConsultant)}
                      label="  This user is a Design Consultant"
                    />
                  </div>
                </div>
                {errorMessage?.designConsultant && (
                  <p className="flex text-left font-light text-xs text-red-700 relative h-0 m-0">
                    {errorMessage?.designConsultant}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 border-r border-[#ECECEC]"></div>

        <div className="w-[45%] px-4 flex flex-col gap-0">
          <div className="w-full flex justify-between py-6">
            <p className="font-gilroy-medium text-[16px] leading-[20px]">
              Permissions
            </p>
            {errorMessage?.permissions && (
              <p className="flex text-left font-light text-xs text-red-700 relative h-0 m-0">
                {errorMessage?.permissions}
              </p>
            )}
            <div className="flex gap-3 items-center">
              <Checkbox
                checkValue={
                  permissions.filter((item: any) => item.value === 1).length ===
                  0
                    ? 0
                    : permissions.filter((item: any) => item.value === 1)
                        .length !== permissions.length
                    ? 2
                    : 1
                }
                onClick={handleSelectAllClick}
                label="Select all"
              />
            </div>
          </div>

          <div className="flex flex-col text-start">
            <div className="flex flex-col gap-4 mt-2">
              {Object.entries(availablePermission).map(
                ([category, permissionList]: any) => (
                  <div key={category} className="flex flex-col gap-1">
                    <p className="font-gilroy-semi-bold text-[12px] leading-[20px] ">
                      {category}
                    </p>
                    {permissionList?.map((permission: any) => {
                      return (
                        <div
                          className="flex gap-3 items-center"
                          key={permission[0]}
                        >
                          <Checkbox
                            checkValue={
                              permissions.find(
                                (item: any) => item.id === permission[0]
                              )?.value
                            }
                            onClick={(value) =>
                              handleCheckboxClick(permission[0], value)
                            }
                            label={permission[1]}
                          />
                        </div>
                      );
                    })}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex gap-x-4 w-[100%] py-3 border-t  border-[#ECECEC] px-4">
        <Button label="Create" variant="primary" onClick={handleSubmit} />
        <Button
          label="Cancel "
          variant="secondary-outline"
          onClick={() => navigate("/users")}
        />
      </div>
    </div>
  );
}

export default CreateUser;
