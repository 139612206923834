import { protectedAxiosInstance } from "./axiosManagement";
export const getPending = (params) => {
  return protectedAxiosInstance
    .get("/admin/accounts/professional", {
      params: {
        status: "DRAFT,AWAITING_EXPERIENCE_APPROVAL,IN_REVIEW", // Remove spaces after commas
        ...params,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
