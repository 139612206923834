const DetailSection = ({ data, loading }: any) => {
  return (
    <div className="text-start px-4 pt-2 py-4">
      <div className="w-full flex justify-between">
        <h1 className="text-pot-yellow font-medium text-lg">
          Employee Profile:{" "}
        </h1>
      </div>

      <div
        className={`flex flex-col ${
          loading ? "gap-3" : "gap-2 "
        } text-start mt-4 text-[12px] leading-6`}
      >
        <div className="flex flex-col gap-2">
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE]">Profile picture:</p>
          )}

          <div className="h-14 w-14 rounded-full">
            {loading ? (
              <div className="w-full h-full object-cover rounded-full bg-skeleton-text"></div>
            ) : (
              <img
                src={`${process.env.REACT_APP_BUCKET}/${data?.p_individual?.profile_picture}`} //eslint-disable-line
                alt="profile-picture"
                className="w-full h-full object-cover rounded-full"
              />
            )}
          </div>
        </div>
        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE] leading-[20px] text-[12px]">Name:</p>
          )}
          {loading ? (
            <div className="w-[60%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p className="leading-[20px] text-[12px]">
              {data?.p_individual?.name ? data?.p_individual?.name : "-"}
            </p>
          )}
        </div>

        <div>
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE] leading-[20px] text-[12px]">
              Location:
            </p>
          )}

          {loading ? (
            <div className="w-[60%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p>
              {data?.p_individual?.practice_location_city},{" "}
              {data?.p_individual?.practice_location_state}
            </p>
          )}
        </div>

        <div className="font-gilroy-medium">
          {loading ? (
            <div className="w-24 h-4 bg-skeleton-text"></div>
          ) : (
            <p className="text-[#AEAEAE] leading-[20px] text-[12px]">
              Profile Link:
            </p>
          )}

          {loading ? (
            <div className="w-[60%] h-5 bg-skeleton-text mt-1.5"></div>
          ) : (
            <p
              className="leading-[20px] text-[12px] text-pot-yellow underline"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_DOMAIN}/i/${data?.p_individual?.slug}`, //eslint-disable-line
                  "_blank"
                )
              }
              style={{ cursor: "pointer" }}
            >
              Open public profile
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailSection;
