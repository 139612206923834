import React, { useState } from "react";

type UpTriangleProps = {
  stroke?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
};

const UpTriangle = ({
  stroke = "black",
  onClick = () => {},
  className = "",
  disabled = false,
}: UpTriangleProps) => {
  const [triangleFill, setTriangleFill] = useState("none");

  const handleMouseOver = () => {
    setTriangleFill(stroke);
  };

  const handleMouseOut = () => {
    setTriangleFill("none");
  };
  return (
    <svg
      className={`${className} ${
        disabled ? "opacity-30 cursor-not-allowed" : "hover:scale-125"
      }`}
      onClick={onClick}
      onMouseOver={disabled ? () => {} : handleMouseOver}
      onMouseOut={disabled ? () => {} : handleMouseOut}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill={triangleFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.14198 1.78109C6.43262 1.27769 7.15922 1.27769 7.44986 1.78109L12.0274 9.70966C12.3181 10.2131 11.9548 10.8423 11.3735 10.8423H2.21836C1.63708 10.8423 1.27378 10.2131 1.56442 9.70966L6.14198 1.78109Z"
        stroke={stroke}
        strokeWidth="1.13265"
      />
    </svg>
  );
};

export default UpTriangle;
