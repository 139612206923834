import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";

import VariantCounts from "./components/VariantCounts";
import VariantTable from "./components/VariantTable";

export default function VariantList() {
  const setTitle = useSetRecoilState(titleAtom);

  return (
    <div className="h-full flex flex-col justify-start items-start">
      <div className="h-[13vh] w-full flex items-center justify-start">
        <VariantCounts />
      </div>
      <div className="h-[calc(100%-13vh)] w-full">
        <VariantTable />
      </div>
    </div>
  );
}
