import React, { useState, useEffect } from "react";
import tickIcon from "../../../assets/svg/tick.svg";

interface ProgressBarProps {
  data?: string[];
  width?: string;
  orderDetails?: any;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  data,
  width,
  orderDetails,
}) => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (data && orderDetails?.status) {
      const trueIndex = data.findIndex((item) => item === orderDetails?.status); // Find the index of "PENDING" status
      const endIndex = trueIndex !== -1 ? trueIndex : data.length; // Include "PENDING" status in progress calculation
      // This is to add some number to progress width
      const multiplicationFactor = endIndex < 5 ? 1.15 : 1.105 + endIndex / 100;
      // All progress indexes have a specific unique number which is to be added in the progress width that is this factor
      const factor = endIndex * (multiplicationFactor - endIndex / 100);
      const currentProgress = factor * (100 / data.length);
      // Example :
      // 100 / data.length == 0.01 (for data length 10)
      // factor = index * (multiplicationFactor - index/100)    => index/100 gives 0.01, 0.02... 0.0n indexes
      // index : factor * 0.01
      // 1 : 1.15 * 0.01
      // 2 : 2.3 * 0.01
      // Contact Dhiraj for this weird logic
      setProgress(currentProgress);
    }
  }, [data, orderDetails?.status]);

  return (
    <div className={`w-full mb-5`}>
      <div className="bg-gray-300 h-2 w-full relative rounded-s-full">
        <div className="flex justify-between items-center w-full">
          {data?.map((step, index) => (
            <div
              key={index}
              className="relative rounded-full h-2 w-2 bg-pot-yellow"
            >
              <div className="absolute -top-7 w-[1.5vw] -left-1.5">
                <img
                  src={tickIcon}
                  alt="tickIcon"
                  className={`${
                    index <= data.indexOf(orderDetails?.status)
                      ? "opacity-15"
                      : "opacity-20"
                  }`}
                />
              </div>

              <div
                className={`absolute top-4 -left-5 text-[11px] ${
                  index < data.indexOf(orderDetails?.status)
                    ? "text-black  font-semibold  leading-[16px]"
                    : index === data.indexOf(orderDetails?.status)
                    ? "text-pot-yellow  font-semibold  leading-[16px]"
                    : "text-opacity-[0.5] text-pot-yellow font-gilroy-medium leading-[16px] tracking-2"
                }`}
              >
                {step.replace(/_/g, " ")}{" "}
                {/* Replace underscores with spaces */}
              </div>
            </div>
          ))}
        </div>
        <div
          className="bg-pot-yellow h-2 rounded-s-full transition-all duration-500 ease-in-out absolute top-0"
          style={{ width: `${progress}%`, overflow: "hidden" }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
