import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragHandleIcon from "../../../assets/DragHandleIcon";
import InputField from "../../../components/InputField";
import TrashIcon from "../../../assets/TrashIcon";
import Button from "../../../components/Button";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { productPropertySuggestionAtom } from "../../../atom";
import { protectedAxiosInstance } from "../../../api/axiosManagement";

import useOnClickOutside from "../../../hooks/useClickOutside";

type FixedDetailType = {
  form: any;
  setForm: Dispatch<SetStateAction<any>>;
};

const FixedDetail = ({ setForm, form }: FixedDetailType) => {
  const initialDetails = [
    { key: "", value: "" },
    { key: "", value: "" },
  ];
  const [list, setList] = useState(initialDetails);
  const { id } = useParams();
  const [initial, setInitial] = useState(true);
  const [productPropertySuggestion, setProductPropertySuggestion] =
    useRecoilState(productPropertySuggestionAtom);

  useEffect(() => {
    protectedAxiosInstance
      .get("/admin/products/property-suggestions")
      .then((res) => {
        const newData = res.data.data;
        setProductPropertySuggestion(newData);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    form.fixed_details = list;
    setForm(structuredClone(form));
  }, [list]);

  useEffect(() => {
    if (id && initial && form.name) {
      if (form?.fixed_details?.length) {
        setList(form?.fixed_details);
      }
      setInitial(false);
    }
  }, [form]);

  const [focusedId, setFocusedId] = useState<any>(null);

  const NewDropdown = React.memo(({ id, data }: any) => {
    const selectRef = useRef<HTMLInputElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    useOnClickOutside(selectRef, () => {
      if (id === focusedId) {
        setFocusedId(null);
      }
    });

    useEffect(() => {
      if (id === focusedId) {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    });

    const [optionIndex, setOptionIndex] = useState(-1);
    useEffect(() => {
      if (id === focusedId) {
        setOptionIndex(-1);
      }
    }, [data.key]);

    useEffect(() => {
      const handleKeyPress = (event: any) => {
        const length = productPropertySuggestion["product-properties"].filter(
          (el: any) => el.toLowerCase().includes(list[id].key.toLowerCase())
        ).length;
        if (event.key === "Enter" && id === focusedId) {
          list[id].key =
            productPropertySuggestion["product-properties"].filter((el: any) =>
              el.toLowerCase().includes(list[id].key.toLowerCase())
            )[optionIndex] || "";

          setList(structuredClone(list));
          setFocusedId(null);
          return;
        }

        if (event.key === "ArrowUp" && id === focusedId) {
          if (optionIndex <= 0) return;
          setOptionIndex((prev) => prev - 1);
          return;
        }
        if (event.key === "ArrowDown" && id === focusedId) {
          if (optionIndex >= length - 1) return;
          setOptionIndex((prev) => prev + 1);
          return;
        }
      };

      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }, [optionIndex, list[id].key]);

    useEffect(() => {
      const container = document.getElementById(`dropdown-container-${id}`);
      if (container) {
        const selectedOption = container.querySelector(
          `#option-${optionIndex}`
        );
        if (selectedOption) {
          selectedOption.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }
    }, [optionIndex]);

    return (
      <div
        key={id}
        id={`dropdown-container-${id}`}
        ref={selectRef}
        className="relative w-full"
        onClick={() => {
          setFocusedId(id);
        }}
      >
        <input
          ref={inputRef}
          className="text-sm border-2 w-full border-c-white-3 px-2 py-[6px] rounded-md active:border-2 active:border-pot-yellow text-black font-normal  hover:border-2 hover:border-[#D4A15E] hover:border-opacity-50 outline-none focus:bg-white focus:border-pot-yellow placeholder-shown:text-pot-grey2 "
          onChange={(e) => {
            list[id].key = e.target.value;
            setList(structuredClone(list));
          }}
          value={data.key}
          onKeyDown={(e) => {
            if (e.key === "ArrowUp") {
              e.preventDefault();
            }
          }}
          placeholder="Enter key"
        />
        {id === focusedId &&
          productPropertySuggestion["product-properties"]?.filter((el: any) =>
            el?.toLowerCase()?.includes(list[id]?.key?.toLowerCase())
          )?.length > 0 && (
            <ul className="absolute w-full rounded-md  top-[100%] z-[100]  py-1 border-2 border-c-white-3 bg-white flex flex-col  overflow-scroll max-h-[100px] custom-scrollbar overflow-x-hidden text-sm">
              {productPropertySuggestion["product-properties"]
                .filter((el: any) =>
                  el.toLowerCase().includes(list[id].key.toLowerCase())
                )
                .map((el: any, index: any) => (
                  <li
                    className={`cursor-pointer py-1.5 px-2  hover:bg-gray-100 ${
                      optionIndex === index ? "bg-c-white-2" : ""
                    }`}
                    id={`option-${index}`}
                    key={el}
                    onClick={(e) => {
                      e.stopPropagation();
                      list[id].key = el;
                      setList(structuredClone(list));
                      setFocusedId(null);
                    }}
                  >
                    {el}
                  </li>
                ))}
            </ul>
          )}
      </div>
    );
  });

  NewDropdown.displayName = "NewDropdown";

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(list);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setList(structuredClone(reorderedItems));
  };

  return (
    <div className="my-5">
      <h2 className="font-semibold mb-6 flex justify-start">
        Add fixed details
      </h2>
      <div className="flex w-full gap-x-2">
        <div className="w-full text-left pl-8 text-sm font-semibold">Key</div>
        <div className="w-full text-left text-sm font-semibold">Value</div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {list.map((each, id) => (
                <Draggable key={String(id)} draggableId={String(id)} index={id}>
                  {(providedx, snapshot) => (
                    <div
                      ref={providedx.innerRef}
                      {...providedx.draggableProps}
                      className="w-full flex gap-x-2 items-center py-3"
                    >
                      <div {...providedx.dragHandleProps}>
                        <DragHandleIcon />
                      </div>

                      <NewDropdown
                        key={`new-dropdown-${id}`}
                        id={id}
                        data={each}
                      />

                      <InputField
                        onChange={(value) => {
                          list[id].value = value;
                          setList(structuredClone(list));
                        }}
                        value={each.value}
                        placeholder="Enter value"
                      />
                      <button
                        onClick={() => {
                          list.splice(id, 1);
                          setList(structuredClone(list));
                          if (list.length === 0) {
                            list.push({ key: "", value: "" });
                            setList(structuredClone(list));
                          }
                        }}
                        className="scale-75"
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-end">
        <Button
          onClick={() => {
            list.push({ key: "", value: "" });
            setList(structuredClone(list));
          }}
          variant="secondary"
          label="Add"
        />
      </div>
    </div>
  );
};

export default FixedDetail;
