import { protectedAxiosInstance } from "./axiosManagement";

export const getSeries = (params) => {
  return protectedAxiosInstance
    .get("/admin/products/series", {params} )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};


export const getSingleSeries = (id) => {
  return protectedAxiosInstance
    .get(`/admin/products/series/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postSeries = (payload) => {
  return protectedAxiosInstance
    .post("/admin/products/series", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateSeries = (id, payload) => {
  return protectedAxiosInstance
    .patch(`/admin/products/series/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteSeries = (id) => {
  return protectedAxiosInstance
    .delete(`/admin/products/series/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postAssignedData = (payload) => {
  return protectedAxiosInstance
    .post("/admin/products/bulk-update", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

