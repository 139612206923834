import React from "react";
import { IconProp } from "./ArrowIcons";

const TrashIcon = ({ color, width, height }: IconProp) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.83325 15.5C2.37492 15.5 1.98242 15.3367 1.65575 15.01C1.32909 14.6833 1.16603 14.2911 1.16659 13.8333V3C0.930477 3 0.732422 2.92 0.572422 2.76C0.412422 2.6 0.332699 2.40223 0.333255 2.16667C0.333255 1.93056 0.413255 1.7325 0.573255 1.5725C0.733255 1.4125 0.931033 1.33278 1.16659 1.33334H4.49992C4.49992 1.09723 4.57992 0.89917 4.73992 0.739169C4.89992 0.579169 5.0977 0.499447 5.33325 0.500003H8.66659C8.9027 0.500003 9.10075 0.580003 9.26076 0.740003C9.42076 0.900003 9.50048 1.09778 9.49992 1.33334H12.8333C13.0694 1.33334 13.2674 1.41334 13.4274 1.57334C13.5874 1.73334 13.6671 1.93111 13.6666 2.16667C13.6666 2.40278 13.5866 2.60084 13.4266 2.76084C13.2666 2.92084 13.0688 3.00056 12.8333 3V13.8333C12.8333 14.2917 12.6699 14.6842 12.3433 15.0108C12.0166 15.3375 11.6244 15.5006 11.1666 15.5H2.83325ZM2.83325 3V13.8333H11.1666V3H2.83325ZM4.49992 11.3333C4.49992 11.5694 4.57992 11.7675 4.73992 11.9275C4.89992 12.0875 5.0977 12.1672 5.33325 12.1667C5.56937 12.1667 5.76742 12.0867 5.92742 11.9267C6.08742 11.7667 6.16714 11.5689 6.16659 11.3333V5.5C6.16659 5.26389 6.08659 5.06584 5.92659 4.90584C5.76659 4.74584 5.56881 4.66611 5.33325 4.66667C5.09714 4.66667 4.89909 4.74667 4.73909 4.90667C4.57909 5.06667 4.49937 5.26445 4.49992 5.5V11.3333ZM7.83325 11.3333C7.83325 11.5694 7.91326 11.7675 8.07326 11.9275C8.23325 12.0875 8.43103 12.1672 8.66659 12.1667C8.9027 12.1667 9.10075 12.0867 9.26076 11.9267C9.42076 11.7667 9.50048 11.5689 9.49992 11.3333V5.5C9.49992 5.26389 9.41992 5.06584 9.25992 4.90584C9.09992 4.74584 8.90214 4.66611 8.66659 4.66667C8.43048 4.66667 8.23242 4.74667 8.07242 4.90667C7.91242 5.06667 7.8327 5.26445 7.83325 5.5V11.3333Z"
        fill={color || "currentColor"}
      />
    </svg>
  );
};

export default TrashIcon;
