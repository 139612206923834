import { useState } from "react";

type ProjectCardPropType = {
  count: number |string;
  color: string;
  title: string;
};

const ProjectDetailsCards = ({
  count,
  color,
  title,
}: ProjectCardPropType) => {


  return (
    <div
      className="bg-[#F9F9F9] w-full flex flex-col max-w-[14vw]"
    >
      <div
        style={{
          borderColor: color,
        }}
        className="border-l-2 py-2 px-3"
      >
        <div className="flex justify-between items-center">
          <p
            className="font-medium text-[20px]"
           >
            {count}
          </p>
        </div>
        <p
          className="text-[11px] text-[#AEAEAE] text-left"
        >
         {title}
        </p>
      </div>
    </div>
  );
};

export default ProjectDetailsCards;
