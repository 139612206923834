import InputField from "../../../components/InputField";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTable, usePagination, useSortBy } from "react-table";

import SearchIcon from "../../../assets/SearchIcon";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { MainAppSideBarExpandAtom } from "../../../atom";
import { getPending } from "../../../api/pending";
import { useSticky } from "react-table-sticky";
import ManagementTableLayout from "../../../components/MangementTable";
import Pagination from "../../../components/Pagination";

import CircledTickIcon from "../../../assets/CircledTickIcon";
import CircledCrossIcon from "../../../assets/CircledCrossIcon";

export default function PendingTable() {
  const [totalCount, setTotalCount] = useState<number>(100);
  const [pageLength, setPageLength] = useState<number>(25);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [userData, setUserData] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    setLoading(true);
    getPending({ page: page, length: pageLength, search: inputVal })
      .then((res) => {
        // console.log(res?.data?.professional, "data");
        const users = res?.data?.professional;
        setUserData(users);
        setTotalCount(res?.data?.pagination?.total_count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, pageLength, inputVal]);

  // const downloadReport = async () => {
  //   const response = await fetch("your-api-url", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/xml",
  //     },
  //   });

  //   const blob = await response.blob();
  //   const url = window.URL.createObjectURL(new Blob([blob]));
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", "report.xml");
  //   document.body.appendChild(link);
  //   link.click();
  // };

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  const handleStatus = useCallback(async (id: number) => {
    // logic for updating the status
    setStatus((id) => !id);
  }, []);

  console.log(userData, "userData");

  const COLUMN = [
    {
      id: "sr",
      Header: () => <div>Sr. No</div>,
      width: "55px",
      accessor: "sr",
      Cell: ({ row }: { row: any }) => <div>{startIndex + row.index + 1}</div>,
    },
    {
      id: "name",
      Header: ({ column }: { column: any }) => (
        <div className=" w-[118px]">
          <div className="flex justify-start items-center">Name</div>
        </div>
      ),
      accessor: "customer.name",
      Cell: ({ row, value }: any) => {
        return (
          <div className="underline underline-offset-2 hover:scale-105 hover:pl-3">
            <Link
              to={`/customers/professionals/${row.original.customer.id}/edit`}
            >
              <>
                <p>{value}</p>
              </>
            </Link>
          </div>
        );
      },
    },
    {
      id: "email",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Email id</div>
      ),
      accessor: "email",
      Cell: ({ row }: { row: any }) => (
        <>
          {row?.original?.customer?.email ? (
            <p>{row?.original?.customer?.email}</p>
          ) : (
            <p>-</p>
          )}
        </>
      ),
    },
    {
      id: "phone",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Phone Number</div>
      ),
      accessor: "p_company.social_phone",
      width: 100,
      Cell: ({ value }: any) => {
        return <div className="font-semibold">{value ? value : "-"}</div>;
      },
    },
    {
      id: "p_individual",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Individual Profile</p>
        </div>
      ),
      accessor: "p_individual",
      width: 60,
      Cell: ({ row }: { row: any }) => {
        const hasIndividualProfile =
          row.original.p_individual &&
          Object.keys(row.original.p_individual).length > 0;
        return (
          <p>
            {hasIndividualProfile ? (
              <CircledTickIcon color="green" />
            ) : (
              <CircledCrossIcon color="red" />
            )}
          </p>
        );
      },
    },
    {
      id: "p_company",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Company Profile</p>
        </div>
      ),
      accessor: "p_company",
      width: 60,
      Cell: ({ row }: { row: any }) => {
        const hasCompanyProfile =
          row.original.p_company &&
          Object.keys(row.original.p_company).length > 0;
        return (
          <p>
            {hasCompanyProfile ? (
              <CircledTickIcon color="green" />
            ) : (
              <CircledCrossIcon color="red" />
            )}
          </p>
        );
      },
    },
    {
      id: "p_status",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          <p>Status</p>
        </div>
      ),
      accessor: "customer.p_status",
      Cell: ({ value }: { value: any }) => (
        <p
          className={`w-[max-content] m-1.5 py-0.5 px-2 rounded-md ${
            value === "CHANGE_REQUESTED"
              ? "bg-[#BFB2FF]"
              : value === "IN_REVIEW"
              ? "bg-[#FFC5EF]"
              : value === "DRAFT"
              ? "bg-[#EDEDED]"
              : value === "REJECTED"
              ? "bg-[#FFB4A1]"
              : value === "AWAITING_EXPERIENCE_APPROVAL"
              ? "bg-[#FFDCB2]"
              : ""
          }`}
        >
          {value.replace(/_/g, " ")}
        </p>
      ),
    },
    {
      id: "p_status_change_at",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          Last Update At
        </div>
      ),
      accessor: "p_status_change_at",
      Cell: ({ row }: { row: any }) => {
        const statusChangeAt = row?.original?.customer?.p_status_change_at;

        if (!statusChangeAt) {
          return <p>-</p>;
        }

        const date = new Date(statusChangeAt);
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${date.getFullYear()}`;
        const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const amPm = date.getHours() >= 12 ? "PM" : "AM";
        const formattedTime = `${hours}:${minutes} ${amPm}`;

        return (
          <p>
            <span>{formattedDate}</span>
            <span className="block">{formattedTime}</span>
          </p>
        );
      },
    },

    {
      id: "action",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          Action
        </div>
      ),
      accessor: "action",
      Cell: ({ row }: { row: any }) => (
        <Link
          to={`/customers/professionals/${row?.original?.customer?.id}/edit`}
          className="cursor-pointer "
        >
          <p className="bg-black text-white  rounded-md w-[max-content] m-0.5 px-3 py-0.5 font-medium cursor-pointer border-none">
            Review
          </p>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const lastIndex = (page - 1) * pageLength;
    setStartIndex(lastIndex);
  }, [page, pageLength, totalCount]);

  const columns = useMemo(() => COLUMN, [userData]);
  const data = useMemo(() => userData, [userData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
      useSticky,
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  const datas = [
    { id: 1, name: "John", status: "Unverified" },
    { id: 2, name: "Jane", status: "Verified" },
    { id: 3, name: "Mike", status: "Verified" },
  ];
  const handleStatusChange = (values: string[]) => {
    const filteredData = datas.filter((item) => values.includes(item.status));
    // console.log(filteredData);
  };

  return (
    <div className="h-full">
      <div className="flex flex-row justify-between items-center py-2 w-full px-3 h-[3rem]">
        {/*Add Something Input container */}
        <div className="flex  gap-4  w-[235px]">
          <div className="w-full">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Search here"
              value={inputVal}
              rightIcon={<SearchIcon />}
            />
          </div>
        </div>

        {/*
         <div className="w-[65%] flex flex-row justify-end items-center gap-6 ">
          <div className=" flex gap-2 items-center">
            <SelectDropDown
              options={options}
              onChange={handleSelectChange}
              heading="Filters"
              icon={<img src={FilterIcon} alt="filter-icon" />}
            />
            <DropdownCheckbox
              heading="Status"
              options={[
                { label: "Verified", value: "Verified" },
                { label: "Unverified", value: "Unverified" },
              ]}
              onChange={handleStatusChange}
            />
          </div>

          <div
            className="flex items-center gap-1 text-xs pr-6"
            // onClick={downloadReport}
          >
            <DownloadIcon />
            <p className=" underline text-pot-yellow">Download</p>
          </div>
        </div> */}
      </div>

      <div className="h-[calc(100%-5.5rem)] w-full relative -z-0 ">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>

      {loading ? (
        ""
      ) : (
        <div className="w-full ">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}
    </div>
  );
}
