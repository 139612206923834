import editPen from "../../../assets/svg/pen.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { motion } from "framer-motion";
import HipchatChevronDownIcon from "../../../assets/HipchatChevronDownIcon";

import PlusIcon from "../../../assets/PlusIcon";
import Button from "../../../components/Button";
import { formatDate } from "../../../utils/helper";

const ProjectOrderDetails = ({ data }: any) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [showAllOrders, setShowAllOrders] = useState(false);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNavigateOrder = () => {
    navigate("/orders/create");
  };

  const handleShowMoreOrders = () => {
    setShowAllOrders(true);
  };

  return (
    <div>
      <div className="w-full border h-auto">
        <div className="flex justify-between items-center bg-[#FBFBFB] h-[48px] px-5 py-2">
          <p className="font-medium text-[14px] leading-5">Order Details</p>

          <div className="flex gap-3 justify-center items-center">
            <Button
              leftIcon={<PlusIcon />}
              label="Create Order"
              variant="secondary-text"
              onClick={handleNavigateOrder}
            />

            <motion.div
              animate={{ rotate: isDropdownOpen ? 180 : 0 }}
              className="w-[38px]  h-[38px] flex items-center justify-center cursor-pointer"
              onClick={handleDropdownClick}
            >
              <HipchatChevronDownIcon color="black" width="22" />
            </motion.div>
          </div>
        </div>

        {isDropdownOpen && (
          <motion.div
            layout
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{
              opacity: 0,
              y: -30,
              transition: { duration: 0.2 },
            }}
            transition={{ delay: 0.1, duration: 0.2 }}
            className="border-t"
          >
            {data?.length === 0 ? (
              <div className="p-5 text-center">There are no Orders to Show</div>
            ) : (
              <>
                <div className="grid grid-cols-3">
                  {showAllOrders
                    ? data?.map((el: any, i: any) => (
                        <div key={i} className="border-x border-y px-5 py-5">
                          <div className="flex justify-between h-[40px] ">
                            <Link to={"#"}>
                              <p className="underline text-[14px] font-medium">
                                Order id {i + 1}
                              </p>
                            </Link>
                            <div>
                              <img src={editPen} alt="edit-pen" />
                            </div>
                          </div>

                          <div className="flex flex-col text-[11px] leading-5 tracking-[-0.08px] text-start gap-3">
                            <div className="flex space-x-6">
                              <p>Order Date: </p>
                              <span className="">
                                {formatDate(el?.order_date)}
                              </span>
                            </div>
                            <div className="flex space-x-9">
                              <p>Location: </p>
                              <p>
                                {el.address_delivery?.city
                                  ? `${el.address_delivery?.city}, `
                                  : "-"}
                                {el.address_delivery?.state
                                  ? `${el.address_delivery?.state} `
                                  : "-"}
                              </p>
                            </div>
                            <div className="flex space-x-6">
                              <p>Total items: </p>
                              <span className="text-start">
                                {el.line_item_count}
                              </span>
                            </div>
                            <div className="flex space-x-2">
                              <p>Total amounts: </p>
                              <span>
                                {el.grand_total !== undefined
                                  ? el.grand_total.toLocaleString()
                                  : "N/A"}
                              </span>
                            </div>
                            <div className="flex space-x-3">
                              <p>Professional Tagged:</p>
                              <a
                                href={`/profile/${el?.professional?.kind}/${el?.professional?.slug}`}
                                className="text-pot-yellow underline"
                              >
                                {el?.professional?.name}
                              </a>
                            </div>
                          </div>
                        </div>
                      ))
                    : data?.slice(0, 6).map((el: any, i: any) => (
                        <div key={i} className="border-x border-y px-5 py-5">
                          <div className="flex justify-between h-[40px] ">
                            <Link to={"#"}>
                              <p className="underline text-[14px] font-medium">
                                Order id {i + 1}
                              </p>
                            </Link>
                            <div>
                              <img src={editPen} alt="edit-pen" />
                            </div>
                          </div>

                          <div className="flex flex-col text-[11px] leading-5 tracking-[-0.08px] text-start gap-3">
                            <div className="flex space-x-6">
                              <p>Order Date: </p>
                              <span className="">
                                {formatDate(el?.order_date)}
                              </span>
                            </div>
                            <div className="flex space-x-9">
                              <p>Location: </p>
                              <p>
                                {el.address_delivery?.city
                                  ? `${el.address_delivery?.city}, `
                                  : "-"}
                                {el.address_delivery?.state
                                  ? `${el.address_delivery?.state} `
                                  : "-"}
                              </p>
                            </div>
                            <div className="flex space-x-6">
                              <p>Total items: </p>
                              <span className="text-start">
                                {el.line_item_count}
                              </span>
                            </div>
                            <div className="flex space-x-2">
                              <p>Total amounts: </p>
                              <span>
                                {el.grand_total !== undefined
                                  ? el.grand_total.toLocaleString()
                                  : "N/A"}
                              </span>
                            </div>
                            <div className="flex space-x-3">
                              <p>Professional Tagged:</p>
                              <a
                                href={`/profile/${el?.professional?.kind}/${el?.professional?.slug}`}
                                className="text-pot-yellow underline"
                              >
                                {el?.professional?.name}
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>

                {data?.length > 6 && !showAllOrders && (
                  <div
                    className="h-[40px] px-5 py-2 border border-c-white-3 grid-item flex justify-center items-center cursor-pointer text-[14px] leading-[20px] text-pot-yellow font-gilroy-medium"
                    onClick={handleShowMoreOrders}
                  >
                    View More Orders
                  </div>
                )}
              </>
            )}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ProjectOrderDetails;
