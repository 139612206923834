import FilterIcon from "../../../assets/svg/filtericon.svg";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { Link, Navigate } from "react-router-dom";
import UpandDownArrow from "../../../assets/svg/dateIcon.svg";
import pen from "../../../assets/svg/pen.svg";
import activeStatus from "../../../assets/svg/activestatus.svg";
import DeActiveStatus from "../../../assets/svg/deactivatestatus.svg";
import ManagementTableLayout from "../../../components/MangementTable";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import Button from "../../../components/Button";
import PlusIcon from "../../../assets/PlusIcon";
import SelectDropDown from "../../../components/FilterDropDown";
import { useSticky } from "react-table-sticky";
import { useRecoilValue } from "recoil";
import { MainAppSideBarExpandAtom } from "../../../atom";
import FilterCheckboxDropDown from "../../../components/MultipleSelectCheckboxDropDown";
import loadingSpinner from "../../../assets/images/LoadingSpinner.gif";
import {
  getCustomer,
  postCustomer,
  updateCustomer,
} from "../../../api/customers";
import Pagination from "../../../components/Pagination";
import { toast } from "react-toastify";
import EditIcon from "../../../assets/EditIcon";
import EmployeeIcon from "../../../assets/svg-tsx/EmployeeIcon";
import CompanyIcon from "../../../assets/svg-tsx/CompanyIcon";

export type getUserDataProps = {
  page?: number;
  totalCount?: number;
  pageLength?: number;
};

function CustomersTable() {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageLength, setPageLength] = useState<number>(25);
  const [page, setPage] = useState<any>(1);
  const [loading, setLoading] = useState(true);
  const [inputVal, setInputVal] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [userData, setUserData] = useState<Array<any>>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);
  const [filteredData, setFilteredData] = useState<
    { name: string; totalSpend: string }[]
  >([]); // State to store filtered data
  const [isTogglingStatus, setIsTogglingStatus] = useState("");
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    setLoading(true);

    getCustomer({ page: page, length: pageLength, search: inputVal })
      .then((res) => {
        const users = res.data.customers;
        setUserData(users);
        setTotalCount(res.data.pagination.total_count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, pageLength, inputVal]);

  const handleStatusToggle = async (
    customerId: string,
    currentStatus: boolean
  ) => {
    console.log(customerId, currentStatus);

    if (isTogglingStatus) return;
    setIsTogglingStatus(customerId);

    const payload = {
      is_active: !currentStatus,
    };

    try {
      // Update user status via API PATCH request
      await updateCustomer(customerId, payload); // Assuming you have an updateCustomer function
      toast.success("Status updated successfully");

      // Update local userData state (if needed)
      // You can handle this part based on your application's state management
      setUserData((prevData: any) =>
        prevData.map((customer: any) => {
          if (customer.id === customerId) {
            return {
              ...customer,
              is_active: !currentStatus,
            };
          }
          return customer;
        })
      );
    } catch (error) {
      console.error(error);
      toast.error("Failed to update status");
    } finally {
      setIsTogglingStatus("");
    }
  };

  // const downloadReport = async () => {
  //   const response = await fetch("your-api-url", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/xml", // Set the content type to match your API
  //     },
  //   });
  //   const blob = await response.blob();
  //   const url = window.URL.createObjectURL(new Blob([blob]));
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", "report.xml");
  //   document.body.appendChild(link);
  //   link.click();
  // };

  const COLUMN = [
    {
      id: "sr",
      Header: () => <div>Sr. No</div>,
      width: "55px",
      accessor: "sr",
      Cell: ({ row }: { row: any }) => <div>{startIndex + row.index + 1}</div>,
    },
    {
      id: "name",
      Header: ({ column }: { column: any }) => (
        <div className=" w-[100px]">
          <div className="flex justify-start items-center ">Name</div>
        </div>
      ),
      accessor: "name",
      Cell: ({ row }: { row: any }) => {
        return (
          <Link
            to={`/customers/${row.original.id}`}
            className="underline underline-offset-2  flex items-center justify-between gap-1"
          >
            {" "}
            <p className="hover:text-black hover:font-gilroy-bold">
              {row.original.name}
            </p>
            {row.original.is_professional &&
              row.original.p_individual_id &&
              !row.original.p_company_id && <EmployeeIcon />}
            {row.original.is_professional && row.original.p_company_id && (
              <CompanyIcon />
            )}
          </Link>
        );
      },
    },
    {
      id: "email",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Email</div>
      ),
      accessor: "email",
      Cell: ({ value }: { value: any }) => (
        <p className="lowercase">{value ? value : "-"}</p>
      ),
    },

    {
      id: "phone",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Phone Number</div>
      ),
      accessor: "phone",
      Cell: ({ value }: { value: any }) => (
        <div className="font-semibold">{value}</div>
      ),
    },
    {
      id: "date_joined",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 ">
          <p>Date registered</p>
          {/* <img src={UpandDownArrow} alt="UpandDownArrow" /> */}
        </div>
      ),
      accessor: "date_joined",
      Cell: ({ value }: { value: any }) => {
        const formattedDate = new Date(value).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        return <p>{formattedDate}</p>;
      },
    },

    {
      id: "password",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-4 ">
          <p>AOV</p>
          {/* <img src={UpandDownArrow} alt="UpandDownArrow" /> */}
        </div>
      ),
      accessor: "password",
      Cell: ({ value }: { value: any }) => <p>0</p>,
      // <p>{value}</p>
    },
    {
      id: "height",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-4 ">
          <p>Total orders</p>
          {/* <img src={UpandDownArrow} alt="UpandDownArrow" /> */}
        </div>
      ),
      accessor: "height",
      Cell: ({ value }: { value: any }) => <p>0</p>,
      // <p>{value}</p>,
    },
    {
      id: "weight",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-4 ">
          <p>Total projects</p>
          {/* <img src={UpandDownArrow} alt="UpandDownArrow" /> */}
        </div>
      ),
      accessor: "weight",
      Cell: ({ value }: { value: any }) => <p>0</p>,
      // <p>{value}</p>,
    },
    {
      id: "buttons",
      Header: () => <div className="flex w-full justify-start ">Action</div>,
      width: 200,
      Cell: ({ row }: { row: any }) => (
        <div className="flex justify-start items-center gap-3">
          <div
            onClick={() => {}}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
          >
            <Link
              to={`/customers/${row.original.id}/edit`}
              className="cursor-pointer"
            >
              <EditIcon />
            </Link>
          </div>

          <div
            onClick={() => {
              setUserData((prevData: any) =>
                prevData.map((product: any) => {
                  if (product.id === row.original.id) {
                    return {
                      ...product,
                      is_active: !row.original.is_active,
                    };
                  }
                  return product;
                })
              );
              handleStatusToggle(row.original?.id, row.original?.is_active);
            }}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white h-8 w-12"
          >
            {row.original.is_active ? (
              <img
                src={activeStatus}
                alt="status-icon"
                className="w-full object-contain h-full"
              />
            ) : (
              <img
                src={DeActiveStatus}
                alt="status-icon"
                className="w-full object-contain h-full"
              />
            )}
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const lastIndex = (page - 1) * pageLength;
    setStartIndex(lastIndex);
  }, [page, pageLength, totalCount]);
  const columns = useMemo(() => COLUMN, [userData]);
  const data = useMemo(() => userData, [userData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
      useSticky,
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  return (
    <>
      <div className="h-full flex flex-col gap-y-2 pt-2 ">
        <div className="flex flex-row justify-between items-center w-full px-3 h-[6.5vh] ">
          <div className="flex gap-4 w-[max-content]">
            <div className="w-[235px]">
              <InputField
                onChange={(e: string) => setInputVal(e)}
                placeholder="Search here"
                value={inputVal}
                rightIcon={<SearchIcon />}
                withDebounce
              />
            </div>
            <div className="w-fit">
              <Link to={"/customers/create"}>
                <Button label="Create Customer" leftIcon={<PlusIcon />} />
              </Link>
            </div>
          </div>
        </div>

        <div className="h-[calc(100%-12.6vh)] w-full relative -z-0  ">
          <ManagementTableLayout
            tableInstance={tableInstance}
            loading={loading}
          />
        </div>
        {loading ? (
          ""
        ) : (
          <div className="w-full">
            <Pagination
              currentPage={page}
              pageSize={pageLength}
              totalCount={totalCount}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default CustomersTable;
