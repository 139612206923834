import React, { useEffect, useState } from "react";
import { deleteOrderItem } from "../api/orderManagement";
import { toast } from "react-toastify";

const useSeparateOrdersByFloor = (tableData: any) => {
  const [newTableData, setNewTableData] = useState<any>([]);
  const [fetchData, setFetchData] = useState<any>(0);

  // Function to delete the item from the table
  const handleDelete = (orderId: any, productId: any) => {
    const updatedData = newTableData?.map((floors: any) => ({
      floor: floors?.floor,
      items: floors?.items.filter((item: any) => item?.id !== productId),
    }));
    setNewTableData(updatedData);

    trigger();
    deleteOrderItem(orderId, productId)
      .then((res) => {
        toast.success("Successfully deleted item");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // Function to handle the quantity, incr/ decr
  const handleQuantity = (id: any, value: any, item: any) => {
    const updatedData = newTableData?.map((dateData: any) => ({
      estimated_date: dateData.estimated_date,
      floors: dateData.floors?.map((floorData: any) => ({
        floor: floorData.floor,
        items: floorData.items?.map((el: any) => {
          if (el.id === id) {
            return {
              ...el,
              quantity: el.quantity + value,
              grand_total: el.unit_price * (el.quantity + value),
            };
          }
          return el;
        }),
      })),
    }));
    setNewTableData(updatedData);
  };

  const getFloorArray = () => {
    const sortedData = [...tableData]; // Create a copy of the array to avoid modifying the original
    setNewTableData([]);

    // Create an entry for items with no floor
    const noFloorItems = sortedData.filter((item) => !item.floor_name);

    // Iterate over the sortedData array and group items by floor
    const transformedData = sortedData.reduce((result, item) => {
      if (item.floor_name !== undefined && item.floor_name !== null) {
        const floor = { id: item.floor_id, name: item.floor_name };

        // Find the index of the floor in the transformedData array
        const floorIndex = result.findIndex(
          (entry: any) => entry.floor.id === floor.id
        );

        // If the floor is not found, create a new entry
        if (floorIndex === -1) {
          result.push({
            floor: floor,
            items: [item],
          });
        } else {
          // If the floor is found, push the item to the existing floor
          result[floorIndex].items.push(item);
        }
      }
      return result;
    }, []);

    // Add the "No floor found" entry if there are items without a floor
    if (noFloorItems?.length > 0) {
      transformedData.push({
        floor: { id: "no_floor", name: "NO FLOOR" },
        items: noFloorItems,
      });
    }

    const singleObject = {
      floors: transformedData,
    };
    setNewTableData(singleObject.floors);
  };

  useEffect(() => {
    if (tableData) {
      getFloorArray();
    }
  }, [fetchData, tableData]);

  const trigger = () => {
    setFetchData((prev: any) => {
      prev + 1;
    });
  };

  return [newTableData, setNewTableData, handleDelete, handleQuantity, trigger];
};

export default useSeparateOrdersByFloor;
