import React, { useEffect, useRef, useState } from "react";
import InputField from "../../../components/InputField";
import AuthInputField from "../../../components/AuthInputField";

import CalenderIcon from "../../../assets/svg/clenderIcon.svg";
import SelectCheckboxOption from "../../../components/SelectCheckboxOption";
import Button from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import { toast } from "react-toastify";
import {
  removeNullValues,
  validateNumber,
  validateNumberHyphen,
  validateSpaceNotAllowed,
  validateTextSpaceDot,
} from "../../../utils/validations";
import ErrorMessage from "../../../components/ErrorMessage";
import { parsePhoneNumber } from "libphonenumber-js";
import Notfound from "../../404/components/Notfound";
import ReactDatePicker from "react-datepicker";
import {
  formatDateDDMMYYYYToDate,
  formatDateToDDMMYYYY,
} from "../../../utils/formatDateTime";
import useOnClickOutside from "../../../hooks/useClickOutside";
import HipchatChevronLeftIcon from "../../../assets/HipchatChevronLeftIcon";
import HipchatChevronRightIcon from "../../../assets/HipchatChevronRightIcon";
import SelectField2 from "../../../components/SelectedField2";
import { range } from "lodash";
import { getYear, getMonth } from "date-fns";
import { removeExtraSpacesfromObject } from "../../../utils/helper";

const CreateEditCustomer = () => {
  const { id: customerID } = useParams();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [formData, setFormData] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [countryCode, setCountryCode] = useState("+91");
  const [notFound, setNotFound] = useState(false);
  const [calenderOpen, setCalenderOpen] = useState(false);
  const calendarRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(calendarRef, () => {
    setCalenderOpen(false);
  });

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      try {
        const response = await protectedAxiosInstance.get(
          `/admin/accounts/customers/${customerID}`
        );
        const customerData = response?.data?.data?.customer;
        if (customerData && customerData.dob) {
          const dobParts = customerData.dob.split("-");
          const formattedDOB = `${dobParts[2]}-${dobParts[1]}-${dobParts[0]}`;
          customerData.dob = formattedDOB;
        }

        if (customerData && customerData?.phone) {
          const parsedNumber = parsePhoneNumber(customerData.phone);
          setCountryCode("+" + parsedNumber?.countryCallingCode);
          customerData.phone = parsedNumber.nationalNumber;
        }
        setFormData(customerData);
      } catch (error: any) {
        console.error("Error fetching customer data:", error);
        if (error?.status === 404) {
          setNotFound(true);
        }
      } finally {
        setPageLoading(false);
      }
    };

    if (customerID) {
      fetchData();
    }
  }, [customerID]);

  const handleSubmit = () => {
    setPageLoading(true);

    const payload: any = {
      name: formData?.name,
      email: formData?.email,
      gender: formData?.gender,
      is_active:
        formData?.is_active !== undefined || formData?.is_active !== null
          ? formData?.is_active
          : true,
    };
    if (formData && formData.dob) {
      const dobParts = formData.dob.split("-");
      const formattedDOB = `${dobParts[2]}-${dobParts[1]}-${dobParts[0]}`;
      payload.dob = formattedDOB;
    }
    if (formData && formData.phone) {
      payload.phone = countryCode + formData?.phone;
    }
    // removeNullValues(payload);
    if (formData && !formData.email) {
      payload.email = null;
    }
    if (formData && !formData.dob) {
      payload.dob = null;
    }
    removeExtraSpacesfromObject(payload);

    if (customerID) {
      protectedAxiosInstance
        .patch(`/admin/accounts/customers/${customerID}`, payload)
        .then((res) => {
          navigate("/customers");
          toast.success("Customer has been upedated successfully.");
        })
        .catch((err) => {
          setErrors(err?.data?.errors);
          toast.error("Customer update failed.");
        })
        .finally(() => {
          setPageLoading(false);
        });
    } else {
      protectedAxiosInstance
        .post(`/admin/accounts/customers`, payload)
        .then((res) => {
          navigate("/customers");
          toast.success("Customer has been created successfully.");
        })
        .catch((err) => {
          setErrors(err?.data?.errors);
          toast.error("Customer creation failed.");
        })
        .finally(() => {
          setPageLoading(false);
        });
    }
  };

  if (notFound) {
    return <Notfound />;
  }
  const years = range(1950, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
      {/* Header */}
      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  px-4">
        <p className="font-semibold flex justify-start">
          {customerID ? "Edit Customer" : "Create new Customer"}
        </p>
      </div>

      {/* Content */}
      <div className="flex w-full justify-between  flex-1  overflow-y-auto custom-scrollbar  py-3">
        <div className="flex flex-col gap-4 w-[55%] overflow-y-auto custom-scrollbar px-4">
          <InputField
            label="Full Name"
            required
            value={formData?.name || ""}
            onChange={(e) => {
              setFormData((prev: any) => ({ ...prev, name: e }));
              setErrors((prev: any) => ({ ...prev, name: null }));
            }}
            placeholder="Enter full name"
            hint={errors?.name}
            validate={validateTextSpaceDot}
            disabled={pageLoading}
          />
          <InputField
            label="Email ID"
            // required
            value={formData?.email || ""}
            onChange={(e) => {
              setFormData((prev: any) => ({ ...prev, email: e }));
              setErrors((prev: any) => ({ ...prev, email: null }));
            }}
            placeholder="Eg. abc@xyz.com"
            hint={errors?.email}
            validate={validateSpaceNotAllowed}
            disabled={pageLoading}
          />
          <div className="flex  gap-2 items-start ">
            <AuthInputField
              onChange={(e) => {
                setFormData((prev: any) => ({ ...prev, phone: e }));
                setErrors((prev: any) => ({ ...prev, phone: null }));
              }}
              setCode={(countryCode: any) => setCountryCode(countryCode)}
              value={formData?.phone || ""}
              placeholder="XXXXXXXXXX"
              heading="Phone number"
              name="contact"
              type="telephone"
              required
              hint={errors?.phone}
              validate={validateNumber}
              disabled={pageLoading}
            />
            <div className="relative">
              <InputField
                label="Date of Birth"
                // required
                value={formData?.dob || ""}
                onChange={(e) => {
                  setFormData((prev: any) => ({ ...prev, dob: e }));
                  setErrors((prev: any) => ({ ...prev, dob: null }));
                }}
                placeholder="DD-MM-YYYY"
                hint={errors?.dob}
                validate={validateNumberHyphen}
                disabled={pageLoading}
                onFocus={() => setCalenderOpen(false)}
                rightIcon={<img src={CalenderIcon} />}
                rightOnClick={() => {
                  setErrors((prev: any) => ({ ...prev, dob: null }));
                  setCalenderOpen(true);
                }}
              />
              {calenderOpen && (
                <div
                  className="absolute top-[calc(100%+10px)] right-0"
                  ref={calendarRef}
                >
                  <ReactDatePicker
                    inline
                    selected={
                      formData?.dob &&
                      formatDateDDMMYYYYToDate(formData?.dob) instanceof Date &&
                      !isNaN(formatDateDDMMYYYYToDate(formData?.dob).getTime())
                        ? formatDateDDMMYYYYToDate(formData?.dob)
                        : new Date()
                    }
                    onChange={(currentDate: any) => {
                      const formattedDate: any =
                        formatDateToDDMMYYYY(currentDate);
                      setErrors((prev: any) => ({ ...prev, dob: null }));
                      setFormData((prev: any) => ({
                        ...prev,
                        dob: formattedDate,
                      }));
                      setCalenderOpen(false);
                    }}
                    dateFormat="dd-MM-YYYY"
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }: any) => (
                      <div className="m-2 flex justify-between text-c-text1 ">
                        <button
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          <HipchatChevronLeftIcon color="black" />
                        </button>

                        <div className="flex justify-center w-[200px] gap-2 px-2">
                          <SelectField2
                            selectCSS="flex-1"
                            options={years.map((el) => ({
                              value: el.toString(),
                              label: el.toString(),
                            }))}
                            value={getYear(date).toString()}
                            onChange={(e) => changeYear(e)}
                          />
                          <SelectField2
                            selectCSS="flex-1"
                            options={months.map((el, i) => ({
                              value: i.toString(),
                              label: el.toString(),
                            }))}
                            value={months[getMonth(date)]}
                            onChange={(e) => changeMonth(e)}
                          />
                        </div>

                        <button
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          <HipchatChevronRightIcon color="black" />
                        </button>
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <p className="font-gilroy-semi-bold text-[12px] pb-1  flex justify-start">
              Gender
            </p>
            <div className="flex gap-8">
              <SelectCheckboxOption
                kind="Male"
                isSelected={formData?.gender === "MALE"}
                handleSelection={() => {
                  setFormData((prev: any) => ({ ...prev, gender: "MALE" }));
                  setErrors((prev: any) => ({ ...prev, gender: null }));
                }}
                disabled={pageLoading}
              />
              <SelectCheckboxOption
                kind="Female"
                isSelected={formData?.gender === "FEMALE"}
                handleSelection={() => {
                  setFormData((prev: any) => ({ ...prev, gender: "FEMALE" }));
                  setErrors((prev: any) => ({ ...prev, gender: null }));
                }}
                disabled={pageLoading}
              />
              <SelectCheckboxOption
                kind="Others"
                isSelected={formData?.gender === "OTHERS"}
                handleSelection={() => {
                  setFormData((prev: any) => ({ ...prev, gender: "OTHERS" }));
                  setErrors((prev: any) => ({ ...prev, gender: null }));
                }}
                disabled={pageLoading}
              />
            </div>
            <ErrorMessage error={errors?.gender} />
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex gap-x-4 w-[100%] py-3 border-t  border-[#ECECEC] px-4">
        <Button
          label="Save Changes"
          onClick={handleSubmit}
          disabled={pageLoading}
        />
        <Button
          label="Cancel"
          variant="secondary-outline"
          onClick={() => navigate("/customers")}
          disabled={pageLoading}
        />
      </div>
    </div>
  );
};

export default CreateEditCustomer;
