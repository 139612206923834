import React from "react";

const FileIcon = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6668 6.46558C13.6581 6.38903 13.6414 6.31361 13.6168 6.24058V6.16558C13.5768 6.0799 13.5233 6.00114 13.4585 5.93225L8.4585 0.932251C8.38961 0.867431 8.31085 0.813985 8.22516 0.773918H8.15016C8.06551 0.725369 7.97202 0.694204 7.87516 0.682251H2.8335C2.17045 0.682251 1.53457 0.945643 1.06573 1.41448C0.596888 1.88333 0.333496 2.51921 0.333496 3.18225V14.8489C0.333496 15.512 0.596888 16.1478 1.06573 16.6167C1.53457 17.0855 2.17045 17.3489 2.8335 17.3489H11.1668C11.8299 17.3489 12.4658 17.0855 12.9346 16.6167C13.4034 16.1478 13.6668 15.512 13.6668 14.8489V6.51558C13.6668 6.51558 13.6668 6.51558 13.6668 6.46558ZM8.66683 3.52392L10.8252 5.68225H9.50016C9.27915 5.68225 9.06719 5.59445 8.91091 5.43817C8.75463 5.28189 8.66683 5.06993 8.66683 4.84892V3.52392ZM12.0002 14.8489C12.0002 15.0699 11.9124 15.2819 11.7561 15.4382C11.5998 15.5945 11.3878 15.6823 11.1668 15.6823H2.8335C2.61248 15.6823 2.40052 15.5945 2.24424 15.4382C2.08796 15.2819 2.00016 15.0699 2.00016 14.8489V3.18225C2.00016 2.96124 2.08796 2.74928 2.24424 2.593C2.40052 2.43672 2.61248 2.34892 2.8335 2.34892H7.00016V4.84892C7.00016 5.51196 7.26355 6.14784 7.7324 6.61669C8.20124 7.08553 8.83712 7.34892 9.50016 7.34892H12.0002V14.8489Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default FileIcon;
