import React from "react";

const TwitterIcon = ({ size = "20", color = "#AEAEAE" }: any) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2034 1.875H17.96L11.9375 8.75833L19.0225 18.125H13.475L9.13004 12.4442L4.15838 18.125H1.40004L7.84171 10.7625L1.04504 1.875H6.73338L10.6609 7.0675L15.2034 1.875ZM14.2359 16.475H15.7634L5.90338 3.43833H4.26421L14.2359 16.475Z"
        fill={color}
      />
    </svg>
  );
};

export default TwitterIcon;
