import React, { useMemo, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SelectDropDown from "../../../components/FilterDropDown";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ordeAppConfigAtom, titleAtom } from "../../../atom";
import FilterIcon from "../../../assets/svg/filtericon.svg";
import ProgressBar from "./ProgressBar";
import CustomProgressBar from "./CustomProgressBar";

const options = [
  { label: "Download QR code", value: "option1" },
  { label: "Print invoice", value: "option2" },
  { label: "Download invoice", value: "option3" },
];
const progress = "50%"; // Set the initial progress here (e.g., '50%')
const textItems = [
  "Step 1",
  "Step 2",
  "Step 3",
  "Step 4",
  "Step 5",
  "Step 6",
  "Step 7",
];
export default function OrderDetailsCard({
  orderDetails,
  orderFormattedTime,
  orderFormattedDate,
}: any) {
  const setTitle = useSetRecoilState(titleAtom);
  const [selectedValue, setSelectedValue] = useState("");
  const orderAppConfig = useRecoilValue(ordeAppConfigAtom);

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  return (
    <>
      <div className="h-full">
        <div className="w-full h-full border-2 border-r-0">
          <motion.div
            layout
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{
              opacity: 0,
              y: -30,
              transition: { duration: 0.2 },
            }}
            transition={{ delay: 0.1, duration: 0.2 }}
            className="h-full"
          >
            <div className="flex h-full">
              <div className={`w-full h-full px-4 flex flex-col space-y-2 p-4`}>
                <div className="flex justify-end ">
                  {/* Action dropdown */}
                  <div className="flex items-center gap-x-2">
                    <SelectDropDown
                      options={options}
                      onChange={handleSelectChange}
                      heading="Actions"
                      icon={<img src={FilterIcon} alt="filter-icon" />}
                    />
                  </div>
                </div>

                {/*Use For Testing Only */}
                {/* <div>
                  <CustomProgressBar
                    texts={orderAppConfig?.order_status}
                  />
                </div> */}

                {/* Progress bar */}
                <div className={`flex m-auto w-[100%] py-8 px-4 `}>
                  <ProgressBar
                    data={orderAppConfig?.order_status}
                    orderDetails={orderDetails}
                  />
                </div>
              </div>
            </div>
          </motion.div>

          <>
            <motion.div
              layout
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{
                opacity: 0,
                y: -30,
                transition: { duration: 0.2 },
              }}
              transition={{ delay: 0.1, duration: 0.2 }}
            >
              {/* {dropDown} */}
            </motion.div>
          </>
        </div>
      </div>
    </>
  );
}
