import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useRecoilValue } from "recoil";
import { MainAppSideBarExpandAtom } from "../atom";
import loadingSpinner from "../assets/images/LoadingSpinner.gif";

import "../style/fonts.css";

type ManagementTableLayoutProps = {
  tableInstance?: any;
  loading: boolean;
};

const ManagementTableLayout = ({
  tableInstance,
  loading,
}: ManagementTableLayoutProps) => {
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);
  const { getTableProps, getTableBodyProps, prepareRow, headerGroups, page } =
    tableInstance;

  const mainDivStyle = `text-sm text-gray-700 mb-2 h-full overflow-y-scroll table-custom-scrollbar ml-3
  `;

  const tableStyle = `w-full m-auto`;
  const theadStyle =
    "bg-pot-grey1 text-black sticky -top-[0.1px] inset-0 border-t-0 shadow-sm shadow-pot-grey1 pt-[1px] z-50 border-x leading-5 text-[#242424] text-[13px] ";
  const thDivStyle =
    "font-gilroy-semi-bold flex justify-start py-2 px-2 text-gray-700 ";
  const tdDivStyle = `text-gray-700 font-gilroy-medium leading-5 text-[12px] text-[#242424] px-2 border-x border-y text-left`;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ stiffness: 60, duration: 0.2 }}
      exit={{ opacity: 0 }}
      className={`${mainDivStyle}`}
    >
      <table className={tableStyle} {...getTableProps()}>
        <thead className={`${theadStyle}`}>
          {headerGroups.map((headerGroup: any, headerIndex: number) => (
            <tr key={headerIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, columnIndex: number) => (
                <th
                  key={columnIndex}
                  {...column.getHeaderProps()}
                  className={`border-x  ${
                    columnIndex === 0 ? " sticky left-0 z-50 bg-pot-grey1" : ""
                  }`}
                >
                  <div className={thDivStyle}>{column.render("Header")}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <AnimatePresence>
          {loading ? (
            <tbody>
              <tr>
                <td colSpan={headerGroups[0]?.headers.length}>
                  <div className="h-[70vh] flex justify-center items-center">
                    <div className="text-md font-medium text-gray-900 bg-white rounded-lg inline-flex items-center gap-3">
                      <img
                        src={loadingSpinner}
                        alt="spinner"
                        className="h-auto w-[10vh]"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {page.length === 0 ? (
                <tr>
                  <td colSpan={headerGroups[0]?.headers.length}>
                    <div className="h-[70vh] flex justify-center items-center">
                      No data available
                    </div>
                  </td>
                </tr>
              ) : (
                page.map((row: any, rowIndex: any) => {
                  prepareRow(row);
                  return (
                    <tr key={rowIndex} {...row.getRowProps()} className="">
                      {row.cells.map((cell: any, cellIndex: number) => (
                        <td
                          key={cellIndex}
                          {...cell.getCellProps({
                            style: { width: cell.column.width },
                          })}
                          className={`${tdDivStyle} ${
                            cellIndex === 0 ? "sticky left-0  bg-[#FBFBFB]" : ""
                          } `}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          )}
        </AnimatePresence>
      </table>
    </motion.div>
  );
};

export default ManagementTableLayout;
