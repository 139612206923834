interface PDFFileIconProps {
    width?: string;
    height?: string;
    color?: string;
  }
  
  const PDFFileIcon = ({
    color = "black",
    width = "19",
    height = "21",
  }: PDFFileIconProps) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9224 5.96922C14.9251 5.87115 14.9065 5.77368 14.8678 5.68351C14.8292 5.59334 14.7714 5.51263 14.6986 5.44693L9.47573 0.224092C9.41005 0.151218 9.32934 0.0934647 9.23917 0.0548196C9.14899 0.0161746 9.05151 -0.0024415 8.95344 0.000256459H1.49224C1.09683 0.00141779 0.717948 0.159008 0.43835 0.438606C0.158752 0.718204 0.00116133 1.09709 0 1.4925V19.3994C0 19.7952 0.157218 20.1747 0.437067 20.4546C0.716916 20.7344 1.09647 20.8916 1.49224 20.8916H13.4302V19.3994H1.49224V1.4925H7.4612V5.96922C7.46236 6.36463 7.61996 6.74351 7.89955 7.02311C8.17915 7.30271 8.55803 7.4603 8.95344 7.46146H13.4302V8.9537H14.9224V5.96922ZM8.95344 5.96922V1.79095L13.1317 5.96922H8.95344ZM2.29656 10.8203V18.4457H3.60227V15.5209H4.83486C5.34322 15.5209 5.78542 15.4234 6.16146 15.2284C6.53751 15.0334 6.82999 14.7584 7.0389 14.4032C7.25478 14.0481 7.36272 13.6372 7.36272 13.1706C7.36272 12.6971 7.25826 12.2862 7.04934 11.938C6.84043 11.5829 6.54447 11.3078 6.16146 11.1128C5.78542 10.9178 5.34322 10.8203 4.83486 10.8203H2.29656ZM4.83486 14.3405H3.60227V12.0007H4.83486C5.19698 12.0007 5.48597 12.1052 5.70185 12.3141C5.92469 12.523 6.03611 12.8085 6.03611 13.1706C6.03611 13.5258 5.92469 13.8113 5.70185 14.0272C5.48597 14.2361 5.19698 14.3405 4.83486 14.3405ZM8.24055 10.8203V18.4457H10.5073C11.0226 18.4457 11.4683 18.3482 11.8443 18.1532C12.2273 17.9513 12.5233 17.6727 12.7322 17.3175C12.9411 16.9554 13.0456 16.5341 13.0456 16.0536V13.202C13.0456 12.7145 12.9411 12.2932 12.7322 11.938C12.5233 11.5829 12.2273 11.3078 11.8443 11.1128C11.4683 10.9178 11.0226 10.8203 10.5073 10.8203H8.24055ZM10.5073 17.2444H9.54626V12.0216H10.5073C10.8833 12.0216 11.1827 12.1295 11.4056 12.3454C11.6284 12.5543 11.7399 12.8398 11.7399 13.202V16.0536C11.7399 16.4227 11.6284 16.7152 11.4056 16.9311C11.1827 17.14 10.8833 17.2444 10.5073 17.2444ZM14.2577 10.8203V18.4457H15.5634V15.2806H18.7284V14.0689H15.5425V12.032H19V10.8203H14.2577Z"
          fill={color}
        />
      </svg>
    );
  };
  
  export default PDFFileIcon;
  