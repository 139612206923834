import React, { useEffect, useState } from "react";
import close from "../../../assets/svg/close.svg";
import InputField from "../../../components/InputField";
import SingleSelection from "../../../components/SingleSelection";
import { v4 as uuidV4 } from "uuid";
import SelectField from "../../../components/SelectField";
import AuthInputField from "../../../components/AuthInputField";
import { useRecoilValue } from "recoil";
import { stateAtom } from "../../../atom";
import Button from "../../../components/Button";
import {
  findPhoneNumbersInText,
  getCountryCallingCode,
  isSupportedCountry,
} from "libphonenumber-js";
import { toast } from "react-toastify";
import { protectedAxiosInstance } from "../../../api/axiosManagement";

const OrderAddressInput = ({
  targetAddress = null,
  setOpenInputAddress,
  setTargetAddress,
  orderDetails,
  getAddressList,
}: any) => {
  const [formData, setFormData] = useState<any>(targetAddress);
  const [errors, setErrors] = useState<any>({});

  const stateData = useRecoilValue(stateAtom);

  const handleAddress = () => {
    const {
      full_name,
      kind,
      phone,
      state,
      city,
      street_1,
      street_2,
      postal_code,
      tag,
      country_calling_code,
    } = formData;

    if (
      !full_name ||
      !kind ||
      !state ||
      !city ||
      !street_1 ||
      !street_2 ||
      !phone ||
      !postal_code ||
      !tag
    ) {
      if (!full_name) {
        setErrors((prev: any) => ({
          ...prev,
          full_name: "Please enter your full name",
        }));
      }
      if (!kind) {
        setErrors((prev: any) => ({
          ...prev,
          kind: "Please choose billing or shipping",
        }));
      }
      if (!state) {
        setErrors((prev: any) => ({
          ...prev,
          state: "Please choose a state",
        }));
      }

      if (!city) {
        setErrors((prev: any) => ({
          ...prev,
          city: "Please choose a city",
        }));
      }
      if (!street_1) {
        setErrors((prev: any) => ({
          ...prev,
          street_1: "Please enter address line 1",
        }));
      }
      if (!street_2) {
        setErrors((prev: any) => ({
          ...prev,
          street_2: "Please enter address line 2",
        }));
      }
      if (!phone) {
        setErrors((prev: any) => ({
          ...prev,
          phone: "Please enter a phone number",
        }));
      }
      if (!postal_code) {
        setErrors((prev: any) => ({
          ...prev,
          postal_code: "Please enter pin code",
        }));
      }
      if (!tag) {
        setErrors((prev: any) => ({
          ...prev,
          tag: "Please choose type of address",
        }));
      }

      if (Object.keys(errors).length) {
        console.error(errors);
      }
      return null;
    }

    delete formData.country_calling_code;
    delete formData.id;

    if (targetAddress) {
      protectedAxiosInstance
        .patch(
          `/admin/accounts/customers/${orderDetails?.ordered_for}/address/${targetAddress.id}`,
          {
            ...formData,
            phone: `${country_calling_code}${phone}`,
          }
        )
        .then((res) => {
          getAddressList();
          setOpenInputAddress(false);
          setTargetAddress();
          toast.success("Successfully updated address");
        });
    } else {
      protectedAxiosInstance
        .post(
          `/admin/accounts/customers/${orderDetails?.ordered_for}/address`,
          {
            ...formData,
            phone: `${country_calling_code || "+91"}${phone}`,
            country: "IN",
          }
        )
        .then((res) => {
          getAddressList();
          setOpenInputAddress(false);
          setTargetAddress();
          toast.success("Successfully created address");
        });
    }
  };

  useEffect(() => {
    if (targetAddress?.phone) {
      setFormData((prev: any) => ({
        ...prev,
        phone: findPhoneNumbersInText(targetAddress?.phone)?.[0].number
          ?.nationalNumber,
      }));
    }
  }, []);

  return (
    <div
      className="bg-white max-w-xl w-[80%] max-h-[80vh] overflow-y-auto no-scrollbar"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between sticky top-0 z-50 bg-white p-5">
        <p className="text-3xl font-gilroy-bold">
          {targetAddress ? "Edit Address" : "Create Address"}
        </p>
        <button
          className="scale-150"
          onClick={() => {
            setOpenInputAddress(false);
            setTargetAddress(false);
          }}
        >
          <img src={close} alt="close" />
        </button>
      </div>

      <div className="overflow-y-scroll px-5 py-2 no-scrollbar ">
        <div className="text-[0.8rem] flex flex-col  mb-3">
          <p className="font-gilroy-bold ">
            Select type of address:{" "}
            <span className="text-red-500">&nbsp; *</span>
          </p>
          <div className="flex gap-4">
            {["SHIPPING", "BILLING"].map((el) => (
              <div key={uuidV4()}>
                <SingleSelection
                  onClick={() => {
                    if (errors?.kind) {
                      const newErrors = { ...errors };
                      delete newErrors.kind;
                      setErrors(newErrors);
                    }
                    setFormData((prev: any) => ({ ...prev, kind: el }));
                  }}
                  label={el}
                  rounded
                  isSelected={el === formData?.kind}
                />
              </div>
            ))}
          </div>

          {!formData?.kind && errors?.kind && (
            <p className="text-left font-light text-xs pt-1 text-red-700">
              {errors?.kind}
            </p>
          )}
        </div>
        <InputField
          label="Full Name:"
          placeholder="Full Name"
          value={formData?.full_name || ""}
          onChange={(e) => {
            if (errors?.full_name) {
              const newErrors = { ...errors };
              delete newErrors.full_name;
              setErrors(newErrors);
            }
            setFormData((prev: any) => ({ ...prev, full_name: e }));
          }}
          hint={errors?.full_name || ""}
          required
        />

        <AuthInputField
          onChange={(e: any) => {
            if (errors?.phone) {
              const newErrors = { ...errors };
              delete newErrors?.phone;
              setErrors(newErrors);
            }
            setFormData((prev: any) => ({ ...prev, phone: e.target.value }));
          }}
          country_code_value={
            isSupportedCountry(formData?.country)
              ? `+${getCountryCallingCode(formData?.country)}`
              : `+${getCountryCallingCode("IN")}`
          }
          setCode={(e: any) => {
            setFormData((prev: any) => ({
              ...prev,
              country_calling_code: e,
            }));
          }}
          value={formData?.phone}
          placeholder="XXXXXXXXXX"
          heading="Phone number"
          errorMessage={errors?.phone}
          type="telephone"
          required
        />

        <InputField
          className="mb-4"
          label="Address Line 1:"
          placeholder="Address Line 1"
          value={formData?.street_1 || ""}
          onChange={(e) => {
            if (errors?.street_1) {
              const newErrors = { ...errors };
              delete newErrors.street_1;
              setErrors(newErrors);
            }
            setFormData((prev: any) => ({ ...prev, street_1: e }));
          }}
          hint={errors?.street_1 || ""}
          required
        />
        <InputField
          label="Address Line 2:"
          className="mb-4"
          placeholder="Address Line 2"
          value={formData?.street_2 || ""}
          onChange={(e) => {
            if (errors?.street_2) {
              const newErrors = { ...errors };
              delete newErrors.street_2;
              setErrors(newErrors);
            }
            setFormData((prev: any) => ({ ...prev, street_2: e }));
          }}
          hint={errors?.street_2 || ""}
          required
        />

        <div className="flex gap-4 mb-4">
          <div className="flex-1">
            <SelectField
              placeholder="Select City"
              hoverText={formData?.state ? "" : "First select state"}
              label="Select City:"
              options={(
                stateData.find(
                  (el: any) => el.state_code === formData?.state
                ) || { cities: [] }
              ).cities.map((el: any) => ({
                value: el,
                label: el,
              }))}
              value={formData?.city}
              onChange={(e) => {
                if (errors?.city) {
                  const newErrors = { ...errors };
                  delete newErrors.city;
                  setErrors(newErrors);
                }
                if (
                  stateData.find((el: any) => el.state_code === formData?.state)
                ) {
                  setErrors((prev: any) => ({
                    ...prev,
                    city: "Please select a State first",
                  }));
                }
                setFormData((prev: any) => ({ ...prev, city: e }));
              }}
              required
            />

            {!formData?.city && errors?.city && (
              <p className="text-left font-light text-xs pt-1 text-red-700">
                {errors?.city}
              </p>
            )}
          </div>
          <div className="flex-1">
            <SelectField
              label="Select State:"
              options={stateData.map((el: any) => ({
                value: el.state_code,
                label: el.name,
              }))}
              value={formData?.state}
              onChange={(e: any) => {
                if (errors?.state) {
                  const newErrors = { ...errors };
                  delete newErrors.state;
                  setErrors(newErrors);
                }
                setFormData((prev: any) => ({ ...prev, state: e, city: "" }));
              }}
              placeholder="Select state"
              required
            />

            {!formData?.state && errors?.state && (
              <p className="text-left font-light text-xs pt-1 text-red-700">
                {errors?.state}
              </p>
            )}
          </div>
        </div>
        <InputField
          label="Pin code:"
          className="mb-4"
          placeholder="Pin code"
          value={formData?.postal_code || ""}
          onChange={(e) => {
            if (errors?.postal_code) {
              const newErrors = { ...errors };
              delete newErrors.postal_code;
              setErrors(newErrors);
            }
            setFormData((prev: any) => ({ ...prev, postal_code: e }));
          }}
          hint={errors?.postal_code || ""}
          required
        />
        <div className="text-[0.8rem] flex flex-col gap-2">
          <p className="font-gilroy-bold">
            Tag Address as: <span className="text-red-500">&nbsp; *</span>
          </p>
          <div className="flex gap-4">
            {["HOME", "WORK", "OTHERS"].map((el) => (
              <div key={uuidV4()}>
                <SingleSelection
                  rounded
                  label={el}
                  isSelected={el === formData?.tag}
                  onClick={() => {
                    if (errors?.tag) {
                      const newErrors = { ...errors };
                      delete newErrors.tag;
                      setErrors(newErrors);
                    }
                    setFormData((prev: any) => ({ ...prev, tag: el }));
                  }}
                />
              </div>
            ))}
          </div>
          {!formData?.tag && errors?.tag && (
            <p className="text-left font-light text-xs pt-1 text-red-700">
              {errors?.tag}
            </p>
          )}
        </div>
      </div>

      <div className="flex gap-2 text-[12px] md:text-base sticky bottom-0 z-40 bg-white p-5">
        <Button
          variant="primary"
          label={targetAddress ? "Update Address" : "Create Address"}
          onClick={() => {
            handleAddress();
          }}
        />
        <Button
          label="Cancel"
          variant="secondary-outline"
          onClick={() => {
            setOpenInputAddress(false);
            setTargetAddress();
          }}
        />
      </div>
    </div>
  );
};

export default OrderAddressInput;
