import { useEffect, useState } from "react";
import BigBlockWithImgPopup from "./VariantsPopupForms/BigBlockWithImgPopup";
import BigBlockWithoutImgPopup from "./VariantsPopupForms/BigBlockWithoutImgPopup";
import ColorPickerPopup from "./VariantsPopupForms/ColorPickerPopup";
import SmallBlockWithImgPopup from "./VariantsPopupForms/SmallBlockWithImgPopup";
import MiniBlockWithImgPopup from "./VariantsPopupForms/MiniBlockWithImgPopup";
import TrackTypePopup from "./VariantsPopupForms/TrackTypePopup";
import {
  VariantBigBlockWithImageProps,
  VariantBigBlockWithoutImageProps,
  VariantColorPickerProps,
  VariantMiniBlockWithImageProps,
  VariantSmallBlockWithImageProps,
  VariantTrackTypeProps,
} from "../../types";

import Form from "./variantcomponents/Form";
import { useNavigate, useParams } from "react-router-dom";
import { postVariants, updateVariants } from "../../api/variants";
import { toast } from "react-toastify";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import { removeExtraSpacesfromObject } from "../../utils/helper";

// {
//   "name": "Variant Template #1",
//   "description": "This is description.",
//   "stage": "Stage #1",
//   "info": "Test info",
//   "kind": "BIG_BLOCK_WITH_IMAGE",
//   "is_active": true,
//   "options": []
// }

export default function CreateEditVariant() {
  const { id: variantID } = useParams();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedVariants, setSelectedVariants] = useState<string>("");

  const [errors, setErrors] = useState<any>({
    desiption: [],
    info: [],
    kind: [],
    name: [],
    options: [],
  });
  const [seletedOption, setSeletedOption] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    setOpenModal(false);
  };
  const [variantData, setVariantData] = useState<any>({});

  const handleSubmitData = async () => {
    setLoading(true);
    const options = structuredClone(variantData.options);

    options?.forEach((each: any, index: number) => {
      if (each.added) {
        delete each.id;
        delete each.added;
      }
      delete each.type;
      if (each.image && each.image.imageUrl) {
        each.image = each.image.imageUrl;
      }
      if (each.price) {
        each.price = parseFloat(each.price);
      }
      each.position = index;
      if (each.properties && Array.isArray(each.properties)) {
        each.properties = each.properties.reduce(
          (acc: any, cur: { key: string; value: string }) => {
            if (cur.key) {
              acc[cur.key] = cur.value;
            }
            return acc;
          },
          {}
        );
      }
    });

    // const payload = variantID
    //   ? {
    //       id: variantID,
    //       variantData: {
    //         name: variantData.name,
    //         description: variantData.description,
    //         kind: selectedVariants,
    //         info: variantData.info,
    //         stage: "Stage #1",
    //         is_active: true,
    //         options,
    //       },
    //     }
    //   : {
    //       name: variantData.name,
    //       description: variantData.description,
    //       kind: selectedVariants,
    //       info: variantData.info,
    //       stage: "Stage #1",
    //       is_active: true,
    //       options,
    //     };

    const { id, type, ...payload } = variantData;

    if (variantID) {
      try {
        const res = await updateVariants(
          id,
          removeExtraSpacesfromObject({
            ...payload,
            kind: selectedVariants,
            options,
          })
        );
        if (res) {
          toast.success("Variant template updated successfully");
          navigate(`/products/variant-templates`);
        }
      } catch (e: any) {
        if (e?.data?.errors?._schema?.length === 1) {
          toast.error(e?.data?.errors?._schema?.[0]);
        } else if (e?.data?.errors?._schema?.length > 1) {
          toast.error(
            <ul className="list-disc pl-4">
              {e?.data?.errors?._schema?.map((errorMessage: any, i: number) => (
                <li key={i}>{errorMessage}</li>
              ))}
            </ul>
          );
        } else {
          toast.error("Variant template update failed");
        }

        setErrors(e?.data?.errors);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await postVariants(
          removeExtraSpacesfromObject({
            ...payload,
            kind: selectedVariants,
            options,
          })
        );
        if (res) {
          toast.success("Variant template created successfully");
          navigate("/products/variant-templates");
        }
      } catch (e: any) {
        if (e?.data?.errors?._schema?.length > 0) {
          toast.error(e?.data?.errors?._schema?.[0]);
        } else {
          toast.error("Variant template creation failed");
        }
        const errs = e?.data?.errors;
        setErrors(errs);
        if (errs?.options || errs?.kind) {
          setVariantData((prev: any) => ({ ...prev, options: [] }));
          setSelectedVariants("");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleVariantsSelection = (variants: string) => {
    if (errors?.options || errors?.kind) {
      delete errors.options;
      delete errors.kind;
      setErrors({ ...errors });
    }
    if (selectedVariants !== variants) {
      setSelectedVariants(variants);
    } else {
      setSelectedVariants("");
    }
    setVariantData((prev: any) => ({ ...prev, options: [] }));
  };

  const fetchVariant = async () => {
    try {
      const { data: response } = await protectedAxiosInstance.get(
        `/admin/products/variant-template/${variantID}`
      );

      const settable = structuredClone(response.data.variant_template);
      settable?.options?.forEach((each: any) => {
        each.type = settable.kind;
      });
      setSelectedVariants(settable.kind);
      delete settable.kind;
      setVariantData(settable);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    if (variantID) {
      fetchVariant();
    } else {
      setLoading(false);
    }
  }, [variantID]); //eslint-disable-line

  useEffect(() => {
    if (variantData?.name) {
      document.title = `Variant - ${variantData?.name}`;
    } else {
      document.title = `Variant - ...`;
    }
  }, [variantData?.name]);

  return (
    <>
      <div>
        <Form
          loading={loading}
          errors={errors}
          setErrors={setErrors}
          Variants={Variants}
          handleSubmitData={handleSubmitData}
          handleVariantsSelection={handleVariantsSelection}
          selectedVariants={selectedVariants}
          setOpenModal={setOpenModal}
          setSeletedOption={setSeletedOption}
          setVariantData={setVariantData}
          variantData={variantData}
        />
      </div>

      {/* Modals */}
      {selectedVariants === "BIG_BLOCK_WITH_IMAGE" && openModal && (
        <BigBlockWithImgPopup
          handleClose={handleClose}
          setVariantData={setVariantData}
          task={seletedOption?.task}
          data={
            variantData.options.find(
              (el: any) => el === seletedOption?.elid
            ) as VariantBigBlockWithImageProps
          }
        />
      )}
      {selectedVariants === "BIG_BLOCK_WITHOUT_IMAGE" && openModal && (
        <BigBlockWithoutImgPopup
          handleClose={handleClose}
          setVariantData={setVariantData}
          task={seletedOption?.task}
          data={
            variantData.options.find(
              (el: any) => el === seletedOption?.elid
            ) as VariantBigBlockWithoutImageProps
          }
        />
      )}
      {selectedVariants === "COLOR_PICKER" && openModal && (
        <ColorPickerPopup
          handleClose={handleClose}
          setVariantData={setVariantData}
          task={seletedOption?.task}
          data={
            variantData.options.find(
              (el: any) => el === seletedOption?.elid
            ) as VariantColorPickerProps
          }
        />
      )}
      {selectedVariants === "SMALL_BLOCK_WITH_IMAGE" && openModal && (
        <SmallBlockWithImgPopup
          handleClose={handleClose}
          setVariantData={setVariantData}
          task={seletedOption?.task}
          data={
            variantData.options.find(
              (el: any) => el === seletedOption?.elid
            ) as VariantSmallBlockWithImageProps
          }
        />
      )}
      {selectedVariants === "MINI_BLOCK_WITH_IMAGE" && openModal && (
        <MiniBlockWithImgPopup
          handleClose={handleClose}
          setVariantData={setVariantData}
          task={seletedOption?.task}
          data={
            variantData.options.find(
              (el: any) => el === seletedOption?.elid
            ) as VariantMiniBlockWithImageProps
          }
        />
      )}
      {selectedVariants === "TRACK_TYPE" && openModal && (
        <TrackTypePopup
          handleClose={handleClose}
          setVariantData={setVariantData}
          task={seletedOption?.task}
          data={
            variantData.options.find(
              (el: any) => el === seletedOption?.elid
            ) as VariantTrackTypeProps
          }
        />
      )}
    </>
  );
}

export const Variants = [
  { value: "BIG_BLOCK_WITH_IMAGE", label: "Big block with image" },
  { value: "BIG_BLOCK_WITHOUT_IMAGE", label: "Big block without image" },
  { value: "COLOR_PICKER", label: "Color Picker" },
  { value: "SMALL_BLOCK_WITH_IMAGE", label: "Small block with image" },
  { value: "MINI_BLOCK_WITH_IMAGE", label: "Mini block with image" },
  { value: "TRACK_TYPE", label: "Track type" },
];
