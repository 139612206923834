import { protectedAxiosInstance } from "./axiosManagement";

export const getProduct = (params) => {
  return protectedAxiosInstance
    .get("/admin/products", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateProduct = (id, payload) => {
  return protectedAxiosInstance
    .put(`/admin/products/${id}`, payload.json())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getSingleProduct= (id) => {
  return protectedAxiosInstance
    .get(`/admin/products/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

