import { protectedAxiosInstance } from "./axiosManagement";

export const getVariants = (params) => {
  return protectedAxiosInstance
    .get("/admin/products/variant-template", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postVariants = (payload) => {
  return protectedAxiosInstance
    .post("/admin/products/variant-template", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateVariants = (id, payload) => {
  return protectedAxiosInstance
    .patch(`/admin/products/variant-template/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteVariants = (id) => {
  return protectedAxiosInstance
    .delete(`/admin/products/variant-template/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
