const ErrorMessage = ({ error }: any) => {
  // Function to flatten the errors into an array of error messages
  const flattenErrors = (error: any): any => {
    // If error is a string, return it as a single-element array
    if (typeof error === "string") return [error];
    // If error is an array, flatten it
    if (Array.isArray(error)) return error.flatMap(flattenErrors);
    // If error is an object, flatten its values
    if (typeof error === "object" && error !== null && error !== undefined)
      return flattenErrors(Object.values(error));
    // If none of the above, return an empty array
    return [];
  };
  const flattenedErrors = flattenErrors(error);

  // If there are no errors, return null
  if (!flattenedErrors.length) return null;
  return (
    <div className="font-gilroy-bold text-[0.7rem] text-[#FC5A5A] tracking-wider">
      {flattenedErrors &&
        (typeof flattenedErrors === "string" ? (
          <p className="flex text-left my-2  ">{error}</p>
        ) : flattenedErrors.length ? (
          flattenedErrors.length === 1 ? (
            <p className="my-2 text-left  ">{flattenedErrors[0]}</p>
          ) : (
            <ul className="list-disc px-4">
              {flattenedErrors.map((each: any) => (
                <li key={each} className="my-2 text-left  ">
                  {each}
                </li>
              ))}
            </ul>
          )
        ) : (
          ""
        ))}
    </div>
  );
};

export default ErrorMessage;
