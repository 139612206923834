import React from "react";

interface LabelProps {
  label?: string;
  required?: boolean; // Declare the 'required' prop as optional
}

const Label = ({ label, required = false }: LabelProps) => {
  return (
    <div>
      {label && (
        <p className="text-[12px] font-gilroy-bold pb-2 flex justify-start">
          {label}
          {required && <span className="text-red-500 text-xs ml-1">*</span>}
        </p>
      )}
    </div>
  );
};

export default Label;
