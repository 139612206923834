import React, { useRef, useState } from "react";
import HipchatChevronDownIcon from "../assets/HipchatChevronDownIcon";
import { motion } from "framer-motion";
import useOnClickOutside from "../hooks/useClickOutside";
import { MainAppSideBarExpandAtom } from "../atom";
import { useRecoilValue } from "recoil";

type Option = {
  value?: string;
  label?: string;
  full_name?: string | undefined;
  varName?: string;
  varId?: string;
  optionName?: string;
  optionId?: string;
};

type IncompatibleVariant = {
  value: string;
  varName: string | undefined;
  varId: string | undefined;
  opName: string | undefined;
  opId: string | undefined;
};

type SelectFieldProps = {
  label?: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  setIncompatibleVarOps?: (value: IncompatibleVariant) => void;
  placeholder?: string;
  type?: string;
  required?: boolean;
  hoverText?: string;
  disabled?: boolean;
  containerClassName?: string;
};

const SelectField = ({
  options,
  containerClassName,
  value,
  onChange,
  setIncompatibleVarOps,
  label,
  placeholder = "Select an option",
  type,
  required = false,
  hoverText,
  disabled = false,

}: SelectFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  useOnClickOutside(selectRef, () => setIsOpen(false));
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);
  const [showHover, setShowHover] = useState(false);

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option): void => {
    let varOp = option?.varName + " - " + option?.optionName;
    let incompatibleVarOp = {
      value: varOp,
      varName: option.varName,
      varId: option.varId,
      opName: option.optionName,
      opId: option.optionId,
    };
    const selectedValue = type ? varOp : option.value || option.full_name || "";

    onChange(selectedValue);
    type && setIncompatibleVarOps?.(incompatibleVarOp);
    setIsOpen(false);
  };

  const listItems = () => {
    options?.map((e) => {
      console.log(e, "e");
    });
  };

  return (
    <div
      ref={selectRef}
      onMouseEnter={() => setShowHover(true)}
      onMouseLeave={() => setShowHover(false)}
      className={`relative ${containerClassName} flex flex-col justify-start items-start ${
        sidebarExpand && type
          ? "min-w-[13.5rem] max-w-[13.5rem]"
          : "min-w-[13.5rem] max-w-[100%]"
      }`}
    >
      <div className="flex justify-between items-center w-full">
        <div>
          {label && (
            <p className="font-gilroy-semi-bold text-[12px] pb-2 px-1 flex justify-start">
              {label}{" "}
              {required && <span className="text-red-500"> &nbsp;*</span>}
            </p>
          )}
        </div>
        <div>
          {showHover && (
            <p className="font-gilroy-semi-bold text-[12px] pb-2 px-1 text-red-500">
              {hoverText}
            </p>
          )}
        </div>
      </div>
      <div
        onClick={handleSelectClick}
        className={`w-full flex justify-between items-center px-4 py-[0.5vh] bg-white rounded-md cursor-pointerp-2 border-2 border-[#DEDEDE]  outline-none  ${
          disabled
            ? "cursor-not-allowed opacity-30"
            : "focus:bg-white focus:border-pot-yellow hover:border-[#D4A15E]   hover:border-opacity-50"
        } `}
      >
        <span
          className={`block truncate font-gilroy-regular text-[14px] ${
            sidebarExpand && type ? "max-w-[80%]" : "max-w-[80%]"
          }`}
        >
          {value !== "" && value !== undefined ? (
            options?.find((option) => option.value === value)?.label || value
          ) : (
            <span className="text-[#AEAEAE] text-sm">{placeholder}</span>
          )}
        </span>

        <motion.div
          animate={{
            rotate:
              isOpen && !disabled && options && options?.length !== 0 ? 180 : 0,
          }}
          className="w-fit h-fit flex items-center justify-center"
        >
          <HipchatChevronDownIcon color="black" />
        </motion.div>
      </div>
      {isOpen && !disabled && options && options?.length !== 0 && (
        <div
          className={`w-full bg-white rounded-md shadow-md mt-1 z-50 text-start absolute top-[100%] max-h-[20vh] overflow-scroll custom-scrollbar ${
            options.length !== 0 ? "border-2 border-c-white-3" : ""
          }`}
        >
          <ul className={type && `h-[14rem] `}>
            {options?.map((option) =>
              type ? (
                <li
                  key={option.optionId}
                  onClick={() => handleOptionClick(option)}
                  className={`px-4 cursor-pointer hover:bg-gray-100 font-gilroy-regular text-[14px] leading-5 ${
                    option.value === value ? "bg-gray-100" : ""
                  }`}
                >
                  {option.varName} - {option.optionName}
                </li>
              ) : (
                <li
                  key={option.value}
                  onClick={() => handleOptionClick(option)}
                  className={`px-4 py-2 cursor-pointer hover:bg-gray-100 font-gilroy-regular text-[14px] leading-5 ${
                    option.value === value ? "bg-gray-100" : ""
                  }`}
                >
                  {option.label}
                </li>
              )
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectField;
