import React, { Dispatch, SetStateAction } from "react";

type ConsultantCardPropType = {
  text: string;
  count: number;
  icon: React.ReactNode;
};

const ProductCard = ({ text, count, icon }: ConsultantCardPropType) => {
  return (
    <div className="bg-[#F9F9F9] w-full flex flex-col max-w-[14vw]">
      <div className="relative py-3 px-4">
        <div className={`absolute z-10 top-0 w-1 h-full left-0`}></div>
        <div className="relative z-20 flex justify-between items-center">
          <p className="font-medium text-[20px]">{count}</p>
          <div>{icon}</div>
        </div>
        <p className="relative z-20 text-[11px] text-[#AEAEAE] text-left">
          {text}
        </p>
      </div>
    </div>
  );
};

export default ProductCard;
