import React from "react";

interface PNGPreviewIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const PNGPreviewIcon = ({
  width = "20",
  height = "20",
  color = "#AEAEAE",
}: PNGPreviewIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.48901 14.51C5.81567 14.8366 6.20817 15 6.66651 15H16.6665C17.1243 15.0005 17.5165 14.8375 17.8432 14.5108C18.1698 14.1841 18.3332 13.7916 18.3332 13.3333V3.3333C18.3337 2.87552 18.1707 2.4833 17.844 2.15663C17.5173 1.82996 17.1248 1.66663 16.6665 1.66663H6.66651C6.20873 1.66607 5.81651 1.82913 5.48984 2.1558C5.16317 2.48246 4.99984 2.87496 4.99984 3.3333V13.3333C4.99928 13.7911 5.16234 14.1833 5.48901 14.51ZM16.6665 13.3333H6.66651V3.3333H16.6665V13.3333ZM2.15567 17.8433C2.48234 18.17 2.87484 18.3333 3.33317 18.3333H14.9998V16.6666H3.33317V4.99996H1.66651V16.6666C1.66595 17.1244 1.82901 17.5166 2.15567 17.8433Z"
        fill={color}
      />
      <path
        d="M8.66634 10V7.08H9.66234C9.86768 7.08 10.0463 7.11867 10.1983 7.196C10.353 7.27333 10.4717 7.38133 10.5543 7.52C10.6397 7.65867 10.6823 7.82133 10.6823 8.008C10.6823 8.192 10.6397 8.35467 10.5543 8.496C10.469 8.63467 10.3503 8.74267 10.1983 8.82C10.0463 8.89733 9.86768 8.936 9.66234 8.936H9.26634V10H8.66634ZM9.26634 8.396H9.66234C9.79034 8.396 9.89168 8.36133 9.96634 8.292C10.0437 8.22 10.0823 8.12533 10.0823 8.008C10.0823 7.89067 10.0437 7.79733 9.96634 7.728C9.89168 7.656 9.79034 7.62 9.66234 7.62H9.26634V8.396ZM11.0408 10V7.08H11.7408L12.4968 9.34C12.4888 9.24133 12.4794 9.128 12.4688 9C12.4581 8.86933 12.4488 8.74 12.4408 8.612C12.4354 8.48133 12.4328 8.37067 12.4328 8.28V7.08H12.9608V10H12.2608L11.5128 7.74C11.5208 7.82533 11.5288 7.92667 11.5368 8.044C11.5448 8.16133 11.5514 8.28 11.5568 8.4C11.5648 8.52 11.5688 8.62667 11.5688 8.72V10H11.0408ZM14.4152 10.04C14.2206 10.04 14.0499 10.004 13.9032 9.932C13.7566 9.85733 13.6419 9.75467 13.5592 9.624C13.4792 9.49067 13.4392 9.336 13.4392 9.16V7.92C13.4392 7.74133 13.4792 7.58667 13.5592 7.456C13.6419 7.32533 13.7566 7.224 13.9032 7.152C14.0499 7.07733 14.2206 7.04 14.4152 7.04C14.6126 7.04 14.7832 7.07733 14.9272 7.152C15.0739 7.224 15.1872 7.32533 15.2672 7.456C15.3499 7.58667 15.3912 7.74133 15.3912 7.92H14.7912C14.7912 7.80267 14.7579 7.71333 14.6912 7.652C14.6272 7.59067 14.5352 7.56 14.4152 7.56C14.2952 7.56 14.2019 7.59067 14.1352 7.652C14.0712 7.71333 14.0392 7.80267 14.0392 7.92V9.16C14.0392 9.27467 14.0712 9.364 14.1352 9.428C14.2019 9.48933 14.2952 9.52 14.4152 9.52C14.5352 9.52 14.6272 9.48933 14.6912 9.428C14.7579 9.364 14.7912 9.27467 14.7912 9.16V8.908H14.3592V8.4H15.3912V9.16C15.3912 9.336 15.3499 9.49067 15.2672 9.624C15.1872 9.75467 15.0739 9.85733 14.9272 9.932C14.7832 10.004 14.6126 10.04 14.4152 10.04Z"
        fill={color}
      />
    </svg>
  );
};

export default PNGPreviewIcon;
