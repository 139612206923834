import React from "react";

function InfoIcon({ size }: any) {
  return (
    <svg
      width={size || "14"}
      height={size || "14"}
      fill="none"
      stroke={"black"}
      strokeWidth="2.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 24 24"
    >
      <path d="M12 11.5v5"></path>
      <path d="m12 7.511.01-.011"></path>
      <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"></path>
    </svg>
  );
}

export default InfoIcon;
