import React from "react";
import SeriesCard from "../seriescomponents/SeriesCard";
import SeriesIcon from "../../../assets/SeriesIcon";

const BrandsCounts = ({ count }: any) => {
  return (
    <div className="w-full flex  gap-x-6 px-4 py-2 items-center">
      <div className="bg-[#F9F9F9] w-full flex flex-col max-w-[14vw]">
        <div className="relative py-3 px-4">
          <div className={`absolute z-10 top-0 w-1 h-full left-0`}></div>
          <div className="relative z-20 flex justify-between items-center">
            <p className="font-medium text-[20px]">{count}</p>
            <div>
              <SeriesIcon color={"#AEAEAE"} />
            </div>
          </div>
          <p className="relative z-20 text-[11px] text-[#AEAEAE] text-left">
            Total brands
          </p>
        </div>
      </div>
    </div>
  );
};

export default BrandsCounts;
