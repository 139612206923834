import React from "react";
type SearchProps = {
  width?: string;
  height?: string;
};

function SearchIcon({ width, height }: SearchProps) {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <rect width="20" height="20" fill="white" fill-opacity="0.01"/> */}
      <path
        d="M12.3526 13.2359C11.4271 13.9376 10.2698 14.2617 9.11448 14.1428C7.95919 14.024 6.89211 13.471 6.12889 12.5956C5.36567 11.7202 4.96324 10.5877 5.00292 9.42697C5.04261 8.26626 5.52145 7.1639 6.34267 6.34267C7.1639 5.52145 8.26626 5.04261 9.42697 5.00292C10.5877 4.96324 11.7202 5.36567 12.5956 6.12889C13.471 6.89211 14.024 7.95919 14.1428 9.11448C14.2617 10.2698 13.9376 11.4271 13.2359 12.3526L15.4418 14.5584C15.6859 14.8026 15.8834 15.0501 15.4418 15.4417C15.0001 15.8334 14.8026 15.6859 14.5584 15.4417L12.3526 13.2359V13.2359ZM9.58342 12.9167C10.4675 12.9167 11.3153 12.5656 11.9404 11.9404C12.5656 11.3153 12.9168 10.4675 12.9168 9.58342C12.9168 8.69936 12.5656 7.85151 11.9404 7.22639C11.3153 6.60127 10.4675 6.25008 9.58342 6.25008C8.69936 6.25008 7.85152 6.60127 7.22639 7.22639C6.60127 7.85151 6.25008 8.69936 6.25008 9.58342C6.25008 10.4675 6.60127 11.3153 7.22639 11.9404C7.85152 12.5656 8.69936 12.9167 9.58342 12.9167V12.9167Z"
        fill="#AEAEAE"
      />
    </svg>
  );
}

export default SearchIcon;
