import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { assignedCategoryDataAtom } from "../../../atom";
import TrashIcon from "../../../assets/TrashIcon";
import { toast } from "react-toastify";

const ProductsOfCategory = () => {
  const assignedProducts = useRecoilValue(assignedCategoryDataAtom);
  const setAssignedProducts = useSetRecoilState(assignedCategoryDataAtom);
  const handleDeleteAssignedData = (id: any) => {
    const updatedAssignedProducts = assignedProducts.filter(
      (el: any) => el.value.id !== id
    );
    setAssignedProducts(updatedAssignedProducts);
    // toast.success("Assigned data deleted successfully");
  };
  return (
    <div className="w-[45%] px-4 flex flex-col gap-0 ">
      <div className="flex flex-col items-start">
        <p className="font-gilroy-bold leading-5 text-[16px]">
          List of products assigned:
        </p>
      </div>
      <div className="w-full flex bg-[#ECECEC]/80 mt-4 font-gilroy-bold divide-x-2 ">
        <div className="w-[5vw] px-2 py-3 font-gilroy-semi-bold leading-5 text-[12px]">
          Sr. no
        </div>
        <div className="w-full flex justify-start px-4 py-3 font-gilroy-semi-bold leading-5 text-[12px]">
          Product name
        </div>
      </div>
      <div className="flex flex-col justify-start items-start divide-y  overflow-y-auto custom-scrollbar">
        {assignedProducts.map((el: any, index) => (
          <div key={index} className="w-full flex bg-white divide-x">
            <div className="w-[5vw] px-2 py-2">{index + 1}</div>
            <div className="w-full flex justify-between items-center px-4 py-2">
              <p className="font-gilroy-medium leading-5 text-[12px]">
                {el.label}
              </p>
              <div
                onClick={() => handleDeleteAssignedData(el?.value?.id)}
                className="cursor-pointer"
              >
                <TrashIcon />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsOfCategory;
