import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { AnimatePresence, motion } from "framer-motion";
import UpandDownArrow from "../../../assets/svg/dateIcon.svg";
import pen from "../../../assets/svg/pen.svg";
import activeStatus from "../../../assets/svg/activestatus.svg";
import DeActiveStatus from "../../../assets/svg/deactivatestatus.svg";
import { toast } from "react-toastify";
import ManagementTableLayout from "../../../components/MangementTable";
import ShowEntries from "../../../components/ShowEntries";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import Button from "../../../components/Button";
import PlusIcon from "../../../assets/PlusIcon";
import SelectDropDown from "../../../components/FilterDropDown";
import DownloadIcon from "../../../assets/DownloadIcon";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { MainAppSideBarExpandAtom } from "../../../atom";

import DropdownCheckbox from "../../../components/SingleDropdownCheckbox";
import { getProfessional, updateProfessional } from "../../../api/professional";
import EditIcon from "../../../assets/EditIcon";
import UnderDevelopmentIcon from "../../../assets/svg-tsx/UnderDevelopmentIcon";
import Pagination from "../../../components/Pagination";

type getUserDataProps = {
  page?: number;
  totalCount?: number;
  pageLength?: number;
};

function CompanyTable() {
  const [totalCount, setTotalCount] = useState<number>(100);
  const [pageLength, setPageLength] = useState<number>(25);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initial, setInitial] = useState(true);
  const [status, setStatus] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [userData, setUserData] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);
  const [isTogglingStatus, setIsTogglingStatus] = useState("");
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    setLoading(true);
    getProfessional({
      status: "VERIFIED",
      page: page,
      length: pageLength,
      search: inputVal,
      kind: "p_company",
    })
      .then((res) => {
        // console.log(res?.data?.professional);
        const users = res?.data?.professional;
        setUserData(users);
        setTotalCount(res.data.pagination.total_count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, pageLength, inputVal]);

  //   const downloadReport = async () => {
  //     const response = await fetch("your-api-url", {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/xml",
  //       },
  //     });
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(new Blob([blob]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "report.xml");
  //     document.body.appendChild(link);
  //     link.click();
  //   };

  const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  function handleScroll(event: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(event.target.value);
    setScrollPosition(value);
    const container = document.getElementById("slider-main-container");
    if (container) {
      container.scrollLeft = value;
    }
  }

  const handleEdit = async (id: number) => {};

  const handleStatusToggle = async (
    professionalId: string,
    currentStatus: boolean
  ) => {
    if (isTogglingStatus) return;
    setIsTogglingStatus(professionalId);
    const payload = {
      user: {
        is_active: !currentStatus,
      },
    };
    try {
      await updateProfessional(professionalId, payload); // Assuming you have an updateCustomer function
      toast.success("Status updated successfully");
      // Update local userData state (if needed)
      // You can handle this part based on your application's state management
      setUserData((prevData: any) =>
        prevData.map((professional: any) => {
          if (professional.id === professionalId) {
            return {
              ...professional,
              is_active: !currentStatus,
            };
          }
          return professional;
        })
      );
    } catch (error) {
      console.error(error);
      toast.error("Failed to update status");
    } finally {
      setIsTogglingStatus("");
    }
  };

  const COLUMN = [
    {
      id: "sr",
      Header: () => <div>Sr. No</div>,
      width: "55px",
      accessor: "sr",
      Cell: ({ row }: { row: any }) => <div>{startIndex + row.index + 1}</div>,
    },
    {
      id: "name",

      Header: ({ column }: { column: any }) => (
        <div className=" w-[118px]">
          <div className="flex justify-start items-center">Name</div>
        </div>
      ),

      accessor: "p_company.name",

      Cell: ({ row, value }: any) => (
        <div className="underline underline-offset-2 hover:scale-105 hover:pl-1">
          <Link
            to={`/customers/professionals/company/${row?.original?.customer?.id}`}
          >
            {" "}
            <p>{value}</p>
          </Link>
        </div>
      ),
    },

    {
      id: "email",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Email ID</div>
      ),
      accessor: "p_company.social_email",
      Cell: ({ value }: { value: any }) => <p>{value}</p>,
    },
    {
      id: "phone",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Phone Number</div>
      ),
      accessor: "p_company.social_phone",
      Cell: ({ value }: { value: any }) => <p>{value}</p>,
    },

    // {
    //   id: "status",
    //   Header: ({ column }: { column: any }) => (
    //     <div className="flex justify-center items-center gap-2 px-2">
    //       Status
    //     </div>
    //   ),
    //   accessor: "status",
    //   width: 100,
    //   Cell: ({ value }: { value: any }) => (
    //     // <p className="bg-[#BBF8DF] w-12 px-1.5 py-0.5 text-black font-medium">Verified</p>,
    //     <p className="bg-[#FFB4A1] w-16 px-1.5 py-0.5 text-black font-medium">
    //       Unverified
    //     </p>
    //   ),
    // },

    {
      id: "location",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Location</div>
      ),
      accessor: "p_company",
      Cell: ({ value }: { value: any }) => (
        <p>
          {[value?.practice_location_city, value?.practice_location_state].join(
            ", "
          )}
        </p>
      ),
    },
    {
      id: "profile_picture",

      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2  ">
          <p>Profile Picture</p>
        </div>
      ),
      accessor: "p_company.profile_picture",
      width: "25%",
      Cell: ({ row, value }: any) => (
        <div className="font-semibold w-10 h-10">
          <img
            src={`${process.env.REACT_APP_BUCKET}/${value}`}
            alt={row.original?.p_company?.name}
            className="w-full h-full py-1 object-contain"
          />
        </div>
      ),
    },
    {
      id: "buttons",
      Header: () => <div className="flex w-full justify-start ">Action</div>,
      width: 200,
      Cell: ({ row }: { row: any }) => (
        <div className="flex justify-start items-center gap-3">
          <div
            onClick={() => {}}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
          >
            <Link
              to={`/customers/professionals/${row?.original?.customer?.id}/edit`}
              className="cursor-pointer"
            >
              <EditIcon />
            </Link>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const lastIndex = (page - 1) * pageLength;
    setStartIndex(lastIndex);
  }, [page, pageLength, totalCount]);

  const columns = useMemo(() => COLUMN, [userData]);
  const data = useMemo(() => userData || [], [userData]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  return (
    <div className="h-full flex flex-col gap-y-2 pt-2">
      <div className="flex flex-row justify-between items-center w-full px-3 h-[6.5vh] ">
        <div className="flex gap-4">
          <div className="w-[235px]">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Search here"
              value={inputVal}
              rightIcon={<SearchIcon />}
              withDebounce
            />
          </div>
          {/* <div className="w-fit">
            <Link to={"/customers/professionals/create"}>
              <Button label="Create Company" leftIcon={<PlusIcon />} />
            </Link>
          </div> */}
        </div>
      </div>

      <div className="h-[calc(100%-13.2vh)] w-full relative -z-0 ">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>

      {userData.length <= 0 ? (
        ""
      ) : loading ? (
        ""
      ) : (
        <div className="w-full ">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}
    </div>
  );
}

export default CompanyTable;
