import { protectedAxiosInstance } from "./axiosManagement";

// order
export const getProject = (params) => {
  return protectedAxiosInstance
    .get("/admin/projects", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const postProject = (payload) => {
  return protectedAxiosInstance
    .post("/admin/projects", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateProject = (id, payload) => {
  return protectedAxiosInstance
    .put(`/admin/projects/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getSingleProject = (id) => {
  return protectedAxiosInstance
    .get(`/admin/projects/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const deleteProject = (id) => {
  return protectedAxiosInstance
    .delete(`/admin/projects/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getProjectQuicklist = (params) => {
  return protectedAxiosInstance
    .get("/admin/projects/quick-list", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
