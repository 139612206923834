import DocumentIcon from "../../../assets/DocumentIcon";
import ProductCard from "./VariantCard";
import { useRecoilValue } from "recoil";
import { variantsDataCount } from "../../../atom";

const VariantCounts = () => {
  const variantCount = useRecoilValue(variantsDataCount)

  const cardValues = [
    {
      count: variantCount,
      icon: <DocumentIcon color={"#AEAEAE"} />,
      title:"Total Variant Templates"
    },
  ];

  return (
    <div className="w-full flex items-center gap-x-6 px-4 py-2" >
      {cardValues.map((each, index) => (
        <ProductCard
          key={index}
          {...each}
        />
      ))}
    </div>
  );
};

export default VariantCounts;
