import React from "react";
type CircledTickIconProps = {
  color?: string;
  size?: any;
};
const CircledTickIcon = ({ color, size = "18" }: CircledTickIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2668 6.32484L7.69175 9.90817L6.31675 8.53317C6.24205 8.44594 6.15011 8.37509 6.04673 8.32507C5.94334 8.27505 5.83073 8.24694 5.71596 8.24251C5.6012 8.23807 5.48675 8.25741 5.37982 8.29931C5.27288 8.3412 5.17576 8.40475 5.09454 8.48596C5.01333 8.56717 4.94978 8.6643 4.90789 8.77124C4.86599 8.87817 4.84665 8.99262 4.85109 9.10738C4.85552 9.22215 4.88363 9.33476 4.93365 9.43815C4.98367 9.54153 5.05452 9.63347 5.14175 9.70817L7.10009 11.6748C7.17795 11.7521 7.2703 11.8132 7.37183 11.8546C7.47336 11.8961 7.58208 11.9171 7.69175 11.9165C7.91037 11.9156 8.11987 11.8288 8.27509 11.6748L12.4418 7.50817C12.5199 7.4307 12.5819 7.33853 12.6242 7.23698C12.6665 7.13544 12.6883 7.02651 12.6883 6.9165C12.6883 6.80649 12.6665 6.69757 12.6242 6.59602C12.5819 6.49447 12.5199 6.40231 12.4418 6.32484C12.2856 6.16963 12.0744 6.08251 11.8543 6.08251C11.6341 6.08251 11.4229 6.16963 11.2668 6.32484ZM9.00008 0.666504C7.35191 0.666504 5.74074 1.15525 4.37033 2.07092C2.99992 2.9866 1.93182 4.28809 1.30109 5.81081C0.670359 7.33353 0.505331 9.00908 0.826874 10.6256C1.14842 12.2421 1.94209 13.727 3.10753 14.8924C4.27297 16.0578 5.75782 16.8515 7.37433 17.173C8.99084 17.4946 10.6664 17.3296 12.1891 16.6988C13.7118 16.0681 15.0133 15 15.929 13.6296C16.8447 12.2592 17.3334 10.648 17.3334 8.99984C17.3334 7.90549 17.1179 6.82186 16.6991 5.81081C16.2803 4.79976 15.6665 3.8811 14.8926 3.10728C14.1188 2.33346 13.2002 1.71963 12.1891 1.30084C11.1781 0.882052 10.0944 0.666504 9.00008 0.666504ZM9.00008 15.6665C7.68154 15.6665 6.39261 15.2755 5.29628 14.543C4.19996 13.8104 3.34547 12.7692 2.84089 11.5511C2.3363 10.3329 2.20428 8.99244 2.46152 7.69924C2.71875 6.40603 3.35369 5.21814 4.28604 4.28579C5.21839 3.35344 6.40628 2.7185 7.69948 2.46127C8.99269 2.20403 10.3331 2.33606 11.5513 2.84064C12.7695 3.34522 13.8107 4.19971 14.5432 5.29604C15.2758 6.39236 15.6668 7.6813 15.6668 8.99984C15.6668 10.7679 14.9644 12.4636 13.7141 13.7139C12.4639 14.9641 10.7682 15.6665 9.00008 15.6665Z"
        fill={color || "#C3F090"}
      />
    </svg>
  );
};

export default CircledTickIcon;
