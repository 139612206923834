import SideNavigation from "./SideNavigation";
import { MacScrollbar } from "mac-scrollbar";
import { useRecoilState } from "recoil";
import {
  MainAppInitailAtom,
  MainAppSideBarExpandAtom,
  ResizingAtom,
} from "../atom";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Topbar from "../components/Topbar";
import { navItems } from "../utils/NavItems";
import { Outlet, useLocation } from "react-router-dom";
function BaseOutlet() {
  const [sidebarExpand, setSidebarExpand] = useRecoilState(
    MainAppSideBarExpandAtom
  );
  const [containerResizing, setContainerResizing] =
    useRecoilState(ResizingAtom);
  const [initial, setInitial] = useRecoilState(MainAppInitailAtom);
  const location = useLocation();
  useEffect(() => {
    if (initial) {
      setTimeout(() => setInitial(false), 900);
    }
    const width = window.screen.width;
    setSidebarExpand(width > 1280 ? true : false);
  }, [initial]);

  useEffect(() => {
    let timeout: any;
    if (containerResizing) {
      timeout = setTimeout(() => {
        setContainerResizing(false);
      }, 800);
    }
    return () => clearTimeout(timeout);
  }, [containerResizing]);

  // console.log(window.location.pathname, ' window.location.');

  return (
    <>
      <MacScrollbar>
        <div className="h-screen overflow-y-auto overflow-x-hidden ">
          <SideNavigation
            navItems={navItems}
            sidebarExpand={sidebarExpand}
            setSidebarExpand={setSidebarExpand}
            containerResizing={containerResizing}
            setContainerResizing={setContainerResizing}
          />
          <motion.div
            animate={sidebarExpand ? { paddingLeft: 234 } : { paddingLeft: 48 }}
            transition={{ duration: 0.4 }}
          >
            <div className="h-10 sticky top-0 z-[43]">
              <Topbar initial={initial} />
            </div>
            <motion.div
              key={location.pathname}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
              className="h-[calc(100vh-2.5rem)] custom-scrollbar overflow-y-auto"
            >
              <Outlet />
            </motion.div>
          </motion.div>
        </div>
      </MacScrollbar>
    </>
  );
}

export default BaseOutlet;
