import { protectedAxiosInstance } from "./axiosManagement";

export const getProductCategory = (params) => {
  return protectedAxiosInstance
    .get("/admin/products/category", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const getProductSingleCategory = (id) => {
  return protectedAxiosInstance
    .get(`/admin/products/category/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postProductCategory = (payload) => {
  return protectedAxiosInstance
    .post("/admin/products/category", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateProductCategory = (id, payload) => {
  return protectedAxiosInstance
    .put(`/admin/products/category/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};


export const deleteProductCategory = (id) => {
  return protectedAxiosInstance
    .delete(`/admin/products/category/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
