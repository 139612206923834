export default function TrackTypePreview() {
  return (
    <div className="flex flex-col gap-3 text-left ">
      <div>
        <p className="text-[#AEAEAE] text-md">Preview:</p>
      </div>
      <div className="w-[130px] border">
        <div className="flex flex-col gap-4 pb-[100%] w-full  relative">
          <div className="border-[6px] border-[#DEDEDE] w-[60%] h-[60%] absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 "></div>
        </div>
        <table className="w-full">
          <tbody>
            <tr className="border-y ">
              <td className="w-[70%]">
                <div className="w-[40px] h-[14px] bg-[#DEDEDE] rounded-sm float-right m-2"></div>
              </td>
              <td>
                <div className="w-[12px] h-[12px] bg-[#DEDEDE] rounded-full"></div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="w-[45px] h-[14px] bg-[#DEDEDE] rounded-sm float-right m-2 "></div>
              </td>
              <td>
                <div className="w-[20px] h-[14px] bg-[#DEDEDE] rounded-sm"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
