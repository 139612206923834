import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, targetPathAtom } from "../atom";

const PrivateRoute = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authState, setAuthState] = useRecoilState(authAtom);
  const [pathName, setPathName] = useRecoilState(targetPathAtom);

  useEffect(() => {
    if (!authState.is_auth && location.pathname && pathName) {
      setPathName(location.pathname);
      navigate("/login");
    }
  }, [authState, location, navigate, setPathName, pathName]);

  return <main>{children}</main>;
};

export default PrivateRoute;
