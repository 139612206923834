import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useRecoilValue } from "recoil";
import useOnClickOutside from "../hooks/useClickOutside";
import HipchatChevronDownIcon from "../assets/HipchatChevronDownIcon";
import { MainAppSideBarExpandAtom } from "../atom";
import SearchIcon from "../assets/SearchIcon";
import InputField from "./InputField";

type Option = {
  value?: string;
  label?: string;
};

type SelectFieldProps = {
  label?: string;
  options: Option[];
  userSearch?: string;
  setUserSearch?: (value: string) => void;
  value: string | undefined;
  onChange: (value: string) => void;
  placeholder?: string;
  fullWidth?: boolean;
  limit?: number;
  disable?: boolean;
};

const SearchableDropdown = ({
  options,
  value,
  label,
  userSearch = "",
  setUserSearch,
  onChange,
  placeholder = "Select an option",
  fullWidth = false,
  limit = 6,
  disable = false,
}: SelectFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const [currentVal, setCurrentVal] = useState<String>("");
  const [inputVal, setInputVal] = useState(userSearch);
  useOnClickOutside(selectRef, () => setIsOpen(false));
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);

  const disabledStyle = `opacity-30 border-[#DEDEDE] hover:border-2 outline-none focus:bg-white focus:border-pot-yellow text-[#c3c3c3] cursor-not-allowed`;

  useEffect(() => {
    setInputVal(userSearch);
  }, [userSearch]);

  const handleSelectClick = () => {
    if (!disable) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (option: Option): void => {
    setIsOpen(false);
    const selectedValue = option.value || "";
    onChange(selectedValue);
    setCurrentVal(option.label || "");
  };
  useEffect(() => {
    if (value && options) {
      const selectedOption = options.find((option) => option.value === value);
      if (selectedOption) {
        setCurrentVal(selectedOption.label || "");
      }
    }
  }, [value, options]);

  // Added this condition which will remove the selected value if the value in the props is undefined
  useEffect(() => {
    const timer = setTimeout(() => {
      if (value === undefined) {
        setCurrentVal("");
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [value]);

  return (
    <div
      ref={selectRef}
      className={`relative flex flex-col justify-start items-start ${
        fullWidth ? "w-full" : "min-w-[13.5rem] max-w-[100%]"
      }`}
    >
      {label && (
        <p
          className={` font-gilroy-semi-bold text-[12px] pb-2 px-1 flex justify-start`}
        >
          {label}
        </p>
      )}
      <div
        onClick={handleSelectClick}
        className={`${
          disable
            ? disabledStyle
            : "border-[#DEDEDE] hover:border-2 hover:border-[#D4A15E] hover:border-opacity-50 outline-none focus:bg-white focus:border-pot-yellow"
        } w-full flex justify-between items-center px-4 py-[0.4vw] bg-white rounded-md cursor-pointerp-2 border-2 `}
      >
        <span
          className={`block truncate font-gilroy-regular text-[14px]  ${
            sidebarExpand ? "max-w-[80%]" : "max-w-[80%]"
          }`}
        >
          {currentVal !== "" ? (
            currentVal
          ) : (
            <span className="text-[#AEAEAE] text-sm">{placeholder}</span>
          )}
        </span>

        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          className="w-fit h-fit flex items-center justify-center"
        >
          <HipchatChevronDownIcon color="black" />
        </motion.div>
      </div>
      {isOpen && (
        <div className="w-full bg-white border-2 mt-1 z-50 text-start overflow-y-auto no-scrollbar absolute top-[100%]">
          <li className="flex sticky top-0 bg-white">
            <span
              className={`relative w-[max-content] h-[max-content] inset-y-0 left-0 flex items-center m-2 pointer-events-none justify-center`}
            >
              <SearchIcon />
            </span>
            <input
              required
              value={inputVal}
              placeholder={placeholder}
              onChange={(e) => {
                setInputVal(e.target.value);
                setUserSearch && setUserSearch(e.target.value);
              }}
              className={`text-[13px] w-full px-2 py-1.5 outline-none`}
            />
          </li>
          <ul className={`h-auto max-h-72`}>
            {options?.slice(0, limit)?.map((option) => (
              <li
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className={`px-4 py-2 cursor-pointer hover:bg-gray-100 font-gilroy-regular text-xs border-t-2 leading-5 ${
                  option.value === value ? "bg-gray-100" : ""
                }`}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
