import React, { useState, useEffect, useRef, useCallback } from "react";
import { debounce } from "lodash";
import { kMaxLength } from "buffer";
import ErrorMessage from "./ErrorMessage";

export type TextAreaFieldType = {
  label?: string;
  value?: string;
  hint?: string | string[];
  error?: string;
  rows?: number;
  long?: boolean;
  onChange: (text: string) => void;
  rightIcon?: React.ReactNode | null;
  leftIcon?: React.ReactNode | null;
  className?: string;
  placeholder?: string;
  variant?: "primary";
  disabled?: boolean;
  withDebounce?: boolean;
  doNotChangeInput?: boolean;
  required?: boolean;
  validate?: (value: string) => string | undefined | false;
  targetRef?: any;
};

const primaryTextAreaStyle =
  "text-black font-normal border-2 border-[#DEDEDE] hover:border-2 hover:border-[#D4A15E] hover:border-opacity-50 outline-none focus:bg-white focus:border-pot-yellow placeholder-shown:text-pot-grey2 active:border-pot-yellow active:border-2";

const TextAreaDisabledStyle =
  "border-2 border-[#F4F2EE] text-[#b4b4b4] disabled:appearance-none focus:outline-none disabled:opacity-100 cursor-not-allowed bg-white";

const TextArea = ({
  variant = "primary",
  error = "",
  label = "",
  value = "",
  rows = 2,
  long = false,
  onChange,
  hint,
  leftIcon = null,
  rightIcon = null,
  className = "",
  placeholder = "",
  disabled = false,
  withDebounce = false,
  doNotChangeInput = false,
  required = false,
  validate = (val) => val,
  targetRef,
}: TextAreaFieldType) => {
  const [inputVal, setInputVal] = useState<string>(value);
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputVal !== value) {
      setInputVal(value);
    }
  }, [value]);

  const optimisedCall = useCallback(
    debounce((inputVal) => onChange(inputVal), 1000),
    []
  );

  useEffect(() => {
    if (withDebounce) {
      optimisedCall(inputVal);
    }
  }, [inputVal]);

  const typeStyle = variant === "primary" ? primaryTextAreaStyle : "";

  const disabledStyle = TextAreaDisabledStyle;

  return (
    <div className={`${className}  `} ref={targetRef}>
      {label && (
        <p className="font-gilroy-semi-bold text-[12px] pb-1 px-1 flex justify-start">
          {label}
          {required && <span className="text-red-500 text-xs ml-1">*</span>}
        </p>
      )}

      <div className="relative">
        <div
          className={`relative w-full flex justify-between items-center ${
            !disabled ? "" : "opacity-100"
          } ${focusInput && "border-pot-darkmaroon"}  ease-in-out transition`}
        >
          {leftIcon && (
            <div
              className={`absolute inset-y-0 left-0 flex items-center pl-2 ml-2 pointer-events-none `}
            >
              {leftIcon}
            </div>
          )}
          <textarea
            disabled={disabled}
            required
            ref={inputRef}
            maxLength={1000}
            value={inputVal}
            rows={rows}
            placeholder={placeholder}
            onFocus={() => setFocusInput(true)}
            onBlur={() => setFocusInput(false)}
            onChange={(e) => {
              if (!withDebounce) {
                if (doNotChangeInput) return;
                if (inputRef.current) {
                  if (!validate(e.target.value)) {
                    if (e.target.value === "") {
                      inputRef.current.value = "";
                      setInputVal("");
                      onChange("");
                    }
                    return;
                  }
                  inputRef.current.value = validate(e.target.value) as string;
                }
                setInputVal(validate(e.target.value) as string);
                onChange(validate(e.target.value) as string);
              } else {
                setInputVal(validate(e.target.value) as string);
              }
            }}
            className={`text-[13px] w-full rounded-md custom-scrollbar  ${
              leftIcon ? "pl-10" : "pr-10"
            }  px-2 py-1.5 ${disabled ? `${disabledStyle} ` : typeStyle} `}
          />
          {rightIcon && (
            <div
              className={`absolute right-0 flex items-center mr-3 place-content-around`}
            >
              {rightIcon}
            </div>
          )}
        </div>
      </div>

      <ErrorMessage error={hint} />
    </div>
  );
};

export default TextArea;
