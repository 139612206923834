import React from "react";
import Card from "./OrderCard";

const Counts = ({ orderStatusSummary, orderStatus }: any) => {
  return (
    <div className="overflow-auto flex justify-between gap-x-6 px-4 py-2 w-full  overflow-x-auto no-scrollbar">
      <Card orderStatus={orderStatus} orderStatusSummary={orderStatusSummary} />
    </div>
  );
};

export default Counts;
