import React, { useState } from "react";


type DownTriangleProps = {
  stroke?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

const DownTriangle = ({
  stroke = "black",
  onClick = () => {},
  className = "",
  disabled = false,
}:DownTriangleProps) => {
  const [triangleFill, setTriangleFill] = useState("none");

  const handleMouseOver = () => {
    setTriangleFill(stroke);
  };

  const handleMouseOut = () => {
    setTriangleFill("none");
  };
  return (
    <svg
      className={`${className} ${
        disabled ? "opacity-30 cursor-not-allowed" : "hover:scale-125"
      }`}
      onClick={onClick}
      onMouseOver={disabled ? () => {} : handleMouseOver}
      onMouseOut={disabled ? () => {} : handleMouseOut}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill={triangleFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.19103 10.4591C6.90039 10.9625 6.17379 10.9625 5.88315 10.4591L1.30559 2.53057C1.01495 2.02717 1.37825 1.39792 1.95953 1.39792H11.1147C11.6959 1.39792 12.0592 2.02717 11.7686 2.53057L7.19103 10.4591Z"
        stroke={stroke}
        strokeWidth="1.13265"
      />
    </svg>
  );
};

export default DownTriangle;
