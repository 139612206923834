import React, { useState } from "react";
import HourGlassIcon from "../../../assets/HourGlassIcon";
import CategoryCard from "./Card";
import SeriesIcon from "../../../assets/SeriesIcon";
import { useRecoilValue } from "recoil";
import { categoryDataCount } from "../../../atom";
const CategoryCounts = () => {
  const categoryCount = useRecoilValue(categoryDataCount)


  const cardValues = [
    {
      count: categoryCount,
      icon: <SeriesIcon color='#AEAEAE'/>,
      color: "#AEAEAE",
    },
  ];

  return (
    <div className="w-full flex  gap-x-6 px-4 py-2">
      {cardValues.map((each, index) => (
        <CategoryCard
          key={index}
          {...each}
        />
      ))}
    </div>
  );
};

export default CategoryCounts;
