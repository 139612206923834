import React from "react";
import { CustomDropdown } from "../../DropDown";
import editPen from "../../../../assets/svg/pen.svg";
import { Link } from "react-router-dom";

const Projects = [
  {
    createdDate: "25/05/2023",
    location: "Vadodara, Gujrat",
    totalItems: 700,
    proffessionals: "Aishwarya Chatterjee, Nikhil Sen, Riya Agarwal",
  },
  {
    createdDate: "25/05/2023",
    location: "Vadodara, Gujrat",
    totalItems: 700,
    proffessionals: "Aishwarya Chatterjee, Nikhil Sen, Riya Agarwal",
  },
  {
    createdDate: "25/05/2023",
    location: "Vadodara, Gujrat",
    totalItems: 700,
    proffessionals: "Aishwarya Chatterjee, Nikhil Sen, Riya Agarwal",
  },
  {
    createdDate: "25/05/2023",
    location: "Vadodara, Gujrat",
    totalItems: 700,
    proffessionals: "Aishwarya Chatterjee, Nikhil Sen, Riya Agarwal",
  },
  {
    createdDate: "25/05/2023",
    location: "Vadodara, Gujrat",
    totalItems: 700,
    proffessionals: "Aishwarya Chatterjee, Nikhil Sen, Riya Agarwal",
  },
  {
    createdDate: "25/05/2023",
    location: "Vadodara, Gujrat",
    totalItems: 700,
    proffessionals: "Aishwarya Chatterjee, Nikhil Sen, Riya Agarwal",
  },
  {
    createdDate: "25/05/2023",
    location: "Vadodara, Gujrat",
    totalItems: 700,
    proffessionals: "Aishwarya Chatterjee, Nikhil Sen, Riya Agarwal",
  },
  {
    createdDate: "25/05/2023",
    location: "Vadodara, Gujrat",
    totalItems: 700,
    proffessionals: "Aishwarya Chatterjee, Nikhil Sen, Riya Agarwal",
  },
];

export default function ProjectDetails() {
  return (
    <>
      <CustomDropdown createButton="Add project" label="Project Details">
        <div className="grid grid-cols-3">
          {Projects?.map((el, i) => {
            return (
              <div key={i} className=" border-x border-y px-5 py-5">
                <div className="flex justify-between h-[40px] ">
                  <Link to={"#"}>
                    <p className=" underline text-[14px] font-medium">
                      Project {i + 1}
                    </p>
                  </Link>
                  <div>
                    <img src={editPen} alt="edit-pen" />
                  </div>
                </div>

                <div className="flex flex-col text-[11px] leading-5 tracking-[-0.08px] text-start">
                  <div className="flex space-x-6">
                    <p>Created on: </p>
                    <span className="">{el.createdDate}</span>
                  </div>
                  <div className="flex  space-x-9">
                    <p>Location: </p>
                    <span className="">{el.location}</span>
                  </div>
                  <div className="flex  space-x-6">
                    <p>Total items: </p>
                    <span className="text-start"> {el.totalItems}</span>
                  </div>
                  <div className="flex  space-x-2">
                    <p>Proffessionals:</p>
                    <span className="">{el.proffessionals}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </CustomDropdown>
    </>
  );
}
