import React, { CSSProperties } from "react";

import { ScaleLoader } from "react-spinners";

// npm install react-spinners
// npm install @emotion/react@11.5.0

interface LoadingProps {
  loading?: boolean;
  size?: number;
  color?: string;
  position?: number[];
}

const override: CSSProperties = {
  display: "inline",
  margin: "0 auto",
};

const ScaleLoading = ({
  loading = true,
  size = 20,
  color = "#D4A15E",
}: LoadingProps) => {
  return (
    <ScaleLoader
      speedMultiplier={1}
      color={color}
      loading={loading}
      cssOverride={override}
      size={size}
    />
  );
};

export default ScaleLoading;
