// /admin/order/{id]/transaction

import { protectedAxiosInstance } from "./axiosManagement";

export const getPaymentDetails = (id) => {
  return protectedAxiosInstance
    .get(`/admin/orders/${id}/transaction`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const postPaymentStatus = (id, payload) => {
  return protectedAxiosInstance
    .post(`/admin/orders/${id}/change-payment-status`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
