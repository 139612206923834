import React from "react";

interface FileIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const FileIcon = ({
  color = "#1C1C1C",
  width = "14",
  height = "18",
}: FileIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6668 6.46595C13.6581 6.3894 13.6414 6.31398 13.6168 6.24095V6.16595C13.5768 6.08027 13.5233 6.0015 13.4585 5.93262L8.4585 0.932617C8.38961 0.867797 8.31085 0.814351 8.22516 0.774284H8.15016C8.06551 0.725735 7.97202 0.694571 7.87516 0.682617H2.8335C2.17045 0.682617 1.53457 0.946009 1.06573 1.41485C0.596888 1.88369 0.333496 2.51958 0.333496 3.18262V14.8493C0.333496 15.5123 0.596888 16.1482 1.06573 16.6171C1.53457 17.0859 2.17045 17.3493 2.8335 17.3493H11.1668C11.8299 17.3493 12.4658 17.0859 12.9346 16.6171C13.4034 16.1482 13.6668 15.5123 13.6668 14.8493V6.51595C13.6668 6.51595 13.6668 6.51595 13.6668 6.46595ZM8.66683 3.52428L10.8252 5.68262H9.50016C9.27915 5.68262 9.06719 5.59482 8.91091 5.43854C8.75463 5.28226 8.66683 5.0703 8.66683 4.84928V3.52428ZM12.0002 14.8493C12.0002 15.0703 11.9124 15.2823 11.7561 15.4385C11.5998 15.5948 11.3878 15.6826 11.1668 15.6826H2.8335C2.61248 15.6826 2.40052 15.5948 2.24424 15.4385C2.08796 15.2823 2.00016 15.0703 2.00016 14.8493V3.18262C2.00016 2.9616 2.08796 2.74964 2.24424 2.59336C2.40052 2.43708 2.61248 2.34928 2.8335 2.34928H7.00016V4.84928C7.00016 5.51233 7.26355 6.14821 7.7324 6.61705C8.20124 7.08589 8.83712 7.34928 9.50016 7.34928H12.0002V14.8493Z"
        fill={color}
      />
    </svg>
  );
};

export default FileIcon;
