import { useState } from "react";
import ConsultantCard from "./ConsultantCard";
import Thumbsup from "../../../assets/Thumbsup";
import HourGlassIcon from "../../../assets/HourGlassIcon";
import CircledTickIcon from "../../../assets/CircledTickIcon";
import ClockIcon from "../../../assets/ClockIcon";

const ConsultantCounts = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    // setIsHovered(true);
  };

  const handleMouseLeave = () => {
    // setIsHovered(false);
  };

  const cardValues = [
    {
      count: 150,
      icon: <HourGlassIcon color={!isHovered ? "#C3E1F8" : "#ffffff"} />,
      color: "#C3E1F8",
    },
    {
      count: 250,
      icon: <ClockIcon color={!isHovered ? "#81C784" : "#ffffff"}/>,
      color: "#EADA4A",
    },
    {
      count: 250,
      icon: <Thumbsup color={!isHovered ? "#81C784" : "#ffffff"} />,
      color: "#81C784",
    },
    {
      count: 250,
      icon: <CircledTickIcon color={!isHovered ? "#C3F090" : "#ffffff"} />,
      color: "#C3F090",
    },
  ];

  return (
    <div className="w-full flex  gap-x-6 px-4 py-2" >
      {cardValues.map((each, index) => (
        <ConsultantCard
          key={index}
          {...each}
          setIsHovered={setIsHovered}
          isHovered={isHovered}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      ))}
    </div>
  );
};

export default ConsultantCounts;
